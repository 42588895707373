import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import '../Boq/Boq.css'
import View from '../Modal/View'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import cancelledimg from '../../assets/images/brick_icons/Support.png'
import acceptedimg from '../../assets/images/brick_icons/Support.png'
import pendingimg from '../../assets/images/brick_icons/Dashboard.png'
import { connect, useDispatch, useSelector } from 'react-redux'
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const [date, setDate] = useState('')
  const [dateValid, setDateValid] = useState(false)
  const [dateerror, setDateerror] = useState('')
  const [todate, setTodate] = useState('')
  const [todateValid, setTodateValid] = useState(false)
  const [todateerror, setTodateerror] = useState('')
  const [calculatedlist, setCalculatedlist] = useState([])
  const userData = useSelector((state) => state.userDetails)
  const [data1, setData1] = useState([]);
  const [status, setStatus] = useState(false)
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    }, [])


    function handledate(e) {
        //console.log(e.target.value)
        
        setDate(e.target.value.replace(/\s+/g, ''))
      }

      function handletodate(e) {
        //console.log(e.target.value)
        
        setTodate(e.target.value.replace(/\s+/g, ''))
      }

  function onSubmitEvent(event) {
    event.preventDefault();
    
    //const convertedDate = convertDateFormat(date);
     

    // if (dateValid &&materialtypeValid && statusValid  
      //  ) {

     

        // let userData10 = new FormData()
        // userData10.append('material_type',materialtype )
        // userData10.append('date', convertedDate)
        // userData10.append('status', status)
        // userData10.append('project_id', userData.projectId)


        axios({
          method: 'get',
  
          url: baseurl + '/api/chainage_progress/history?project_id='+userData.currentprojectId+'&start_date='+date
          +'&end_date='+todate,

          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + userData.token,
          }
        })
          .then((response) => {
        
            const res = response.data
            const res1=res.structure_data
           setStatus(true)

          setCalculatedlist(res1)
          })
          .catch((error) => {
         
            //console.log("search error",error)
           
          });
    //  }
  
  }
  return (
    <>
      
        {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
        {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3 pagetopspace">
            <div class="row pt-5">
<div class="col-xl-3 col-lg-3">

</div>
<div class="col-xl-6 col-lg-6 ">
              <label for="formFileLg" className="login-text ">
              From Date
              </label>
              <center>
                <InputTextField
                  handleInputChange={handledate}
                  valueText={date}
                  inputName="date"
                  inputId="date"
                  classname="project-box"
                 
                  inputType="date"
                ></InputTextField>

                <div className="login-error">{dateerror}</div>
              </center>
              </div>
              <div class="col-xl-3 col-lg-3">

</div>
            {/* {//////alert(date)} */}
          <div class="col-xl-3 col-lg-3 ">

</div>
<div class="col-xl-6 col-lg-6 pt-3">
              <label for="formFileLg" className="login-text ">
                To Date
              </label>
              <center>
                <InputTextField
                  handleInputChange={handletodate}
                  valueText={todate}
                  inputName="todate"
                  inputId="todate"
                  classname="project-box"
                 
                  inputType="date"
                ></InputTextField>

                <div className="login-error">{dateerror}</div>
              </center>
              </div>
              <div class="col-xl-3 col-lg-3">

</div>
         </div> 
            </div>
            <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-4 ">
         
         <center>
           <button
             type="submit"
             className="project-btn "
             name="submit"
             onClick={onSubmitEvent}
             // disabled={!(dateValid && materialtypeValid && statusValid )}
           >
             <b>Search</b>
           </button>

         </center>{' '}
       </div>
       
                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 col-xxl-12 pt-3 pb-5">
             { status &&  calculatedlist.length != 0 &&  date.length!=0 &&<div><h5>&nbsp;&nbsp;&nbsp;<b>From Date:</b>&nbsp;{date}</h5> </div>}
             { status &&  calculatedlist.length != 0 &&  todate.length!=0 &&<div><h5>&nbsp;&nbsp;&nbsp;<b>To Date:</b>&nbsp;{todate}</h5> </div>}
                {calculatedlist.length == 0 && status &&
                <div class=" login-error pt-1"><center><h6><b>No Data Available</b></h6></center></div>}
                {calculatedlist.length != 0 && 
              <div className=' scrollabletable'>
               <table
              class="table "
              readOnly="readOnly"

            >
              <tr class="trst">
                <th>Structure</th>
                <th>Item of Work</th>
                <th>Level Worked</th>
                <th>Status</th>
                <th>Workers</th>
                <th>Resources</th>
         
                
              </tr>
            
           {calculatedlist.length != 0 && calculatedlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>
                        <td>{item.structure_id}</td>
                    <td><div className='trpad'>{item.item_of_work}</div></td>
                  
                    <td>{item.completed_chainage}</td>
                    <td>{item.completion_status}</td>
                    <td>
                      {/* {item.workers_list.join(', ')} */}
                      {item.workers_list.length!=0 ?
                      <ul>
                {item.workers_list.map((worker, workerIndex) => (
                  <li key={workerIndex}>{worker}</li>
                ))}
              </ul>:'NA'}
                    </td>
                    <td>  
                    {item.resources_list.length!=0 ?
                    <ul>
                {item.resources_list.map((worker, workerIndex) => (
                  <li key={workerIndex}>{worker}</li>
                ))}
              </ul>:'NA'}
                   
         </td>
                 
                   
                   
                   
             
                  
                  </tr>
                )
              })}
            </table>
            </div>}

            {/* <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                          <center>
                            <Link to="/UserCreation">
                            <button
                              type="submit"
                              className="user-btn"
                              name="submit"
                            // disabled={!(projectstatusValid )}
                            >
                              <b>Next</b>
                            </button>
                        
                          </Link>
                          </center>{' '}
                        </div> */}
        
          </div>
      
      
          
  

    
    </>
  )
}

export default FormPage
