import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect, useRef } from 'react';
import './SupportCreation.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'


import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
function FormPage() {
  const fileInputRef = useRef(null);

  const [successstatus, setSuccessstatus] = useState(false)

  const [companyname, setCompanyname] = useState('')
  const [companynameValid, setCompanynameValid] = useState(false)
  const [companynameerror, setCompanynameerror] = useState('')

  const [vendortype, setVendortype] = useState('')
  const [vendortypeValid, setVendortypeValid] = useState(false)
  const [vendortypeerror, setVendortypeerror] = useState('')

  const [resourcetype, setResourcetype] = useState('')
  const [resourcetypeValid, setResourcetypeValid] = useState(false)
  const [resourcetypeeerror, setResourcetypeerror] = useState('')
  
  const [onboardingdate, setOnboardingdate] = useState('')
  const [onboardingdateValid, setOnboardingdateValid] = useState(false)
  const [onboardingdateeerror, setOnboardingdateerror] = useState('')

  const [contactno, setContactno] = useState('')
  const [contactnoValid, setContactnoValid] = useState(false)
  const [contactnoerror, setContactnoerror] = useState('')

  const [description, setDescription] = useState('')
  const [descriptionValid, setDescriptionValid] = useState(false)
  const [descriptionerror, setDescriptionerror] = useState('')
 
  const [contactperson, setContactperson] = useState('')
  const [contactpersonValid, setContactpersonValid] = useState(false)
  const [contactpersonerror, setContactpersonerror] = useState('')

  const [summary, setSummary] = useState('')
  const [summaryValid, setSummaryValid] = useState(false)
  const [summaryerror, setSummaryerror] = useState('')

  const [successmsg, setSuccessmsg] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);

  const userData = useSelector((state) => state.userDetails)

    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, []);

    function handleCompanyname(e) {
      setCompanyname(e.target.value.replace(/\s+/g, ''))
      validateField('companyname', e.target.value)
    }
    function handleImageUpload(e) {

      setSuccessmsg('')
      const files = [...e.target.files]; 
     // ////////////alert(files) // Spread operator to convert FileList to an array
      setSelectedFiles([...selectedFiles, ...files]);
    }
    function handleDeleteFile(index) {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index, 1);
      setSelectedFiles(updatedFiles);
    
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
        fileInputRef.current.files = updatedFiles;
      }
    }
    
  function handleVendortype(e) {
    setVendortype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleResourceType(e) {
    setSuccessmsg('')
    setResourcetype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)

  }

  function handleOnboardingdate(e) {
    setOnboardingdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleDeleteFile(index) {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  }
 
  function handleDescription(e) {
    setSuccessmsg('')
    setDescription(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  
  
  function handleSummary(e) {
    setSuccessmsg('')
    setSummary(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }


  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {

  
      if (fieldName === 'resourcetype') {
        if (value.length != 0) {
          setResourcetypeerror('')
          setResourcetypeValid(true)
        } else {
          setResourcetypeerror('Please enter your type')
          setResourcetypeValid(false)
        }
      }
   
  }
  

  function onSubmitEvent(event) {
    event.preventDefault();
    
    // if (resourcetypeValid && summaryValid && description ) {

   // ////alert("Success created")

     
        

  //       axios.post(baseurl + '/api/support/issue/ticket/raise?ticket_type='+resourcetype+'&summary='+summary+
  //       '&issue_description='+description +'&client_id='+userData.clientId
  //  //     +'&ticket_document='+selectedFiles
  //       ,  {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //       })

        axios({
          method: 'POST',
  
          url: baseurl+'/api/support/issue/ticket/raise?ticket_type='+resourcetype+'&summary='+summary+
          '&issue_description='+description +'&client_id='+userData.clientId,
  
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + userData.token
          }
        })
          .then((response) => {
            setSuccessstatus(true)
            setSuccessmsg("Successfully Created")
            setResourcetype('')
            setSummary('')
            setDescription('')
          })
          .catch((error) => {
           
          });
  //  }
  
  }
  
  return (

    
        <div className="cont-margin1">

            <div class="cont-margin-latest ">
                <div className='table1bdstyle'>
              <div className=' row table1st pt-3'>

               
           
                
                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12 pb-5 pt-5 ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={onSubmitEvent}
                  >

                    <div class="row">

                 
                
                <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                    Ticket Type
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleResourceType}
                          valueText={resourcetype}
                          inputName="resourcetype"
                          inputId="resourcetype"
                          classname="project-box"
                          maxLen={10}
                          inputType="text"
                          placeholderValue="Ticket Type"

                        ></InputTextField>
     </center>
                        <div className="login-error">{resourcetypeeerror}</div>
                   
                      </div>
                    
                    
                     

                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                    Summary
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleSummary}
                          valueText={summary}
                          inputName="summary"
                          inputId="summary"
                          classname="project-box"                          
                          inputType="text"
                          placeholderValue="Summary"

                        ></InputTextField>

                        <div className="login-error">{summaryerror}</div>
                        </center>
                      </div>





               
                     
                     
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                    Description
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleDescription}
                          valueText={description}
                          inputName="description"
                          inputId="description"
                          classname="project-box"                          
                          inputType="text"
                          placeholderValue="Description"

                        ></InputTextField>

                        <div className="login-error">{descriptionerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
      <label for="formFileLg" className="login-text">
        Upload Document
      </label>
      <input
        type="file"
        className="project-box"
        id="imageUpload"
        name="imageUpload"
        accept="image/*"
        multiple
        onChange={handleImageUpload}
      />
    </div>

    {selectedFiles.length > 0 && (
      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
        <label className="login-text">Selected Files</label>
        <ul>
        {selectedFiles.map((file, index) => (
        <li key={index} class="pb-2">
          {file.name} &nbsp;&nbsp;&nbsp;
          <button
            type="button"
            onClick={() => handleDeleteFile(index)}
            className="user-btn"
          >
            Delete
          </button>
        </li>
      ))}
        </ul>
      </div>
    )}

                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                   
                      <center>

                      <div className="success pb-3">{successmsg}</div>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                        
                        >
                          <b>Submit</b>
                        </button>
                       
                      </center>{' '}
                
                        
                    </div>
                  
                  </form>
                </div>
              
              
            </div>
            </div>
              
              </div>
            </div>
    );
                          }
  
  export default FormPage;

