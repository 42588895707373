import React from 'react';
import RadialBar from '../ApexCharts/RadialBar';
import './ViewVendorPjts.css';

import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaStarAndCrescent } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from 'react'
import RequestModal from '../Modal/CreateRequest'
import StatusupdateModal from '../Modal/Edit'
import Renewal from '../Modal/Renewal'
import ViewTickets from '../Modal/ViewTickets'
import { baseurl } from '../BaseUrl/Baseurl'
import completed from '../../assets/images/completed-5.png'
import inprogress from '../../assets/images/buttons/inprogress.png'
import received from '../../assets/images/buttons/completed.png'
import delayed from '../../assets/images/buttons/delayed.png'
// import  cancelled from '../../assets/images/buttons/cancelled.png'
import sandcorrect from '../../assets/images/buttons/sandcorrect.png'
import brickcorrect from '../../assets/images/buttons/brickcorrect.png'
import stonescorrect from '../../assets/images/buttons/stonescorrect.png'
import ironrodcorrect from '../../assets/images/buttons/ironrodcorrect.png'
import cementcorrect from '../../assets/images/buttons/cementcorrect.png'
import ordered from '../../assets/images/buttons/ordered.png'
import sandhead from '../../assets/images/resources/sandhead.png'
import brick from '../../assets/images/resources/brick.png'
import cement from '../../assets/images/resources/cement.png'
import ironrod from '../../assets/images/resources/ironrod.png'
import sand from '../../assets/images/resources/sand.png'
import stones from '../../assets/images/resources/stones.png'
import tiles from '../../assets/images/resources/tiles.png'
import LineChart from '../ApexCharts/LineChart'
import LineChart1 from '../ApexCharts/LineChart1'
import ButtonLink from '../ButtonLink/ButtonLink';
import EditVendor from './EditVendor';
import $ from 'jquery';
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import { useLocation } from "react-router-dom"
import DocumentView from '../Modal/DocumentView'
const WeareHere = () => {
  const [structureName, setStructureName] = useState('')
  const [structureId, setStructureId] = useState('')
  const [vendorName, setVendorName] = useState('')

  const [vendorStatus, setVendorStatus] = useState(false)
  const [vendorwork, setVendorwork] = useState('')
  const [itemName, setItemName] = useState('')
  const [compDate, setCompDate] = useState('')
  const [itemOfWork, setItemOfWork] = useState('')
  const [fulldata, setFulldata] = useState([]);
  const [btnname, setBtnname] = useState('')
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [caxis, setCaxis] = useState([]);
  const [daxis, setDaxis] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [graphform, setGraphform] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [imagelist, setImagelist] = useState('');
  const [list, setList] = useState([]);
  const [projectname, setProjectname] = useState(false);
  const userData = useSelector((state) => state.userDetails)
  const [successstatus, setSuccessstatus] = useState(false);


  const location = useLocation()
  const vendorId = location.state?.vendorID


  useEffect(() => {
    console.log("hai1")
    // ////alert(userData.projectId)
    axios({
      method: 'GET',

      url: baseurl + '/api/vendor/get/eachvendor/orderandquantity?contractor_id=' + vendorId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })

      .then((response) => {

        console.log(response.data)
        const RequestData = response.data
        // setRequestdata1(RequestData)
        console.log("request", RequestData)
        const dt = RequestData
        setList(dt)
        setSuccessstatus(true)
      })
      .catch((error) => {

        console.log(error)
        // setErrormsg(error.response.data.message)
      })



    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })


  }, [])

  const toggleRenewalStatus = () => {
    setModalStatus(false);
  };

  function documentClick() {

    axios({
      method: 'GET',

      url: baseurl + '/api/vendor/get/document?contractor_id=' + vendorId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        console.log(response.data)
        const data = response.data
        setImagelist(data)
        setModalStatus(true);
      })
      .catch((error) => {
        console.log(error)
        // setErrormsg(error.response.data.message)
      })


  };
  function ViewOrder(projectname) {
    setProjectname(projectname)
    setModalStatus(true);


  };
  return (
    <>
      {vendorStatus && <ButtonLink btnPath="/EditVendor" vendorName={vendorName} vendorwork={vendorwork} vendorStatus={vendorStatus} />}
      {modalStatus && <ButtonLink btnPath="/ViewOrder" vendorID={vendorId} projectName={projectname} />}

      <div className="cont-margin1">
        <div class="cont-margin-latest pt-2 ">
          <div className='table1bdstyle'>
            <div class="row pt-5 padleft">


              <Link to="/Vendor">
                <button
                  type="submit"
                  className="contractor-btn1"
                  name="submit"
                  float="right"

                >
                  <b>Back</b>
                </button>
              </Link>


            </div>
            <div class="row ">


              {list.length == 0 && successstatus &&
                // !checkduedate &&

                <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pt-3 pb-3">
                  <center><b><h6>No Data Available</h6></b></center>

                </div>}
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 ">
                {list.length !== 0 &&

                  <div class="row">
                    <div class=" col-lg-1 col-xl-1" ></div>
                    <div class="col-12 col-md-12 col-lg-10 col-xl-10 ">  <h5 ><b>Vendor Projects</b></h5></div>
                    <div class=" col-lg-1 col-xl-1" ></div>

                    <div class=" col-lg-1 col-xl-1" ></div>
                    <div class="col-12 col-md-12 col-lg-10 col-xl-10" >
                      <table
                        class="table"
                        readOnly="readOnly"


                      >
                        <tr class='trst'>

                          <th>Project Name</th>
                          <th>Resource Type</th>
                          <th>Delivered Quantity</th>
                          <th>Pending Quantity</th>
                          <th>Delivered order</th>
                          <th>Pending Order</th>
                          <th>View Orders</th>


                        </tr>
                        {/* {vendorStatus && (
                <EditVendor
                  status={vendorStatus}
                  vendornames={vendorName}
                  vendorworks={vendorwork}
                />
              )} */}
                        {/* {modalStatus && (
                <DocumentView
                  status={modalStatus}
                  btnClick={toggleRenewalStatus}
                  imageData={imagelist}
            
                />
              )} */}
                        {list.map((dt, index) => {
                          return (
                            <tr key={index} className='tablefontsize'>

                              <td className='fontcolor fontsize'>{dt.project_name}<b></b></td>

                            

                              <td>
                                {/* {dt.resource_type} */}
              {dt.resource_type.length!=0 ?
              <ul>
            {dt.resource_type.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
    </ul>:'NA'}
              </td>

                              <td className='fontcolor fontsize'>{dt.delivered_quantity!=undefined && dt.delivered_quantity!=null ?dt.delivered_quantity:'NA' }</td>

                              <td className='fontcolor1 fontsize1'>{dt.pending_quantity!=undefined && dt.pending_quantity!=null ?dt.pending_quantity:'NA' }</td>

                              <td className='fontcolor fontsize'>{dt.delivered_order!=undefined && dt.delivered_order!=null ?dt.delivered_order:'NA' }</td>

                              <td className='fontcolor1 fontsize1'>{dt.pending_order!=undefined && dt.pending_order!=null ?dt.pending_order:'NA' }</td>


                              {/* <td>
                        <center>
                          <button
                            type="button"
                             className="modalbutton"
                            onClick={()=>documentClick()}
                          >
                            
                            Document
                            
                          </button>
                        </center>
                      </td>
                   */}

                              <td>
                                <center>
                                  <button
                                    type="button"
                                    className="modalbutton"
                                    onClick={() => ViewOrder(dt.project_name)}
                                  >

                                    View

                                  </button>
                                </center>
                              </td>




                            </tr>
                          )
                        })
                        }


                        {/*               
              {viewticketstatus &&
                <ViewTickets status={viewticketstatus} btnClick={toggleViewTicketStatus} TicketNumber={ticketno}/>}
           {ticketlist.length != 0 && ticketlist.map((item, index) => {
                return (
                  <tr key={index}>

                    <td>{item.project_id}</td>
                    <td>{item.contact_person}</td>
                    <td>{item.ticket_number}</td>
                    <td>  <button
                      type="button"
                      className="project-btn1"
                      onClick={() => ViewticketstatusUpdate(item.ticket_number)}
                    >
                      <b>View Tickets</b>
                    </button></td>

                  </tr>
                )
              })} */}

                      </table>
                    </div>

                    <div class="col-lg-1 col-xl-1" ></div>
                  </div>}

              </div>
            </div>
            {/* <div class="modal fade pt-5" id="myModal">
            <div class="modal-dialog modal-md ">
              <button
                type="button"
                className="demobtn2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x"></i>
              </button>
              <div class="modal-content">
                <div class="modal-header">
                  <div className="header-text">
                    <b>{btnname}</b>
                  </div>
                </div>

              </div>
              <div className="modal-body">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-12 pt-3 pb-3 ">
                    <ul class="list-unstyled">
                      {btnname === 'Edit' && (
                        <>
                        
                          <h1>Hello</h1>
                        </>
                      )}


                    </ul>
                  </div>
                </div>
              
              </div>
            </div>
          </div> */}



          </div>
        </div>

      </div>

    </>
  );
};

export default WeareHere;