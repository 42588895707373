
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Chainagestyle.css'
const ChartComponent = ({itemofworkdata,xaxisdata,yaxisdata,heightdata,chainageindexrange,structureInChainageData})=> {
    if (itemofworkdata.length==0 || xaxisdata.length==0 || yaxisdata.length==0) {
        // Handle the case when data is not available
        return <div></div>;
      }

   
//  const xValues = [0,0.2,0.4,0.6,0.8,1.0,1.2,1.4,1.6,1.8,2.0,2.2,2.4,2.6,2.8,3.0,3.2,3.4,3.6,3.8,4.0,4.2,4.4,4.6,4.8,5.0,5.2,5.4,5.6,5.8,6.0];
  // const xValues = [1.0,2.03,4.05,4.5,5.05,5.625,5.825,6.0];
 // const xValues1 = [1.0,1.2,1.4,1.6,1.8,2.0,2.2];


//   const data8YValues = [null, null, null,null,null,1, 1,1,1,1,1,null, null, null,null,null,null, null, null,null,null,null,null,null,null,null,null,null,null,null,null];
//   const data7YValues = [null, null, null,null,null,null, null,null,null,null,2, 2, 2, 2, 2,2, 2, 2, 2, 2,2];
//   const data9YValues = [null, null, null,null,null,null, null,null,null,null,null, null,null,null,null, null,null,null,null,null, null,3, 3, 3, null, null];
//   const data1YValues = [null, null, null,null,null,null, null,null,null,null,null, null,null,null,null, null,null,null,null,null, null,null,null,4, 4, 4, null, null];
//   const data2YValues = [null, null, null,null,null,null, null,null,null,null,null, null,null,null,null, null,null,null,null,null, null,null,null,null,null,5,5,5,5];
//   const data3YValues = [null, null, null,null,null,null, null,null,null,null,null, null,null,null,null, null,null,null,null,null, null,null,null,null,null,null,null,null,6, 6,];
//   const data4YValues = [null, null, null,null,null,null, null,null,null,null,null, null,null,null,null, null,null,null,null,null, null,null,null,null,null,null,null,null,null, 7,7];
// const data1YValues1 = [1, 1,1,1,1,1,1];
// const data2YValues1 = [2,2,2,null,null,null,null];
// const data3YValues1 = [3, 3, 3,3,3,3, 3];
// const data4YValues1 = [4, null, null,4, 4, 4, null];
// const data5YValues1 =  [null,null,null,null,null,5,5];
// const data6YValues1 = [ null,6,6,null,null,6, 6,];
// const data7YValues1 = [null,7,null,null,null, 7,7];

// const s= [
//     "paving_slab",
//     "sand_bed",
//     "gsb_v",
//     "kerbwall_rhs",
//     "stone_dust",
//     "gsb_iii",
//     "paver_block"
// ]
// const modifiedArray = s.map(work => work.replace(/ /g, '_'));

// const arraydata={
//     "paving_slab": [1, 1, 1, 1, 1, 1, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
//     "sand_bed": [2, 2, 2, 2, 2, 2, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
//     "gsb_v": [3, 3, 3, 3, 3, 3, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
//     "kerbwall_rhs": [4, 4, 4, 4, 4, 4, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
//     "stone_dust": [5, 5, 5, 5, 5, 5, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
//     "gsb_iii": [6, 6, 6, 6, 6, 6, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
//     "paver_block": [7, 7, 7, 7, 7, 7, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
// }
let keysList = []
if(yaxisdata!=undefined && yaxisdata!='' && yaxisdata.length!=0 && yaxisdata!=null)
{
 keysList = Object.keys(yaxisdata);
}
// ////alert(keysList)
const len=keysList.length

// const outputData = {};

// for (const key in yaxisdata) {
//     const currentArray = yaxisdata[key];
//     const newArray = currentArray.map(value => (value !== null ? value : currentArray.find(v => v !== null)));
//     outputData[key] = newArray;
// }



const colordata=[ '#008000', '#66ffff', '#CDDC39', '#ff9999', '#b366ff',
'#e6b800', '#ffb3ff', '#4d4dff', '#ff9933', '#94b8b8',
'#ff00ff', '#ffff99', '#F44336', '#03A9F4', '#FFEB3B',
'#CDDC39', '#FF5722', '#8BC34A', '#9E9E9E', '#FF5722',
'#03A9F4', '#FFEB3B', '#CDDC39', '#FF5722', '#8BC34A',
'#9E9E9E', '#FF5722', '#03A9F4', '#FFEB3B', '#4CAF50']

const coloroflabel=['#6600cc','#ff9900', '#00663d',  '#cc00cc', '#666699', '#CDDC39', '#FF5722', '#8BC34A', '#9E9E9E', '#FF5722',
'#03A9F4', '#FFEB3B', '#CDDC39', '#FF5722', '#8BC34A',
'#9E9E9E', '#FF5722', '#03A9F4', '#FFEB3B', '#4CAF50', '#e6b800', '#ffb3ff', '#003399', '#9C27B0', '#009688','#e6b800', '#ffb3ff', '#003399', '#9C27B0', '#009688',]
const colorlen=colordata.length

// const seriesData = (keysList!=undefined && yaxisdata!=undefined && xaxisdata!=undefined && 
//   yaxisdata!=null && xaxisdata!=null && keysList!=null && keysList.length!=0 && yaxisdata.length!=0 && xaxisdata.length!=0) ?
// keysList.map((work, index) => ({
  
//   name: work.replace(/_/g, ' '),
//   //  color: '#008000',
//   color:colordata[(index%colorlen)],
//   data: yaxisdata[work].map((y, dataIndex) => ({
//     x: xaxisdata[dataIndex],
//     y: y

//   })),
//   color:'#ff0000',
//   data: yaxisdata[work].map((y, dataIndex) => ({
//     x: xaxisdata[dataIndex],
//     y: y===null?index+1:null

//   })),
// }
// ))
// :
// ''

const seriesData = keysList.map((work, index) => {
  const primaryLine = {
    name: work.replace(/_/g, ' '),
    color: colordata[index % colorlen],
    data: yaxisdata[work].map((y, dataIndex) => ({
      x: xaxisdata[dataIndex],
      y: y,
    })),
  };

  const secondLine = {
    name: work.replace(/_/g, ' ') + ' Second Line',
   color: '#E5E5E5', // Set your desired color for the second line
   //color: '#000000',
   // data: outputData[work].map((y, dataIndex) => ({
    data: structureInChainageData[work].map((y, dataIndex) => ({
      x: xaxisdata[dataIndex],
      y:y
      //y: y === null ? index + 1 : null,
    //  y: yaxisdata[work][dataIndex+1]===null  || yaxisdata[work][dataIndex-1]===null || y  === null ? index + 1 : null,
    })),
  };

  return [secondLine,primaryLine];
}).flat();


  let chartData = {}
  if(keysList!=undefined && yaxisdata!=undefined && xaxisdata!=undefined && 
    yaxisdata!=null && xaxisdata!=null && keysList!=null && keysList.length!=0 && yaxisdata.length!=0 && xaxisdata.length!=0)
  chartData={
    
    options: {
      chart: {
        type: 'line',
        menu: false, // Hide the context menu
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      tooltip: {
        enabled: false // Set this to false to disable tooltip
      },
      xaxis: {
        type: 'category',
        categories: xaxisdata,
        axisBorder: {
          show: true,
          color: '#000000',  // Set the desired color for the x-axis line
          height: 2, // Set the desired height for the x-axis line
          // style: {
          //   colors: xaxisdata.map((_, index) => (index >= 0 && index < 10 ? '#ff0000' : '#0000ff')),
          // },
        },
        
        labels: {
          show: true,
          // style: {
          //   colors: xaxisdata.map((_, index) => (index >= 0 && index <= parseInt(indexrange.level1) ? '#F44336' :
          //   (index >parseInt(indexrange.level1) && index <= parseInt(indexrange.level2) ?  '#4700b3':
          //   (index > parseInt(indexrange.level2) && index <= parseInt(indexrange.level3) ? 	'#800000':
          //   (index > parseInt(indexrange.level3) && index <= parseInt(indexrange.level4) ? '#008000':
          //   (
          //     index > parseInt(indexrange.level4) && index <= parseInt(indexrange.level5) ? '#ff9900':'#CDDC39'
             
          //   )
          //   )
          //   )


          //   )
            
          //  )),
          // },
          // style:{

          //       colors: xaxisdata.map((_, index) =>
      
          // chainageindexrange.map((item,index1)=>
          // {
          //   if(index1==0)
          //   {
          //   index >= 0 && index <= parseInt(item) ? colordata[0] :''
          //   }
          //   else{
          //     index >= item[index1-1] && index <= parseInt(item) ? colordata[index] :colordata[index+1]
          //   }
          // })
          //  ),
          // }
          style: {
            colors: xaxisdata.map((_, index) => {
              if(chainageindexrange!=null && chainageindexrange!=undefined)
              {
              const colorIndex = chainageindexrange.findIndex((item, index1) => {
                // if (index1 === 0) {
                //   console.log("if")
                //   return index >= 0 && index < parseInt(item);
                 
                // } else {
                //   console.log("else")
                //   return index >= chainageindexrange[index1 - 1] && index < parseInt(item);
                // }


                // if(index<item)
                // {
                //   if(index1==0)
                //   {
                //   return index1-1;
                //   }
                //   else
                //   {
                //     return index1;
                //   }
                // }
                return index<item

              });
            
           
              return coloroflabel[colorIndex];
            }
            }),
          }
          

        },
        // min: 0,
      // color:'#03A9F4'
        
      },
      yaxis: {
        title: {
          text: 'Structures',
         
        },
       
       
        // lines: {
        //   show: true, // Show y-axis lines
        //   borderColor: '#000000' // Change the color of the y-axis lines
        // },
        axisBorder: {
          show: true,
          color: '#000000', // Set the desired color for the y-axis line
          width: 2, // Set the desired width for the y-axis line
        },
        min: 0,
      
        labels: {
          minWidth: 150,
          formatter: function (value, index) {
            //   if (index === 1) {
            //   return 'Type-1';
            // } 
            // else if (index === 2) {
            //   return 'Type-2';
            // }
            // else if (index === 3) {
            //   return 'Type-3';
            // }
            // else if (index === 3) {
            //   return 'Type-4';
            // }
            // else if (index === 3) {
            //   return 'Type-5';
            // }
            for(var i=1;i<=len;i++){
              if (index === i) {
              
                return keysList[index-1].replace(/_/g, ' ');
              } 
            }
            // if (index === 1) {
            //   return chainage[0];
            // } 
            // else if (index === 2) {
            //   return chainage[1];
            // }
            // else if (index === 3) {
            //   return chainage[2];
            // }
            // else if (index === 4) {
            //   return chainage[3];
            // }
            // else if (index === 5) {
            //   return chainage[4];
            // }
            // else if (index === 6) {
            //   return chainage[5];
            // }
            // else if (index === 7) {
            //   return chainage[6];
            // }
            // Add more conditions for other series if needed
            return value;
          },
          // style: {
          //   fontSize: '12px',
          //   whiteSpace: 'nowrap',
          //   overflow: 'hidden',
          //   textOverflow: 'ellipsis',
          //   width: '300px',
            
          // },
        },
        tickAmount: len,
      },
      legend: {
        show: false, // Set to false to hide the series name
      },
    },
    // series: [
    //   {
    //     name: itemofwork[0],
    //     color: '#008000',
    //     data: data8YValues1.map((y, index) => ({
    //       x: xValues1[index],
    //       y: y,
    //     })),
    //   },
    //   {
    //     name: itemofwork[1],
    //     color: '#008000',
    //     data: data7YValues1.map((y, index) => ({
    //       x: xValues1[index],
    //       y: y,
    //     })),
    //   },
    //   {
    //     name: itemofwork[2],
    //     color: '#008000',
    //     data: data9YValues1.map((y, index) => ({
    //       x: xValues1[index],
    //       y: y,
    //     })),
    //   },
    //   {
    //     name: itemofwork[3],
    //     color: '#008000',
    //     data: data1YValues1.map((y, index) => ({
    //       x: xValues1[index],
    //       y: y,
    //     })),
    //   },
    //   {
    //     name: itemofwork[4],
    //     color: '#008000',
    //     data: data2YValues1.map((y, index) => ({
    //       x: xValues1[index],
    //       y: y,
    //     })),
    //   },
    //   {
    //     name: itemofwork[5],
    //     color: '#008000',
    //     data: data3YValues1.map((y, index) => ({
    //       x: xValues1[index],
    //       y: y,
    //     })),
    //   },
    //   {
    //     name: itemofwork[6],
    //     color: '#008000',
    //     data: data4YValues1.map((y, index) => ({
    //       x: xValues1[index],
    //       y: y,
    //     })),
    //   },

    // ],
   
  };

  return (
    <div 
    // style={{ 
    //  width: '1000px',
    //   overflowX: 'scroll',
    //    height:'auto',overflowY:'hidden' }}
       >
{/* {itemofworkdata!=undefined && xaxisdata!=undefined && yaxisdata!=undefined  && seriesData!=undefined && seriesData!=null
&& chartData!=null && chartData!=undefined && */}
      <ReactApexChart options={chartData.options} series={seriesData} type="line"  
    // width="1500px"
        height={400}
      
      />
      
      {/* } */}
    </div>
  );
};

export default ChartComponent;
