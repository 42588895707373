import React,{useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal, Form } from 'react-bootstrap'
import './Modal.css'
import CreateRequest from '../CreateRequest/CreateRequest'
import { useState} from 'react'
import InputTextField from '../InputText/InputText'
import {baseurl} from '../BaseUrl/Baseurl'
import axios from 'axios'
import userDataStore from '../../store/userDetails'
import { connect, useDispatch, useSelector } from 'react-redux'
export default function RenewalModel({ btnClick, status ,  ProjectId , RequestId, RequestStatus}) {
  const [requeststatus, setRequeststatus] = useState('')
  const [projectstatusValid, setProjectstatusValid] = useState(false)
  const [projectstatuserror, setProjectstatuserror] = useState('')
 
  const [duedate, setDuedate] = useState('')
  const [duedateValid, setDuedateValid] = useState(false)
  const [duedateerror, setDuedateerror] = useState('')

  const [vendorname, setVendorname] = useState('')
  const [vendornameValid, setVendornameValid] = useState(false)
  const [vendornameerror, setVendornameerror] = useState('')
  const [list, setList] = useState([]);
  const [errormsg,setErrormsg] = useState('')
  const [modalVisible, setModalVisible] = useState(status);
  const[processing,setProcessing]=useState(false)
  const userData = useSelector((state) => state.userDetails)
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })


      axios({
        method: 'GET',

        url: baseurl+'/api/vendor/list/get/vendor_details?client_id='+userData.clientId+'&contractor_type=Vendor',

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
      })

        .then((response) => {
         
          //console.log(response.data)
          const RequestData = response.data
          // setRequestdata1(RequestData)
          //console.log("request",RequestData)
         const dt=RequestData
         setList(dt)
        
        })
        .catch((error) => {
          
          //console.log(error)
          // setErrormsg(error.response.data.message)
        })
    }, [status,modalVisible])

  function handleRequeststatus(e) {
   //console.log('********'+e.target.value)
    setRequeststatus(e.target.value)
    validateField(e.target.name, e.target.value)
  }
  function handleDuedate(e) {
    //console.log('********'+e.target.value)
     setDuedate(e.target.value)
     validateField(e.target.name, e.target.value)
   }
   function handleVendorname(e) {
    //console.log('********'+e.target.value)
     setVendorname(e.target.value)
     validateField(e.target.name, e.target.value)
    
   }
 
  const validateField = (fieldName, value) => {
    if (fieldName === 'duedate') {
   
      if (value.length != 0) {
        setDuedateerror('')
        setDuedateValid(true)
      } else {
        setDuedateerror('Please select duedate')
        setDuedateValid(false)
      }
    }
    if (fieldName === 'vendorname') {
   
        if (value!='select') {
          setVendornameerror('')
          setVendornameValid(true)
        } else {
          setVendornameerror('Please select vendorname')
          setVendornameValid(false)
        }
      }
}
 
 
 

  function onSubmitEvent(event) {
    event.preventDefault()

    if(requeststatus!='ORDERED')
    {
        

          axios({
            method: 'PUT',
      
            url: baseurl + '/api/request/resource/update?project_id='+ProjectId+'&resource_requested_id='
            +RequestId+'&requested_status='+requeststatus,
      
            headers: {
              'Content-Type': 'multipart/form-data',     
              Authorization: 'Bearer ' + userData.token
            },
      
        
          })

          .then((response) => {
            // ////alert("success")
            btnClick(); // Close the modal
          })
          .catch((error) => {
             //console.log(error)
           // setErrormsg(error.response.data.message)
          })
        }

        else{

            if(duedateValid && vendornameValid)
            {
setProcessing(true)
              //console.log(ProjectId)
              //console.log(RequestId)
              //console.log(duedate)
              //console.log(vendorname)
              //console.log(requeststatus)
              
                axios({
                  method: 'POST',
            
                  url: baseurl + '/api/purchase_order/generate_report?delivery_date='+duedate+'&project_id='+
                  ProjectId+'&res_req_id='+RequestId+'&vendor_name='+vendorname+'&status='+requeststatus,
            
                  headers: {
                    'Content-Type': 'multipart/form-data',     
                    Authorization: 'Bearer ' + userData.token
                  },
            
              
                })

                .then((response) => {
                //  ////alert("success")
                  setErrormsg('')
                  setDuedateValid(false)
                  setVendornameValid(false)
                  setProcessing(false)
                  btnClick(); // Close the modal
                })
                .catch((error) => {
                   //console.log(error)
                 // setErrormsg(error.response.data.message)
                })

                // axios
                // .put(baseurl + '/api/request/resource/update?project_id='+ProjectId+'&resource_requested_id='
                // +RequestId+'&requested_status='+requeststatus, {
                //   headers: { 'Content-Type': 'multipart/form-data' },
                // })
      
                // .then((response) => {
                //   // ////alert("success")
                //   btnClick(); // Close the modal
                // })
                // .catch((error) => {
                //    //console.log(error)
                //  // setErrormsg(error.response.data.message)
                // })
            }
            else{
                setErrormsg('Please fill required fields')
            }
        }
        }
const dropdownlist=["REQUESTED","APPROVED","ORDERED","DISPATCHED","DELIVERED"]
function getNextStatuses(currentStatus) {
  const currentIndex = dropdownlist.indexOf(currentStatus);

  if (currentIndex === -1 || currentIndex === dropdownlist.length - 1) {
    // If the current status is not found or it's the last status, return an empty array
    return [];
  }

  // Get the next statuses from the array
  const nextStatuses = dropdownlist.slice(currentIndex + 1);

  return nextStatuses;
}
const nextStatuses = getNextStatuses(RequestStatus);

  return (
    <>
      <Modal show={modalVisible} onHide={btnClick} id="modal-size1">
        <Modal.Body>
          <center>  <div className="model-top"><b>Material Request Update</b></div></center>
          {/* <CreateRequest /> */}
          
  
          <div className="cont-modalstyle">
           
                <div class="row pb-2">
           
                
                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={(event) => {
                      onSubmitEvent(event);
                     
                    }}
                  >

             
                     
                      <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-md-12 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="login-text"
                  >
                    Status
                  </label>
                  
                  <select
                            className="project-box"
                            id="requeststatus"
                            name="requeststatus"
                            defaultValue={requeststatus}
                            onKeyPress={(e) => {
                              e.key === 'Enter' && e.preventDefault()
                            }}
                            value={requeststatus}
                            onChange={(e)=>handleRequeststatus(e)}
                          >
                            <option value={null}>Select</option>
                            {/* <option value="REQUESTED" selected={RequestStatus==="REQUESTED"?true :false}>Requested</option>
                            <option value="APPROVED" selected={RequestStatus==="APPROVED"?true :false}>Approved</option>
                            <option value="ORDERED" selected={RequestStatus==="ORDERED"?true :false}>Ordered</option>
                            <option value="DISPATCHED" selected={RequestStatus==="DISPATCHED"?true :false}>Dispatched</option>
                            <option value="DELIVERED" selected={RequestStatus==="DELIVERED"?true :false}>Delivered</option> */}
                           {nextStatuses.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
                          </select>
                  <div className="login-error">{}</div>
                </div>
          {requeststatus==="ORDERED" &&      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Due date
                      </label>
                      <center>
                        <InputTextField
                         handleInputChange={handleDuedate}
                          valueText={duedate}
                          inputName="duedate"
                          inputId="duedate"
                          classname="project-box"
                    
                          inputType="date"
                     
                        ></InputTextField>

                        <div className="login-error">{duedateerror}</div>
                        </center>
                      </div>
                      

                      
                      }
 
 {requeststatus==="ORDERED" &&   
 
 
 <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                    Vendor Name
                      </label>
                     <center>

                      <select
                      className="project-box"  
                       id="vendorname"
                       name="vendorname"
                      //  defaultValue={companyname}
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                      value={vendorname}
                   onChange={handleVendorname}
                    >
                      <option value="select" selected>
                        Select
                      </option>
                      {list.map((namelist, index) => (
        <option key={index} value={namelist.vendor_company_name}>
          {namelist.vendor_company_name}
        </option>
      ))}
                    
                    </select>
                    <div className="login-error">{vendornameerror}</div>
                      </center>
                      </div>
                      }
                  
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                  
                      <center>
                      {requeststatus==="ORDERED" && !(duedateValid && vendornameValid) && 
                        <div className="login-error">
                           <p>{errormsg}</p> 
                        </div>}
                        {processing && 
                        <div className='success'>
                           <p>Please wait... Processing...</p> 
                        </div>}
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                    //   disabled={requeststatus==="ORDERED"? !(duedateValid && vendornameValid):false}
                        >
                          <b>Submit</b>
                        </button>
                      </center>{' '}
                    </div>
                  
                  </form>
                </div>
              
              </div>
            </div>
           
         
        </Modal.Body>
      </Modal>
    </>
  )
}
