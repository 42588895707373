import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect ,useRef} from 'react'
import '../VendorCreation/VendorCreation.css'
import './SubcontcontCreation.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'



import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
function FormPage() {
  
  const [successstatus, setSuccessstatus] = useState(false)
  const [successmsg, setSuccessmsg] = useState('');
  const [companyname, setCompanyname] = useState('')
  const [companynameValid, setCompanynameValid] = useState(false)
  const [companynameerror, setCompanynameerror] = useState('')

  const [vendortype, setVendortype] = useState('')
  const [vendortypeValid, setVendortypeValid] = useState(false)
  const [vendortypeerror, setVendortypeerror] = useState('')

  const [typeofwork, setTypeofwork] = useState('')
  const [typeofworkValid, setTypeofworkValid] = useState(false)
  const [typeofworkeerror, setTypeofworkerror] = useState('')
  
  const [onboardingdate, setOnboardingdate] = useState('')
  const [onboardingdateValid, setOnboardingdateValid] = useState(false)
  const [onboardingdateeerror, setOnboardingdateerror] = useState('')

  const [contactno, setContactno] = useState('')
  const [contactnoValid, setContactnoValid] = useState(false)
  const [contactnoerror, setContactnoerror] = useState('')

  const [contactmail, setContactmail] = useState('')
  const [contactmailValid, setContactmailValid] = useState(false)
  const [contactmailerror, setContactmailerror] = useState('')
 
  const [contactperson, setContactperson] = useState('')
  const [contactpersonValid, setContactpersonValid] = useState(false)
  const [contactpersonerror, setContactpersonerror] = useState('')
  const [itemofwork, setItemofwork] = useState('')
  const [itemofworkValid, setItemofworkValid] = useState(false)
  const [itemofworkerror, setItemofworkerror] = useState('')
  const [structuretype, setStructuretype] = useState('ALL')
  const [structuretypeValid, setStructuretypeValid] = useState(false)
  const [structuretypeerror, setStructuretypekerror] = useState('')
  const userData = useSelector((state) => state.userDetails)
  const [fileattach, setFileattach] = useState('')
  const [fileattacherror, setFileattacherror] = useState('')
  const [fileattachValid, setFileattachValid] = useState(false)
  const [contractornames, setContractornames] = useState([])
  const hiddenFileInput = React.useRef(null)
  const [itemofworklist, setItemofworklist] = useState([])
  const [structurelist, setStructurelist] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  function handleImageUpload(e) {
    setSuccessmsg('')
    const files = [...e.target.files]; 
   // ////////////alert(files) // Spread operator to convert FileList to an array
    setSelectedFiles([...selectedFiles, ...files]);
  }
  function handleDeleteFile(index) {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  }

  // function handleChangeFile(event) {
  //   const file = event.target.files[0]
  //   // let filename = file.name
  //   setFileattach(file)
  //   validateField(event.target.name, event.target.files[0])
  // }
    useEffect(() => {
    //  // ////////////alert(""+userData.projectId);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });


      // let fData = new FormData()
      // fData.append('project_id', userData.currentprojectId)
      // axios
      // .get(baseurl+'/api/vendor/list/get/vendor_company_names?project_id=' + userData.currentprojectId, +fData, {
      //   headers: { 'Content-Type': 'multipart/form-data' },
      // })
      axios({
        method: 'GET',

        url: baseurl+'/api/vendor/list/get/vendor_company_names?project_id=' + userData.currentprojectId,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
      })

  
        .then((response) => {
  
  //console.log(response.data)
          const dat = response.data
   const names=dat.vendor_companies_name
   setContractornames(names)
  // setlevel(lel)
// getStructuretype(lel)
        })
        .catch((error) => {
          //console.log(error)
          //setErrormsg(error.response.data.message)
        })

        // let fDatan2 = new FormData()
        // fDatan2.append('project_id', userData.currentprojectId)
        // axios
        // .get(baseurl+'/api/plan/list/structure_items&work_items?project_id=' + userData.currentprojectId, +fDatan2, {
        //   headers: { 'Content-Type': 'multipart/form-data' },
        // })
        axios({
          method: 'GET',
  
          url: baseurl+'/api/plan/list/structure_items&work_items?project_id=' + userData.currentprojectId,
  
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + userData.token
          }
        })


          .then((response) => {
    
    //console.log(response.data)
            const dat = response.data
     const names=dat.item_of_works
 
     setItemofworklist(names)
    // setlevel(lel)
  // getStructuretype(lel)
          })
          .catch((error) => {
            //console.log(error)
            //setErrormsg(error.response.data.message)
          })
    }, []);

    function handleCompanyname(e) {
      setSuccessmsg('')
      setCompanyname(e.target.value.replace(/\s+/g, ''))
      validateField('companyname', e.target.value)
    }
    
  function handleVendortype(e) {
    setVendortype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleTypeofwork(e) {

    setTypeofwork(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleOnboardingdate(e) {
    setOnboardingdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }


 
  function handleContactmail(e) {
    setContactmail(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  
  
  function handleContactperson(e) {
    setContactperson(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }


  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {

  if (fieldName === 'companyname') {
    //console.log(value)

    if (value != 0) {
      setCompanynameerror('')
      setCompanynameValid(true)
    } else {
      setCompanynameerror('Please select Company name')
      setCompanynameValid(false)
    }
  }

 
  }
  function handleitemofwork(e) {
    setSuccessmsg('')
    //console.log(e.target.value)
    setItemofwork(e.target.value)
    validateField(e.target.name, e.target.value)
  }
  function handlestructuretype(e) {



    //console.log(e.target.value)
    setStructuretype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
    setItemofwork('')
    const struc=e.target.value
//     let fDatan1 = new FormData()
//     fDatan1.append('project_id', userData.currentprojectId)
//     fDatan1.append('structure_type', struc)
//     axios
//     .get(baseurl+'/api/plan/list/item_of_work?project_id=' + userData.currentprojectId+'&structure_type='+struc, +fDatan1, {
//       headers: { 'Content-Type': 'multipart/form-data' },
//     })

//       .then((response) => {

// //console.log(response.data)
//         const dat = response.data

// //// ////////////alert("OHHHHHHHHHHHHH"+dat)
// //  const item=dat.
// //  setItemofworklist(dat)
// // setlevel(lel)
// // getStructuretype(lel)
//       })
//       .catch((error) => {
//         //console.log(error)
//         //setErrormsg(error.response.data.message)
//       })


  }

  function onSubmitEvent(event) {
    event.preventDefault();





//     if (companynameValid &&typeofworkValid && onboardingdateValid  
//         && vendortypeValid && contactpersonValid && contactmailValid && contactnoValid) {
//  //// ////////////alert("hi")
     


       //// ////////////alert(selectedFiles[0].name)

  
  let userData0 = new FormData()
  userData0.append('contractor_name',companyname )
  userData0.append('item_of_work', itemofwork)
       
for(   let i=0;i<selectedFiles.length;i++)
{
  userData0.append('assigned_vendor_document', selectedFiles[i])
        // axios.post(baseurl + '/api/vendor/assign_work?project_id='+userData.currentprojectId, userData0,{
        //   headers: { 'Content-Type': 'multipart/form-data' },
        // })
        axios({
          method: 'POST',
    
          url: baseurl + '/api/vendor/assign_work?project_id='+userData.currentprojectId,
    
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + userData.token
          },
          data:userData0
    
        })
          .then((response) => {
              setSuccessstatus(true)

              // ////////////alert("super")
              setSuccessmsg("Successfully Updated")
          })
          .catch((error) => {
            //console.log(error)
            //setErrormsg(error.response.data.message)
          });
        }
//     }
setCompanyname('')
setItemofwork('')
setSelectedFiles([])
if (fileInputRef.current) {
  fileInputRef.current.value = '';
}
  }
  
  return (

    
    <div className="cont-margin1">
              {/* {successstatus && <ButtonLink btnPath="/Overallvendorlist" />} */}

              <div class="cont-margin-latest pt-2 ">
    <div className='table1bdstyle'>
          <div class="row table1st">

                <div class="row pb-2">
                <div class="col-lg-8 col-xl-8 col-md-12 col-sm-12 col-12 pt-5 ">
                    </div>
                <div class="col-lg-3 col-xl-3 col-md-12 col-sm-12 col-12 ">
                <Link to="/Road">
                        <button
                          type="submit"
                          className="thirdparty-btn1"
                          name="submit"  
                          float="right"   
                                     
                        >
                          <b>Back</b>
                        </button>
                        </Link>
                        <div class="col-lg-1 col-xl-1 col-md-12 col-sm-12 col-12 pt-5 ">
                    </div>
                    </div>          
                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12 pb-5 ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={onSubmitEvent}
                  >

                    <div class="row">

                    <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast login-text" 
                  >
                    Contractor Name
                  </label>
                  <select
                    className="project-box"  
                     id="companyname"
                     name="companyname"
                    //  defaultValue={companyname}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={companyname}
                 onChange={handleCompanyname}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                      {contractornames.map((name, index) => (
                <option key={index} value={name}>
                  {name.replace(/_/g, ' ')}
                </option>
              ))}
                    {/* <option value="AIS">AIS</option>
                    <option value="AtheesGroup">Athees Group</option>
                    <option value="AtheesBlueMetal">Athees Blue Metal</option> */}
                  </select>
                  <div className="login-error">{companynameerror}</div>
                </div>
              
                {/* <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Structures
            </label>
            <select
              className="project-box"
              id="structuretype"
              name="structuretype"
              defaultValue="All"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={structuretype}
              onChange={handlestructuretype}
            >
              <option value="ALL" selected>
                Select
              </option>
             
              {structurelist.map((name, index) => (
                <option key={index} value={name}>
                  {name.replace(/_/g, ' ')}
                </option>
              ))}
            </select>
            <div className="login-error"></div>
          </div> */}


             <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Item of Work
            </label>
            <select
              className="project-box"
              id="itemofwork"
              name="itemofwork"
              defaultValue={itemofwork}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={itemofwork}
              onChange={handleitemofwork}
            >
              <option value={null} selected>
                Select
              </option>
              {/* <option value="Rcc" >
                RCC
              </option>
              <option value="Rcc" >
             SandFilling
              </option> */}
              {itemofworklist.length!=0 && itemofworklist.map((item, index) => (
                <option key={index} value={item.item_name}>
                  {item.replace(/_/g, ' ')}
                </option>
              ))}
            </select>
            <div className="login-error"></div>
          </div> 
          {/* <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Upload
            </label>
              <button
                type="button"
                className="project-box "
                onClick={() => handleClick()}
              >
                  {fileattach != undefined && fileattach != ''
                        ? <b>Uploaded {fileattach.name}</b>
                        : 'Upload'}
              </button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChangeFile}
                style={{ display: 'none' }}
                name="fileattach"
                id="fileattach"
                accept="image/jpeg"
              />
               <div className="login-error">{fileattacherror}</div>
                 </div> */}
    <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
  <label for="formFileLg" className="login-text">
    Upload Image
  </label>
  <input
    type="file"
    className="project-box"
    id="imageUpload"
    name="imageUpload"
    
    accept="image/*"
    multiple
    onChange={(e)=>handleImageUpload(e)}
    ref={fileInputRef}
  />
</div>

{selectedFiles.length > 0 && (
  <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
    <label className="login-text">Selected Files</label>
    <ul>
      {selectedFiles.map((file, index) => (
        <li key={index} class="pb-2">
          {file.name} &nbsp;&nbsp;&nbsp;
          <button
            type="button"
            onClick={() => handleDeleteFile(index)}
            className="user-btn"
          >
            Delete
          </button>
        </li>
      ))}
    </ul>
  </div>
)}
                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                   
                      <center>
                      <div className="success pb-3">{successmsg}</div>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                        //   disabled={!(companynameValid && typeofworkValid && onboardingdateValid 
                        //     && vendortypeValid && contactpersonValid && contactmailValid && contactnoValid)}
                        >
                          <b>Submit</b>
                        </button>
                       
                      </center>{' '}
                
                        
                    </div>
                  
                  </form>
                </div>
              
              </div>
            </div>
            </div>
              
              </div>
            </div>
    );
                          }
  
  export default FormPage;

