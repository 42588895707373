import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import './ProgressSearch.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
// import DuedateModel from '../Modal/SetDueDate'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'

function FormPage(data) {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const userData = useSelector((state) => state.userDetails)
  const [level, setlevel] = useState('')
  const [levelValid, setlevelValid] = useState(false)
  const [levelerror, setlevelerror] = useState('')
  const [itemofwork, setItemofwork] = useState('')
  const [itemofworkValid, setItemofworkValid] = useState(false)
  const [itemofworkerror, setItemofworkerror] = useState('')
  const [structuretype, setStructuretype] = useState('')
  const [structuretypeValid, setStructuretypeValid] = useState(false)
  const [structuretypeerror, setStructuretypekerror] = useState('')
  const [date, setDate] = useState('')
  const [dateValid, setDateValid] = useState(false)
  const [dateerror, setDateerror] = useState('')
  const [owner, setOwner] = useState('')
  const [ownerValid, setOwnerValid] = useState(false)
  const [ownererror, setOwnererror] = useState('')
  const [requeststatus, setRequeststatus] = useState(false);
  const [successstatus, setSuccessstatus] = useState(false)
  const [reslist1, setReslist1] = useState([])
  const [reslist2, setReslist2] = useState([])
  const [itemofworklist, setItemofworklist] = useState([])
  const [structurelist, setStructurelist] = useState([])
  const [checkboxval, setCheckboxval] = useState(false);
  const [checklist, setChecklist] = useState([])
  const [checkduedate, setCheckduedate] = useState(false);

  const [checkboxes, setCheckboxes] = useState(Array(reslist1.length).fill(false));
  const [checkboxErrors, setCheckboxErrors] = useState(Array(reslist1.length).fill(''));
  const [strucliststatus, setStrucliststatus] = useState(false);
  const [projectstatus, setProjectstatus] = useState('')
  const [projectstatusValid, setProjectstatusValid] = useState(false)
  const [projectstatuserror, setProjectstatuserror] = useState('')
  const [successstatus1, setSuccessstatus1] = useState(false)
  const leveldata = data.data
  useEffect(() => {

    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    // // axios
    //   .get(baseurl+'/api/plan/list/structure_items&work_items?project_id='+userData.currentprojectId, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

    //   .then((response) => {

    //     const dat = response.data
    //     //console.log(response.data)

    //     const itemwork = dat.item_of_works

    //     // setItemofworklist(itemwork)
    //     const modifiedList = itemwork.map(item => item.replace(/ /g, '_'));
    //     setItemofworklist(modifiedList)
    //     const struc = dat.structure_types
    //     const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
    //     setStructurelist(modifiedList1)

    //   })
    //   .catch((error) => {
    //     //console.log(error)
    //     //setErrormsg(error.response.data.message)
    //   })








  }, [successstatus, checkboxes, checklist, strucliststatus])
  // function getStructuretype(levelval)
  // {

  //   if(!strucliststatus)
  //   {

  //     let fDatanew = new FormData()
  //     fDatanew.append('project_id', userData.currentprojectId)
  //     fDatanew.append('level', levelval)
  //     axios
  //       .get(baseurl + '/api/plan/structure_type?project_id=' + userData.currentprojectId+'&level='+levelval, +fDatanew, {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //       })

  //       .then((response) => {

  //         //console.log(response.data)
  //         const dat = response.data
  //         const struc = dat.structure_types      
  //         setStructurelist(struc)
  //         setStrucliststatus(true)

  //       })
  //       .catch((error) => {
  //         //console.log(error)
  //         //setErrormsg(error.response.data.message)
  //       })
  //   }
  // }
  function handlelevel(e) {
    //console.log(e.target.value)
    setlevel(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)

    // setStructuretype('ALL')
    const leveldata = e.target.value
    let fData = new FormData()
    fData.append('project_id', userData.currentprojectId)
    fData.append('level', leveldata)
  
      axios({

        method: 'GET',
      
        url: baseurl+'/api/plan/list/structure_type?project_id=' + userData.currentprojectId + '&level=' + leveldata,
      
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
        //data: fData
      })
      .then((response) => {

        //console.log(response.data)

        const dat = response.data
        const struc = dat.structure_types
        // const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
        //console.log(struc)
        setStructurelist(struc)
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })
  }
  function handleitemofwork(e) {
    //console.log(e.target.value)
    setItemofwork(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handlestructuretype(e) {

    //console.log(e.target.value)
    setStructuretype(e.target.value.replace(/\s+/g, ''))


    validateField(e.target.name, e.target.value)
  }
  function handledate(e) {
    //console.log(e.target.value)
    setDate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleOwner(e) {
    //console.log(e.target.value)
    setOwner(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)

  }
  function handleProjectstatus(e) {
    //console.log(e.target.value)
    setProjectstatus(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  //    function handleCheckboxChange(e,item) {
  //     //console.log(e.target.value)
  //      setCheckboxval(e.target.value.replace(/\s+/g, ''))
  //      validateField(e.target.name, e.target.value)
  //    }
  const validateField = (fieldName, value) => {
    if (fieldName === 'itemofwork') {

      if (value != 'Select') {
        setItemofworkerror('')
        setItemofworkValid(true)
      } else {
        setItemofworkerror('Please select')
        setItemofworkValid(false)
      }
    }
    if (fieldName === 'structuretype') {

      if (value != 'Select') {
        setStructuretypekerror('')
        setStructuretypeValid(true)
      } else {
        setStructuretypekerror('Please select')
        setStructuretypeValid(false)
      }
    }
    if (fieldName === 'owner') {

      if (value != 'Select') {
        setOwnererror('')
        setOwnerValid(true)
      } else {
        setOwnererror('Please select')
        setOwnerValid(false)
      }
    }
    if (fieldName === 'date') {

      if (value != '') {
        setDateerror('')
        setDateValid(true)
      } else {
        setDateerror('Please select')
        setDateValid(false)
      }
    }
  }


  function onSubmitEvent(event) {
    event.preventDefault()
    setSuccessstatus(false)
    setReslist1([])


    // if (itemofworkValid && structuretypeValid && dateValid && ownerValid) {

    // const d = date;
    // const y = 'f0'
    // const currentDate = new Date(d)
    // const day = String(currentDate.getDate()).padStart(2, '0')
    // const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    // const year = currentDate.getFullYear()
    // const currentDateString1 = `${day}-${month}-${year}`

    let fmData5 = new FormData()
    fmData5.append('project_id', userData.currentprojectId)
    fmData5.append('floor', level)
    fmData5.append('structure_type', structuretype)
    fmData5.append('structure_item_completion_status', projectstatus)


    // ////alert(level)
    // ////alert(structuretype)
  

      axios({
        method: 'GET',
      
        url: baseurl+'/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId +
        '&item_of_work=' + structuretype + '&structure_item_completion_status=' + projectstatus
        + '&level=' + level,
      
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
      
      })
      .then((response) => {
      console.log("Progress"+response.data)
        // ////alert("data"+response.data)
        const data = response.data
        const dt = data.searched_data || []

        console.log(JSON.stringify(data))
      
        setReslist1(dt)
        setSuccessstatus(true)
        setSuccessstatus1(true)
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })


    //}


  }

  return (
    <>

      {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
      {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}

      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  pt-3">
        <div class=" pt-5 labels ff">
          <center><h5><b>Work Status</b></h5></center>
        </div>
        <form
          class="row d-flex justify-content-center align-center  blacktext "

        >

          <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">

            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Level <font className="login-error">{"*"}</font>
            </label>
            <select
              className="project-box"
              id="level"
              name="level"
              defaultValue={level}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={level}
              onChange={handlelevel}
            >
              <option value={null} selected>
                Select
              </option>
              {/* <option value="0">Project</option>
                          <option value="1">Payment</option> */}
              {leveldata.length != 0 && leveldata.map((item, index) => (
                <option key={index} value={item.floor}>
                  {item.floor.replace(/_/g, ' ')}
                </option>
              ))}
            </select>
            <div className="login-error"></div>
          </div>
          {/* <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="login-text"
                  >
                    Item of Work
                  </label>
                  <select
                    className="project-box"
                    id="itemofwork"
                    name="itemofwork"
                    defaultValue={itemofwork}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={itemofwork}
                    onChange={handleitemofwork}
                  >
                    <option value={null} selected>
                      Select
                    </option>
                 
                    {itemofworklist.map((name, index) => (
                      <option key={index} value={name}>
                        {name.replace(/_/g, ' ')}
                      </option>
                    ))}
                  </select>
                  <div className="login-error"></div>
                </div> */}
          <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Structures
            </label>
            <select
              className="project-box"
              id="structuretype"
              name="structuretype"
              defaultValue={structuretype}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={structuretype}
              onChange={handlestructuretype}
            >
              <option value={null} selected>
                Select
              </option>
              {/* <option value="0">Project</option>
                          <option value="1">Payment</option> */}
              {structurelist.map((name, index) => (
                <option key={index} value={name}>
                  {name.replace(/_/g, ' ')}
                </option>
              ))}
            </select>
            <div className="login-error"></div>
          </div>
          <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-md-12 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Status
            </label>
            <select
              className="project-box"
              id="projectstatus"
              name="projectstatus"
              //  defaultValue={companyname}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={projectstatus}
              onChange={handleProjectstatus}
            >
              <option value="Select" selected>
                Select
              </option>
              <option value="0">Not Yet Started</option>
              <option value="1">Paused</option>
              <option value="2">InProgress</option>
              <option value="3">Done</option>
              <option value="4">Completed</option>
            </select>
            <div className="login-error">{projectstatuserror}</div>
          </div>

          {/* <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3 ">
                  <label for="formFileLg" className="login-text">
                    Owner
                  </label>
                  <center>
                   
                    <select
                      className="project-box"
                      id="owner"
                      name="owner"
                      defaultValue={owner}
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                      value={owner}
                      onChange={handleOwner}
                    >
                      <option value={null} selected>
                        Select
                      </option>
                      <option value="contractor">Contractor</option>
                      <option value="owner">Owner</option>

                    </select>

                    <div className="login-error"></div>
                  </center>
                </div> */}

          <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 ">
            <center>
              <button
                type="submit"
                className="project-btn"
                name="submit"
                onClick={onSubmitEvent}

              >
                <b>Search</b>
              </button>
            </center>{' '}
          </div>

        </form>
        <div >
          <div class="row">
            <div class=" col-xl-8 col-lg-8 col-md-8 pt-1 pb-1">

            </div>
            <div class=" col-xl-3 pt-1 pb-1 ">

            </div>
            <div class=" col-xl-1 pt-1 pb-1">

            </div>
          </div>
          {successstatus && reslist1.length == 0 &&
            // !checkduedate &&
            <div className='row '>
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error">
                <center><b><h6>No Data Available</h6></b></center>
                </div>
                </div>}
          {successstatus && reslist1.length != 0 &&
            // !checkduedate &&
            <div className=' '>
              <div class="row">

                <table
                  class="table"
                  readOnly="readOnly"

                >

                  <tr class="trst">
                    {/* <th><center>S.NO</center></th> */}
                    <th><center>Structure Id</center></th>
                    <th><center>Item of Work</center></th>
                    <th><center> Item of work Completed </center></th>
                    <th><center> Item of work Pending </center></th>
                    <th><center>Quantities Used</center></th>
                    <th><center>Quantities Pending</center></th>
                  </tr>

                  {reslist1 != 0 && reslist1.map((item, index) => {

                    //  const quan=item.quantities_instock || []
                    //  const quanlen=quan.length
                    // const own=item.owner || []
                    // const ownlen=own.length
                    const comp = item.item_of_works_completed || []
                    const complen = comp.length
                    const itemOfWorksPending = item.item_of_works_pending || []
                    const pendlen = itemOfWorksPending.length
                    const quanused = item.quantities_used || []
                    const quanlen = quanused.length
                    const quanpend = item.quantities_pending || []
                    const quanpendlen = quanpend.length
                    const itemw=item.item_of_works
                    const itemlen=itemw.length
                    return (
                      <tr key={index + 1} className='tablefontsize'>
                        {/* <td>

                          <center>{index}</center>
                        </td> */}

                        <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.structure_item_id}</center> </td>
                        <td><center>{itemlen!=0 ?item.item_of_works.join(', '):'NA'} </center></td>
                        <td><center>
                          {complen != 0 ? item.item_of_works_completed.join(', ') : 'NA'}
                        </center></td>
                        <td><center>
                          {pendlen != 0 ? item.item_of_works_pending.join(', ') : 'NA'}
                        </center></td>
                        <td><center>{quanlen!=0 ?item.quantities_used.join(', ') : 'NA'}</center></td>
                        <td><center>{quanpend!=0 ?item.quantities_pending.join(', ') : 'NA'}</center></td>
                      </tr>
                    )
                  })}
                </table>

              </div>

            </div>}
          {/*  */}



        </div>
      </div>







    </>
  )
}

export default FormPage
