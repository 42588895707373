import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal, Form } from 'react-bootstrap'
import './Modal.css'
import CreateRequest from '../CreateRequest/CreateRequest'
import { useState } from 'react'
import InputTextField from '../InputText/InputText'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import parse from 'html-react-parser'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Document, Page } from 'react-pdf';
export default function RenewalModel({ btnClick, status, imageData, imageUrl }) {
  const [projectstatus, setProjectstatus] = useState('')
  const [projectstatusValid, setProjectstatusValid] = useState(false)
  const [projectstatuserror, setProjectstatuserror] = useState('')
  const [paymentdate, setPaymentdate] = useState('')
  const [paymentdateValid, setPaymentdateValid] = useState(false)
  const [paymentdateeerror, setPaymentdateerror] = useState('')
  const [fileattach, setFileattach] = useState('')
  const [fileattacherror, setFileattacherror] = useState('')
  const [fileattachValid, setFileattachValid] = useState(false)
  const [message, setMessage] = useState('')
  const hiddenFileInput = React.useRef(null)
  const [purchaseorder, setPurchaseorder] = useState('')

  // const dataUri = `data:application/pdf;base64,${imageData}`;
  const dataUri = imageData;

  const userData = useSelector((state) => state.userDetails)
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }
  const [modalVisible, setModalVisible] = useState(status);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [status, modalVisible])

  function handleProjectstatus(e) {
    ////console.log(e.target.value)
    setProjectstatus(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleChangeFile(event) {
    const file = event.target.files[0]
    // let filename = file.name
    setFileattach(file)
    validateField(event.target.name, event.target.files[0])
  }
  function handlepaymentdate(e) {

    setPaymentdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {
    if (fieldName === 'projectstatus') {

      if (value.length != 'Select') {
        setProjectstatuserror('')
        setProjectstatusValid(true)
      } else {
        setProjectstatuserror('Please select your project status')
        setProjectstatusValid(false)
      }
    }



    if (fieldName === 'fileattach') {
      const filesize1 = fileattach.size
      if (filesize1 != 0) {
        setFileattacherror('')
        setFileattachValid(true)
      } else {
        setFileattacherror('Please attach your payment file')
        setFileattachValid(false)
      }
    }
  }
  function handlemessage(e) {

    ////console.log(message)

    setMessage(e.target.value.replace(/\s+/g, ''))

    validateField(e.target.name, e.target.value)

  }


  function onSubmitEvent(event) {
    event.preventDefault()

    ////console.log(projectstatus)
    ////console.log(paymentdate)
    ////console.log(fileattach)

    // ////console.log(name)
    // ////console.log(phno)
    if (fileattachValid && projectstatusValid) {
      let userData = new FormData()
      //  userData.append('project_id',ProjectId )
      userData.append('payment_proof', fileattach)
      userData.append('renewal_status', projectstatus)
      userData.append('comment', message)

      axios({
        method: 'PUT',

        url: baseurl + '/api/project/renewal/update/status',

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
        data: userData
      })

        .then((response) => {
          // ////alert("success renewal"+ProjectId)
        })
        .catch((error) => {
          ////console.log(error)
          // setErrormsg(error.response.data.message)
        })

    }
    setModalVisible(false)
  }

function onDocumentLoadSuccess({ numPages }) {
  //console.log('Number of pages: ', numPages);
}

  return (
    <>
      <Modal size='md' show={modalVisible} onHide={btnClick} id="modal-siz" >
        <Modal.Body>
          <div class="pt-3 bg-white mx-auto text-center">
            
              <a href={imageUrl} target="_blank" rel="noopener noreferrer">
              
              <button class="Btn ml-3 mb-2">
                <svg class="svgIcon" viewBox="30 90 300 400" height="10px" 
                      xmlns="http://www.w3.org/2000/svg">
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>
                <span class="icon2"></span>
                {/* <span class="tooltip">Download Condition Of Agreement</span> */}
              </button>
                
              </a>
              {/* <Document
        file={imageUrl}  // Pass the Blob URL to the PDF viewer
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={1} />
      </Document> */}
              
              {/* //base64 image  */}
              <iframe   src={imageUrl} width="100%" height="650px"  type="application/pdf"/>
              {/* <embed src={imageUrl} width="100%" height="500px" /> */}
          {/* <object data={imageUrl} type="application/pdf" width="100%" height="500px">
  <p>PDF cannot be displayed.</p>
</object> */}

              
              <center className='border'>
              {/* Direct img render */}
              {/* {parse(imageData.toString())} */}
            </center>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
