import React, { useState, useEffect } from 'react';
import './Subscription.css';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaPlusCircle } from 'react-icons/fa';
import { AiOutlinePlusCircle } from 'react-icons/ai'; // Fixed the import path
import RequestModal from '../Modal/CreateRequest';
import StatusupdateModal from '../Modal/Edit';
import Renewal from '../Modal/Renewal';
import ViewTickets from '../Modal/ViewTickets';
import { baseurl } from '../BaseUrl/Baseurl';
import cancelledimg from '../../assets/images/Status/cancelled.png';
import acceptedimg from '../../assets/images/Status/accepted.png';
import pendingimg from '../../assets/images/Status/pending.png';
import axios from 'axios';
import close from '../../assets/images/Status/closed.png';
import open from '../../assets/images/Status/open.png';
import renewal from '../../assets/images/Status/renewal.png';
import renewed from '../../assets/images/Status/renewed.png';
import suspended from '../../assets/images/Status/suspended.png';
import { unhover } from '@testing-library/user-event/dist/hover';
import { connect, useDispatch, useSelector } from 'react-redux'
const WeareHere = () => {
  const [btnname, setBtnname] = useState('');
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [project_renewalid, setProject_renewalid] = useState('');
  const [ticketno, setTicketno] = useState('');
  const [successstatus, setSuccessstatus] = useState(false)
  const userData = useSelector((state) => state.userDetails)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    axios({
      method: 'GET',
      url: baseurl + '/api/project/renewal/list',
      headers: {
        'Content-Type': 'application/json', 
 Authorization: 'Bearer ' +userData.token,
      },
    })
      .then((response) => {
        const renewalData = response.data;
        setRenewallist(renewalData);
        setSuccessstatus(true)
      })
      .catch((error) => {
        ////console.log(error);
      });

    axios({
      method: 'GET',
      url: baseurl + '/api/support/issue/ticket/list',
      headers: {
        'Content-Type': 'application/json', 
        Authorization: 'Bearer ' +userData.token,
      },
    })
      .then((response) => {
        const TicketData = response.data;
        setTicketlist(TicketData);
       // setLoading(false)
      })
      .catch((error) => {
        ////console.log(error);
      });
  }, []);




  const toggleRenewalStatus = () => {
    setRenewalstatus(false);

    //////alert("modal false is running")
  };

  const toggleViewTicketStatus = () => {
    setViewticketstatus(false);
  };

  const requestdata = () => {
    setRequeststatus(true);
  };



  const RenewalUpdate = (id) => {
    setProject_renewalid(id);
    //////alert("Before"+renewalstatus)
    setRenewalstatus(true);
    //////alert("After"+renewalstatus)
  };

  const ViewticketstatusUpdate = (id) => {
    setTicketno(id);
    setViewticketstatus(true);
  };

  return (
    <div className="cont-margin1">
      <div className='cont-margin-latest pt-5'>
        <div className="table1bdstyle pt-5">
          <div className="row table1st">
            <div className="pt-5pb-1 padheadleft1 ff">
              <h5>
                <b>Upcoming Renewal</b>
              </h5>
            </div>
            { renewallist.length == 0 && successstatus &&
            // !checkduedate &&
           
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error">
                <center><b><h6>No Data Available</h6></b></center>
             
                </div>}
                {renewallist.length !== 0 &&    <table className="table tablemargin" readOnly="readOnly">
              <tr className="trst">
                <th>
                  <div>
                    <center>Project ID</center>
                  </div>
                </th>
                <th>
                  <center>Contact Person</center>
                </th>
                <th>
                  <center>Renewal Date</center>
                </th>
                <th>
                  <center>Last Renewal Date</center>
                </th>
                <th>Status</th>
                <th>
                  <center>Edit</center>
                </th>
              </tr>
              {renewalstatus && (
                <Renewal
                  status={renewalstatus}
                  btnClick={toggleRenewalStatus}
                  ProjectId={project_renewalid}
                />

              )}
              
              {renewallist.length !== 0 &&
                renewallist.map((item, index) => {
                  return (
                    <tr key={index} className="tablefontsize">
                      <td>
                        <div>
                          <center>{item.project_id}</center>
                        </div>
                      </td>
                      <td>
                        <center>{item.contact_person}</center>
                      </td>
                      <td>
                        <center>{item.next_renewal_date!=undefined? item.next_renewal_date:'NA'}</center>
                      </td>
                      <td>
                        <center>{item.last_renewal_date!=undefined? item.last_renewal_date:'NA'}</center>
                      </td>
                      <td>
                        {item.renewal_status === 'cancelled' ? (
                          <img src={cancelledimg} className="leftimg" />
                        ) : item.renewal_status === 'renewed' ? (
                          <img src={renewed} className="leftimg" />
                        ) : item.renewal_status === 'suspended' ? (
                          <img src={suspended} className="leftimg" />
                        ) : (
                          <img src={renewal} className="leftimg" />
                        )}
                      </td>
                      <td>
                        <center>
                          <button
                            type="button"
                            className="modalbtn"
                            onClick={() => RenewalUpdate(item.project_id)}
                          >
                            <b>
                              <FaEdit />
                            </b>
                          </button>
                        </center>
                      </td>
                    </tr>
                  );
                })}
            </table>
}
          </div>
        </div>

      </div>
    </div>
  );
};

export default WeareHere;
