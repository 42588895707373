import React,{useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal, Form } from 'react-bootstrap'
import './Modal.css'
import CreateRequest from '../CreateRequest/CreateRequest'
import { useState} from 'react'
import InputTextField from '../InputText/InputText'
import {baseurl} from '../BaseUrl/Baseurl'
import PDF from '../Doc/SarasuPrivacyPolicy.pdf'
import axios from 'axios'
import parse from 'html-react-parser'
import { connect, useDispatch, useSelector } from 'react-redux'
export default function RenewalModel({ btnClick, status,imageData ,imageUrl}) {
  const [projectstatus, setProjectstatus] = useState('')
  const [projectstatusValid, setProjectstatusValid] = useState(false)
  const [projectstatuserror, setProjectstatuserror] = useState('')
  const [paymentdate, setPaymentdate] = useState('')
  const [paymentdateValid, setPaymentdateValid] = useState(false)
  const [paymentdateeerror, setPaymentdateerror] = useState('')
  const [fileattach, setFileattach] = useState('')
  const [fileattacherror, setFileattacherror] = useState('')
  const [fileattachValid, setFileattachValid] = useState(false)
  const [message, setMessage] = useState('')
  const hiddenFileInput = React.useRef(null)
  const [purchaseorder, setPurchaseorder] = useState('')
  const dataUri = `data:application/pdf;base64,${imageData}`;
  const userData = useSelector((state) => state.userDetails)
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }
  const [modalVisible, setModalVisible] = useState(status);
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    



    }, [status,modalVisible])

  function handleProjectstatus(e) {
   //console.log(e.target.value)
    setProjectstatus(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  
  function handleChangeFile(event) {
    const file = event.target.files[0]
    // let filename = file.name
    setFileattach(file)
    validateField(event.target.name, event.target.files[0])
  }
  function handlepaymentdate(e) {

    setPaymentdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {
    if (fieldName === 'projectstatus') {
   
      if (value.length != 'Select') {
        setProjectstatuserror('')
        setProjectstatusValid(true)
      } else {
        setProjectstatuserror('Please select your project status')
        setProjectstatusValid(false)
      }
    }
    
 
 
    if (fieldName === 'fileattach') {
     const filesize1 = fileattach.size
      if (filesize1 != 0) {
          setFileattacherror('')
          setFileattachValid(true)
        } else {
          setFileattacherror('Please attach your payment file')
          setFileattachValid(false)
        }
      }
  }
  function handlemessage(e) {

    //console.log(message)

    setMessage(e.target.value.replace(/\s+/g, ''))

    validateField(e.target.name, e.target.value)

  }


  function onSubmitEvent(event) {
    event.preventDefault()

  //console.log(projectstatus)
  //console.log(paymentdate)
  //console.log(fileattach)

      // //console.log(name)
      // //console.log(phno)
if(fileattachValid && projectstatusValid)
{
        let userData1 = new FormData()
      //  userData.append('project_id',ProjectId )
        userData1.append('payment_proof', fileattach)
        userData1.append('renewal_status', projectstatus)
        userData1.append('comment', message)
       
          axios({
            method: 'PUT',
    
            url: baseurl + '/api/project/renewal/update/status', userData1,
    
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token,
            }
          })
          .then((response) => {
            // ////alert("success renewal"+ProjectId)
          })
          .catch((error) => {
             //console.log(error)
           // setErrormsg(error.response.data.message)
          })
 
        }
    setModalVisible(false)
  }

  return (
    <>
      <Modal show={modalVisible} onHide={btnClick} id="modal-siz" >
        <Modal.Body >
          {/* <div class="pt-3 ">
        <center> <a href={imageUrl} target="_blank" rel="noopener noreferrer">
      Download Purchase Order
    </a></center>
    </div> */}
          <div >
   {/* //base64 image  */}
 {/* <iframe src={dataUri} width="100%" height="650px" /> */}
{/* Direct img render */}
<div id="plan">
                    {parse(imageData.toString())}
                    </div>
            </div>
           
         
        </Modal.Body>
      </Modal>
    </>
  )
}
