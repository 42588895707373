import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({graph1,graph2,graph1data,graph2data,xaxisdata,xaxistext,yaxistext,colorList}) => {

const state = {
  series: [
    {
      name: graph1,
      data: graph1data || [], 
    },
    {
      name: graph2,
      data: graph2data || [],
    },
   
  ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        menu: false, // Hide the context menu
        toolbar: 
        { show: true, 
         tools: { 
           download: false, 
           selection: false,
            zoom: false, 
            zoomin: false,
             zoomout: false,
              pan: false,
              reset: false }, },
      },
      xaxis: {
        categories: xaxisdata || [],
        title: {
          text: xaxistext,
        },
        axisBorder: {
          show: true,
          color: '#000000', // Set the desired color for the x-axis line
          height: 2, // Set the desired height for the x-axis line
        }, 
      },
      yaxis: {
        title: {
          text: yaxistext,
        },
        axisBorder: {
          show: true,
          color: '#000000', // Set the desired color for the y-axis line
          width: 2, // Set the desired width for the y-axis line
        },
      },
     
      fill: {
        opacity: 1,
      },
       colors: colorList,
      legend: {
        position: 'top',
        horizontalAlign: 'left',
      },
      
    },
   
  };
  return (
    <div id="chart">
      <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
    </div>
  );
};

export default ApexChart;