import React, { useState, useEffect } from "react";
import { RxSlash } from "react-icons/rx";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import "./EngineerHome.css";
import CustomToastContainer from "../../CustomToastContainer";
import { Modal } from "react-bootstrap";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import billhead from "../../assets/brickdemoicons/bill-head.png";
import bill from "../../assets/brickdemoicons/bill.png";
import ReactPaginate from "react-paginate";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
function Billing() {
  const [tableShow, setTableShow] = useState(false);
  const [typeOfWork, setTypeOfWork] = useState([]);
  const [structure, setStructure] = useState([]);
  const [billeddata, setBilleddata] = useState([]);
  const [billdisplay, setBilldisplay] = useState(false);
  const [err, setErr] = useState("");
  const userData = useSelector((state) => state.userDetails);
  const [searchData, setSearchData] = useState([]);
  const [filteredChainage, setFilteredChainage] = useState([]);
  const [successstatus, setSuccessstatus] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Set items per page
  const pageCount = Math.ceil(searchData.length / itemsPerPage);

  // Calculate start and end index for current page
  const startIndex = currentPage * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, searchData.length);
  
  // Slice the data for the current page
  const currentItems = searchData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  useEffect(() => {
    setCurrentPage(0);
  }, [searchData]);

  // Handle page click
  const handlePageClick = (event) => {
    setCurrentPage(event.selected); // Update currentPage state
  };


  const [formData, setFormData] = useState({
    from: "",
    from1: "",
    to: "",
    to1: "",
    type: "",
    structure: "",
  });
  const [messageList, setMessageList] = useState([]);
  const [digiter, setDigiter] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const [isChecked, setIsChecked] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [billlist, setBilllist] = useState([]);
  const [viewbilldata, setViewbilldata] = useState([]);

  const [billliststatus, setBillliststatus] = useState(false);

  const [viewbilldata1, setViewbilldata1] = useState([]);
  // Handle individual item selection

  // Handle "Select All" or "Deselect All"
  const handleSelectAllChange = () => {
    if (selectedItems.length === searchData.length) {
      // If all items are selected, deselect all
      setSelectedItems([]);
    } else {
      // If not all items are selected, select all
      const allItems = searchData.map((item) => ({
        tID: item.table_id,
        qty: item.qty,
        item_of_work: item.item_of_work,
      }));
      setSelectedItems(allItems);
    }

    //////console.log("All select"+JSON.stringify(selectedItems))
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessageList([]);

    if (name === "from") {
      setFormData({
        from: value,
        from1: "",
        to: "",
        to1: "",
        type: "",
        structure: "",
      });
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "from1") {
      setFormData((prev) => ({
        ...prev,
        from1: value,
        to: "",
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "to") {
      setFormData((prev) => ({
        ...prev,
        to: value,
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "to1") {
      setFormData((prev) => ({
        ...prev,
        to1: value,
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "type") {
      setFormData((prev) => ({
        ...prev,
        type: value,
        structure: "",
      }));
      
      setStructure([]);
      setSuccessstatus(false)
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setSuccessstatus(false)
    }

    const digit = e.target.value.replace(/\D/g, "");
    if (e.target.name === "from1" || e.target.name === "to1") {
      if (value.length != 3 && name === "from1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in from field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      if (value.length !== 3 && name === "to1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in to field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }

      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: digit };

        if (parseFloat(updatedData.to) <= parseFloat(updatedData.from)) {
          if (parseFloat(updatedData.from1) >= parseFloat(updatedData.to1)) {
            const gt_check = "Enter values greater than start chainage";
            setMessageList((prevMessages) => [...prevMessages, gt_check]);
          } else {
            setErr("");
          }
        }

        return updatedData;
      });
    }

    if (e.target.name === "from") {
      const selectedValue = parseInt(e.target.value, 10);
      const filtered = userData.chainageArr.filter(
        (value) => value >= selectedValue
      );
      setFilteredChainage(filtered);
    }

    //type of work , http://localhost:8080/brick/api/work_details/get/type_of_work?project_id=PR-2&start_chainage=2.03&end_chainage=5.7
    if (formData.from != "" && formData.from1 != "" && formData.to!="") {
    
      if (e.target.name==="to1") {
          axios
            .get(`${baseurl}/api/work_details/get/type_of_work`, {
              params: {
                project_id: userData.projectId,
                start_chainage: `${formData.from}.${formData.from1}`,
                end_chainage: `${formData.to}.${e.target.value}`,
              },
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userData.token}`,
              },
            })
            .then((response) => {
              const data = response.data;
              const type_of_work_list = data.type_of_work_list;
              setTypeOfWork(type_of_work_list);
              // ////////alert("type of work", type_of_work_list);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      
    }

    //structure //http://localhost:8080/brick/api/work_details/get/strs_or_layers?project_id=PR-2&type_of_work=TYPE1
    if (e.target.name === "type" && e.target.value != "") {
      axios
        .get(`${baseurl}/api/work_details/get/strs_or_layers`, {
          params: {
            project_id: userData.projectId,
            type_of_work: e.target.value,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const strs_or_layers_list = data.strs_or_layers_list;
          setStructure(strs_or_layers_list);
          //////console.log(strs_or_layers_list);
          // ////////alert(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    setTableShow(false)

  };

  const validate = () => {
    if (
      formData.from === "" ||
      formData.from1 === "" ||
      formData.to === "" ||
      formData.to1 === "" ||
      formData.type === "" ||
      formData.structure === ""
    ) {
      const empty_check = "Please fill all the fields";
      setMessageList((prevMessages) => [...prevMessages, empty_check]);
    } else {
      axios
        .get(
          `${baseurl}/api/work_details/get/str_or_layer_ids?rqd_for=billing&`,
          {
            params: {
              project_id: userData.projectId,
              strs_or_layers: formData.structure,
              type_of_work: formData.type,
              start_chainage: `${formData.from}.${formData.from1}`,
              end_chainage: `${formData.to}.${formData.to1}`,
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userData.token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data.str_or_layer_ids_data;
          //////console.log(data);
          ////////alert(JSON.stringify(data))
          setSuccessstatus(true)
          setSearchData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      setTableShow(true);
      setErr("");
    }
  };

  const handleSubmit = (e) => {
    setMessageList([]);

    e.preventDefault();
    validate();
  };
  const handleItemChange = (item_of_work, qty, tid) => {
    setSelectedItems((prevSelectedItems) => {
      const found = prevSelectedItems.find((obj) => obj.tID === tid);
      if (!found) {
        //////console.log("Adding item:", { tID: tid, qty, item_of_work });
        return [...prevSelectedItems, { tID: tid, qty, item_of_work }];
      } else {
        //////console.log("Removing item:", { tID: tid });
        return prevSelectedItems.filter((item) => item.tID !== tid);
      }
    });
  };

  //   const handleItemChange = (itemofwork,qty,tid) => {

  //     // if (selectedItems.includes(item)) {
  //     //   setSelectedItems(selectedItems.filter((i) => i !== item));
  //     //   setIsChecked(false)
  //     // } else {
  //     //   setSelectedItems([...selectedItems, item]);
  //     //   setIsChecked(true)
  //     // }

  //     const found = selectedItems.find((obj) => {
  //       return obj.tID== tid
  //     })
  //     if (found === undefined) {
  //       //////console.log("Notfound running")
  //       selectedItems.push({
  //         tID: tid,
  //         qty: qty,
  //         item_of_work: itemofwork
  //       }
  //       )
  // //////console.log(JSON.stringify(selectedItems))

  //     }
  //     else {

  //         const updatedChecklist = selectedItems.filter(item => item.tID != tid);
  //         setSelectedItems(updatedChecklist);
  //         //////console.log("Deleted",JSON.stringify(selectedItems))
  //       }

  //   };
  const isCheckedchecking = (item) => {
    //////console.log(item.table_id)
    const checked = selectedItems.some(
      (selectedItem) => selectedItem.tID === item.table_id
    );
    //////console.log(`Is item ${item.tID} checked?`, checked);
    return checked;
  };

  useEffect(() => {
    //////console.log('Updated selectedItems:', selectedItems);
  }, [selectedItems]);

  const PrepareBill = () => {
    const data = {
      project_id: userData.projectId,
      bill_report_creation_data: selectedItems,
    };

    const apiUrl =
      `${baseurl}/api/bill_report/create?user_id=` + userData.userId;

    axios
      .post(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer" + " " + userData.engineertoken,
        },
      })

      .then((response) => {
        // ////////alert("Run")
        const data = response.data;
        //////console.log("success"+JSON.stringify(data))
        const billed = data.bill_report_data || [];
        ////////alert(JSON.stringify(billed))
        setBilleddata(billed);
        setShowImg(true);
      })
      .catch((error) => {
        console.log(error)
        //setErrormsg(error.response.data.message)
      });

    // setShowImg(true);
  };

  const viewBill = () => {
    axios({
      method: "GET",

      url:
        baseurl +
        "/api/bill_report/list/bills?project_id=" +
        userData.projectId +
        "&user_id=" +
        userData.userId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.engineertoken,
      },
    })
      .then((response) => {
        // ////////alert("Run")
        const data = response.data;
        const bill = data.bill_list || [];
        ////////alert(bill)

        // if(bill==0)
        // {
        //   setBilllist(["bill_1","bill_2"])
        // }
        // else{
        setBilllist(bill);
        //}

        setBillliststatus(true);
      })
      .catch((error) => {
        //////console.log(error)
        //setErrormsg(error.response.data.message)
      });

    // setShowImg(true);
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleImgClose = () => {
    setShowImg(false);
    setSelectedItems([]);
    setBilleddata([]);

    axios
      .get(
        `${baseurl}/api/work_details/get/str_or_layer_ids?rqd_for=billing&`,
        {
          params: {
            project_id: userData.projectId,
            strs_or_layers: formData.structure,
            type_of_work: formData.type,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data.str_or_layer_ids_data;
        //////console.log(data);
        ////////alert(JSON.stringify(data))
        setSearchData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handlebilllistclose = () => {
    setBillliststatus(false);
    setBilldisplay(false);
  };
  const handleClick = (item) => {
    //////console.log(`You clicked on: ${item}`);
    setBilldisplay(true);
    axios({
      method: "GET",

      url:
        baseurl +
        "/api/bill_report/view?project_id=" +
        userData.projectId +
        "&user_id=" +
        userData.userId +
        "&bill_id=" +
        item,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.engineertoken,
      },
    })
      .then((response) => {
        // ////////alert("Run")
        const data = response.data;
        const bill = data.bill_report_data || [];
        setViewbilldata1(bill);
        ////////alert(bill)
      })
      .catch((error) => {
        //////console.log(error)
        //setErrormsg(error.response.data.message)
      });
  };
  return (
    <>
      <CustomToastContainer messageList={messageList} />

      <div
        className="container-fluid w-100 method-cont ml-4"
        style={{
          position: "absolute",
          top: "140%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="row sh border bg-white mx-auto  pt-3 pb-3 rounded-pill border"
          style={{ maxWidth: "85%" }}
        >
          <div className="col-12 "></div>
          <div className="col-12  ">
            <div className="container ">
              <div className="row">
                <div className="col-1">
                  <label
                    className="form-label mb-0 mt-1"
                    style={{ fontWeight: "500", fontSize: "15px" }}
                  >
                    Chainage&nbsp;
                  </label>
                </div>
                <div className=" col-lg-5  ">
                  <div className="w-100  p-0">
                    <div className="row ">
                      <div className="col-lg-6 ">
                        <div class=" input-group">
                          <label
                            className="form-label mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            From :&nbsp;
                          </label>
                          <select
                            onChange={handleChange}
                            value={formData.from}
                            name="from"
                            className="form-control border rounded-0"
                            style={{
                              // height: "30px",
                              width: "30px",
                              fontSize: "12px",
                            }}
                          >
                            <option value="" disabled></option>
                            {userData.chainageArr != undefined &&
                              userData.chainageArr.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>

                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="from1"
                            type="text"
                            value={formData.from1}
                            onChange={handleChange}
                            className="form-control border rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div class="input-group">
                          <label
                            className="form-label  mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            To :&nbsp;
                          </label>

                          <select
                            name="to"
                            onChange={handleChange}
                            value={formData.to}
                            className="border form-control rounded-0"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {formData.from1.length == 3 &&
                              filteredChainage.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0 rounded-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="to1"
                            type="text"
                            value={formData.to1}
                            onChange={handleChange}
                            className=" border form-control rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 px-0 ">
                  <div className=" mx-auto w-100 text-center">
                    {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}

                    <select
                      name="type"
                      onChange={handleChange}
                      value={formData.type}
                      className=" border form-control rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Type of Work...</option>
                      {formData.to1.length == 3 &&
                        typeOfWork.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 ">
                  <div className="mx-auto w-100 text-center">
                    {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}

                    <select
                      name="structure"
                      onChange={handleChange}
                      value={formData.structure}
                      className=" border form-control rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="" disabled>Structure/Layer</option>
                      {structure.map((item, index) => (
                        <option
                          key={index}
                          value={
                            item === "Structures And Layers" ? "All" : item
                          }
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-center">
                  <div className="col-1"></div>

                  <button
                    disabled={successstatus ? true : false}

                    onClick={digiter == "" ? handleSubmit : ""}
                    className="btn text-center text-white rounded-pill mt-0 "
                    style={{
                      backgroundColor: "#1953AA",
                      fontSize: "14px",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
        {
          <div className="text-red er">
            <center>{digiter}</center>
          </div>
        }
      </div>
      { searchData.length==0 && successstatus &&
        <div
          className="w-75 "
          style={{
            position: "absolute",
            top: "230%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >     
         <center><div className="login-error"><b>No Data Available</b> </div></center> 
          </div>}
      {searchData.length!=0 && successstatus &&(
        <div>
          <div
            className="w-100  ml-4"
            style={{
              position: "absolute",
              top: "365%",
              left: "47%",
              transform: "translate(-50%,-50%)",
            }}
          >
            {" "}
            <div className="w-100 d-flex justify-content-end align-items-center pb-1 ">
              <button
                // disabled={selectedItems.length==0}
                // style={{backgroundColor: selectedItems.length==0? "":"#bbbbbb"

                // }}
                onClick={(e) => viewBill()}
                className="btn btn-sm text-center text-white   "
                style={{
                  backgroundColor: "orange",
                  fontSize: "14px",
                  borderRadius: "8px",
                  height:'35px'
                }}
              >
                Running Bills
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                // disabled={selectedItems.length==0}
                // style={{backgroundColor: selectedItems.length==0? "":"#bbbbbb"

                // }}
                onClick={(e) => PrepareBill(selectedItems)}
                class="Documents-btn  p-2   "
              >
                <span class="folderContainer">
                  <svg
                    class="fileBack"
                    width="175"
                    height="115"
                    viewBox="0 0 50 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 4C0 1.79086 1.79086 0 4 0H50.3802C51.8285 0 53.2056 0.627965 54.1553 1.72142L64.3303 13.4371C65.2799 14.5306 66.657 15.1585 68.1053 15.1585H141.509C143.718 15.1585 145.509 16.9494 145.509 19.1585V109C145.509 111.209 143.718 113 141.509 113H3.99999C1.79085 113 0 111.209 0 109V4Z"
                      fill="url(#paint0_linear_117_4)"
                    ></path>
                    <defs>
                      <linearGradient
                        id="paint0_linear_117_4"
                        x1="0"
                        y1="0"
                        x2="72.93"
                        y2="95.4804"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#8F88C2"></stop>
                        <stop offset="1" stop-color="#5C52A2"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                  <svg
                    class="filePage"
                    width="90"
                    height="50"
                    viewBox="-25 30 90 85"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="88"
                      height="99"
                      fill="url(#paint0_linear_117_6)"
                    ></rect>
                    <defs>
                      <linearGradient
                        id="paint0_linear_117_6"
                        x1="0"
                        y1="0"
                        x2="81"
                        y2="160.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white"></stop>
                        <stop offset="1" stop-color="#686868"></stop>
                      </linearGradient>
                    </defs>
                  </svg>

                  <svg
                    class="fileFront"
                    width="200"
                    height="80"
                    viewBox="0 0 65 85"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.29306 12.2478C0.133905 9.38186 2.41499 6.97059 5.28537 6.97059H30.419H58.1902C59.5751 6.97059 60.9288 6.55982 62.0802 5.79025L68.977 1.18034C70.1283 0.410771 71.482 0 72.8669 0H77H155.462C157.87 0 159.733 2.1129 159.43 4.50232L150.443 75.5023C150.19 77.5013 148.489 79 146.474 79H7.78403C5.66106 79 3.9079 77.3415 3.79019 75.2218L0.29306 12.2478Z"
                      fill="url(#paint0_linear_117_5)"
                    ></path>
                    <defs>
                      <linearGradient
                        id="paint0_linear_117_5"
                        x1="38.7619"
                        y1="8.71323"
                        x2="66.9106"
                        y2="82.8317"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#C3BBFF"></stop>
                        <stop offset="1" stop-color="#51469A"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <p
                  class="text mb-0 mr-1 text-nowrap"
                  style={{ fontSize: "12px" }}
                >
                  Bill Preparation
                </p>
              </button>
            </div>
            


            <div
              className=" border-0 table-bordered pt-0  pl-0"
              style={{ height: "340px" }}
            >
             <table className="mx-auto mt-0 table mb-1">
        <thead
          style={{
            background: "linear-gradient(to right,#4889CF,#144A9A)",
            fontSize: "14px",
            color: "white",
            position: "sticky",
            top: "0",
          }}
        >
          <tr>
            <th className="p-2">
              <input
                className="ui-checkbox"
                checked={selectedItems.length === searchData.length}
                onChange={handleSelectAllChange}
                type="checkbox"
              />
            </th>
            <th className="p-2">Item Of Work</th>
            {(formData.structure === "Structures" || formData.structure === "All" || formData.structure === "Structures And Layers") && (
              <th className="p-2">Structure ID</th>
            )}
            {(formData.structure === "Layers" || formData.structure === "All" || formData.structure === "Structures And Layers") && (
              <th className="p-2">Layer ID</th>
            )}
            <th className="p-2">Quantity</th>
            <th className="p-2">Status</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "11px" }}>
          {currentItems.map((item, index) => (
            <tr key={index}>
              <td className="p-1" style={{ color: "#FF004D", fontSize: "12px" }}>
                <input
                  type="checkbox"
                  checked={isCheckedchecking(item)}
                  onChange={() =>
                    handleItemChange(item.item_of_work, item.qty, item.table_id)
                  }
                />
              </td>
              <td className="p-1">{item.item_of_work}</td>
              {(formData.structure === "Structures" || formData.structure === "All" || formData.structure === "Structures And Layers") && (
                <td className="text-dark p-1 " style={{ fontSize: "12px" }}>
                  {item.str_id}
                </td>
              )}
              {(formData.structure === "Layers" || formData.structure === "All" || formData.structure === "Structures And Layers") && (
                <td className="text-dark p-1 " style={{ fontSize: "12px" }}>
                  {item.layer_id}
                </td>
              )}
              <td className="p-1" style={{ fontSize: "12px" }}>
                {item.qty === 0 ? "" : item.qty + "\u00A0"}
                {item.unit}
              </td>
              <td className="p-1" style={{ fontSize: "12px" }}>{item.bill_status}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex w-100 justify-content-between  marg2 mx-auto align-items-center">
        <p className="mb-0" style={{ fontWeight: "500" }}>
          Showing &nbsp;
          <span style={{ fontWeight: "bold" }}>{startIndex}</span> to{" "}
          <span style={{ fontWeight: "bold" }}>{endIndex}</span> of {searchData.length}
        </p>
        <ReactPaginate
          breakLabel="..."
          nextLabel={<GrFormNext />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel={<GrFormPrevious />}
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          activeClassName={"active"}
          forcePage={currentPage} // Reset current page to active page
        />
      </div>

              {showImg && (
                <Modal
                  size="xl"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={true}
                  animation={true}
                >
                  <Modal.Body>
                    <div className=" bg-white ">
                      <div className="w-100 d-inline-flex justify-content-end pr-2">
                        <button
                          className="btn p-0 bg-white"
                          onClick={handleImgClose}
                        >
                          <IoMdClose />
                        </button>
                      </div>
                      <div
                        className="text-center px-3 pb-5"
                        style={{
                          overflowX: "auto",
                          maxHeight: "500px",
                          overflowY: "auto",
                        }}
                      >
                        <center>
                          <h4>
                            <b>{userData.projectName}</b>
                          </h4>
                        </center>
                        <table
                          style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                          <thead>
                            <tr style={{ backgroundColor: "#E4E4E4" }}>
                              {/* Adjust the column names based on your data structure */}
                              <th className="table-header">S.No</th>
                              <th className="table-header">Agt.No.</th>
                              <th className="table-header">Content</th>
                              <th className="table-header">Agt.Qty.</th>
                              <th className="table-header">Bill.Qty.</th>
                              <th className="table-header">Balance.Qty.</th>
                              <th className="table-header">Rate</th>
                              <th className="table-header">Agt.Amount</th>
                              <th className="table-header">Bill.AMount</th>
                              <th className="table-header">Balance Amount </th>
                            </tr>
                          </thead>
                          <tbody>
                            {billeddata.length != 0 &&
                              billeddata.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #000000",
                                      color: "black",
                                    }}
                                  >
                                    {index+1}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #000000",
                                      color: "black",
                                    }}
                                  >
                                    {item.agt_no}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #000000",
                                      color: "black",
                                    }}
                                  >
                                    {item.content}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #000000",
                                      color: "black",
                                    }}
                                  >
                                    {item.agt_qty}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #000000",
                                      color: "black",
                                    }}
                                  >
                                    {item.billed_qty}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #000000",
                                      color: "black",
                                    }}
                                  >
                                    {item.balance_qty}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #000000",
                                      color: "black",
                                    }}
                                  >
                                    {item.rate}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #000000",
                                      color: "black",
                                    }}
                                  >
                                    {item.agt_amount}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #000000",
                                      color: "black",
                                    }}
                                  >
                                    {item.billed_amount}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #000000",
                                      color: "black",
                                    }}
                                  >
                                    {item.balance_amount}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}

              {billliststatus && (
                <Modal
                  size={billdisplay ? "xl" : "md"}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={true}
                  animation={true}
                >
                  <Modal.Body className="">
                    <div className=" bg-white modal-bdy ">
                      <div
                        className=" p-3  modal-bdy1 d-flex justify-content-end"
                        style={{
                          backgroundColor: "#65DEC6",
                          backgroundImage: `url(${billhead})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "40px",
                        }}
                      >
                        <button
                          className="btn btn-sm p-0  "
                          onClick={handlebilllistclose}
                          style={{ height: "20px", width: "20px" }}
                        >
                          <IoMdCloseCircle
                            className="mb-1 rounded-circle"
                            style={{
                              color: "#65DEC6",
                              backgroundColor: "white",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          />
                        </button>
                      </div>

                      <div
                        className="text-center m-3 pb-5"
                        style={{
                          overflowX: "auto",
                          maxHeight: "500px",
                          overflowY: "auto",
                        }}
                      >
                        {!billdisplay && (
                          <div>
                            <div class="">
                              <center>
                                <h6 className="mb-0">
                                  <b>Running Bill List</b>
                                </h6>
                              </center>
                            </div>
                            <table
                              className="mb-0"
                              style={{ listStyleType: "none", padding: "10px" }}
                            >
                              {billlist.length != 0 &&
                                billlist.map((item, index) => (
                                  <tbody>
                                    <tr>                                      
                                      <td >
                                      <img src={bill} alt="" width={20} />
                                      &nbsp;&nbsp;
                                        <button
                                          onClick={() => handleClick(item)}
                                          onMouseEnter={() =>
                                            setHoveredIndex(index)
                                          }
                                          onMouseLeave={() =>
                                            setHoveredIndex(null)
                                          }
                                          style={{
                                            background: "none",
                                            color:
                                              hoveredIndex === index
                                                ? "#fc0377"
                                                : "#000000",
                                            border: "none",
                                            padding: "0",
                                            cursor: "pointer",

                                            fontSize: "inherit",
                                          }}
                                        >
                                          {/* {index + 1}&nbsp;.&nbsp;{" "} */}
                                          {item.replace(/_/g, " ")}
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                            </table>
                          </div>
                        )}
                        {billdisplay && (
                          <table
                            style={{
                              width: "100%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead>
                              <tr style={{ backgroundColor: "#E4E4E4" }}>
                                {/* Adjust the column names based on your data structure */}
                                <th className="table-header">S.No</th>
                                <th className="table-header">Agt.No.</th>
                                <th className="table-header">Content</th>
                                <th className="table-header">Agt.Qty.</th>
                                <th className="table-header">Bill.Qty.</th>
                                <th className="table-header">Balance.Qty.</th>
                                <th className="table-header">Rate</th>
                                <th className="table-header">Agt.Amount</th>
                                <th className="table-header">Bill.AMount</th>
                                <th className="table-header">
                                  Balance Amount{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {viewbilldata1.length != 0 &&
                                viewbilldata1.map((item, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        padding: "8px",
                                        border: "1px solid #000000",
                                        color: "black",
                                      }}
                                    >
                                      {index+1}
                                    </td>
                                    <td
                                      style={{
                                        padding: "8px",
                                        border: "1px solid #000000",
                                        color: "black",
                                      }}
                                    >
                                      {item.agt_no}
                                    </td>
                                    <td
                                      style={{
                                        padding: "8px",
                                        border: "1px solid #000000",
                                        color: "black",
                                      }}
                                    >
                                      {item.content}
                                    </td>
                                    <td
                                      style={{
                                        padding: "8px",
                                        border: "1px solid #000000",
                                        color: "black",
                                      }}
                                    >
                                      {item.agt_qty}
                                    </td>
                                    <td
                                      style={{
                                        padding: "8px",
                                        border: "1px solid #000000",
                                        color: "black",
                                      }}
                                    >
                                      {item.billed_qty}
                                    </td>
                                    <td
                                      style={{
                                        padding: "8px",
                                        border: "1px solid #000000",
                                        color: "black",
                                      }}
                                    >
                                      {item.balance_qty}
                                    </td>
                                    <td
                                      style={{
                                        padding: "8px",
                                        border: "1px solid #000000",
                                        color: "black",
                                      }}
                                    >
                                      {item.rate}
                                    </td>
                                    <td
                                      style={{
                                        padding: "8px",
                                        border: "1px solid #000000",
                                        color: "black",
                                      }}
                                    >
                                      {item.agt_amount}
                                    </td>
                                    <td
                                      style={{
                                        padding: "8px",
                                        border: "1px solid #000000",
                                        color: "black",
                                      }}
                                    >
                                      {item.billed_amount}
                                    </td>
                                    <td
                                      style={{
                                        padding: "8px",
                                        border: "1px solid #000000",
                                        color: "black",
                                      }}
                                    >
                                      {item.balance_amount}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Billing;
