import React, { useEffect, useState } from "react";
import search1 from "../../assets/brickdemoicons/finder png 1.png";
import { BsFillStarFill } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { FcGallery } from "react-icons/fc";
import { FaFilePdf } from "react-icons/fa";

import Cad from "../../assets/brickdemoicons/cad.png";
import Pdf from "../../assets/brickdemoicons/pdf-color-red-icon.png";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";

import "./Completedwork.css";
import { FaSearch } from "react-icons/fa";
import { RxSlash } from "react-icons/rx";
import ReactPaginate from "react-paginate";

import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { baseurl } from "../BaseUrl/Baseurl";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import Arr from '../../assets/brickdemoicons/Arrow 1.png'
import CustomToastContainer from "../../CustomToastContainer";
import { FiDownload } from "react-icons/fi";
import nodata from "../../assets/brickdemoicons/nodata.png";

export default function Completedworklist() {
  // const Completedworklist = [
  //   {
  //     Chainage: "1/000-2/003",
  //     ItemofWork: "Earthwork",
  //     TestingDependency: Pdf,
  //     CadImage: Cad,
  //     gallery: <FcGallery style={{ fontSize: "30px" }} />,
  //     Structure: "YUP",
  //     TypeofWork: "Type 2",
  //   },
  //   {
  //     Chainage: "2/560-2/803",
  //     ItemofWork: "Soil Filling",
  //     TestingDependency: Pdf,
  //     CadImage: Cad,
  //     gallery: <FcGallery style={{ fontSize: "30px" }} />,
  //     Structure: "ccc",
  //     TypeofWork: "Type 4",
  //   },
  //   {
  //     Chainage: "2/803-3/542",
  //     ItemofWork: "WMM",
  //     TestingDependency: Pdf,
  //     CadImage: Cad,
  //     gallery: <FcGallery style={{ fontSize: "30px" }} />,
  //     Structure: "YUP",
  //     TypeofWork: "Type 6",
  //   },
  //   {
  //     Chainage: "1/000-2/003",
  //     ItemofWork: "Earthwork",
  //     TestingDependency: Pdf,
  //     CadImage: Cad,
  //     gallery: <FcGallery style={{ fontSize: "30px" }} />,
  //     Structure: "YUP",
  //     TypeofWork: "Type 2",
  //   },
  //   {
  //     Chainage: "1/000-2/003",
  //     ItemofWork: "Earthwork",
  //     TestingDependency: Pdf,
  //     CadImage: Cad,
  //     gallery: <FcGallery style={{ fontSize: "30px" }} />,
  //     Structure: "YUP",
  //     TypeofWork: "Type 2",
  //   },
  //   {
  //     Chainage: "1/000-2/003",
  //     ItemofWork: "Earthwork",
  //     TestingDependency: Pdf,
  //     CadImage: Cad,
  //     gallery: <FcGallery style={{ fontSize: "30px" }} />,
  //     Structure: "YUP",
  //     TypeofWork: "Type 2",
  //   },
  //   {
  //     Chainage: "1/000-2/003",
  //     ItemofWork: "Earthwork",
  //     TestingDependency: Pdf,
  //     CadImage: Cad,
  //     gallery: <FcGallery style={{ fontSize: "30px" }} />,
  //     Structure: "YUP",
  //     TypeofWork: "Type 2",
  //   },
  // ];

  const itemsPerPage = 10;
  const [data,setData]=useState([])
  const [cadShow, setCadShow] = useState(false);
  const [galShow, setGalShow] = useState(false);
  const [cadImg, setCadImg] = useState([]);
  const [galImg, setGalImg] = useState([]);
  const [testingdep, setTestingdep] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  // const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [showModal, setShowModal] = useState(false);
  const [tableShow, setTableShow] = useState(false);
  const [typeOfWork, setTypeOfWork] = useState([]);
  const [structure, setStructure] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [searchsts, setSearchsts] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [successstatus, setSuccessstatus] = useState(false);
  const [testname, setTestname] = useState('');
  const [testpdf, setTestpdf] = useState('');
  const [testresult, setTestresult] = useState('');

  const startIndex = itemOffset + 1;
  const endIndex = Math.min(itemOffset + itemsPerPage, filteredData.length);
  
  const userData = useSelector((state) => state.userDetails);

  

  const handleGalOpen = (img) => {

    setGalImg(img);
    setGalShow(true);
  };

  const handleGalClose = () => {
    setGalShow(false);
    setGalImg("");
  };
  const handleCadOpen = (img) => {
   // //alert(img)
    setCadImg(img);
    setCadShow(true);
  };

  const handleCadClose = () => {
    setCadShow(false);
    setCadImg("");
  };


  // useEffect(() => {
  //   const endOffset = itemOffset + itemsPerPage;
  //   setCurrentItems(filteredData.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(filteredData.length / itemsPerPage));
  // }, [itemOffset, filteredData]);

  let samplegallery=["https://bricklane.s3.ap-south-1.amazonaws.com/6681a631-ab69-48e5-a9aa-58e8f427fa9d.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241016T103923Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241016%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=a797d00f9918267cfae824f560e8b3c5e8f72a2bef20b84d34b3286931fe6c2b",
    "https://bricklane.s3.ap-south-1.amazonaws.com/6681a631-ab69-48e5-a9aa-58e8f427fa9d.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241016T103923Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241016%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=a797d00f9918267cfae824f560e8b3c5e8f72a2bef20b84d34b3286931fe6c2b"]



 const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    // Filter data based on search term
    const filtered = data.filter(
      (item) =>
       item.structure.toLowerCase().includes(value) ||
        item.type_of_work.toLowerCase().includes(value) ||
        item.item_of_work.toLowerCase().includes(value) ||
        item.layer_id.toString().includes(value)
    );

    setFilteredData(filtered);
    setItemOffset(0); // Update filtered data
  };

  const [filteredChainage, setFilteredChainage] = useState([]);
  const [digiter, setDigiter] = useState("");

  const [formData, setFormData] = useState({
    from: "",
    from1: "",
    to: "",
    to1: "",
    type: "",
    structure: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessageList([]);
    setSearchsts(false)


    if (name === "from") {
      setFormData({
        from: value,
        from1: "",
        to: "",
        to1: "",
        type: "",
        structure: "",
      });
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "from1") {
      setFormData((prev) => ({
        ...prev,
        from1: value,
        to: "",
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "to") {
      setFormData((prev) => ({
        ...prev,
        to: value,
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "to1") {
      setFormData((prev) => ({
        ...prev,
        to1: value,
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "type") {
      setFormData((prev) => ({
        ...prev,
        type: value,
        structure: "",
      }));
      
      setStructure([]);
      setSuccessstatus(false)
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setSuccessstatus(false)
    }

    const digit = e.target.value.replace(/\D/g, "");
    if (e.target.name === "from1" || e.target.name === "to1") {
      if (value.length != 3 && name === "from1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in from field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      if (value.length !== 3 && name === "to1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in to field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }

      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: digit };

        if (parseFloat(updatedData.to) <= parseFloat(updatedData.from)) {
          if (parseFloat(updatedData.from1) >= parseFloat(updatedData.to1)) {
            const gt_check = "Enter values greater than start chainage";
            setMessageList((prevMessages) => [...prevMessages, gt_check]);
          }
        }

        return updatedData;
      });
    }

    if (e.target.name === "from") {
      const selectedValue = parseInt(e.target.value, 10);
      const filtered = userData.chainageArr.filter(
        (value) => value >= selectedValue
      );

      setFilteredChainage(filtered);
    }

    if (formData.from != "" && formData.from1 != "" && formData.to!="") {
    
      if (e.target.name==="to1") {
          axios
            .get(`${baseurl}/api/work_details/get/type_of_work`, {
              params: {
                project_id: userData.projectId,
                start_chainage: `${formData.from}.${formData.from1}`,
                end_chainage: `${formData.to}.${formData.to1}`,
              },
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userData.token}`,
              },
            })
            .then((response) => {
              const data = response.data;
              const type_of_work_list = data.type_of_work_list;
              setTypeOfWork(type_of_work_list);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      
    }

    if (e.target.name === "type" && e.target.value != "") {
      setStructure([]);
      axios
        .get(`${baseurl}/api/work_details/get/strs_or_layers`, {
          params: {
            project_id: userData.projectId,
            type_of_work: e.target.value,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const strs_or_layers_list = data.strs_or_layers_list;
          setStructure(strs_or_layers_list);
          ////console.log(formData.structure);
          
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    setTableShow(false);
  };



  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  // Event handler for the page change
  
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleList = (testdependency) => {


setTestingdep(testdependency)
    setShowModal(true);
    
   
  };
  const handleClose = () => setShowModal(false);
  


  const validate = () => {
    setData([])
    setFilteredData([])
    setSuccessstatus(false)
    if (
      formData.from === "" ||
      formData.from1 === "" ||
      formData.to === "" ||
      formData.to1 === "" ||
      formData.type === "" ||
      formData.structure === ""
    ) {
      const empty_check = "Please fill all the fields";
      setMessageList((prevMessages) => [...prevMessages, empty_check]);
    } else {
      axios
    .get(`${baseurl}/api/work_details/get/completed_str_or_layer_ids`, {
      params: {
        // rqd_for:'updated_works',
        project_id:userData.projectId,
        strs_or_layers:formData.structure,
        type_of_work:formData.type,
        start_chainage:`${formData.from}.${formData.from1}`,
        end_chainage:`${formData.to}.${formData.to1}`
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    })
    .then((response) => {
      const res = response.data.completed_str_or_layer_ids_data;
      
      setData(res);
      setFilteredData(res);
      setSuccessstatus(true)
      console.log("jhhhhhhhh"+JSON.stringify(res))
      // setTestingdep([])
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
   
    setSearchsts(true);
    }
  }

  function handleSubmit(e) {
    setMessageList([]);

   setSuccessstatus(false)
    e.preventDefault();
    validate();
    
  }
  function downloadXl(pdfdata)
  {
    //alert(pdfdata)

  //  axios({
  //   method: "GET",
  //   url: baseurl + "/api/bill_report/export_as_excel?project_id="+userData.projectId,
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: "Bearer " + userData.token,
  //   },
  //   responseType: "blob", // This is important for handling binary data
  // })
  //   .then((response) => {
    
      const blob = new Blob([pdfdata], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download =  `${userData.projectName}.xlsx`; // Set the file name for download
      link.click(); // Programmatically click the link to trigger the download
  
     
    // })
    // .catch((error) => {
    //   console.error(error); 
    // });
  
  }

  return (
    
    <div className=" mt-1">
        <CustomToastContainer messageList={messageList} />
      <div className="w-100 d-flex align-items-center justify-content-center "><h5 className="mb-0">{userData.projectName}</h5 ><img className="mb-0 ml-2" src={Arr} width={100} height={10}/>
      <h6 className="mb-0 ml-2">Total Chainage<span className="ml-1"style={{color:'#0AAF4C',fontWeight:'600'}}>{userData.startChainage}-{userData.endChainage}</span></h6></div>
     <div className="container"> 
      <div
        className="row sh  mx-auto mb-0  pt-2 pb-2 rounded "
        // style={{ maxWidth: "85%" }}
      >
        <div className="col-12  px-1">
          <div className="container bg-white ">
            <div className="row">
              <div className="col-1">
                <label
                  className="form-label mb-0 mt-1"
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Chainage&nbsp;
                </label>
              </div>
              <div className=" col-lg-5  ">
                <div className="w-100  p-0">
                  <div className="row ">
                    <div className="col-lg-6 ">
                      <div class=" input-group">
                        <label
                          className="form-label mb-0 mt-1"
                          style={{ fontSize: "14px" }}
                        >
                          From :&nbsp;
                        </label>
                        <select
                          onChange={handleChange}
                          value={formData.from}
                          name="from"
                          className="form-control border rounded-0"
                          style={{
                            width: "30px",
                            fontSize: "12px",
                          }}
                        >
                          <option value="" className="pl-0"></option>
                          {userData.chainageArr != undefined &&
                            userData.chainageArr.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>

                        <div class="input-group-append rounded-0">
                          <span class="input-group-text px-0" id="basic-addon1">
                            <RxSlash />
                          </span>
                        </div>
                        <input
                          maxLength={3}
                          name="from1"
                          type="text"
                          value={formData.from1}
                          onChange={handleChange}
                          className="form-control border rounded-0 pl-2"
                          style={{
                            fontSize: "12px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div class="input-group">
                        <label
                          className="form-label  mb-0 mt-1"
                          style={{ fontSize: "14px" }}
                        >
                          To :&nbsp;
                        </label>

                        <select
                          name="to"
                          onChange={handleChange}
                          value={formData.to}
                          className="border form-control rounded-0"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <option value=""></option>
                          {formData.from1.length == 3 &&
                            filteredChainage.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                        <div class="input-group-append rounded-0">
                          <span
                            class="input-group-text px-0 rounded-0"
                            id="basic-addon1"
                          >
                            <RxSlash />
                          </span>
                        </div>
                        <input
                          maxLength={3}
                          name="to1"
                          type="text"
                          value={formData.to1}
                          onChange={handleChange}
                          className=" border form-control rounded-0 pl-2"
                          style={{
                            fontSize: "12px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 px-0 ">
                <div className=" text-center">
                  <select
                    name="type"
                    onChange={handleChange}
                    value={formData.type}
                    className=" border form-control rounded-0 border-top-0 border-bottom-0 border-right-0"
                    style={{
                      fontSize: "12px",
                      width: "170px",
                    }}
                  >
                    <option value="">Type of Work...</option>
                    {formData.to1.length == 3 &&
                      typeOfWork.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-3  px-0 ">
                <div className=" text-center">
                  <select
                    name="structure"
                    onChange={handleChange}
                    value={formData.structure}
                    className=" border form-control border-top-0 border-bottom-0 border-right-0 rounded-0"
                    style={{
                      fontSize: "12px",
                      width: "270px",
                    }}
                  >
                    <option disabled value="">
                      Structure/Layer
                    </option>
                    {structure.map((item, index) => (
                      <option
                        key={index}
                        value={item === "Structures And Layers" ? "All" : item}
                      >
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="text-center col-1">
                <button
                  disabled={successstatus ? true : false}
                  onClick={digiter == "" ? handleSubmit : ""}
                  className="btn text-center text-white  mt-0 "
                  style={{
                    backgroundColor: "#1953AA",
                    fontSize: "14px",
                  }}
                >
                  <FaSearch />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div></div>

     {/* {searchsts &&data.length!=0 &&(
        <div>
          
         
            <div class="col-xl-4 col-lg-4 pl-0 mt-2 ">
              
              <div className="input-group radius w-75  text-end in  mb-1 ">
                <input
                  value={searchTerm}
                  onChange={handleSearch}
                  className="form-control border-0 bg-transparent text-black "
                  placeholder="Keyword search"
                  type="search"
                />{" "}
                <div class="input-group-append  ">
                  <button
                    onClick={""}
                    class="input-group-text radi border-0  "
                    id="basic-addon1"
                  >
                    <img src={search1} />
                  </button>
                </div>
              </div>
            </div>
         

            {(searchTerm ? filteredData : currentPageData).map((item, index) => {
            return (
              <div>
                {" "}
                <div
                  className="border sha2 mb-2 pl-3 pr-3  "
                  style={{ borderRadius: "5px" }}
                >
                
                 
           
                    <div
                      className="d-flex p-2 border-bottom align-items-center justify-content-end mb-0"
                      style={{ color:item.bill_status=='Completed / Billed'?'#069E55':'red', fontWeight: "600" }}
                    >
                      <BsFillStarFill
                        className="mr-2"
                        style={{ fontSize: "20px" }}
                      />
                      <span style={{}}>{item.bill_status}</span>
                    </div>

                   
                  

                  <div className="row ">
                    <div className="col-4 mx-auto ">
                      <table
                        className="border-0 m-0 table-borderless table"
                        key={index}
                      >
                        <tbody>
                          <tr>
                            <th className="textt p-0 pl-5 w-50">Chainage</th>
                            <th className="textt  p-0">:</th>
                            <td
                              className="p-0 text-start  "
                              style={{ color: "#074591", fontWeight: "600" }}
                            >
                              {item.completed_chainage}
                            </td>
                          </tr>
                          <tr>
                            <th className="textt p-0 pl-5 w-50">ItemofWork</th>
                            <th className="textt   p-0">:</th>
                            <td
                              className="p-0  text-start  "
                              style={{ color: "#074591", fontWeight: "600" }}
                            >
                              {item.item_of_work}
                            </td>
                          </tr>
                          <tr className="">
                            <th className="textt  p-0  w-50">
                              Testing Dependency
                            </th>
                            <th className="textt  p-0 ">:</th>

                            <td className=" p-0 text-start  ">
{item.are_testing_dependency_present==1 ?
                              <button
                                onClick={handleList}
                                className="btn btn-sm mt-2 boxsha text-white"
                                style={{
                                  backgroundColor: "#069E55",
                                  width: "50%",
                                }}
                              >
                                View
                              </button>
:<div className="">NA</div>}

                            </td>
                          </tr>
                        </tbody>
                      </table>{" "}
                    </div>
                    <div className="col-5 mx-auto ">
                      <table
                        className="border-0 m-0 table-borderless table"
                        key={index}
                      >
                        <tbody>
                        <tr>
                            <th className="textt p-0  ">
                              Type of Work
                            </th>
                            <th className="textt  p-0" style={{ width: "2%" }}>
                              :
                            </th>
                            <td className="p-0   " style={{ color: "#074591", fontWeight: "600" }}>
                              {item.type_of_work}
                            </td>
                          </tr>
                         

                          <tr>
                            <th className="textt p-0   w-25">Structure </th>
                            <th className="textt  p-0">:</th>
                            <td
                              className="p-0   "
                              style={{ color: "#074591", fontWeight: "600" }}
                            >
                             
                              {item.str_id}
                            </td>
                          </tr>
                          <tr>
                            <th className="textt p-0  pl-4 w-25">Layer </th>
                            <th className="textt  p-0">:</th>
                            <td
                              className="p-0  "
                              style={{ color: "#074591", fontWeight: "600" }}
                            >
                              {item.layer_id}
                            </td>
                          </tr>
                         
                        </tbody>
                      </table>
                    </div>

                    <div className="col-3 mx-auto">
                      <table
                        className="border-0 m-0 table-borderless table"
                        key={index}
                      >
                        <tbody>
                         
                          <tr>
                            <th className="textt p-0  w-25">
                              Gallery Image
                            </th>
                            <th
                              className="textt  p-0 "
                              style={{ width: "2%" }}
                            >
                              :
                            </th>
                            <td className="p-0 w-25  ">
                            {item.progress_attachment_url!=undefined && item.progress_image_url!='' ?
                              <button className="btn"onClick={() => handleGalOpen(item.progress_attachment_url)}><FcGallery style={{fontSize:'23'}}/> 
                              </button>
                              :<div className="">NA</div>}
                            </td>
                          </tr>
                          <tr>
                            <th className="textt p-0   w-25" >
                              Cad 
                            </th>
                            <th className="textt  p-0 ">:</th>
                            <td className="p-0   ">
                             {item.cad_image_url!='' && item.cad_image_url!=[] && item.cad_image_url!=undefined ?
                              <button className="btn"onClick={() => handleCadOpen(item.cad_image_url)}>
                                {" "}
                                <img src={Cad} width={20} />
                              </button>
                               :<div className="">NA</div>}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="d-flex justify-content-between mt-2 align-items-center">
            <p style={{ fontWeight: "500" }}>
              Showing &nbsp;
              <span style={{ fontWeight: "bold" }}>{startIndex}</span> to{" "}
              <span style={{ fontWeight: "bold" }}>{endIndex}</span> of{" "}
              {data.length}
            </p>
            <ReactPaginate
        breakLabel="..."
        nextLabel={<GrFormNext />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<GrFormPrevious />}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        activeClassName="active"
        previousClassName="previous"
        nextClassName="next"
        disabledClassName="disabled"
        breakClassName="break"
      />
          </div>
        </div>)} */}


{ successstatus && data.length==0 &&(
    <center><div className="login-error pt-5"><b>No Data Available</b> </div></center> 
 )}


        
        {successstatus && data.length!=0? (
        <div className="  ">
            <div className="marg">
             <div className="input-group  radius  text-end in mt-2  mb-1 ">
             <input
             style={{fontSize:'15px'}}
               value={searchTerm}
               onChange={handleSearch}
               className="form-control p-0  pl-3 border-0 bg-transparent text-black "
               placeholder="Keyword search"
               type="search"
             />{" "}
             <div class="input-group-append  ">
               <button
                 onClick={""}
                 class="input-group-text radi border-0  "
                 id="basic-addon1"
               >
                 <img src={search1} width={16} />
               </button>
             </div>
           </div></div>
           <div>
          <div className="mx-auto mt-1"style={{width:'95%'}}>
            <table className="mt-0 table mb-1 table-bordered ">
              <thead
              className="table-bordered"
                style={{
                  background: "linear-gradient(to right,#4889CF,#144A9A)",
                  fontSize: "14px",
                  color: "white",
                  position: "sticky",
                  top: "0",
                }}
              >
                <tr>
                  <th className="mb-0  p-2" style={{ fontSize: "12px",borderTopLeftRadius:'10px' ,width:'12%' }}>
                    Chainage
                  </th>
                  <th className=" p-2"style={{ fontSize: "12px" }}>
                    <b>Item Of Work</b>
                  </th>

                  {formData.structure!='Layers'&&  <th
                    className="p-2"
                    style={{fontSize: "12px"}}
                  >
                    <b>Structure</b>
                  </th>}
                  {formData.structure!='Structures'&&    <th
                    className=" p-2"
                    style={{ fontSize: "12px" }}
                  >
                    <b>Layer</b>
                  </th>}

                  <th className=" p-2"style={{ fontSize: "12px",width:'8%' }}>Type Of Work</th>
                  <th className="mb-0 p-2 " style={{ fontSize: "12px",width:'11%' }}>
                    Testing Dependency
                  </th>
                  <th className=" p-2"style={{ fontSize: "12px",width:'7%' }}>CAD Image</th>
                  <th className=" p-2"style={{ fontSize: "12px",width:'5%' }}>Gallery</th>
                  <th className="p-2" style={{borderTopRightRadius:'10px',fontSize: "12px",width:'12%' }}> Status</th>
                </tr>
              </thead>
              {(searchTerm ? filteredData : currentPageData).map((item, index) => {
                  return (
                    <>
                      <tbody key={index} >
                        <tr>
                        <td
                              className="p-0 text-start pt-2 "
                              style={{ color: "#00682A", fontWeight: "600",fontSize:'11px' }}
                            >
                              {item.completed_chainage}
                            </td>

                            <td
                              className="p-0  text-start pt-2 "
                              style={{ color: "#074591", fontWeight: "600",fontSize:'11px' }}
                            >
                              {item.item_of_work}
                            </td>

                            {formData.structure!='Layers'&&  <td
                              className="p-0  pt-2"
                              style={{ color: "#074591", fontWeight: "600",fontSize:'11px' }}
                            >
                             
                              {item.str_id}
                            </td>}
                            {formData.structure!='Structures'&&     <td
                              className="p-0 pt-2 "
                              style={{ color: "#074591", fontWeight: "600",fontSize:'11px' }}
                            >
                              {item.layer_id}
                            </td>}

                          <td className="p-0  pt-2 " style={{ color: "#074591", fontWeight: "600",fontSize:'11px' }}>
                              {item.type_of_work}
                            </td>

                          <td className=" p-0 text-start pt-1 ">
{item.are_testing_dependency_present==1?
                              <button
                                onClick={()=>handleList(item.testing_dependency_data)}
                              className="custom-btn-view "
                                
                              >
                                View
                              </button>
:<div className="pt-1" style={{fontSize:'11px'}}>NA</div>}

                            </td>

                           <td className="p-0  pt-1 ">
                             {item.cad_image_url!='' && item.cad_image_url!=[] && item.cad_image_url!=undefined ?
                              <button className=""onClick={() => handleCadOpen(item.cad_image_url)}>
                                {" "}
                                <img src={Cad} width={18} />
                              </button>
                               :<div className="pt-1"style={{fontSize:'11px'}}>NA</div>}
                            </td>

                          <td className="p-0  pb-1 pt-1">
                            {item.progress_attachment_url!=undefined && item.progress_image_url!='' && item.progress_attachment_url[0]!='-'?
                              <button className="btn p-0 pt-0 m-0"onClick={() => handleGalOpen(item.progress_attachment_url)}><FcGallery style={{fontSize:'23'}}/> 
                              </button>
                              :<div className="pt-1">NA</div>}
                            </td>
                            <td
                      className="d-flex p-0 pt-1  align-items-center justify-content-center mb-0"
                      style={{ color:item.bill_status=='Completed / Billed'?'#069E55':'red', fontWeight: "600",fontSize:'12px' }}
                    >
                      {/* <BsFillStarFill
                        className="mr-2"
                        style={{ fontSize: "16px" }}
                      /> */}
                      <span style={{}}>{item.bill_status}</span>
                    </td>
                        </tr>
                      </tbody>
                    </>
                  );
                })}
            </table>
          </div>
          <div className="d-flex  justify-content-between mt-2  marg2 mx-auto align-items-center">
          <p className="mb-0" style={{ fontWeight: "500" }}>
            Showing &nbsp;
            <span style={{ fontWeight: "bold" }}>{startIndex}</span> to{" "}
            <span style={{ fontWeight: "bold" }}>{endIndex}</span> of
            {data.length }
          </p>
          <ReactPaginate
      breakLabel="..."
      nextLabel={<GrFormNext />}
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      previousLabel={<GrFormPrevious />}
      renderOnZeroPageCount={null}
      containerClassName="pagination"
      activeClassName="active"
      previousClassName="previous"
      nextClassName="next"
      disabledClassName="disabled"
      breakClassName="break"
    />
        </div></div>
        </div>): 
        // <div className="d-flex flex-column justify-content-center align-items-center mt-5 ">
        //     <img src={nodata} width={100}/><h6>No Data Available!</h6>
        //     </div>
        ''
            }

        {galShow && (  <Modal
     
     size="lg"
     aria-labelledby="contained-modal-title-vcenter"
     centered
     show={galShow}
     animation={true}
     onHide={handleGalClose}
    >
      <Modal.Body>
      <div className="bg-white p-4">
                        <div className="w-100 d-inline-flex justify-content-end pr-2">
                          <button
                            className="btn p-0 bg-white"
                            onClick={handleGalClose}
                          >
                            <IoMdClose />
                          </button>
                        </div>
                        <div className="text-center">
                          {galImg.length >0?(
                          galImg.map((imgSrc, index) => (
                            <img
                              key={index}
                              src={imgSrc}
                            
                              width={720}
                              height={400}
                            />
                          ))):(
                            <p className="bg-primary mb-0  text">No data available</p>
                          )}
                        </div>
                      </div>
      </Modal.Body>
     
    </Modal>)}


    
    {showModal && (
  <Modal show={showModal} onHide={handleClose} keyboard={false}>
    <Modal.Body className="bg-white">
      <div>
        <table>
          {testingdep.length > 0 && (
            testingdep.map((item, index) => (
              <tbody key={index}>
                <tr>
                  <td className="textt"><b>Test Name</b></td>
                  <td className="textt">:</td>
                  <td className="textt">
  {item.test_name}
</td>
                </tr>
                <tr>
                  <td className="textt"><b>Test Result</b></td>
                  <td className="textt">:</td>
                  <td className="textt">
                    NA
                    {/* <img src={Pdf} width={50} alt="PDF Icon" /> */}
                  </td>
                </tr>
                <tr>
                 
              
                
                          <td className="textt"><b>Testing Dependency:</b>  </td> 
                          <td className="textt">:</td>
                           
                           <td className="textt">
                           
                          {item.test_result!="-" ?
                           <Link href={item.test_result} download target="_blank" rel="noopener noreferrer">
                            <button
                                           
                              className="btn d-inline-flex align-items-center text-white"
                              style={{
                                fontSize: "14px",
                                backgroundColor: "#374F77",
                              }}
                            >
                              <FiDownload />
                              &nbsp;
                              </button></Link>
                              :"NA"
                              
                     
                          }
                           


                          </td>
                </tr>
              </tbody>
            ))
          ) }
        </table>
      </div>
    </Modal.Body>
  </Modal>
)}


      {cadShow && (
                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={cadShow}
                    animation={true}
                    onHide={handleCadClose}
                  >
                    <Modal.Body>
                      <div className="bg-white p-4">
                        <div className="w-100 d-inline-flex justify-content-end pr-2">
                          <button
                            className="btn p-0 bg-white"
                            onClick={handleCadClose}
                          >
                            <IoMdClose />
                          </button>
                        </div>
                        <div className="text-center">
                          {cadImg.length>0?(
                          cadImg.map((imgSrc, index) => (
                            <img
                              key={index}
                              src={imgSrc}
                              alt={`cadimg-${index}`}
                              width={720}
                              height={400}
                            />
                          ))): (
                            <p className="mb-0  text ">No data available</p>
                          )}
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                )}


    </div>
  );
}
