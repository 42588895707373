import React from 'react';
import RadialBar from '../ApexCharts/RadialBar';
import '../VendorList/Vendorlist.css';
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from 'react'
import RequestModal from '../Modal/CreateRequest'
import DocumentView from '../Modal/ContractOrVendorDoc'
import { baseurl } from '../BaseUrl/Baseurl'
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import ButtonLink from '../ButtonLink/ButtonLink'
const WeareHere = () => {


  const userData = useSelector((state) => state.userDetails)

  const [list, setList] = useState([]);

  const [updatestatus, setUpdatestatus] = useState(false);
  const [contractorid, setContractorid] = useState('');
  const location = useLocation()

  const [modalStatus, setModalStatus] = useState(false);
  const [docImage, setDocImage] = useState('');
  const [docurldownload, setDocurldownload] = useState('');
  const [successstatus, setSuccessstatus] = useState(false);
  const [paymentstatus, setPaymenttatus] = useState(false);
  const [projectid, setProjectid] = useState('');
  const [projecttype, setProjecttype] = useState('');
  const contractorId = location.state?.contractorId
  useEffect(() => {

    //  ////alert("Id"+contractorId)
    axios({
      method: 'GET',

      url: baseurl + '/api/vendor/get/eachcontractor/project_details?contractor_id=' + contractorId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })

      .then((response) => {

        console.log(response.data)
        const RequestData1 = response.data

      //  console.log("RRRRRR"+JSON.stringify(response.data))
        // setRequestdata1(RequestData)

        const dt = RequestData1.noofcompletedstructures
        console.log(dt)
        setList(RequestData1)
        setSuccessstatus(true)
      })
      .catch((error) => {

        //     console.log(error)
        //  setErrormsg(error.response.data.message)
      })

    //   let fmData5 = new FormData()

    // fmData5.append('project_id', userData.projectId)









    // console.log("useeffect")
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    // document.addEventListener("click", handleClick);
    // return () => {
    //   document.removeEventListener("click", handleClick);
    // };

    // document.addEventListener("click", handleClick1);
    // return () => {
    //   document.removeEventListener("click", handleClick1);
    // };

    ////////////////////////// api for first graph ////////////////////////////////




    // axios({
    //   method: 'GET',

    //   url: 'http://localhost:8080/api/support/issue/ticket/list',

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   }

    // })
    //   .then((response) => {
    //     console.log(response.data)
    //     console.log("result coming")
    //     const TicketData = response.data
    //     setTicketlist(TicketData)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     // setErrormsg(error.response.data.message)
    //   })

  }, [])

  const Editdata = (id) => {
    setContractorid(id)
    setUpdatestatus(true)
  }

  const tocloseModal = () => {
    setModalStatus(false);
  };

  function documentClick(docurl, docimage) {

    setDocImage(docimage)
    setDocurldownload(docurl)
    setModalStatus(true)
  };

  // const toggleModelStatus = () => {

  //   setRequeststatus(false)
  // };
  // const toggleModelStatus1 = () => {

  //   setUpdatestatus(false)
  // };
  // const toggleRenewalStatus = () => {
  //   console.log("***********")
  //   setRenewalstatus(false)
  // };
  // const toggleViewTicketStatus = () => {
  //   console.log("***********")
  //   setViewticketstatus(false)
  // };
  // const requestdata = () => {
  //   console.log("*****" + requeststatus)
  //   setRequeststatus(true)
  // }
  // const Editdata = (id) => {
  //   setProjectid(id)
  //   setUpdatestatus(true)
  // }
  // const ViewticketstatusUpdate = (id) => {
  //   setTicketno(id)
  //   setViewticketstatus(true)
  // }
  const toggleModelStatus1 = () => {

    setUpdatestatus(false)

  };

  const UpdatePayment = (projectid,projecttype) => {

    setProjectid(projectid)
    setProjecttype(projecttype)
    setPaymenttatus(true)

  };

  return (

    <div className="cont-margin1">
         {paymentstatus && <ButtonLink btnPath="/Payment" projectId={projectid} projectType={projecttype}/>}
      <div class="cont-margin-latest pt-2 ">
        <div className='table1bdstyle'>
          <div class="row table1st">

            <div class="row pt-5 padleft">


              <Link to="/SubContractor">
                <button
                  type="submit"
                  className="contractor-btn1"
                  name="submit"
                  float="right"

                >
                  <b>Back</b>
                </button>
              </Link>


            </div>

            {list.length == 0 && successstatus &&
              // !checkduedate &&

              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pt-3 pb-3">
                <center><b><h6>No Data Available</h6></b></center>

              </div>}
            {list.length !== 0 && <div className='tableContainer' >


              <table
                class="table"
                readOnly="readOnly"


              >
                <tr class='trst'>

                  <th>Project Name</th>
                  <th>Structure Type/Item of Work</th>
                  <th>No of Structures Completed</th>
                  <th>No of Structures Pending</th>
                  <th>Percentage of Work Completed(%) Structure Wise</th>
                  <th>Percentage of Work Completed(%)Material Wise</th>
                  <th>Status</th>
                  <th>Payment</th>
                  <th>View Contract</th>

                </tr>
                {modalStatus && (
                  <DocumentView
                    status={modalStatus}
                    btnClick={tocloseModal}
                    imageData={docImage}
                    imageUrl={docurldownload}


                  />
                )}
                {list.map((dt, index) => {
                  const itemofwork = dt.structure_or_itemOfWork || []
                  const itemofworklen = itemofwork.length

                  return (
                    <tr key={index} className='tablefontsize'>

                      <td className='fontcolor fontsize'>{dt.project_name != undefined ? dt.project_name : 'NA'}<b></b></td>

                      <td>

                        {itemofworklen != 0 ?
                          <ul>
                            {dt.structure_or_itemOfWork.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}


                          </ul> : 'NA'}

                      </td>

                      <td className='fontcolor fontsize'>
                        {dt.noofcompletedstructures != null ? dt.noofcompletedstructures : 'NA'}
                      </td>


                      {/* <td>{(dt.project_status === 'completed'? <img src = {completed} alt='completed' height={30} width={90} />:<img src = {inprogress} alt='completed' height={30} width={90} />)}</td> */}

                      <td className='fontcolor1 fontsize1'>  {dt.noofpendingstructures != undefined ? dt.noofpendingstructures : 'NA'}</td>



                      <td className='fontcolor1 fontsize1'> {dt.percentageofcompletion != undefined ? parseInt((dt.percentageofcompletion)).toString().match(/^-?\d+(?:\.\d{0,2})?/) : 'NA'}%
                      </td>
                      <td className='fontcolor1 fontsize1'> {dt.percentageofpending != undefined ? parseInt((dt.percentageofpending)).toString().match(/^-?\d+(?:\.\d{0,2})?/) : 'NA'}%</td>
                      <td className='fontcolor1 fontsize1'>{dt.status != undefined ? dt.status : 'NA'}</td>
                      <td className='fontcolor1 fontsize1'>
                      <center>
                       
                            <button
                              type="button"
                              className="modalbutton"
                              onClick={()=>UpdatePayment(dt.project_id,dt.project_type)}
                            >

                              Payment

                            </button>
                         
                        </center>

                      </td>

                      <td>
                        <center>
                          {dt.purchase_order_report_url != 'NA' ?
                            <button
                              type="button"
                              className="modalbutton"
                              onClick={() => documentClick(dt.contractor_document_url, dt.contractor_document_image)}

                              disabled={dt.contract_document_image != 'NA'
                                ? false : true
                              }
                            >

                              Document

                            </button> : 'NA'}
                        </center>
                      </td>

                    </tr>
                  )
                })
                }


                {/*               
              {viewticketstatus &&
                <ViewTickets status={viewticketstatus} btnClick={toggleViewTicketStatus} TicketNumber={ticketno}/>}
           {ticketlist.length != 0 && ticketlist.map((item, index) => {
                return (
                  <tr key={index}>

                    <td>{item.project_id}</td>
                    <td>{item.contact_person}</td>
                    <td>{item.ticket_number}</td>
                    <td>  <button
                      type="button"
                      className="project-btn1"
                      onClick={() => ViewticketstatusUpdate(item.ticket_number)}
                    >
                      <b>View Tickets</b>
                    </button></td>

                  </tr>
                )
              })} */}

              </table>
            </div>
            }
          </div>
          {/* <div class="modal fade pt-5" id="myModal">
            <div class="modal-dialog modal-md ">
              <button
                type="button"
                className="demobtn2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x"></i>
              </button>
              <div class="modal-content">
                <div class="modal-header">
                  <div className="header-text">
                    <b>{btnname}</b>
                  </div>
                </div>

              </div>
              <div className="modal-body">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-12 pt-3 pb-3 ">
                    <ul class="list-unstyled">
                      {btnname === 'Edit' && (
                        <>
                        
                          <h1>Hello</h1>
                        </>
                      )}


                    </ul>
                  </div>
                </div>
              
              </div>
            </div>
          </div> */}



        </div>
      </div>

    </div>


  );
};

export default WeareHere;