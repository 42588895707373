import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AfterloginHeader from "./Component/AfterLoginHeader/NewHeader";
import Sidebar from "./Component/SideBar/SidebarwithDropdownNew";
import AfterLoginhome from "./Pages/DashBoard";
import ProjectPage from "./Pages/Project";
import Sample from "./Component/Projects/Projects";
import PlanUpdate from "./Component/PlanUpdate/PlanUpdate";
import Loginform from "./Pages/LoginPage";
import { useSelector } from "react-redux";
import Dropdownlist from "./Component/DropDownList/TabList";
import Levelsearch from "./Component/LevelSearch/Search";
import Twoline from "./Component/ApexCharts/Twoline";
import ProjectTimeline from "./Component/ProjectDropdown/ProjectTimeline";
import ThirdPartyCont from "./Component/ThirdPartyContractorDetails/ThirdPartyControctor";
import ProjectList from "./Component/ProjectList/NewProjectList";
import VendorList from "./Component/VendorList/Vendorlist";
import SubcontractorCreation from "./Component/SubcontractorCreation/SubContractorForm";
//  import Graph from './Component/ProjectProgressDetails/Graph'
//  import ResourceMgmt from './Component/ResourceMgmt/ResourceMgmt'
import Roaddropdown from "./Component/RoadDropdown/RoadTab";
import RoadSubcntractorForm from "./Component/RoadSubcontCreation/Subcontractorcreation";
import RoadSubContdetails from "./Component/RoadSubcontractorDetails/RoadSubcont";
import Sampleline from "./Component/sample/Main";
import Support from "./Component/Support/Support";
import ContractDetails from "./Component/ContractdetailsRoadNew/ContractDetails1";

// import ContractDetails from './Component/EngineerLoginHome/Exceltable.js'

import SupportCreation from "./Component/SupportCreation/SupportCreation";
import Subscription from "./Component/Subscription/Subscription";
import RoadContrac from "./Component/RoadSubcontractor/RoadSubcontractor";
import PurchaseOrder from "./Component/PurchaseOrder/PurchaseOrder";
import SubcontractorView from "./Component/SubContractorView/SubContractorView";
import EditVendor from "./Component/VendorList/EditVendor";
import EditContractor from "./Component/ContractorList/EditContractor";
import ViewVendorPjts from "./Component/VendorList/ViewVendorPjts";
import ViewOrder from "./Component/VendorList/ViewOrder";
import SubContractorList from "./Component/ContractorList/ContractorList";
import ContractorCreation from "./Component/ContractorCreation/ContractorCreation";
import VendorCreation from "./Component/VendorCreation/VendorCreation";
import Table from "./Component/SampleTable/TableApp";
import WithoutVideoLogin from "./Component/LoginForm/NewLogin";
import Payment from "./Component/ContractorList/SubcontractorPayment";
import ProjectlistSearch from "./Component/DashBoard/HomeNew";
import ProjectDashboard from "./Component/ProjectDashboard/RoadProjectDashboard";
import NewReports from "./Component/NewRoadReports/NewRoadReports";
import Engineerlogin from "./Component/EngineerLogin/Engineerlogin";
import Location from "./Component/RoadLocationNew/LocationNewIntegration";
import Work_Details from "./Component/WorkDetails/WorkDetails.js";
import EngineerLoginHome from "./Component/EngineerLoginHome/Home";
import Workmaster from "./Component/Workmaster/Workmaster.js";
import ProjectReport from "./Component/ReportsNew/Workstatus.js";
import SiteUpdatesCon from "./Component/Contractor site updates/SiteUpdatesCon.js";
import ContractorHeader from "./Component/AfterLoginHeader/ContractorHeader.js";
// import Completedwork from "./Component/Completedwork/Completedwork.js";
import Completedworklist from "./Component/Completedwork/Completedworklist.js";
import UpdatePage from "./Component/Contractor site updates/SiteUpdate.js";
import ContractorProjectList from "./Component/Completedwork/ContractorProjectList.js";
import Webcamapp from './Component/Webcam/Webcam.js'
function RoutePage() {
  const userData = useSelector((state) => state.userDetails);
  const isLoggedIn = useSelector((state) => state.userDetails.isLoggedIn);
  useEffect(() => {}, [userData.projectName]);
  return (
    <>
      <Router>
        {isLoggedIn && userData.role === 'Contractor' ? <ContractorHeader/> : isLoggedIn ? <AfterloginHeader /> :''}
        {isLoggedIn && userData.role != 'Contractor' ? <Sidebar /> : ""}
        <Routes>
          <Route path="/" index element={<WithoutVideoLogin />} />
          {/* <Route path="/Login" index element={<WithoutVideoLogin />} /> */}
          {/* <Route path="/Dashboard" index element={<AfterLoginhome />} /> */}
          <Route path="/Webcam" index element={<Webcamapp />} />
          <Route path="/Project" index element={<ProjectPage />} />
          <Route path="/PlanUpdate" index element={<PlanUpdate />} />
          <Route path="/Building" index element={<Dropdownlist />} />
          <Route path="/Road" index element={<Roaddropdown />} />
          <Route path="/LevelSearch" index element={<Levelsearch />} />
          <Route path="/Twoline" index element={<Twoline />} />
          <Route path="/ProjectTimeline" index element={<ProjectTimeline />} />
          <Route
            path="/ThirdPartyContractorDetails"
            index
            element={<ThirdPartyCont />}
          />
          {/* <Route path="/Projects" index element={<ProjectList />} /> */}
          <Route path="/Projects" index element={<ProjectlistSearch />} />
          <Route
            path="/SubContractorCreation"
            index
            element={<SubcontractorCreation />}
          />
          {/* <Route path="/Progress" index element={<Graph />} /> */}
          {/* <Route path="/ResourceManagement" index element={<ResourceMgmt />} /> */}
          <Route
            path="/AddSubContractor"
            index
            element={<RoadSubcntractorForm />}
          />
          <Route
            path="/SubContractorDetails"
            index
            element={<RoadSubContdetails />}
          />
          <Route path="/Line" index element={<Sampleline />} />
          {/* <Route path="/Inspection_Schedule" index element={<Support />} /> */}
          <Route path="/Inspection_Schedule" index element={<Support />} />
          <Route path="/SupportCreation" index element={<SupportCreation />} />
          <Route path="/Subscription" index element={<Subscription />} />
          <Route path="/Contractor" index element={<SubContractorList />} />
          <Route path="/Purchase" index element={<PurchaseOrder />} />
          <Route
            path="/SubcontractorViewDetails"
            index
            element={<SubcontractorView />}
          />
          <Route path="/Vendor" index element={<VendorList />} />
          <Route path="/EditVendor" index element={<EditVendor />} />
          <Route path="/EditContractor" index element={<EditContractor />} />
          <Route
            path="/ViewVendorProjects"
            index
            element={<ViewVendorPjts />}
          />
          <Route path="/ViewOrder" index element={<ViewOrder />} />
          <Route path="/Subcontractor" index element={<SubContractorList />} />
          <Route
            path="/ContractorCreation"
            index
            element={<ContractorCreation />}
          />
          <Route path="/VendorCreation" index element={<VendorCreation />} />
          <Route path="/Payment" index element={<Payment />} />

          <Route path="/Table" index element={<Table />} />

          <Route path="/Contract_Details" index element={<ContractDetails />} />
          <Route path="/Dashboard" index element={<ProjectDashboard />} />
          {/* <Route path="/Reports" index element={<NewReports />} /> */}
          <Route path="/Engineer_Login" index element={<Engineerlogin />} />
          <Route path="/Location" index element={<Location />} />
          <Route path="/Work_Details" index element={<Work_Details />} />
          <Route path="/EngineerPage" index element={<EngineerLoginHome />} />
          <Route path="/Work_Master" index element={<Workmaster />} />
          {/* <Route path="/Reports" index element={<ProjectReport />} /> */}
          <Route
            path="/Reports"
            index
            element={
              userData.projectName === "" ||
              userData.projectName === undefined ||
              userData.projectName === null ? (
                <ProjectReport />
              ) : (
                <NewReports />
              )
            }
          />
          {/* <Route path="/Siteupdates" index element={<SiteUpdatesCon/>}/> */}
          <Route path="/ContractorProjectList" index element={<ContractorProjectList/>}/>
          <Route path="/Completedworklist" index element={<Completedworklist/>}/>
          <Route path="/Siteupdates" index element={<UpdatePage/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default RoutePage;
