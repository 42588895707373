import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const EquipmentGraph = ({ graphData }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (graphData) {
      const labels = graphData.map((data) => data.equipmentType);
      const usedData = graphData.map((data) => {
        const [hours, minutes] = data.totalUsed.split(':');
        return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
      });

      setChartData({
        options: {
          chart: {
            type: 'bar',
            background: '', // Set a light background color
            foreColor: '#333', // Set a dark foreground color
            animations: {
              enabled: true,
              easing: 'easeinout', // Use easing for smoother animations
              speed: 800, // Set animation speed
              show: true,
           
            },
            
          },
         
          
          xaxis: {
            categories: labels,
            labels:{
              style: {
                colors: '#333', // Set the desired color for x-axis labels
              },
            },
            title: {
              text: 'Equipment Name',
              colors: ['#333'],
            },
            axisTicks: {
              show: false, 
            },
            axisBorder: {
              show: true,
              color: '#000000', // Set the desired color for the x-axis line
              height: 2, // Set the desired height for the x-axis line
            }, 
          },
          yaxis: {
            min: 1,
            title: {
              text: 'Number of hours',
            },
            axisBorder: {
              show: true,
              color: '#000000', // Set the desired color for the y-axis line
              width: 2, // Set the desired width for the y-axis line
            },
          },
          colors: ['#2ecc71', '#f39c12'],
          grid: {
            borderColor: '#ddd',
          },
         
          plotOptions: {
            bar: {
              columnWidth: '65%',
            },
          },
          stacked: true,
          dataLabels: {
            formatter: function (val) {
              const hours = Math.floor(val / 60);
              const minutes = val % 60;
              return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
            },
          },
          legend: {
            show: true,
            position: 'top', // Change legend position
            horizontalAlign: 'center', // Center the legend horizontally
          },
        },
        series: [
          {
            name: 'Used',
            data: usedData,
          },
          {
            name: 'Idle Minutes',
            data: graphData.map((data) => {
              const [hours, minutes] = data.idleHours.split(':');
              return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
            }),
          },
        ],
      });
    }
  }, [graphData]);

  return (
    <div>
          <div style={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.15)' }}>

      {chartData ? (
        <Chart options={chartData.options} series={chartData.series} type="bar" height={500} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
    </div>

  );
};

export default EquipmentGraph;
