// export const baseurl = 'http://localhost:8080/brick'
// export const pythonurl = 'http://localhost:5000/brick'


// export const baseurl = 'http://192.168.0.2:8080/brick'
// export const pythonurl = 'http://localhost:5000/brick'


// //latest
export const baseurl = 'https://www.brick-erp.com/brick'
export const pythonurl = 'https://www.brick-erp.com/brick'
