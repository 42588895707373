import { useState, useEffect, useRef } from 'react';
import InputTextField from '../InputText/InputText';
							 
import axios from 'axios';
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import { baseurl } from '../BaseUrl/Baseurl'
import './RoadWorkerMgnt.css'
// import '../WorkerMgmt/WorkerMgmt.css'
function FormPage() {
  const [startDate, setStartDate] = useState('');
  const [startDatebydate, setStartDatebydate] = useState('');
  const userData = useSelector((state) => state.userDetails)
  const [endDate, setEndDate] = useState('');
  const [workerName, setWorkerName] = useState('');
  const [workernamelist,setWorkernamelist]= useState('');
  const [activeTab, setActiveTab] = useState('date');
  const [successstatus, setSuccessstatus] = useState(false)
  const [list2, setList2] = useState([]);
  const [list5, setList5] = useState([]);
  const [onetimecall, setOnetimecall] = useState(true);
  const [successstatus1, setSuccessstatus1] = useState(false)

  useEffect(() => {
    if(onetimecall)
    {
    // axios
    // .get(baseurl+'/api/request/list/added_workers?project_id='+userData.currentprojectId, {
     
    // })
    axios({
      method: 'GET',

      url: baseurl + '/api/request/list/added_workers?project_id='+userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })

    .then((response) => {
      const wn=response.data
      const namelist=wn.names
      //console.log(namelist)
      setWorkernamelist(namelist)
      setOnetimecall(false)
    })
    .catch((error) => {
      console.error('Error for Worker:', error);
    });
    
  }
  },[])
  
  function convertDateFormat(date) {
    const parts = date.split('-');
    if (parts.length === 3) {
													   
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
																  
    return date;
  }
  function handleStartDateChange(e) {
    setStartDate(e.target.value);
    setSuccessstatus(false)
  }

  function handleStartDateChangebydate(e) {
   
    setStartDatebydate(e.target.value);
    setSuccessstatus(false)
  }

  function handleEndDateChange(e) {
    setEndDate(e.target.value);
    setSuccessstatus(false)
  }

  function handleWorkerNameChange(e) {
    setWorkerName(e.target.value);
    setSuccessstatus(false)
  }

  function handleTabChange(tab) {
    setSuccessstatus(false)
    setSuccessstatus1(false)
    setActiveTab(tab);

  }

  
  function onSubmitEvent(event) {
								  
    //console.log('worker_name',workerName)
  //console.log('start_date',convertDateFormat(startDate))
  //console.log('end_date',convertDateFormat(endDate))
    event.preventDefault();
  
    if (activeTab === 'worker') {
      setSuccessstatus(false)
      axios
        .get(baseurl+'/api/report/worker/attendance/list', {
          params: {
            project_id: userData.currentprojectId,
            worker_name: workerName,
            start_date: convertDateFormat(startDate),
            end_date: convertDateFormat(endDate),
            
          },
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userData.token}`,
          },

        })
        .then((response) => {
          //console.log('API Response for Worker:', response);
          setList2(response.data.attendance_details);
          setSuccessstatus(true)
        })
        .catch((error) => {
          console.error('Error for Worker:', error);
        });
    } else if (activeTab === 'date') {
    
      setSuccessstatus1(false)			   
      axios
        .get(baseurl+'/api/report/worker/attendance/list', {
          params: {
            project_id: userData.currentprojectId,
            start_date: convertDateFormat(startDatebydate),
           
          },
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          //console.log('API Response for Date:', response);
          const data=response.data.attendance_details								  
          setList5(response.data.attendance_details);
          setSuccessstatus1(true)	
        })
        .catch((error) => {
          console.error('Error for Date:', error);
        });
    }
  
  }
  
 
  return (
    <div className="container11 pagetopspace">
      <div className="form-containernew">
      
        <div class="row pt-2">
            <div class="col-1g-4 col-xl-4">

            </div>
            <div class="col-1g-4 col-xl-4">
          
            <label >
           <b> Search By</b> &nbsp;&nbsp;
             </label>

            <select
              className="project-boxe"
              id="searchType"
              name="searchType"
              value={activeTab}
              onChange={(e) => handleTabChange(e.target.value)}
            >
											 
              <option value="worker"> Worker</option>
              <option value="date"> Date</option>

																		  
												   
            </select>
            </div>
            <div class="col-1g-4 col-xl-4">

            </div>
            </div>

    
         
          {activeTab === 'worker' && 
            <div class="row pt-5">
            <div className="col-md-4 col-lg-4 col-xl-4">
            <label htmlFor="workerName" className="login-text">
              Worker Name
            </label>
            <select
             
              className="project-box"
              id="workerName"
              name="workerName"
              value={workerName}
              onChange={(e) => setWorkerName(e.target.value)}
            >


              <option value="">Select</option>
           {workernamelist.length !== 0 &&
                workernamelist.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
            </select>
            <div className="login-error"></div>
          </div>
             <div className="col-md-4 col-lg-4 col-xl-4">
             <label htmlFor="startDate" className="login-text">
               Start Date
             </label>
             <InputTextField
               handleInputChange={handleStartDateChange}
               valueText={startDate}
               inputName="startDate"
               inputId="startDate"
               classname="project-box"
          
               inputType="date"
             />
           </div>
            <div className="col-md-4 col-lg-4 col-xl-4">
              <label htmlFor="endDate" className="login-text">
                End Date
              </label>
      
              <InputTextField
                handleInputChange={handleEndDateChange}
                valueText={endDate}
                inputName="endDate"
                inputId="endDate"
                classname="project-box"
               
                inputType="date"
              />
            </div>
            </div>
          }


          {activeTab === 'date' && (
           <div class="row pt-5">
              <div className="col-md-4 col-lg-4 col-xl-4"></div>
            <div className="col-md-4 col-lg-4 col-xl-4">
              <label htmlFor="startDate" className="login-text ">
              &nbsp; &nbsp;    Date
              </label>
              <InputTextField
                handleInputChange={handleStartDateChangebydate}
                valueText={startDatebydate}
                inputName="startDatebydate"
                inputId="startDatebydate"
                classname="project-box"
             
                inputType="date"
              />
            
            </div>
            <div className="col-md-4 col-lg-4 col-xl-4"></div>
            </div>
          )}
           </div>
 
          <div className="input-button pt-3">
            <button
              type="submit"
              className="project-btnalign "
              name="submit"
              onClick={onSubmitEvent}
            >
              <b>Search</b>
            </button>
          </div>
       
      <br></br>
     
     
							   
								  
																				
																  
					  
						   
  {activeTab === 'date' && successstatus1 && list5.length != 0 &&  (
     <div className="table-container">
    <table className="table table-bordered ">
      <thead>
        <tr>
        <th className="green" style={{width:'130px'}}>&nbsp; &nbsp;Date &nbsp;&nbsp;</th>
          <th className="green" >Worker Name</th>
          <th className="green" >Attendance</th>
          <th className="green">In-time</th>
          <th className="green">Out-time</th>
          <th className="green" style={{width:'150px'}}>Chainage Worked</th>
          <th className="green" style={{width:'210px'}}>Structure</th>
          <th className="green" style={{width:'150px'}}>Designation</th>

        </tr>
      </thead>
      <tbody>
        {list5.length > 0 && (
          list5.map((item, index) => (
            <tr key={index} className="table-row workmgntfont">
            <td> {item.date!=null && item.date!=undefined ?item.date:'NA'}</td>
            <td>{item.worker_name!=null && item.worker_name!=undefined ?item.worker_name:'NA'}</td>
            <td>{'Present'}</td>
            <td> {item.in_time!=null && item.in_time!=undefined ?item.in_time:'NA'}</td>
            <td> {item.out_time!=null && item.out_time!=undefined ?item.out_time:'NA'}</td>
            <td>
              {/* {item.worked_chainages}   */}
            
         


  {item.worked_chainages.length!=0 ?
               <ul>
    {item.worked_chainages.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>:'NA'}
  </td>
            <td> 
            {item.structure_type.length!=0 ?
              <ul>
            {item.structure_type.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
    </ul>:'NA'}
  </td>
               <td> {item.designation!=null && item.designation!=undefined ?item.designation:'NA'}</td>

          </tr>
          ))
        ) 
       
        }
      </tbody>
    </table>
    </div>
  )}
  {activeTab === 'date' && successstatus1 && list5.length == 0 && 
    <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pt-3 pb-3">
    <center><b><h6>No Data Available</h6></b></center>
  
    </div>
   }
  {activeTab === 'worker' && successstatus && list2.length != 0 && (
     <div className="table-container">
    <table className="table table-bordered">
      <thead>
        <tr>
        <th className="green" >Worker&nbsp;Name</th>
          <th className="green" style={{width:'130px'}}>&nbsp; &nbsp;Date &nbsp;&nbsp;</th>
          <th className="green">Attendance</th>
          <th className="green">In-time</th>
          <th className="green">Out-time</th>
          <th className="green">Chainage Worked</th>
          <th className="green" style={{width:'210px'}}>Structure</th>
          <th className="green" style={{width:'150px'}}>Designation</th>
        </tr>
      </thead>
      <tbody>
        {list2.length > 0 && (
          list2.map((item, index) => (
            <tr key={index} className="table-row">
            <td>{item.worker_name!=null && item.worker_name!=undefined ?item.worker_name:'NA'}</td>
            <td >{item.date!=null && item.date!=undefined ?item.date:'NA'}</td>
            <td>{'Present'}</td>
             <td> {item.in_time!=null && item.in_time!=undefined ?item.in_time:'NA'}</td>
            <td> {item.out_time!=null && item.out_time!=undefined ?item.out_time:'NA'}</td>
            <td>{item.worked_chainages.length!=0 ?
               <ul>
    {item.worked_chainages.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>:'NA'}
  </td>
            <td>
              {item.structure_type.length!=0 ?
              <ul>
            {item.structure_type.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
    </ul>:'NA'}
              </td>
              <td> {item.designation!=null && item.designation!=undefined ?item.designation:'NA'}</td>
          </tr>
          ))
        ) 
        
      
        }
      </tbody>
    </table>
    </div>
  )}

   {activeTab === 'worker' && successstatus && list2.length == 0 && 
    <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pt-3 pb-3">
    <center><b><h6>No Data Available</h6></b></center>
  
    </div>
   }

</div>
  );
}

export default FormPage;