import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

export default function CustomToastContainer({ messageList = [] }) {
  useEffect(() => {
    if (messageList.length > 0) {
      messageList.forEach(message => {
        toast.error(message);
      });
    }
  }, [messageList]);

  return (
    <ToastContainer
      className="custom-toast"
      position="top-right"
      autoClose={2500}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover={false}
    />
  );
}
