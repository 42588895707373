import React, { useEffect, useState ,useRef} from "react";
import { RxSlash } from "react-icons/rx";
import auto from "../../assets/brickdemoicons/Auto cad.png";
import Modal from "react-bootstrap/Modal";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomToastContainer from "../../CustomToastContainer";
import { FaSearch } from "react-icons/fa";
import { FiDownload, FiDownloadCloud, FiEdit, FiUpload } from "react-icons/fi";
import arrow from "../../assets1/images/Arrow 1.png";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import search1 from "../../assets/brickdemoicons/finder png 1.png";
import { FaXmark } from "react-icons/fa6";
import { FcGallery } from "react-icons/fc";
import { AiFillCloseCircle } from "react-icons/ai";
import { ThreeDots } from "react-loader-spinner";
import img from "../../assets/brickdemoicons/pdficon.png";
import testing from "../../assets/brickdemoicons/testing.png";
import cad from "../../assets/brickdemoicons/Auto cad.png";
import fileexplorer from "../../assets/brickdemoicons/FileExplorer.png"
import { FaFilePdf } from "react-icons/fa";
import './Siteupdate.css'
import pdficon  from "../../assets/brickdemoicons/pdf-color-red-icon.png"
import below from "../../assets/brickdemoicons/belowChainage.png";
import deleteicon from "../../assets/brickdemoicons/Trash.png";
import downicon from "../../assets/brickdemoicons/cloud-computing.png";
import galicon from "../../assets/brickdemoicons/gal.png";
import gallery from "../../assets/brickdemoicons/Gallerybg.png";
import galleryicon from "../../assets/brickdemoicons/Galleryicon.png";
import Webcam from 'react-webcam';
import {getDistance, getPreciseDistance } from 'geolib';
import geolib from 'geolib';
import { LuCamera } from "react-icons/lu";

function UpdatePage() {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState([]);
  const [locations, setLocations] = useState([]); 
  const [show1, setShow1] = useState(false);
  const [typeOfWork, setTypeOfWork] = useState([]);
  const [structure, setStructure] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [searchsts, setSearchsts] = useState(false);
  const [data, setData] = useState([]);
  const [cadShow, setCadShow] = useState(false);
  const [galshow, setGalShow] = useState(false);
  const [galshowindex, setGalShowindex] = useState(null);
  const [cancelsts, setCancelsts] = useState(false);
  const [cadImg, setCadImg] = useState([]);
  const [fileErrMsg, setFileErrMsg] = useState("");
  const [imgErrMsg, setImgErrMsg] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [galImg, setGalImg] = useState([]);
  const [galleryshow, setGalleryshow] = useState("");
  const [successstatus, setSuccessstatus] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const[uploadedGalleryImage,setUploadedGalleryImage]=useState([])

  const [itemOffset, setItemOffset] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [imgSelected, setImgSelected] = useState(false);
  const [removestatus, setRemovestatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [gallerydata, setGallerydata] = useState('');
  const [pdffiledata, setPdffiledata] = useState([]);
  const [savestatus, setSavestatus] = useState(false);
  const [oncapture, setOncapture] = useState(false);
  const [locationerror, setLocationerror] = useState('');
  const [noofdependency, setNoofdependency] = useState(2);

  const takephoto= (e) => {
    e.preventDefault()
  
    setOncapture(true)
  };

  const capture = (e) => {
    e.preventDefault()
   
    const imageSrc = webcamRef.current.getScreenshot();
    
    if (imageSrc) {

      //setImgSrc((prevImgSrc) => [...prevImgSrc, imageSrc]);

     

      axios({
        method: "GET",
        url: baseurl + "/api/location/coordinates?type_of_work="+formData.type+"&project_id="+userData.projectId,
        headers: {
          "Content-Type": "multipart/form-data",
         // Authorization: "Bearer " + userData.token,
        },
        // params: {
        //   type_of_work: "TYPE1",
        //   project_id: userData.projectId,
         
        // },
      })
        .then((response) => {
          const res = response.data
          let stcoords=res.start_coords
          let endcords=res.end_coords
         setCallstatus(true)
          fetchLocation(stcoords,endcords,imageSrc)

        })
        .catch((error) => {
          console.error(error);
        });

    }
   
  };


  
  const videoConstraints = {
    facingMode: { exact: "environment" } // Request the back camera
  };
  

  const [status, setStatus] = useState("");
  const [comments, setComments] = useState("");

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };

  const itemsPerPage = 10;
  const startIndex = currentPage * itemsPerPage + 1;
  const endIndex = Math.min((currentPage + 1) * itemsPerPage, data.length);
  // const startIndex = itemOffset + 1;
  // const endIndex = Math.min(itemOffset + itemsPerPage, filteredData.length);

  // Calculate the current page's data
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const [areContractorDetailsLoading, setAreContractorDetailsLoading] = useState(false);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    // Filter data based on search term
    const filtered = data.filter(
      (item) =>
        item.structure.toLowerCase().includes(value) ||
        item.type_of_work.toLowerCase().includes(value) ||
        item.item_of_work.toLowerCase().includes(value) ||
        item.layer_id.toString().includes(value)
    );

    setFilteredData(filtered);
    setItemOffset(0); // Update filtered data
  };


  const [editshow, setEditshow] = useState(false);

  // const handleEditClick = (
   
  // ) => {
  
  //   setEditshow(true);
   
  // };


  const [address,setAddress]=useState('')
  const [isNearby, setIsNearby] = useState(false);
  const [callstatus, setCallstatus] = useState(false);
    const fetchLocation = (startCoord,endCoord,imagedata) => {
     
      ////alert("hi")
      setOncapture(false)
      // Check if geolocation is supported by the browser
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(async function (position) {
      //     const { latitude, longitude } = position.coords;
      //     //console.log(latitude)
      //     //console.log(longitude)
      //     try {
      //       const response = await axios.get(`https://geocode.maps.co/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`);
      //       const result = response.data;
      //       const address = result.display_name;
      //       //console.log("IIIIIIIIIIIIIIII"+address)
      //       setAddress(latitude +"********8"+longitude);
      //     } catch (error) {
      //       //////////////// //console.log(error);
      //     }
      //   });
      // }


      // navigator.geolocation.getCurrentPosition(
      //   async function (position) {
      //     const { latitude, longitude } = position.coords;
      //     //console.log(latitude);
      //     //console.log(longitude);
      //     try {
      //       const response = await axios.get(`https://geocode.maps.co/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`);
      //       const result = response.data;
      //       const address = result.display_name;
      //       //console.log("Address: " + address);
      //       setAddress(latitude + " ********8 " + longitude);
      //     } catch (error) {
      //       //console.log(error);
      //     }
      //   },
      //   function (error) {
      //     //console.log("Geolocation error:", error.message);
      //   },
      //   { enableHighAccuracy: true,
      //     timeout: 5000, // Wait up to 5 seconds for a more accurate location
      //     maximumAge: 0, // No caching; forces a fresh fetch
      //     }
      // );

      const successCallback = (position) => {
        const { latitude, longitude } = position.coords;
        console.log("latitude:"+latitude)
        console.log("longitude:"+longitude)
      ////alert("hello")

      //  let lat=9.9286201
      //  let lon= 78.0958243 
       let lat1=9.9260297
       let lon1= 78.1200861 

   


       const userLocation = {
        latitude: latitude,
        longitude: longitude
      };
    
      const apiLocation = {
        latitude: lat1, // Replace with your API latitude
        longitude: lon1// Replace with your API longitude
      };
    
    
  const currentTime = new Date().getTime();
  let lastUpdateTime = 0; // Store the last update time globally
  const minUpdateInterval = 300000; // Minimum interval in milliseconds (e.g., 1 minute)
  const minDistanceThreshold = 20; // Minimum distance threshold in meters
    // Only continue if the time interval has passed and distance threshold is met
    if (
      (  (currentTime - lastUpdateTime >= minUpdateInterval) ||
        getDistance({latitude, longitude}, locations[locations.lenth-1]) >= minDistanceThreshold && callstatus)
    ) {
        lastUpdateTime = currentTime; // Update last update time
        locations[locations.lenth-1] = {latitude, longitude}; // Store last known location

      const distanceFromStart = getDistance(userLocation, startCoord);
      const distanceFromEnd = getDistance(userLocation, endCoord);
      const totalPathDistance = getPreciseDistance(startCoord, endCoord);
  
      //console.log(distanceFromStart)
      //console.log(distanceFromEnd)
      //console.log(totalPathDistance)
      //console.log(latitude)
      //console.log(longitude)
      // Calculate tolerance distance from path
      const threshold = 100; // Adjust this based on the tolerance you want (in meters)
      
      // Check if user is close to line within a threshold
      setImgSrc((prevImgSrc) => [...prevImgSrc, imagedata]);
      setLocations((prevLocations) => [
        ...prevLocations,
        { latitude, longitude }
      ]);
      setCallstatus(false)
     
if(!(distanceFromStart + distanceFromEnd <= totalPathDistance + threshold))
{
 // setImgSrc([])
 // setLocations([])
  setLocationerror("Location Mismatch")
  
}
else{

  setImgSrc((prevImgSrc) => [...prevImgSrc, imagedata]);
  setLocations((prevLocations) => [
    ...prevLocations,
    { latitude, longitude }
  ]);

}
    }


      // setIsNearby(distanceFromStart + distanceFromEnd <= totalPathDistance + threshold)
    
      //////  const distance = getDistance(userLocation, apiLocation, 1); // 1 for kilometers
       // const threshold = 1000; // 1 kilometer
        // if(distance <= threshold)
        // {
        //   //////alert("Its very Near")
        // }
        // else{
        //   //////alert("Its not very Near")
        // }
        //setIsNearby(distance <= threshold);
     // }
      };
  
      const errorCallback = (error) => {
        //console.log(error)
       
      };
  
      if (navigator.geolocation) {
        ////alert("navi")
        navigator.geolocation.getCurrentPosition(successCallback,   
   errorCallback, {
          enableHighAccuracy: true,
          timeout: 1000,   
  
          maximumAge: 0,
        });
      }
     
//   if (navigator.geolocation && callstatus ) {
//     const watchId = navigator.geolocation.watchPosition(successCallback, errorCallback, {
//         enableHighAccuracy: true,
//         timeout: 1000,
//         maximumAge: 0
//     });

//     // Stop watching position after some time or on component unmount
//     return () => navigator.geolocation.clearWatch(watchId);

// };


    };
    
    
  
  
  const testDep = [
    {
      test_name: "Testing Dependency",
      Doc_name: "Document.pdf",
      sub_date: "9 Nov 2024",
    },
    {
      test_name: "Testing Dependency",
      Doc_name: "Document.pdf",
      sub_date: "9 Nov 2024",
    },
  ];
  const Gallery = [
    {
      img_name: "img1.jpeg",
      sub_date: "9 Nov 2024",
    },
    {
      img_name: "img2.jpeg",
      sub_date: "9 Nov 2024",
    },
  ];

  const Data = [
    {
      item_of_work: "Earth Work Excavation",
      stru_id: "TYPE2-KERB PCC LHS (OUTER)",
      layer_id: "TYPE2-EARTHWORK-FP",
    },
  ];

  const [filteredChainage, setFilteredChainage] = useState([]);

  const userData = useSelector((state) => state.userDetails);

  const [formData, setFormData] = useState({
    from: "",
    from1: "",
    to: "",
    to1: "",
    type: "",
    structure: "",
  });

  const [digiter, setDigiter] = useState("");
  const [saveindex, setSaveindex] = useState(null);
  function onSave(inx)
  {
    setSavestatus(true)
    setSaveindex(inx)
    //setSelectedIndex(null)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessageList([]);
    setSearchsts(false);

    if (name === "from") {
      setFormData({
        from: value,
        from1: "",
        to: "",
        to1: "",
        type: "",
        structure: "",
      });
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "from1") {
      setFormData((prev) => ({
        ...prev,
        from1: value,
        to: "",
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "to") {
      setFormData((prev) => ({
        ...prev,
        to: value,
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "to1") {
      setFormData((prev) => ({
        ...prev,
        to1: value,
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "type") {
      setFormData((prev) => ({
        ...prev,
        type: value,
        structure: "",
      }));
      setSuccessstatus(false)
      setStructure([]);
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setSuccessstatus(false)
    }

    const digit = e.target.value.replace(/\D/g, "");
    if (e.target.name === "from1" || e.target.name === "to1") {
      if (value.length != 3 && name === "from1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in from field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      if (value.length !== 3 && name === "to1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in to field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }

      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: digit };

        if (parseFloat(updatedData.to) <= parseFloat(updatedData.from)) {
          if (parseFloat(updatedData.from1) >= parseFloat(updatedData.to1)) {
            const gt_check = "Enter values greater than start chainage";
            setMessageList((prevMessages) => [...prevMessages, gt_check]);
          }
        }

        return updatedData;
      });
    }

    if (e.target.name === "from") {
      const selectedValue = parseInt(e.target.value, 10);
      const filtered = userData.chainageArr.filter(
        (value) => value >= selectedValue
      );

      setFilteredChainage(filtered);
    }

    if (formData.from != "" && formData.from1 != "" && formData.to!="") {
    
      if (e.target.name==="to1") {
      
          axios
            .get(`${baseurl}/api/work_details/get/type_of_work`, {
              params: {
                project_id: userData.projectId,
                start_chainage: `${formData.from}.${formData.from1}`,
                end_chainage: `${formData.to}.${e.target.value}`,
              },
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userData.token}`,
              },
            })
            .then((response) => {
              const data = response.data;
              const type_of_work_list = data.type_of_work_list;
              setTypeOfWork(type_of_work_list);

              //////console.log(type_of_work_list);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              //////console.log("type_of_work_list");
            });
        }
      }
    

    if (e.target.name === "type" && e.target.value != "") {
      setStructure([]);
      axios
        .get(`${baseurl}/api/work_details/get/strs_or_layers`, {
          params: {
            project_id: userData.projectId,
            type_of_work: e.target.value,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const strs_or_layers_list = data.strs_or_layers_list;
          setStructure(strs_or_layers_list);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const validate = () => {
    if (
      formData.from === "" ||
      formData.from1 === "" ||
      formData.to === "" ||
      formData.to1 === "" ||
      formData.type === "" ||
      formData.structure === ""
    ) {
      const empty_check = "Please fill all the fields";
      setMessageList((prevMessages) => [...prevMessages, empty_check]);
    } else {
      axios({
        method: "GET",
        url: baseurl + "/api/work_details/get/str_or_layer_ids",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + userData.token,
        },
        params: {
          rqd_for: "work_update",
          project_id: userData.projectId,
          strs_or_layers: formData.structure,
          type_of_work: formData.type,
          // start_chainage: "1.4",
          start_chainage: `${formData.from}.${formData.from1}`,
          // end_chainage: "1.9",
          end_chainage: `${formData.to}.${formData.to1}`,
        },
      })
        .then((response) => {
          const res = response.data.str_or_layer_ids_data || [];
          //console.log(JSON.stringify(res))
          setData(res);
          // setCurrentItems(data);
          setFilteredData(res);
          setSuccessstatus(true)
          // //console.log(res.chainage)
          // //////console.log(JSON.stringify(data));
        })
        .catch((error) => {
          console.error(error);
        });
      setSearchsts(true);
    }
  };

  function handleSubmit(e) {
    setMessageList([]);
    setSelectedIndex(null)
    setRemovestatus(false)
    setRemoveeditstatus(false)
    e.preventDefault();
    validate();
  }

  const [editEnabled, setEditEnabled] = useState(false);
  const [testingDependencyFile, setTestingDependencyFile] = useState(null);
  const [galleryFile, setGalleryFile] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [galviewUrl, setGalviewUrl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [removeeditstatus,setRemoveeditstatus]=useState(false)
  const[uploadedpdf,setUploadedpdf]=useState('')
  const[itemofwork,setItemofwork]=useState('')
  const[layerid,setLayerid]=useState('')
  const[strid,setStrid]=useState('')
  const[selectedchainage,setSelectedChainage]=useState('')
  const [istestingDependency, setIstestingDependency] = useState(false);

  const handleEditClose = () => {
    setEditshow(false);
    setImgSrc([])
    setPdffiledata([])
    setSelectedIndex(null)
    setItemofwork('')
    setStrid('')
    setLayerid('')
    setLocations([])
    setLocationerror('')
   
    setOncapture(false)


  }
  const handleEditClick = (inx,imagedata,istest,testdependata,itemwork,sid,lid,chainage) => {

    setSelectedIndex(inx);
    setEditEnabled(true)
setOncapture(false)
    setIstestingDependency(true)


    // if(imagedata.length!=0)
    // {
    // setImgSrc(imagedata)
    // }
    // if(istest==1|| true)//dummy
    // {
    //   setIstestingDependency(true)
      
    //   if(testdependata!=undefined && testdependata!=[] )
    //   {
    //     setPdffiledata(testdependata)
    //   }
    // }
    // else{
    //   setIstestingDependency(false)
    // }

    // setGalleryFile('');
    // setSaveindex('')
    // setSavestatus(false)
    setEditshow(true)
    setItemofwork(itemwork)
    setStrid(sid)
    setLayerid(lid)
    setSelectedChainage(chainage)
   
  }

function showTestdependencyPdf(pdfdata,index,test_id,table_id,chainage,are_testing_dependency_present)
{
 //console.log(pdfdata)
 //console.log(index)
 //console.log(test_id)
 //console.log(table_id)
 //console.log(chainage)
 //console.log(are_testing_dependency_present)
 
setInd(index)
setTestid(test_id)
setTableid(table_id)
setChainage(chainage)
setIstestdepen(are_testing_dependency_present)

if(pdfdata!=undefined && pdfdata!="" && pdfdata!="-" )
{
 
  // const byteCharacters = atob(pdfdata); // Base64 decode if required
  // const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
  // const byteArray = new Uint8Array(byteNumbers);
  // const blob = new Blob([byteArray], { type: 'application/pdf' });
  // const url = URL.createObjectURL(blob);
  //////////alert(pdfdata)
  setUploadedpdf(pdfdata)
  setShow1(true)
  //setRemovestatus(false)

}
else{
  setUploadedpdf('')
  setShow1(true)
}





    // setTestingDependencyFile(null);
    // setPreviewUrl(null);

    // if (selectedIndex != inx) {
    //   setCancelsts(true);
    //   setEditEnabled(true);
    // } else {
    //   setCancelsts(!cancelsts);
    //   setEditEnabled(!editEnabled);
    // }

    // setFileErrMsg("");
    // setImgErrMsg("");
  }

  // useEffect(()=>
  // {
  //   //////////////alert(galleryFile)
  //   //////////////alert(editEnabled)
  //   //////////////alert(selectedIndex)
  // },[editEnabled])

  const onchangeGallery = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
        setUploadedGalleryImage([...e.target.files]); 
   
    setImgErrMsg("");
    }
    else {
      
       setImgErrMsg("Please select a valid image file.");
     }
  }

  const uploadpdffile = (e) => {
    // e.preventDefault()
    //////alert("hello")
    const selectedFile = e.target.files[0];
    //////alert(selectedFile)
    if (selectedFile && selectedFile.type === "application/pdf") {
      setPdffiledata((prevSelectedfile) => [...prevSelectedfile, selectedFile]);
      setFileErrMsg("");
      //////alert(pdffiledata.length)
    }
    else {
       setFileErrMsg("Please select a valid pdf file.");
     }
     e.target.value = null;
  }
  const handleTestingDependencyFileChange = (e) => {
    e.preventDefault()
   // const selectedFile = e.target.files[0];
    if (pdffiledata!="") {
     // setTestingDependencyFile(selectedFile);

      //setFileSelected(true);
      setShow1(false);
      setFileErrMsg("");


      let updatedata = {
        project_id: userData.projectId,
        table_id: tableid,
        test_id: testid,
        chainages: chainage,
      };
      const formData = new FormData();
      formData.append("progress_image", '');
      formData.append("test_result", pdffiledata);
      // Append JSON data (stringified)
      formData.append("update_data", JSON.stringify(updatedata));
      axios({
        method: "PUT",
        url:
          baseurl +
          "/api/work_details/update_site_work?user_id=" +
          userData.userId
         +'&is_test_result_changed=1'
          , // Replace with your actual API endpoint
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + userData.token,
        },
        data: formData,
      })
        .then((response) => {
          //////////////alert("Update")
          setRemovestatus(false)
        //  setRemoveeditstatus(false)
          newUpdatedlist();
         // setSelectedIndex(null)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

    } else {
      setFileSelected(false);
      setFileErrMsg("Please select a valid PDF file.");
    }
  };

  const handleGalleryFileChange = (e ) => {

  e.preventDefault()
  //  const selectedFile = e.target.files[0];
   // if (gallerydata!='') {
     // const imageUrl = URL.createObjectURL(selectedFile);
     
      //setGalShow(false);

      let updatedata = {
        project_id: userData.projectId,
        table_id: tableid,
        test_id: testid,
        chainages: chainage,
      };
      const formData = new FormData();
      formData.append("progress_image", imgSrc);
      formData.append("test_result", pdffiledata);
      // Append JSON data (stringified)
      formData.append("update_data", JSON.stringify(updatedata));
      axios({
        method: "PUT",
        url:
          baseurl +
          "/api/work_details/update_site_work?user_id=" +
          userData.userId
          +'&is_progress_img_changed=1'
          , // Replace with your actual API endpoint
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + userData.token,
        },
        data: formData,
      })
        .then((response) => {
          //////alert("Updated")
          setRemovestatus(false)
        //  setRemoveeditstatus(false)
          newUpdatedlist();
          //setSelectedIndex(null)
         handleEditClose()
          setImgSrc([])
          setPdffiledata([])
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });





      // setPreviewUrl(URL.createObjectURL(selectedFile));
      // setGalviewUrl(imageUrl);
      // setImgSelected(true);
   
      //setGalImg((prev) => [...prev, imageUrl]);
   
  };
  // const handleGalleryFileChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   if (selectedFile && selectedFile.type.startsWith("image/")) {
  //     const imageUrl = URL.createObjectURL(selectedFile);
  //     setGalleryFile(selectedFile);
  //     setPreviewUrl(imageUrl);
  //     setImgSelected(true);
  //     setGalShow(false);
  //     setGalImg((prev) => [...prev, imageUrl]); // Add image URL to gallery array
  //   } else {
  //     setImgSelected(false);
  //     setGalImg([]); // Reset gallery on error
  //     setImgErrMsg("Please select a valid image file.");
  //   }
  // };
//   useEffect(()=>
//   {
// ////////alert("LOcatoo"+JSON.stringify(locations))
// //setLocations(locations)
//   },[locations])

  const handleDone = (
    inx,
    testid,
    tableid,
    chainagelist,
 
  ) => {
    setSelectedIndex(null)

    let updatedata = {
          project_id: userData.projectId,
          table_id: tableid,
          test_id: testid,
          chainages: chainagelist,
          is_done:1
        };
        const formData = new FormData();
      //  formData.append("progress_image", galleryFile);
      //  formData.append("test_result", testingDependencyFile);
        // Append JSON data (stringified)
        formData.append("update_data", JSON.stringify(updatedata));
        axios({
          method: "PUT",
          url:
            baseurl +
            "/api/work_details/update_site_work?user_id=" +
            userData.userId, // Replace with your actual API endpoint
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + userData.token,
          },
          data: formData,
        })
          .then((response) => {
            //////////////alert("Done")
            newUpdatedlist();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })

  }


function RemoveImage()
{
setRemovestatus(true)
  // let updatedata = {
  //   project_id: userData.projectId,
  //   table_id: tableid,
  //   test_id: testid,
  //   chainages: chainage,
  // };
  // const formData = new FormData();
  // formData.append("progress_image", '');
  // formData.append("test_result", 'NA');
  // // Append JSON data (stringified)
  // formData.append("update_data", JSON.stringify(updatedata));
  // axios({
  //   method: "PUT",
  //   url:
  //     baseurl +
  //     "/api/work_details/update_site_work?user_id=" +
  //     userData.userId, // Replace with your actual API endpoint
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: "Bearer " + userData.token,
  //   },
  //   data: formData,
  // })
  //   .then((response) => {
     
  //     //////////////alert("I removed the image")
  //     setGalShow(false)
  //     setUploadedGalleryImage('')
  //     newUpdatedlist();
  //   })
  //   .catch((error) => {
  //     console.error("Error fetching data:", error);
  //   });
}


  const handleFileSubmit = (
    inx,
    testid,
    tableid,
    chainagelist,
    testingdepencystatus
  ) => {
  
    setSelectedIndex(inx);

   
      if (testingDependencyFile!='' || galleryFile!='') {
      
        let updatedata = {
          project_id: userData.projectId,
          table_id: tableid,
          test_id: testid,
          chainages: chainagelist,
        };
        const formData = new FormData();
        formData.append("progress_image", galleryFile);
        formData.append("test_result", testingDependencyFile);
        // Append JSON data (stringified)
        formData.append("update_data", JSON.stringify(updatedata));
        axios({
          method: "PUT",
          url:
            baseurl +
            "/api/work_details/update_site_work?user_id=" +
            userData.userId, // Replace with your actual API endpoint
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + userData.token,
          },
          data: formData,
        })
          .then((response) => {
           
            newUpdatedlist();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
   // } else {
      //if (galleryFile) {
        //   let updatedata = {
        //     project_id: userData.projectId,
        //     table_id: tableid,
        //     // test_id:testid,
        //     chainages: chainagelist,
        //   };
        //   const formData = new FormData();
        //   formData.append("progress_image", galleryFile);
        //   formData.append("test_result", testingDependencyFile);
        //   // Append JSON data (stringified)
        //   formData.append("update_data", JSON.stringify(updatedata));
        //   axios({
        //     method: "PUT",
        //     url: baseurl + "/api/work_details/update_site_work?user_id="+ userData.userId, // Replace with your actual API endpoint
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //       Authorization: "Bearer " + userData.token,
        //     },
        //     data: formData,
        //   })
        //     .then((response) => {
        //       // //////////////alert("sucess without testdepen")
        //       newUpdatedlist();
        //     })
        //     .catch((error) => {
        //       console.error("Error fetching data:", error);
        //     });
    //  }
    //}
    setSelectedIndex(null);
  };
const[ind,setInd]=useState('')
const[testid,setTestid]=useState('')
const[tableid,setTableid]=useState('')
const[chainage,setChainage]=useState('')
const[istestdepen,setIstestdepen]=useState(0)

  function showGalleryImage(image,index,test_id,table_id,chainage,are_testing_dependency_present)
  {
setInd(index)
setTestid(test_id)
setTableid(table_id)
setChainage(chainage)
setIstestdepen(are_testing_dependency_present)
setSelectedIndex(index)
////////alert("selectedIndex"+selectedIndex)
////////alert("showindex"+index)


  if(image!=undefined)
  {
    setUploadedGalleryImage(image)
    setGalShow(true)
    setRemovestatus(false)
    setGalShowindex(index)

  }
  else{
    setUploadedGalleryImage('')
    setGalShow(true)
  }
  }

//   useEffect(()=>
//   {
// //console.log("Uploaded Image"+uploadedGalleryImage)
//   },[uploadedGalleryImage])
const[confirmation,setConfirmation]=useState(false)


function  noChangeImage() {

  setRemovestatus(false)

}


  function newUpdatedlist() {
    setAreContractorDetailsLoading(true)
    setData([])
    axios({
      method: "GET",
      url: baseurl + "/api/work_details/get/str_or_layer_ids",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
      params: {
        rqd_for: "work_update",
        project_id: userData.projectId,
        strs_or_layers: formData.structure,
        type_of_work: formData.type,
        // start_chainage: "1.4",
        start_chainage: `${formData.from}.${formData.from1}`,
        // end_chainage: "1.9",
        end_chainage: `${formData.to}.${formData.to1}`,
      },
    })
      .then((response) => {
        const res = response.data.str_or_layer_ids_data;
        //console.log("New Updated list"+JSON.stringify(res))
        //////////////alert("Hi i am new list")
        setData(res);
        setFilteredData(res)
        //console.log(JSON.stringify(res));
        setAreContractorDetailsLoading(false)
        // setFilteredData(res)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleClose = () => {
    setShow1(false);
    setRemovestatus(false)
   // setRemoveeditstatus(false)
    setFileErrMsg('')
    setSavestatus(false)
    setUploadedpdf('')
    setSaveindex('')
    setSavestatus(false)
  };
  const handleGalClose = () => {
    setGalShow(false);
    setRemovestatus(false)
    //setRemoveeditstatus(false)
    setImgErrMsg('')
    setSavestatus(false)
    setUploadedGalleryImage('')
    setSaveindex('')
    setSavestatus(false)
  };

// useEffect(()=>
// {
// //console.log("Updated data"+JSON.stringify(data))
// },[data])

const[viewstatus,setViewstatus]=useState()
const[viewpdfstatus,setViewpdfstatus]=useState()
const[imageviewindex,setImageviewindex]=useState(null)
const[pdfviewindex,setPdfviewindex]=useState(null)

const onView=(e,index)=>
{
  e.preventDefault()
setViewstatus(true)
setImageviewindex(index)
}

const onDelete=(e,index)=>
  {

  e.preventDefault()
  setPdffiledata((prevPdffiledata) => 
    prevPdffiledata.filter((_, i) => i !== index)
  );

  }
  const onDelete1=(e,index)=>
    {
  
    e.preventDefault()
    setLocations((prevLocations) => 
      prevLocations.filter((_, i) => i !== index)
    );
    setImgSrc((prevImgSrc) => 
      prevImgSrc.filter((_, i) => i !== index)
    );
  
    }
const closeView=()=>
{
  setViewstatus(false)
  setImageviewindex(null)
}

const onViewpdf=(e,index)=>
  {
    e.preventDefault()
  setViewpdfstatus(true)
  setPdfviewindex(index)
  }

const closeViewpdf=()=>
  {
    setViewpdfstatus(false)
    setPdfviewindex(null)
  }

  const handleCadOpen = (img) => {
    setCadImg(img);
    setCadShow(true);
  };

  const handleCadClose = () => {
    setCadShow(false);
    setCadImg("");
  };

  const data1 = {
    str_or_layer_ids_data: [
      {
        chainage: ["1/4 - 1/9", "2/0-2/90"],
        test_result_status: ["-"],
        type_of_work: "TYPE1",
        test_result_uploaded_date: ["-"],
        start_chainage: "1/4",
        end_chainage: "1/9",
        bill_status: "Incompleted / NA",
        cad_image_url: [
          "https://bricklane.s3.ap-south-1.amazonaws.com/6297e8d8-6689-479c-9b1a-f17beb79ba2f.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241015T041222Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241015%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=27e8835db07df27fe51e35eb1f10e07e3a456f2b29cb76b7a16fbc9ae8ab74aa",
        ],
        structure: "paving slab - FP",
        layer_id: "-",
        item_of_work: "Paver Slab",
        test_result: ["-"],
        progress_image_url: [
          "https://bricklane.s3.ap-south-1.amazonaws.com/9dcbd915-b6b3-45d7-a396-0ed588bece0a.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240823T055114Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZESOME4GNI%2F20240823%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=c807a55d1437a48a9c7a1cdfc9264026961c29d5d9fa0b73ea83525a468edbce",
        ],
        str_id: "TYPE1-PAVING SLAB-FP - 60MM",
        test_name: ["-"],
        test_id: [0],
        table_id: "0",
      },
      {
        chainage: ["1/4 - 1/9", "2/0-2/90"],
        test_result_status: ["-"],
        type_of_work: "TYPE1",
        test_result_uploaded_date: ["-"],
        start_chainage: "1/4",
        end_chainage: "1/9",
        bill_status: "Incompleted / NA",
        cad_image_url: [
          "https://bricklane.s3.ap-south-1.amazonaws.com/6297e8d8-6689-479c-9b1a-f17beb79ba2f.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241015T041222Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241015%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=27e8835db07df27fe51e35eb1f10e07e3a456f2b29cb76b7a16fbc9ae8ab74aa",
        ],
        structure: "paving slab - FP",
        layer_id: "-",
        item_of_work: "Paver Slab",
        test_result: ["-"],
        progress_image_url: [
          "https://bricklane.s3.ap-south-1.amazonaws.com/9dcbd915-b6b3-45d7-a396-0ed588bece0a.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240823T055114Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZESOME4GNI%2F20240823%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=c807a55d1437a48a9c7a1cdfc9264026961c29d5d9fa0b73ea83525a468edbce",
        ],
        str_id: "TYPE1-PAVING SLAB-FP - 60MM",
        test_name: ["-"],
        test_id: [0],
        table_id: "1",
      },
      {
        chainage: ["1/4 - 1/9", "2/0-2/90"],
        test_result_status: ["-"],
        type_of_work: "TYPE1",
        test_result_uploaded_date: ["-"],
        start_chainage: "1/4",
        end_chainage: "1/9",
        bill_status: "Incompleted / NA",
        cad_image_url: [
          "https://bricklane.s3.ap-south-1.amazonaws.com/6297e8d8-6689-479c-9b1a-f17beb79ba2f.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241015T041222Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241015%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=27e8835db07df27fe51e35eb1f10e07e3a456f2b29cb76b7a16fbc9ae8ab74aa",
        ],
        structure: "paving slab - FP",
        layer_id: "-",
        item_of_work: "Paver Slab",
        test_result: ["-"],
        progress_image_url: [
          "https://bricklane.s3.ap-south-1.amazonaws.com/9dcbd915-b6b3-45d7-a396-0ed588bece0a.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240823T055114Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZESOME4GNI%2F20240823%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=c807a55d1437a48a9c7a1cdfc9264026961c29d5d9fa0b73ea83525a468edbce",
        ],
        str_id: "TYPE1-PAVING SLAB-FP - 60MM",
        test_name: ["-"],
        test_id: [0],
        table_id: "2",
      },
      {
        chainage: ["1/4 - 1/9", "2/0-2/90"],
        test_result_status: ["-"],
        type_of_work: "TYPE1",
        test_result_uploaded_date: ["-"],
        start_chainage: "1/4",
        end_chainage: "1/9",
        bill_status: "Incompleted / NA",
        cad_image_url: [
          "https://bricklane.s3.ap-south-1.amazonaws.com/6297e8d8-6689-479c-9b1a-f17beb79ba2f.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241015T041222Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241015%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=27e8835db07df27fe51e35eb1f10e07e3a456f2b29cb76b7a16fbc9ae8ab74aa",
        ],
        structure: "paving slab - FP",
        layer_id: "-",
        item_of_work: "Paver Slab",
        test_result: ["-"],
        progress_image_url: [
          "https://bricklane.s3.ap-south-1.amazonaws.com/9dcbd915-b6b3-45d7-a396-0ed588bece0a.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240823T055114Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZESOME4GNI%2F20240823%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=c807a55d1437a48a9c7a1cdfc9264026961c29d5d9fa0b73ea83525a468edbce",
        ],
        str_id: "TYPE1-PAVING SLAB-FP - 60MM",
        test_name: ["-"],
        test_id: [0],
        table_id: "3",
      },
    ],
  };

  //const arr = data1.str_or_layer_ids_data

  ////////console.log(arr);

  return (
    <>
      <CustomToastContainer messageList={messageList} />

      <div className="container-fluid mt-1">
        <div className="w-100 mb-0 d-flex align-items-center justify-content-center ">
          <h5 className="mb-0">{userData.projectName}</h5>
          <img className="mb-0 ml-2" src={arrow} width={100} height={10} />
          <h6 className="ml-2 mb-0">
            Total Chainage
            <span
              className="ml-1"
              style={{ color: "#0AAF4C", fontWeight: "600" }}
            >
              {userData.startChainage}-{userData.endChainage}
            </span>
          </h6>
        </div>
        {/* <div className="container">
        <div
          className="row sh  mx-auto mb-0  pt-2 pb-2 rounded"
          // style={{ maxWidth: "85%" }}
        >
          <div className="col-12  px-1">
            <div className=" bg-white ">
              <div className="row">
                <div className="col-1">
                  <label
                    className="form-label mb-0 mt-1"
                    style={{ fontWeight: "500", fontSize: "15px" }}
                  >
                    Chainage&nbsp;
                  </label>
                </div>
                <div className=" col-lg-5  ">
                  <div className="w-100  p-0">
                    <div className="row ">
                      <div className="col-lg-6 ">
                        <div class=" input-group">
                          <label
                            className="form-label mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            From :&nbsp;
                          </label>
                          <select
                            onChange={handleChange}
                            value={formData.from}
                            name="from"
                            className="form-control border rounded-0"
                            style={{
                              width: "30px",
                              fontSize: "12px",
                            }}
                          >
                            <option value="" className="pl-0"></option>
                            {userData.chainageArr != undefined &&
                              userData.chainageArr.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>

                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="from1"
                            type="text"
                            value={formData.from1}
                            onChange={handleChange}
                            className="form-control border rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div class="input-group">
                          <label
                            className="form-label  mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            To :&nbsp;
                          </label>

                          <select
                            name="to"
                            onChange={handleChange}
                            value={formData.to}
                            className="border form-control rounded-0"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {formData.from1.length == 3 &&
                              filteredChainage.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0 rounded-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="to1"
                            type="text"
                            value={formData.to1}
                            onChange={handleChange}
                            className=" border form-control rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 px-0 ">
                  <div className=" text-center">
                    <select
                      name="type"
                      onChange={handleChange}
                      value={formData.type}
                      className=" border form-control rounded-0 border-top-0 border-bottom-0 border-right-0"
                      style={{
                        fontSize: "12px",
                        width: "135px",
                      }}
                    >
                      <option value="">Type of Work...</option>
                      {formData.to1.length == 3 &&
                        typeOfWork.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3  px-0 ">
                  <div className=" text-center">
                    <select
                      name="structure"
                      onChange={handleChange}
                      value={formData.structure}
                      className=" border form-control border-top-0 border-bottom-0 border-right-0 rounded-0"
                      style={{
                        fontSize: "12px",
                        width: "200px",
                      }}
                    >
                      <option disabled value="">
                        Structure/Layer
                      </option>
                      {structure.map((item, index) => (
                        <option
                          key={index}
                          value={
                            item === "Structures And Layers" ? "All" : item
                          }
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-center col-1">
                  <button
                    onClick={handleSubmit}
                    className="btn text-center text-white  mt-0 "
                    style={{
                      backgroundColor: "#1953AA",
                      fontSize: "14px",
                    }}
                  >
                    <FaSearch />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div></div> */}
        <div className="container">
          <div
            className="row sh  mx-auto mb-0  pt-2 pb-2 rounded "
            // style={{ maxWidth: "85%" }}
          >
            <div className="col-12  px-1">
              <div className="container bg-white ">
                <div className="row">
                  <div className="col-1">
                    <label
                      className="form-label mb-0 mt-1"
                      style={{ fontWeight: "500", fontSize: "15px" }}
                    >
                      Chainage&nbsp;
                    </label>
                  </div>
                  <div className=" col-lg-5  ">
                    <div className="w-100  p-0">
                      <div className="row ">
                        <div className="col-lg-6 ">
                          <div class=" input-group">
                            <label
                              className="form-label mb-0 mt-1"
                              style={{ fontSize: "14px" }}
                            >
                              From :&nbsp;
                            </label>
                            <select
                              onChange={handleChange}
                              value={formData.from}
                              name="from"
                              className="form-control border rounded-0"
                              style={{
                                width: "30px",
                                fontSize: "12px",
                              }}
                            >
                              <option value="" className="pl-0"></option>
                              {userData.chainageArr != undefined &&
                                userData.chainageArr.map((item, index) => {
                                  return (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                            </select>

                            <div class="input-group-append rounded-0">
                              <span
                                class="input-group-text px-0"
                                id="basic-addon1"
                              >
                                <RxSlash />
                              </span>
                            </div>
                            <input
                              maxLength={3}
                              name="from1"
                              type="text"
                              value={formData.from1}
                              onChange={handleChange}
                              className="form-control border rounded-0 pl-2"
                              style={{
                                fontSize: "12px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div class="input-group">
                            <label
                              className="form-label  mb-0 mt-1"
                              style={{ fontSize: "14px" }}
                            >
                              To :&nbsp;
                            </label>

                            <select
                              name="to"
                              onChange={handleChange}
                              value={formData.to}
                              className="border form-control rounded-0"
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              <option value=""></option>
                              {formData.from1.length == 3 &&
                                filteredChainage.map((item, index) => {
                                  return (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                            </select>
                            <div class="input-group-append rounded-0">
                              <span
                                class="input-group-text px-0 rounded-0"
                                id="basic-addon1"
                              >
                                <RxSlash />
                              </span>
                            </div>
                            <input
                              maxLength={3}
                              name="to1"
                              type="text"
                              value={formData.to1}
                              onChange={handleChange}
                              className=" border form-control rounded-0 pl-2"
                              style={{
                                fontSize: "12px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 px-0 ">
                    <div className=" text-center">
                      <select
                        name="type"
                        onChange={handleChange}
                        value={formData.type}
                        className=" border form-control rounded-0 border-top-0 border-bottom-0 border-right-0"
                        style={{
                          fontSize: "12px",
                          width: "170px",
                        }}
                      >
                        <option value="">Type of Work...</option>
                        {formData.to1.length == 3 &&
                          typeOfWork.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3  px-0 ">
                    <div className=" text-center">
                      <select
                        name="structure"
                        onChange={handleChange}
                        value={formData.structure}
                        className=" border form-control border-top-0 border-bottom-0 border-right-0 rounded-0"
                        style={{
                          fontSize: "12px",
                          width: "270px",
                        }}
                      >
                        <option disabled value="">
                          Structure/Layer
                        </option>
                        {structure.map((item, index) => (
                          <option
                            key={index}
                            value={
                              item === "Structures And Layers" ? "All" : item
                            }
                          >
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="text-center col-1">
                    <button
                      disabled={successstatus ? true : false}
                      onClick={handleSubmit}
                      className="btn text-center text-white  mt-0 "
                      style={{
                        backgroundColor: "#1953AA",
                        fontSize: "14px",
                      }}
                    >
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          <div className="text-red er">
            <center>{digiter}</center>
          </div>
        }

{areContractorDetailsLoading && (
  <center>
            <ThreeDots
              visible={true}
              height="20"
              width="40"
              color="#3369a6"
              radius="7"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
            </center>
          )}
 {!areContractorDetailsLoading && successstatus && data.length==0 &&(
    <center><div className="login-error"><b>No Data Available</b> </div></center> 
 )}


        {!areContractorDetailsLoading && successstatus && data.length!=0 &&(
          <div className="  ">
            <div className="marg">
              <div className="input-group  radius  text-end in mt-2  mb-1 ">
                <input
                  style={{ fontSize: "15px" }}
                  value={searchTerm}
                  onChange={handleSearch}
                  className="form-control p-0  pl-3 border-0 bg-transparent text-black "
                  placeholder="Keyword search"
                  type="search"
                />{" "}
                <div class="input-group-append  ">
                  <button
                    onClick={""}
                    class="input-group-text radi border-0  "
                    id="basic-addon1"
                  >
                    <img src={search1} width={16} />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="mx-auto mt-1" style={{ width: "96%" }}>
                <table className="mt-0 table mb-1 table-bordered ">
                  <thead
                    className="table-bordered"
                    style={{
                      background: "linear-gradient(to right,#4889CF,#144A9A)",
                      fontSize: "14px",
                      color: "white",
                      position: "sticky",
                      top: "0",
                    }}
                  >
                    <tr>
                      <th
                        className="mb-0  p-2"
                        style={{
                          fontSize: "12px",
                          borderTopLeftRadius: "10px",
                          width: "12%",
                        }}
                      >
                        Chainage
                      </th>
                      <th className=" p-2" style={{ fontSize: "12px" }}>
                        <b>Item Of Work</b>
                      </th>

                    {(formData.structure==="Structures" || formData.structure==="All" )&& <th className="p-2" style={{ fontSize: "12px" }}>
                        <b>Structure</b>
                      </th>}
                      {(formData.structure==="Layers" || formData.structure==="All" )&& <th
                        className=" p-2"
                        style={{
                          fontSize: "12px",
                          // ,width:'19%'
                        }}
                      >
                        <b>Layer</b>
                      </th>}

                      <th
                        className=" p-2"
                        style={{ fontSize: "12px", width: "8%" }}
                      >
                        Type Of Work
                      </th>
                      {/* <th
                        className="mb-0 p-2 "
                        style={{ fontSize: "12px", width: "11%" }}
                      >
                        Testing Dependency
                      </th> */}
                      <th
                        className=" p-2"
                        style={{ fontSize: "12px", width: "7%" }}
                      >
                        CAD Image
                      </th>
                     
                      <th
                        className="p-2"
                        style={{
                          borderTopRightRadius: "10px",
                          fontSize: "12px",
                          width: "10%",
                        }}
                      >
                        {" "}
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {(searchTerm ? filteredData : currentPageData).map(
                    (item, index) => {
                      return (
                        <>
                          <tbody key={index} className="">
                            <tr>
                              <td className=" p-0 my-auto pb-1 pt-1">
                                {" "}
                                <span
                                  style={{
                                    color: "#00682A",
                                    fontWeight: "600",
                                    fontSize: "11px",
                                  }}
                                >
                                  {item.chainage.join(", ")}
                                </span>
                              </td>

                              <td className=" p-0 my-auto pb-1 pt-1">
                                {" "}
                                <span
                                  style={{
                                    color: "#1761CF",
                                    fontWeight: "500",
                                    fontSize: "11px",
                                  }}
                                >
                                  {item.item_of_work}
                                </span>
                              </td>
                              {(formData.structure==="Structures" || formData.structure==="All" )&&      <td className=" p-0 my-auto pb-1 pt-1">
                                {" "}
                                <span
                                  style={{
                                    color: "#1761CF",
                                    fontWeight: "500",
                                    fontSize: "11px",
                                  }}
                                >
                                  {item.str_id}
                                </span>
                              </td>}
                              {(formData.structure==="Layers" || formData.structure==="All" )&&    <td className=" p-0 my-auto pb-1 pt-1">
                                {" "}
                                <span
                                  style={{
                                    color: "#1761CF",
                                    fontWeight: "500",
                                    fontSize: "11px",
                                  }}
                                >
                                  {item.layer_id}
                                </span>
                              </td>}
                              <td className=" p-0 my-auto pb-1 pt-1">
                                {" "}
                                <span
                                  style={{
                                    color: "#1761CF",
                                    fontWeight: "500",
                                    fontSize: "11px",
                                  }}
                                >
                                  {item.type_of_work}
                                </span>
                              </td>
                              {/* <td className=" p-0 my-auto pb-1 pt-1">
                                {" "}
                                <span
                                  style={{
                                    color: "#1761CF",
                                    fontWeight: "500",
                                    fontSize: "11px",
                                  }}
                                >
                                  {item.are_testing_dependency_present == 1 ? (
                                     
                                     (item.testing_dependency_data[0].test_result!="-" && item.testing_dependency_data[0].test_result!="")
                                     ?

                                    <button   onClick={() => showTestdependencyPdf(item.testing_dependency_data[0].test_result, index,
                                      item.testing_dependency_data[0].test_id,
                                      item.table_id,
                                      item.chainage,
                                      item.are_testing_dependency_present)}
                                      className="btn d-inline-flex align-items-center p-1 text-white"
                                      style={{
                                        fontSize: "11px",
                                     //   backgroundColor: "#374F77",
                                      }}
                                    >
                                      
                                  
                                    <FaFilePdf  
                                     style={{
                                        
                                        backgroundColor: "red",
                                        width:15,
                                        height:15
                                      }}/>
                                    </button>
                                    :
<button
                                  onClick={() => showTestdependencyPdf(item.testing_dependency_data[0].test_result, index,
                                    item.testing_dependency_data[0].test_id,
                                    item.table_id,
                                    item.chainage,
                                    item.are_testing_dependency_present)}
                                    disabled={ index!=selectedIndex}
                                  className="btn p-0 d-inline-flex align-items-center text-white border-0"
                                  style={{
                                    fontSize: "10px",
                                    // backgroundColor: "#374F77",
                                  }}
                                >
                                   <p
                                      className="p-1 mb-0 rounded"
                                      style={{ backgroundColor: "#374F77" }}
                                    >
                                      <FiUpload />
                                      &nbsp;{" "}
                                     
                                      Upload
                                    </p>
                                    </button>


                                  ) : (
                                    "NA"
                                  )}
                                </span>
                              </td> */}
                              <td className=" p-0 my-auto pb-1 pt-1">
                                {" "}
                                <button
                                  className="btn p-0"
                                  onClick={() =>
                                    handleCadOpen(item.cad_image_url)
                                  }
                                >
                                  <img src={auto} alt="CAD" width={24} />
                                </button>
                              </td>
                              {/* <td className=" p-0 my-auto pb-1 pt-1">
                                {" "}
                               
                                  {(item.progress_image_url.length!=0)
                                && (
                                  <button
                                  onClick={() => showGalleryImage(item.progress_image_url[0], index,
                                    item.test_id,
                                    item.table_id,
                                    item.chainage,
                                    item.are_testing_dependency_present)}
                                  className="btn p-0 d-inline-flex align-items-center text-white border-0"
                                  style={{
                                    fontSize: "10px",
                                    // backgroundColor: "#374F77",
                                  }}
                                >
                                    <FcGallery
                                      className="p-0"
                                      style={{
                                        fontSize: "20px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                    </button>
                                  ) 
                                }
                                
                                
                                  {(item.progress_image_url.length==0 )&&
                                   (
                                    <button
                                    onClick={() => showGalleryImage(item.progress_image_url[0],
                                      index,
                                      item.test_id,
                                      item.table_id,
                                      item.chainage,
                                      item.are_testing_dependency_present
                                    )}
                                  disabled={ index!=selectedIndex}
                                    className="btn p-0 d-inline-flex align-items-center text-white border-0"
                                    style={{
                                      fontSize: "10px",
                                      // backgroundColor: "#374F77",
                                    }}
                                  >
                                    <p
                                      className="p-1 mb-0 rounded"
                                      style={{ backgroundColor: "#374F77" }}
                                    >
                                      <FiUpload />
                                      &nbsp;{" "}
                                     
                                      Upload
                                    </p>
                                    </button>
                                  )}
                                
                              </td> */}

                              <td className=" p-0 my-auto pb-1 pt-1">
                              
                                    {/* {
                                    
                                    item.are_testing_dependency_present==0 
                                    ?
                                    item.progress_image_url.length!=0 && (editEnabled && index==selectedIndex)&&<button
                                        className="btn boxsha2 btn-sm text-white p-1"
                                        style={{
                                          fontSize: "10px",
                                          backgroundColor: "#6777EF",
                                        }}
                                      onClick={() =>  onSave(index)}
                                      >
                                      {savestatus && index==saveindex? "Saved":"Save"}  
                                      </button>
                                      :(item.testing_dependency_data[0].test_result!="-" ||
                                      item.progress_image_url.length!=0) && editEnabled && index==selectedIndex &&  <button
                                      className="btn boxsha2 btn-sm text-white p-1"
                                      style={{
                                        fontSize: "10px",
                                        backgroundColor: "#6777EF",
                                      }}
                                      onClick={() =>  onSave(index)}
                                    >
                                    {savestatus && index==saveindex ? "Saved":"Save"}  
                                    </button>


                    }
                                    */}
                                  
                               

                               
                                  
                               {(removeeditstatus && index==selectedIndex)||(editEnabled&& index==selectedIndex)
                               ?''
                                :
                               
                                      <button
                                        className="btn p-0 pl-2"
                                        onClick={() => handleEditClick(index,item.progress_image_url,item.are_testing_dependency_present,item.testing_dependency_data.test_result,item.item_of_work,item.str_id,item.layer_id,item.chainage)}
                                      >
                                        <FiEdit style={{ color: "#00A350" }} />
                                      </button>
                                     
                                    
                                }
                              
                              </td>
                            </tr>
                          </tbody>
                        </>
                      );
                    }
                  )}
                </table>
              </div>
              <div className="d-flex justify-content-between mt-2  marg1 mx-auto align-items-center">
                <p className="mb-0" style={{ fontWeight: "500" }}>
                  Showing &nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    {startIndex}
                  </span> to{" "}
                  <span style={{ fontWeight: "bold" }}>{endIndex}</span> of
                  {data.length}
                </p>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<GrFormNext />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={<GrFormPrevious />}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  activeClassName="active"
                  previousClassName="previous"
                  nextClassName="next"
                  disabledClassName="disabled"
                  breakClassName="break"
                />
              </div>
            </div>
          </div>
        )}

        <Modal centered show={show1} onHide={handleClose}>
          <Modal.Body className="bg-white p-5 rounded">
            <div
              className="d-flex justify-content-end align-items-center "
              style={{ marginTop: "-2%", marginRight: "-2%" }}
            >
              <button className="btn  " onClick={handleClose}>
                <FaXmark />
              </button>
            </div>
            {( uploadedpdf!='' && (!removestatus) && removeeditstatus)&&
<center>
   <button
   className="btn p-0 pl-2 pb-3"
   onClick={() => RemoveImage()}
 >
 <b> Change PDF</b> <AiFillCloseCircle style={{ color: "red" }} />
 </button>
 </center>
}
{( uploadedpdf!=''&& (!removestatus))&&
<iframe   src={uploadedpdf} width="100%" height="650px"  type="application/pdf"/>
}


{(uploadedpdf=='' || removestatus) && (
  <form className="mb-2">
              <h5>Upload File</h5>
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">
                  Select a file to upload.
                </label>
                <input
                 // onChange={(e)=>onchangepdf(e)}
                  className="form-control h-auto p-1"
                  type="file"
                  id="pdffiledata"
                  name="pdffiledata"
                />
                <p className="text-danger mt-1" style={{ fontWeight: "500" }}>
                  {fileErrMsg}
                </p>
              </div>
              <center> <button
                                        className="btn boxsha2 btn-sm text-white p-2"
                                        style={{
                                          fontSize: "15px",
                                          backgroundColor: "#6777EF",
                                        }}
                                        onClick={handleTestingDependencyFileChange}
                                         
                                      >
                                        Save
                                      </button>
                                      &nbsp;&nbsp;
                                      {removestatus && <button className="btn  btn-sm text-white p-2"
                                        style={{
                                          fontSize: "15px",
                                          backgroundColor: "#ff0000",
                                        }}
                                        onClick={noChangeImage}
                                         
                                      >
                                  Cancel
                                      </button>}
                                      </center>
            </form>)}
          </Modal.Body>
        </Modal>


{/* Edit Model */}

<Modal centered show={editshow} onHide={handleEditClose} backdrop="static"  >
          <div className="d-flex justify-content-end align-items-center  ">
            <button
              style={{
                backgroundColor: "#E56C6C",
                position: "absolute",
                zIndex: "999",
              }}
              className="btn ml-3 rounded-circle"
              onClick={handleEditClose}
            >
              <FaXmark className="text-white" />
            </button>
          </div>


          { oncapture && 

          <Modal.Body className="bg-white p-2 rounded custom-modal-body ">
          <div style={{ marginBottom: '20px' }} className="text-center">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
         style={{
          borderRadius: "8px",
          width: "100%",
          // maxWidth: '400px',
          // bo     xShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
          height: "350px",
        }}
        className="mx-auto w-100 "
        />
           <button 
         onClick={capture} 
         style={{
           padding: '10px 20px',
           borderRadius: '5px',
           backgroundColor: '#007BFF',
           color: '#fff',
           border: 'none',
           cursor: 'pointer',
           marginBottom: '20px',
           boxShadow: "0px 4px 10px #5874C6",
         }}
       >
                          <LuCamera />
                          Take Photo
       </button>
        {/* {imgSrc.length!=0 && (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <img 
            src={imgSrc} 
            alt="captured" 
            style={{
              width: '100%',
             // maxWidth: '400px',
              borderRadius: '8px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
            }}
          />
        </div>
      )} */}
        </div>
        </Modal.Body>
      
}





         {!oncapture && <Modal.Body className="bg-white p-2 rounded custom-modal-body ">
            <form className="mb-2 pt-2">
              <center>
                <h5 className="p-3 pt-2" style={{background:`url(${below})`,backgroundRepeat:'no-repeat',backgroundPosition:'bottom center',backgroundSize:'250px '}}>Chainage:1/000-6/000</h5>
              </center>
              <div class="shadow-box pt-0  mb-3">
                <div class="row">
                  <div class="col-lg-12 bluestyle"></div>
                  <div class="col-lg-12">
                    <table
                      style={{ color: "black", borderCollapse: "collapse" }}
                    >
                  
                            <tbody>
                              <tr className="text-start">
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  Item of work
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  :
                                </td>
                                <td className=""
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                    color: "black",
                                  }}
                                >
                                  {itemofwork}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  Structure Id
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                    color: "black",
                                  }}
                                >
                                  {strid}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  Layer Id
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                    color: "black",
                                  }}
                                >
                                  {layerid}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "2px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  Cad Image
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  <img src={cad} height={20} />
                                </td>
                              </tr>
                            </tbody>
                         
                    </table>
                  </div>
                </div>
              </div>


 {/* <label
          htmlFor="gallerydata"
          className="upload-label"
          style={{
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: "#6777EF",
            padding: "10px 20px",
            color: "#fff",
            borderRadius: "20px",
            fontSize: "15px"
          }}
        >
          <img src={fileexplorer} alt="Upload Icon" className="upload-icon" style={{ marginRight: '10px' }} />
          Upload File
        </label>
        <input
          type="file"
          id="gallerydata"
          name="gallerydata"
          multiple
          className="file-input"
          onChange={uploadpdffile}
          style={{ display: 'none' }} // Hide actual file input
        /> */}




    
             {istestingDependency && <div class="shadow-box mt-2 pt-0">
                <div className=" border-bottom mb-2 p-2">
                  <center className="d-flex align-items-center justify-content-center">
                  <img
                      src={testing}
                      width="50"
                      height="25"
                      className="img-fluid"
                    />
                    <span className="mt-1" style={{ fontWeight: "bold" }}>
                      Testing Dependency
                    </span>
                  </center>
                </div>
                <div className="custom-file-upload">
               
               <center>
                
                <label htmlFor="gallerydata">
                 <img src={fileexplorer} alt="Upload Icon" className="upload-icon" />
               </label>
               <input
                 type="file"
                 id="gallerydata"
                 name="gallerydata"
                 multiple
                 className="file-input"
                 onChange={(e) => uploadpdffile(e)}
               />
               <button
                                                     className="btn boxsha2 btn-sm text-white p-2"
                                                     style={{
                                                       fontSize: "15px",
                                                       backgroundColor: "#6777EF",
                                                       borderRadius:"20px"
                                                     }}
                                                    onClick={(e) => uploadpdffile(e)}        
                                                   >
                                                     Upload File
                                                   </button>
                                                   


               </center>
             </div>
             <center><div className="login-error">{fileErrMsg}</div></center>

                {pdffiledata.length!=0 && pdffiledata.map((item, index) => {
                  return (
                    <>
                      <div className="" key={index}>
                        <div className="row border mt-2 p-2 container mx-auto rounded">
                          <div className="col-6 px-0 d-flex align-items-center ">
                            <img
                              src={pdficon}
                              width={40}
                              height={40}
                              className="img-fluid mr-2"
                            />
                            <div className="" style={{ fontSize: "14px" }}>
                              <div>Document &nbsp;{index+1} </div>
                              <div
                                style={{ color: "#838080", fontSize: "12px" }}
                              >
                                {/* {item.sub_date} */}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <button
                            className="custom-btn-view "
                            
                              onClick={(e)=>onView(e,index)}
                            >
                              View
                            </button> &nbsp;&nbsp;
                            <button
                               className="btn text-center text-white rounded-pill mt-0 "
                              //  style={{
                              //    backgroundColor: "#1953AA",
                              //    fontSize: "14px",
                              //  }}
                              onClick={(e)=>onDelete(e,index)}
                            > 
                              <img src={deleteicon} height={20} width={20}/>
                            </button>&nbsp;&nbsp;
                            <button
                               className="btn text-center text-white rounded-pill mt-0 "
                              //  style={{
                              //    backgroundColor: "#1953AA",
                              //    fontSize: "14px",
                              //  }}
                              disabled
                            >
                              
                              <img src={downicon} height={20} width={20}/>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
}
              <div class="shadow-box mt-2 pt-0">
                <div className=" border-bottom mb-2 p-2">
                  <center className="d-flex align-items-center justify-content-center">
                    <img
                      src={gallery}
                      width="50"
                      height="25"
                      className="img-fluid"
                    />
                    <span className="mt-1" style={{ fontWeight: "bold" }}>
                      Gallery Image
                    </span>
                  </center>
                </div>
                <div className="custom-file-upload">
               
               <center><label htmlFor="gallerydata">
                 <img src={galleryicon} alt="Upload Icon" width={30} height={30} />
               </label>
               {/* <input
                 type="file"
                 id="gallerydata"
                 name="gallerydata"
                 multiple
                 className="file-input"
                 onChange={(e) => onchangeGallery(e)}
               />
               */}



               <button
                                                     className="btn boxsha2 btn-sm text-white p-2"
                                                     style={{
                                                       fontSize: "15px",
                                                       backgroundColor: "#6777EF",
                                                       borderRadius:"20px"
                                                     }}
                                                     onClick={(e)=>takephoto(e)}
                                                      
                                                   >
                                                     Upload Image
                                                   </button>
                                                  
               </center>
             </div>

             <center><div class="login-error">{locationerror}</div></center>


                {imgSrc.length!=0 && locations.length==imgSrc.length && imgSrc.map((item, index) => {
                
                  return (
                    <>
                      <div className="" key={index}>
                        <div className="row border mt-2 p-2 container mx-auto rounded">
                          <div className="col-6 px-0 d-flex align-items-center ">
                            <img
                              src={galicon}
                              width={40}
                              height={40}
                              className="img-fluid mr-2"
                            />
                            <div className="mb-3" style={{ fontSize: "14px" ,}}>
                              <div> Image {index+1}</div>
                              <div
                                style={{ color: "#838080", fontSize: "12px" }}
                              >
                                {locations.length>0 && 
    (<div>
    <font>Latitude: {locations[index].latitude}</font>
    <br></br>
    <font>Longitude: {locations[index].longitude}</font>
  </div>)

                                // <h1>Hello</h1>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <button
                            className="custom-btn-view "
                            
                              onClick={(e)=>onView(e,index)}
                            >
                              View
                            </button> &nbsp;&nbsp;
                            <button
                               className="btn text-center text-white rounded-pill mt-0 "
                              //  style={{
                              //    backgroundColor: "#1953AA",
                              //    fontSize: "14px",
                              //  }}
                            //  onClick={(e)=>onDelete(e,index)}
                            disabled
                            > 
                              <img src={deleteicon} height={20} width={20}/>
                            </button>&nbsp;&nbsp;
                            <button
                               className="btn text-center text-white rounded-pill mt-0 "
                              //  style={{
                              //    backgroundColor: "#1953AA",
                              //    fontSize: "14px",
                              //  }}
                              disabled
                            >
                              
                              <img src={downicon} height={20} width={20}/>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>


             
              {/* <div class="shadow-box mt-2 pt-0">
                <div className=" border-bottom mb-2 p-2">
                  <center className="d-flex align-items-center justify-content-center">
                    <img
                      src={testing}
                      width="50"
                      height="25"
                      className="img-fluid"
                    />
                    <span className="mt-1" style={{ fontWeight: "bold" }}>
                      AE Section
                    </span>
                  </center>
                </div>
                <div className="">
                  <div className="row border mt-2 p-2 container mx-auto rounded">
                    <div className="col-4 px-0 ">
                      <label style={{fontSize:'14px'}} className="form-label">Status</label>
                      <select
                        onChange={handleStatusChange}
                        value={status}
                        className="form-control"
                        style={{ fontSize: "14px" }}
                      >
                        <option>Accept</option>
                        <option>To Be Viewed</option>
                        <option>Rework</option>
                      </select>
                    </div>
                    <div className="col-8">
                      <label style={{fontSize:'14px'}} className="form-label">Comments</label>
                      <textarea
                        onChange={handleCommentsChange}
                        value={comments}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
{/* 
              <div class="shadow-box pt-0 mt-2  mb-3">
                <div className=" border-bottom mb-2  p-2">
                  <center className="d-flex align-items-center justify-content-center">
                    <img
                      src={testing}
                      width="50"
                      height="25"
                      className="img-fluid"
                    />
                    <span className="mt-1" style={{ fontWeight: "bold" }}>
                      To Be Viewed
                    </span>
                  </center>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <table
                      style={{ color: "black", borderCollapse: "collapse" }}
                    >
                      <tr>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                          }}
                        >
                          Approved Chainage
                        </td>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                          }}
                        >
                          :
                        </td>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          From &nbsp;&nbsp;
                          <span
                            className="p-1 rounded"
                            style={{
                              backgroundColor: "#D8F5FF",
                              color: "#054993",
                            }}
                          >
                            1/000
                          </span>
                          &nbsp;&nbsp; to &nbsp;&nbsp;
                          <span
                            className="p-1 rounded"
                            style={{
                              backgroundColor: "#D8F5FF",
                              color: "#054993",
                            }}
                          >
                            2/054
                          </span>
                        </td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                          }}
                        >
                          Rework Chainage
                        </td>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                          }}
                        >
                          :
                        </td>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          From &nbsp;&nbsp;
                          <span
                            className="p-1 rounded"
                            style={{
                              backgroundColor: "#D8F5FF",
                              color: "#054993",
                            }}
                          >
                            4/361
                          </span>
                          &nbsp;&nbsp; to &nbsp;&nbsp;
                          <span
                            className="p-1 rounded"
                            style={{
                              backgroundColor: "#D8F5FF",
                              color: "#054993",
                            }}
                          >
                            4/552
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div> */}
              <div className="d-flex justify-content-end">
                <button
                  className="btn p-1"
                  style={{ color: "#265DF5", border: "1px solid #265DF5" }}
                 // disabled={!(
                  ////noofdependency<=pdffiledata.length 
                   //&& 
                 ////  imgSrc.length!=0
                 // ) 
                  //</div>||!((!istestingDependency) 
                 //  && imgSrc.length!=0
               // )
             // }
                  onClick={handleGalleryFileChange}
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>}
        </Modal>




        {/* <Modal centered show={galshow} onHide={handleGalClose}>
          <Modal.Body className="bg-white p-2 rounded">
            <div
              className="d-flex justify-content-end align-items-center "
              style={{ marginTop: "-2%", marginRight: "-2%" }}
            >
              <button className="btn  " onClick={handleGalClose}>
                <FaXmark />
              </button>
            </div>
            <form className="mb-2">
               <center><h5>Chainage:1/000-6/000</h5></center> 
               <div class="shadow-box mb-3">

                <div class="row">
                  <div class="col-lg-12 bluestyle"></div>
                  <div class="col-lg-6">
                    <table style={{color:"black",borderCollapse: "collapse"}}>
                      <tr >
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px" }} >Item of work</td>
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px" }}> :</td>
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px" ,color:"black",}}>{itemofwork}</td>
                      </tr>
                      <tr >
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px" }}>Structure Id</td>
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px" }}> :</td>
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px" ,color:"black",}}> {strid}</td>
                      </tr>
                      <tr >
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px" }}>Layer Id</td>
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px" }}> :</td>
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px",color:"black", }}>{layerid}</td>
                      </tr>
                      <tr >
                        <td style={{ padding: "2px", lineHeight: "1", fontSize: "14px" }}>Cad Image</td>
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px" }}> :</td>
                        <td style={{ padding: "4px", lineHeight: "1", fontSize: "14px" }}> <img src={cad} height={20}/></td>
                      </tr>
                    </table>
 



                  </div>
                  
                </div>
                </div>
               <div class="shadow-box">

              
               <div>
            <center> <img src={testing} width="25" height="25"/>&nbsp;&nbsp;&nbsp;Testing Dependency</center>           
                </div>
                <div>   <center>Test Name : Test dependency name</center>    </div>
               <div className="custom-file-upload">
               
  <center><label htmlFor="gallerydata">
    <img src={fileexplorer} alt="Upload Icon" className="upload-icon" />
  </label>
  <input
    type="file"
    id="gallerydata"
    name="gallerydata"
    multiple
    className="file-input"
    onChange={(e) => onchangeGallery(e)}
  />
  <br></br>
  <button
                                        className="btn boxsha2 btn-sm text-white p-2"
                                        style={{
                                          fontSize: "15px",
                                          backgroundColor: "#6777EF",
                                          borderRadius:"20px"
                                        }}
                                       // onClick={handleTestingDependencyFileChange}
                                         
                                      >
                                        Upload
                                      </button>
  </center>
</div>
<div>   <center>Test Name : Test dependency name</center>    </div>
<div class="row">
  <div class="col-lg-8">
  <img src={pdficon} width={40} height={40}/>
  </div>
  <div class="col-lg-4">
  <button
                                        className="btn boxsha2 btn-sm text-white p-2"
                                        style={{
                                          fontSize: "10px",
                                          backgroundColor: "#6777EF",
                                          // borderRadius:"20px"
                                        }}
                                       // onClick={handleTestingDependencyFileChange}
                                         
                                      >
                                        View
                                      </button>
  <img src={pdficon} width={20} height={20}/>
  <img src={pdficon} width={20} height={20}/>
  </div>

</div>
</div>

                <center> <button
                                        className="btn boxsha2 btn-sm text-white p-2"
                                        style={{
                                          fontSize: "15px",
                                          backgroundColor: "#6777EF",
                                        }}
                                        onClick={handleGalleryFileChange}
                                         
                                      >
                                        Save
                                      </button> &nbsp;&nbsp;
                                  
                                      </center>
              </form>
            
         
          
                

           
          </Modal.Body>
        </Modal> */}
      </div>

      {cadShow && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={cadShow}
          animation={true}
          onHide={handleCadClose}
        >
          <Modal.Body>
            <div className="bg-white p-2">
              <div
                className="d-flex justify-content-end align-items-center "
                style={{ marginTop: "-2%", marginRight: "-2%" }}
              >
                <button className="btn  " onClick={handleCadClose}>
                  <FaXmark />
                </button>
              </div>
              <div className="text-center">
                {cadImg.length > 0 && (
                  cadImg.map((imgSrc, index) => (
                    <img
                      key={index}
                      src={imgSrc}
                      alt={`cadimg-${index}`}
                      width={720}
                      height={400}
                    />
                  ))
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

{viewstatus && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={viewstatus}
          animation={true}
          onHide={closeView}
        >
          <Modal.Body>
            <div className="bg-white p-2">
              <div
                className="d-flex justify-content-end align-items-center "
                style={{ marginTop: "-2%", marginRight: "-2%" }}
              >
                <button className="btn  " onClick={closeView}>
                  <FaXmark />
                </button>
              </div>
              <div className="text-center">
                {/* {imgSrc.length > 0 && (
                  imgSrc.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`cadimg-${index}`}
                      width={720}
                      height={400}
                    />
                  ))
                )} */}
                 <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
        {imgSrc.length!=0 && (
          <img 
          
            src={imgSrc[imageviewindex]} 
          
            style={{
              width: '100%',
          
              borderRadius: '8px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
            }}
          />
        )}
      </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

    </>
  );
}

export default UpdatePage;
