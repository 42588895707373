import { Outlet, Link, json } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import '../ProgressSearch/ProgressSearch.css'
import '../RoadPayments/Payments.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
// import DuedateModel from '../Modal/SetDueDate'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
    updateLoginStatus,
    setUserName,
    setUserId,
} from '../../store/userDetails'

import completed from '../../assets/images/comp.png'
import inprogress from '../../assets/images/buttons/inprogress.png'
import received from '../../assets/images/buttons/received.png'
import delayed from '../../assets/images/buttons/delayed.png'
import cancelled from '../../assets/images/buttons/cancelled.png'
import { FaPlus, FaEdit } from "react-icons/fa";
import { Dropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
function FormPage() {
    function Loginspace() {
        // var username = document.getElementById('firstname')
    }
    const userData = useSelector((state) => state.userDetails)
    const [level, setlevel] = useState('')
    const [level1, setlevel1] = useState('')
    const [levelValid, setlevelValid] = useState(false)
    const [levelerror, setlevelerror] = useState('')
    const [itemofwork, setItemofwork] = useState('')
    const [itemofworkValid, setItemofworkValid] = useState(false)
    const [itemofworkerror, setItemofworkerror] = useState('')
    // const [structuretype, setStructuretype] = useState([]);

    const [structuretypeValid, setStructuretypeValid] = useState(false)
    const [structuretypeerror, setStructuretypekerror] = useState('')
    const [date, setDate] = useState('')
    const [dateValid, setDateValid] = useState(false)
    const [dateerror, setDateerror] = useState('')
    const [owner, setOwner] = useState('')
    const [ownerValid, setOwnerValid] = useState(false)
    const [ownererror, setOwnererror] = useState('')
    const [requeststatus, setRequeststatus] = useState(false);
    const [successstatus, setSuccessstatus] = useState(false)
    const [reslist1, setReslist1] = useState([])
    const [reslist2, setReslist2] = useState([])
    const [itemofworklist, setItemofworklist] = useState([])
    const [structurelist, setStructurelist] = useState([])
    const [checkboxval, setCheckboxval] = useState(false);
    const [checklist, setChecklist] = useState([])
    const [checkduedate, setCheckduedate] = useState(false);
    const [checkboxErrors, setCheckboxErrors] = useState(Array(reslist1.length).fill(''));
    const [strucliststatus, setStrucliststatus] = useState(false);
    const [projectstatus, setProjectstatus] = useState('')
    const [projectstatusValid, setProjectstatusValid] = useState(false)
    const [projectstatuserror, setProjectstatuserror] = useState('')

    const [contractorType, setContractorType] = useState('')
    const [contractorTypeValid, setContractorTypeValid] = useState(false)
    const [contractorTypeerror, setContractorTypeerror] = useState('')

    const [companyname, setCompanyname] = useState('')
    const [companynameValid, setCompanynameValid] = useState(false)
    const [companynameerror, setCompanynameerror] = useState('')

    const [chainagelist, setChainagelist] = useState([])
    const [companynamelist, setCompanynamelist] = useState('')
    const [validationerrormsg, setValidationerrormsg] = useState('')

    const location = useLocation()
    const projectid = location.state?.projectId
    const projecttype = location.state?.projectType

    useEffect(() => {

        //Get  Chainage list 
        axios({
            method: 'GET',

            url: baseurl + '/api/plan/specifications/get/due_date?project_id=' + projectid,

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + userData.token
            }
        })
            .then((response) => {
                //console.log(response.data);
                const dat = response.data || []
                setChainagelist(dat);
            })
            .catch((error) => {
                //console.log(error);
            });




        // 


    }, [])
    // Multiple fields set
    const [fields, setFields] = useState([
        {
            // projectname: '',
            level: '',
            structuretype: [],
            amount: '',
        },
    ]);


    const [fieldValidations, setFieldValidations] = useState([
        {

            levelValid: false,
            levelError: '',
            structuretypeValid: false,
            structuretypeError: '',
            amountValid: false,
            amountError: '',
        },
    ]);

    const handleAddFields = () => {

        setFields([...fields, { level: '', structuretype: [], amount: '', }]);

        setFieldValidations([...fieldValidations, {
            levelValid: false,
            levelError: '',
            structuretypeValid: false,
            structuretypeError: '',
            amountValid: false,
            amountError: '',
        }]);
    };


    const handleChangeField = (index, fieldName, value) => {
        // //////alert("calling"+value+"----"+fieldName)
        const newFields = [...fields];

        //////alert("***"+newFields[index][fieldName])
        if (fieldName === "structuretype") {

            const strucarray = newFields[index][fieldName]; // Use bracket notation

            if (!Array.isArray(strucarray)) {
                strucarray = []; // Initialize as an empty array if it's not already an array
            }

            if (strucarray.includes(value)) {
                const filteredArray = strucarray.filter((item) => item !== value);
                newFields[index][fieldName] = filteredArray;
                setFields(newFields);

            }
            else {
                strucarray.push(value);

                newFields[index][fieldName] = strucarray;
                setFields(newFields);
            }
        }
        if (fieldName === "level") {

            //////alert("level")
            setStrucliststatus([])

            newFields[index][fieldName] = value;
            setFields(newFields);


            let chainage = value

            const firstHyphenIndex = chainage.indexOf('-');

            // Extract the text after the first hyphen
            const extractedText = firstHyphenIndex !== -1 ? chainage.slice(firstHyphenIndex + 1).trim() : null;

            axios({
                method: 'GET',

                url: baseurl + '/api/plan/list/structure_type?project_id='+projectid+'&level=' + extractedText,

                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + userData.token
                }
            })

                .then((response) => {

                    //console.log(response.data)
                    const dat = response.data
                    const struc = dat.structure_types

                    //console.log(struc)
                    setStructurelist(struc)
                    ////////////alert("level"+struc)
                })
                .catch((error) => {
                    //console.log(error)
                    //setErrormsg(error.response.data.message)
                })


        }

        if (fieldName === "amount") {
            newFields[index][fieldName] = value;
            setFields(newFields);
        }



        const newValidations = [...fieldValidations];
        const validation = newValidations[index];



        if (fieldName === 'level') {
            if (value.trim() === '') {
                validation.levelValid = false;
                validation.levelError = 'Please select ';
            } else {
                validation.levelValid = true;
                validation.levelError = '';
            }
        }

        if (fieldName === 'structuretype') {
            if (value.length == 0) {
                validation.structuretypeValid = false;
                validation.structuretypeError = 'Please select';
            } else {
                validation.structuretypeValid = true;
                validation.structuretypeError = '';
            }
        }

        if (fieldName === 'amount') {
            if (value.trim() === '') {
                validation.amountValid = false;
                validation.amountError = 'Please enter the amount';
            } else {
                validation.amountValid = true;
                validation.amountError = '';
            }
        }

        newValidations[index] = validation;
        setFieldValidations(newValidations);
    };

    /***************************************/
    useEffect(() => {

        // if (level == '' && level1 == '') {

        //     axios({
        //         method: 'GET',

        //         url: baseurl + '/api/plan/list/structure_type?project_id=' + userData.currentprojectId,

        //         headers: {
        //             'Content-Type': 'multipart/form-data',
        //             Authorization: 'Bearer ' + userData.token
        //         }

        //     })
        //         .then((response) => {

        //             //  //console.log(response.data)
        //             const dat = response.data

        //             const struc = dat.structure_types
        //             // const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));


        //             setStructurelist(struc)
        //         })
        //         .catch((error) => {
        //             //console.log(error)
        //             //setErrormsg(error.response.data.message)
        //         })
        // }

    }, [successstatus, strucliststatus])
    const resultList = [];


    // const structureonClick = (option) => {
    //     if (structuretype.includes(option)) {
    //         setStructuretype(
    //             structuretype.filter((item) =>
    //                 item !== option));
    //     } else {
    //         setStructuretype(
    //             [...structuretype, option]);
    //     }
    // };




    function handlelevel(e) {
        //console.log(e.target.value)
        setlevel(e.target.value)
        // setStructuretype('')


    }



    function handleProjectstatus(e) {
        //console.log(e.target.value)
        setProjectstatus(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
    }

    const validateField = (fieldName, value) => {

        if (fieldName === 'contractorType') {

            if (value != null) {
                setContractorTypeerror('')
                setContractorTypeValid(true)
            } else {
                setContractorTypeerror('Please select')
                setContractorTypeValid(false)
            }
        }
        if (fieldName === 'companyname') {

            if (value != null) {
                setCompanynameerror('')
                setCompanynameValid(true)
            } else {
                setCompanynameerror('Please select')
                setCompanynameValid(false)
            }
        }

    }
    function oncontractorType(e) {

        setContractorType(e.target.value.replace(/\s+/g, ''))

        // Gets Comapany Name list
        axios({
            method: 'GET',

            url: baseurl + '/api/vendor/list/get/company_names?project_id=' + projectid + '&contractor_type=' + e.target.value,

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + userData.token
            }
        })
            .then((response) => {
                //console.log(response.data);
                const dat = response.data
             
                const comapanynames = dat.companies_list || []
                setCompanynamelist(comapanynames);
            })
            .catch((error) => {
                //console.log(error);
            });
            validateField(e.target.name, e.target.value)
    }

    function onCompanyname(e) {

        setCompanyname(e.target.value)
        validateField(e.target.name, e.target.value)

    }
    function onSubmitEvent(event) {
        event.preventDefault()


        const allFieldsValid = fields.every((field, index) => {

            const validation = fieldValidations[index];

            return (
                field.level.trim() !== '' &&
                field.structuretype.length != 0 &&
                field.amount !== '' &&
                validation.levelValid &&
                validation.structuretypeValid &&
                validation.amountValid
            );

        });

       
        const company=companyname.replace(/_/g, ' ')
        const apiUrl = `${baseurl}/api/finance/details/add`;

        // axios
        //     .post(apiUrl, fields, {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Bearer' + ' ' + userData.token
        //         },
        //     })
        if (contractorTypeValid && companynameValid && allFieldsValid) {

            setValidationerrormsg('')
        axios
            .post(apiUrl, {
                fields, // Include existing fields
                contractor_type: contractorType,
                company_name: company,
                project_id: projectid
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userData.token // Note the space after 'Bearer'
                },
            })
            .then((response) => {

                const dat = response.data
                //console.log(response.data)
                setSuccessstatus(true)
                // ////alert("updated successsfully")
                setFields([
                    {
                       
                        level: '',
                        structuretype: [],
                        amount: '',
                    },
                ]);
            
            
                setFieldValidations([
                    {
            
                        levelValid: false,
                        levelError: '',
                        structuretypeValid: false,
                        structuretypeError: '',
                        amountValid: false,
                        amountError: '',
                    },
                ]);
                 setContractorType('')
                 setCompanyname('')

            })
            .catch((error) => {
                //console.log(error)
                //setErrormsg(error.response.data.message)
            })
        }
        else {

            setValidationerrormsg("Please fill all the required fields")

        }

    }

    return (
        <>

            {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
            {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}
            <div className="cont-margin1">
                <div class="cont-margin-latest pt-1 ">
                    <div className='table1bdstyle'>
                        <div className='row table1st'>
                            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
                                <div class="row pageleftandrightmargin">
                                    {/*  */}
                                    <div class="col-xl-12 col-lg-12 col-md-12 pt-5">
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6  pb-3 ">
                                                <label for="formFileLg" className="login-text">
                                                    Contractor Type
                                                </label>
                                                <center>
                                                    <select
                                                        className="project-box"
                                                        id="contractorType"
                                                        name="contractorType"
                                                        defaultValue={contractorType}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        value={contractorType}
                                                        onChange={oncontractorType}
                                                    >
                                                        <option value="Select" selected>
                                                            Select
                                                        </option>
                                                        <option value="Contractor">Contractor</option>
                                                        <option value="Sub_Contractor">Sub Contractor</option>

                                                    </select>
                                                    <div className="login-error">{contractorTypeerror}</div>
                                                </center>
                                            </div>
                                            {/* 
               */}
                                            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6  pb-3 ">
                                                <label for="formFileLg" className="login-text">
                                                    Company Name
                                                </label>
                                                <center>
                                                    <select
                                                        className="project-box"
                                                        id="companyname"
                                                        name="companyname"
                                                        defaultValue={companyname}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        value={companyname}
                                                        onChange={onCompanyname}
                                                    >
                                                        <option value="Select" selected>
                                                            Select
                                                        </option>
                                                        {/* <option value="AIS">AIS</option>
                                                        <option value="Athees">Athees</option> */}
                                                        {companynamelist.length !== 0 && companynamelist.map((cname, index) => (
                                                            <option key={index} value={cname.replace(/ /g, '_')}>
                                                                {cname}
                                                            </option>
                                                        ))}

                                                    </select>
                                                    <div className="login-error">{companynameerror}</div>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div class="col-xl-10 col-lg-10 col-md-10 "></div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 pt-2 pb-3 labels ff">

                                        <button onClick={handleAddFields}
                                            type="button"
                                            className="create-btn"
                                        ><FaPlus /> &nbsp;Add</button>
                                    </div>
                                </div>

                                {/*  */}
                                <form
                                    class="row d-flex justify-content-center align-center    "

                                >
                                    {/*  */}

                                    {fields.map((field, index) => (
                                        <div key={index} class="col-12 col-lg-12 col-xl-12 " >

                                            <div class="row pageleftandrightmargin">
                                                <div class="col-12 col-sm-12  col-md-4 col-lg-4 ol-md-4 pb-3">
                                                    <label
                                                        for="formFileLg"
                                                        class="form-label"
                                                        className="login-text"
                                                    >
                                                        {projecttype === "Road" ? 'Chainage' : 'Level'}
                                                    </label>
                                                    <select
                                                        className="project-box"
                                                        id={`level-${index}`}
                                                        name={`level-${index}`}
                                                        defaultValue={level}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        value={field.level}
                                                        onChange={(e) => handleChangeField(index, 'level', e.target.value)}
                                                    >
                                                        <option value="" selected>
                                                            Select
                                                        </option>

                                                        {chainagelist.length != 0 && chainagelist.map((item, index) => {
                                                            const parts = item.floor.split('-');
                                                            const result = parts.slice(1).join('-').trim();
                                                            const chainage = result.replace(/\./g, '/');
                                                            return (
                                                                <option key={index} value={item.floor}>
                                                                    {chainage}
                                                                </option>)
                                                        })}

                                                    </select>
                                                    <div className="login-error">{fieldValidations[index].levelError}</div>
                                                </div>


                                                <div class="col-12 col-sm-12  col-md-4 col-lg-4 ol-md-4 pb-3">
                                                    <label
                                                        for="formFileLg"
                                                        class="form-label"
                                                        className="login-text"
                                                    >
                                                        Structures
                                                    </label>
                                                    <Dropdown drop="up">
                                                        <Dropdown.Toggle variant={false}
                                                            className="project-box"
                                                            id={`structuretype-${index}`}
                                                            name={`structuretype-${index}`}
                                                            style={{ backgroundColor: '#f1f5fb', borderColor: '#f1f5fb' }}>
                                                            Select
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                            {structurelist.map((option, index1) => (
                                                                <Dropdown.Item
                                                                    key={index1}

                                                                    onClick={() => handleChangeField(index, 'structuretype', option)}
                                                                    active={field.structuretype && field.structuretype.includes(option)}
                                                                >
                                                                    {option}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                </div>
                                                <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4 pb-3">
                                                    <label
                                                        for="formFileLg"
                                                        class="form-label"
                                                        className="login-text" >
                                                        Amount
                                                    </label>
                                                    <center>
                                                        <InputTextField
                                                            handleInputChange={(e) => handleChangeField(index, 'amount', e.target.value)}
                                                            valueText={field.amount}
                                                            inputName={`amount-${index}`}
                                                            inputId={`amount-${index}`}
                                                            classname="project-box"
                                                            inputType="text"
                                                            placeholderValue="Amount"
                                                            PreventEnter={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                        ></InputTextField></center>

                                                    <div className="login-error">{fieldValidations[index].amountError}</div>
                                                </div>




                                            </div>
                                        </div>
                                    ))}
                                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-5 ">
                                        <center>
                                        {validationerrormsg!='' &&
                            <div className='login-error pb-3' >
                                {validationerrormsg}
                                </div>}
                                            <button
                                                type="submit"
                                                className="project-btn"
                                                name="submit"
                                                onClick={(e) => onSubmitEvent(e)}

                                            >
                                                <b>Submit</b>
                                            </button>
                                        </center>{' '}
                                    </div>

                                    {/*  */}

                                </form>
                                <div >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormPage
