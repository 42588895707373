import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal, Form } from 'react-bootstrap'
import './Modal.css'
import CreateRequest from '../CreateRequest/CreateRequest'
import { useState } from 'react'
import InputTextField from '../InputText/InputText'
import { FaYenSign } from 'react-icons/fa'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
export default function Model({ btnClick, status, contractorid }) {
    const [projectstatus, setProjectstatus] = useState('')
    const [projectstatusValid, setProjectstatusValid] = useState(false)
    const [projectstatuserror, setProjectstatuserror] = useState('')
    const [projecttype, setProjecttype] = useState('')

    const [level1, setLevel1] = useState('')
    const [level, setLevel] = useState('')
    const [structure, setStructure] = useState('')
    const [structure1, setStructure1] = useState('')
    const [submitstatus, setSubmitstatus] = useState(false)

    const [contactperson1, setContactperson1] = useState('')
    const [contactpersonstatus, setContactpersonstatus] = useState('')
    const [contactpersonstatusValid, setContactpersonstatusValid] = useState(false)
    const [contactpersonstatuserror, setContactpersonstatuserror] = useState('')

    const [newcontactpersonstatus, setNewcontactpersonstatus] = useState('')
    const [newcontactpersonstatusValid, setNewcontactpersonstatusValid] = useState(false)
    const [newcontactpersonstatuserror, setNewcontactpersonstatuserror] = useState('')

    const [newcontactnostatus, setNewcontactnostatus] = useState('')
    const [newcontactnostatusValid, setNewcontactnostatusValid] = useState(false)
    const [newcontactnostatuserror, setNewcontactnostatuserror] = useState('')

    const [newcontactmailstatus, setNewcontactmailstatus] = useState('')
    const [newcontactmailstatusValid, setNewcontactmailstatusValid] = useState(false)
    const [newcontactmailtatuserror, setNewcontactmailstatuserror] = useState('')

    const [paymentdate, setPaymentdate] = useState('')
    const [paymentdateValid, setPaymentdateValid] = useState(false)
    const [paymentdateeerror, setPaymentdateerror] = useState('')
    const [fileattach, setFileattach] = useState('')

    const [work, setWork] = useState('')
    const [workValid, setWorkValid] = useState(false)
    const [workerror, setWorkerror] = useState('')

    const [modalVisible, setModalVisible] = useState(status);

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionValid, setSelectedOptionValid] = useState(false);
    const [selectedOptionerror, setSelectedOptionerror] = useState('');

    const [projectnamelist, setProjectnamelist] = useState('');
    const [itemofworklist, setItemofworklist] = useState('')

    const [contactno, setContactno] = useState('')
    const [contactnoValid, setContactnoValid] = useState(false)
    const [contactnoerror, setContactnoerror] = useState('')

    const [contactmail, setContactmail] = useState('')
    const [contactmailValid, setContactmailValid] = useState(false)
    const [contactmailerror, setContactmailerror] = useState('')

    const [contactperson, setContactperson] = useState('')
    const [contactpersonValid, setContactpersonValid] = useState(false)
    const [contactpersonerror, setContactpersonerror] = useState('')
    const [resourcetypelist, setResourcetypelist] = useState([]);

    const [resourcetype, setResourcetype] = useState('')
    const [resourcetypeValid, setResourcetypeValid] = useState(false)
    const [resourcetypeeerror, setResourcetypeerror] = useState('')

    const userData = useSelector((state) => state.userDetails)

    useEffect(() => {


        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        //console.log("id2", contractorid)
        ////////alert("status"+status)
        ////////alert("Model calling "+modalVisible)
        axios({
            method: 'GET',

            url: baseurl + '/api/vendor/list/get/all_project_name',

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + userData.token
            }
        })

            .then((response) => {
                const datas = response.data;
                //console.log("dataaas", response.data)
                setProjectnamelist(datas)

                // const email1 = datas.email
                // setEmail(email1)
                // //console.log(email1); 
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
        ///////////////////structure type

        axios
            .get(`${baseurl}/api/project/list/resources&equipments`, {
                params: {
                    project_id: userData.projectId,
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userData.token}`,
                },
            })
            .then((response) => {
                const data = response.data;
                const mt = data.materials;
                setResourcetypelist(mt);
                //console.log('Data received:', response.data); // Log the received data
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

    }, [status, modalVisible])

    function handleProjectstatus(e) {

        setLevel("");
        setStructure("");
        setWork("");
        const pname = e.target.value
        setProjectstatus(e.target.value.replace(/\s+/g, ''))

        ///////////////////label

        axios({
            method: 'GET',

            url: baseurl + '/api/vendor/get/project_type?project_name=' + pname,

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + userData.token,
            }
        })

            .then((response) => {

                setProjecttype(response.data)

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        //  ////////alert(pname)

        axios({
            method: 'GET',

            url: baseurl + '/api/plan/list/item_of_work?project_name=' + pname,

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + userData.token,
            }
        })

            .then((response) => {
                const datas = response.data;
                //console.log("dataaas", response.data)
                const data = datas.item_of_work
                // ////////alert(JSON.stringify(data))
                setItemofworklist(data)

                // const email1 = datas.email
                // setEmail(email1)
                // //console.log(email1); 
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        ///////////////////////////get level

        axios({
            method: 'GET',

            url: baseurl + '/api/plan/list/level?project_name=' + pname,

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + userData.token,
            }
        })

            .then((response) => {
                const level23 = response.data;

                //console.log("dat2", level23.level)
                setLevel1(level23.level)

                // setLevel1(datas12.structure_types)


            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        /////////////////////////////////get structure
        axios({
            method: 'GET',

            url: baseurl + '/api/plan/list/structure_type?level=' + level + '&project_name=' + pname,

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + userData.token,
            }
        })

            .then((response) => {
                const datas12 = response.data;
                //console.log("dat1", datas12.structure_types)

                setStructure1(datas12.structure_types)

                // const email1 = datas.email
                // setEmail(email1)
                // //console.log(email1); 
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        validateField(e.target.name, e.target.value)

    }

    function handlework(e) {

        // setWork(e.target.value.replace(/\s+/g, ''))
        setWork(e.target.value)

        validateField(e.target.name, e.target.value)
    }

    function handlelevel(e) {
        setStructure("");
        setWork("");

        // setWork(e.target.value.replace(/\s+/g, ''))
        //console.log("selected level11" + e.target.value)
        const selectedlevel = e.target.value
        setLevel(e.target.value)

        validateField(e.target.name, e.target.value)
        //console.log("selected level" + level)
        //////////////////////////structure

        axios({
            method: 'GET',

            url: baseurl + '/api/plan/list/structure_type?level=' + selectedlevel + '&project_name=' + projectstatus,

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + userData.token,
            }
        })

            .then((response) => {
                const datas12 = response.data;
                //console.log("dat145", datas12.structure_types)

                setStructure1(datas12.structure_types)

                // const email1 = datas.email
                // setEmail(email1)
                // //console.log(email1); 
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
        ///////////////////////////////////item of work

        axios({
            method: 'GET',

            url: baseurl + '/api/plan/list/item_of_work?level=' + selectedlevel + '&project_name=' + projectstatus,

            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + userData.token,
            }
        })

            .then((response) => {
                const datas = response.data;
                //console.log("item of work on level", response.data)
                const data = datas.item_of_work
                // ////////alert(JSON.stringify(data))
                setItemofworklist(data)

                // const email1 = datas.email
                // setEmail(email1)
                // //console.log(email1); 
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });



    }

    function handlestructure(e) {

        setWork("");
        const selectedstructure = e.target.value
        //console.log("selected structure" + selectedstructure)
        setStructure(e.target.value)

        validateField(e.target.name, e.target.value)

        if (projectstatus != null && level != null) {
            axios({
                method: 'GET',

                url: baseurl + '/api/plan/list/item_of_work?level=' + level + '&project_name=' + projectstatus + '&structure_type=' + selectedstructure,

                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + userData.token,
                }
            })

                .then((response) => {
                    const datas = response.data;
                    //console.log("item of work on level", response.data)
                    const data = datas.item_of_work
                    // ////////alert(JSON.stringify(data))
                    setItemofworklist(data)

                    // const email1 = datas.email
                    // setEmail(email1)
                    // //console.log(email1); 
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }


    }

    function handleResourceType(e) {

        setResourcetype(e.target.value)
        validateField(e.target.name, e.target.value)
        // setSuccessstatus(false)
        // setErrormsg('')
    }
    function handleNewcontactperson(e) {

        // setWork(e.target.value.replace(/\s+/g, ''))
        setNewcontactpersonstatus(e.target.value)

        validateField(e.target.name, e.target.value)
    }

    function handleNewcontactno(e) {

        // setWork(e.target.value.replace(/\s+/g, ''))
        setNewcontactnostatus(e.target.value)

        validateField(e.target.name, e.target.value)
    }

    function handleNewcontactmail(e) {

        // setWork(e.target.value.replace(/\s+/g, ''))
        setNewcontactmailstatus(e.target.value)

        validateField(e.target.name, e.target.value)
    }

    function handlepaymentdate(e) {

        setPaymentdate(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)

    }



    const validateField = (fieldName, value) => {

        if (fieldName === 'contactpersonstatus') {


            if (value.length != 0) {
                setContactpersonstatuserror('')
                setContactpersonstatusValid(true)
                //////alert("ontactpersonstatusValid true running")
            } else {
                setContactpersonstatuserror('Please select Contact Person name')
                setContactpersonstatusValid(false)
            }

        }

        if (fieldName === 'projectstatus') {
            //console.log(value)

            if (value.length != 0) {
                setProjectstatuserror('')
                setProjectstatusValid(true)
                //////alert("projectstatus true running")
            } else {
                setProjectstatuserror('Please select Contact Person name')
                setProjectstatusValid(false)
            }
        }

        if (fieldName === 'selectedOption') {
            //console.log(value)

            if (value.length != 0) {
                setContactpersonstatuserror('')
                setContactpersonstatusValid(true)
                //////alert("selectedOption true running")

            } else {
                setContactpersonstatuserror('Please select Yes or no')
                setContactpersonstatusValid(false)
            }
        }

        if (fieldName === 'paymentdate') {
            //console.log(value)

            if (value.length != 0) {
                setPaymentdateerror('')
                setPaymentdateValid(true)
                //////alert("paymentdate true running")
            } else {
                setPaymentdateerror('Please select Company name')
                setPaymentdateValid(false)
            }
        }


        if (fieldName === 'newcontactpersonstatus') {
            //console.log(value)

            if (value.length != 0) {
                setNewcontactpersonstatuserror('')
                setNewcontactpersonstatusValid(true)

            } else {
                setNewcontactpersonstatuserror('Please select Company name')
                setNewcontactpersonstatusValid(false)
            }
        }
        if (fieldName === 'newcontactnostatus') {
            //console.log(value)

            if (value.length != 0) {
                setNewcontactnostatuserror('')
                setNewcontactnostatusValid(true)
            } else {
                setNewcontactnostatuserror('Please select Company name')
                setNewcontactnostatusValid(false)
            }
        }
        if (fieldName === 'newcontactmailstatus') {
            //console.log(value)

            if (value.length != 0) {
                setNewcontactmailstatuserror('')
                setNewcontactmailstatusValid(true)
            } else {
                setNewcontactmailstatuserror('Please select Company name')
                setNewcontactmailstatusValid(false)
            }
        }
    }


    function onSubmitEvent(event) {
        event.preventDefault()

        //////alert("calling")
        //console.log(projectstatusValid)
        //console.log(paymentdateValid)
        //console.log(selectedOptionValid)
        //console.log(contactpersonstatusValid)
        if (selectedOption === 'no') {
            axios({
                method: 'POST',

                url: baseurl + '/api/vendor/contractor/assign_new_project?contractor_id=' + contractorid + '&project_name=' + projectstatus +
                     '&starting_date=' + paymentdate +  '&contact_person=' + newcontactpersonstatus +
                    '&contact_no=' + newcontactnostatus + '&contact_mail=' + newcontactmailstatus   + '&use_existing_details=0'+
                    '&type='+'Vendor'+'&resource_type='+resourcetype+'&client_id='+userData.clientId,

                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + userData.token,
                }
            })

                .then((response) => {

                    //console.log("created")
                    //console.log("create work", response.data)


                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });

        }
        if (selectedOption === 'yes') {
            axios({
                method: 'POST',

                url: baseurl + '/api/vendor/contractor/assign_new_project?contractor_id=' + contractorid + '&project_name=' + projectstatus +
                    '&starting_date=' + paymentdate +  '&contact_person=' + contactpersonstatus +
                    '&use_existing_details=1'+ '&type='+'Vendor'+'&resource_type='+resourcetype+'&client_id='+userData.clientId,

                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + userData.token,
                }
            })

                .then((response) => {

                    //console.log("created")
                    //console.log("create work", response.data)


                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });

        }
        setSubmitstatus(true)
        setModalVisible(false)
    }

    const handleOptionChange = (event) => {
        const soption = event.target.value

        setSelectedOption(soption);
        //console.log("contactID", contractorid)
        if (soption === 'yes') {
            axios({
                method: 'GET',

                url: baseurl + '/api/vendor/get/contractor/contact_persons?contractor_id=' + contractorid,

                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + userData.token,
                }
            })

                .then((response) => {
                    //console.log("contact")
                    //console.log("contactperson", response.data)
                    const person = response.data;
                    //console.log(person)
                    setContactperson1(person)

                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });


        }

        //  validateField(e.target.name, e.target.value)
    };
    function handleContactpersonstatus(e) {
        const personname = e.target.value
        setContactpersonstatus(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
    }

    return (
        <>
            <Modal show={modalVisible} onHide={btnClick} id="modal-size1">
                <Modal.Body>
                    <center>  <div className="model-top pt-3"><b>Assign Project</b></div></center>
                    {/* <CreateRequest /> */}


                    <div className="cont-modalstyle">

                        <div class="row pb-2">


                            <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                                <form
                                    class="row d-flex justify-content-center align-center  blacktext"
                                    onSubmit={(event) => {
                                        onSubmitEvent(event);
                                        btnClick(); // Close the modal
                                    }}
                                >

                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                                            <label for="formFileLg" className="login-text">
                                                Starting date
                                            </label>
                                            <center>
                                                <InputTextField
                                                    handleInputChange={handlepaymentdate}
                                                    valueText={paymentdate}
                                                    inputName="paymentdate"
                                                    inputId="paymentdate"
                                                    //   classname="formboxnew"
                                                    classname="project-box"
                                                    maxLen={20}
                                                    inputType="date"
                                                    placeholderValue="payment Date"
                                                ></InputTextField>

                                                <div className="login-error">{paymentdateeerror}</div>
                                            </center>
                                        </div>

                                        <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                                            <label
                                                for="formFileLg"
                                                class="form-label"
                                                className="login-text"
                                            >
                                                <b>Project List</b>
                                            </label>
                                            <select
                                                className="project-box"
                                                id="projectstatus"
                                                name="projectstatus"
                                                //  defaultValue={companyname}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' && e.preventDefault()
                                                }}
                                                value={projectstatus}
                                                onChange={handleProjectstatus}
                                            >
                                                <option value="Select" selected>
                                                    Select
                                                </option>
                                                {/* <option value="Tallakulam">Tallakulam</option>
                    <option value="Irukankudi">Irukankudi</option>
                    <option value="Sattur">Sattur</option> */}
                                                {projectnamelist.length !== 0 && projectnamelist.map((item, index) => (
                                                    <option key={index} value={item.project_name}>
                                                        {item.project_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="login-error">{projectstatuserror}</div>
                                        </div>

                                        <div class="col-12 col-sm-12  col-md-6 col-lg-6 col-md-6 pb-3">
                                            <label
                                                for="formFileLg"
                                                class="form-label"
                                                className="formfontst ast= login-text"
                                            >
                                                Resource Type
                                            </label>
                                            <select
                                                className="project-box"
                                                id="resourcetype"
                                                name="resourcetype"
                                                //  defaultValue={companyname}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' && e.preventDefault()
                                                }}
                                                value={resourcetype}
                                                onChange={handleResourceType}
                                            >
                                                <option value="Select" selected>
                                                    Select
                                                </option>
                                                {resourcetypelist.length !== 0 && resourcetypelist.map((name, index) => (
                                                    <option key={index} value={name}>
                                                        {name.replace(/_/g, ' ')}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="login-error">{resourcetypeeerror}</div>
                                        </div>


                                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3">
                                            <label for="formFileLg" className="login-text">
                                                Already Existing Contact Person?
                                            </label>
                                            <div class='row pl-3'>
                                                <div class='col-lg-6 col-xl-6 col-xxl-6'>

                                                    <input
                                                        type="radio"
                                                        value="yes"
                                                        checked={selectedOption === "yes"}
                                                        onChange={handleOptionChange}
                                                        name="selectedOption"
                                                        id="selectedOption"

                                                    />
                                                    Yes


                                                </div>
                                                <div class='col-lg-6 col-xl-6 col-xxl-6'>

                                                    <input
                                                        type="radio"
                                                        value="no"
                                                        checked={selectedOption === 'no'}
                                                        onChange={handleOptionChange}
                                                        name="selectedOption"
                                                        id="selectedOption"
                                                    />
                                                    No

                                                </div>
                                            </div>
                                        </div>
                                        {selectedOption === "yes" &&

                                            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-md-12 pb-3">
                                                <label
                                                    for="formFileLg"
                                                    class="form-label"
                                                    className="login-text"
                                                >
                                                    Contact Person
                                                </label>
                                                <select
                                                    className="project-box"
                                                    id="contactpersonstatus"
                                                    name="contactpersonstatus"
                                                    //  defaultValue={companyname}
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    value={contactpersonstatus}
                                                    onChange={handleContactpersonstatus}
                                                >
                                                    <option value="Select" selected>
                                                        Select
                                                    </option>
                                                    {contactperson1.length !== 0 && contactperson1.map((item4, index) => (
                                                        <option key={index} value={item4.contact_person}>
                                                            {item4.contact_person}
                                                        </option>
                                                    ))}

                                                </select>
                                                <div className="login-error">{contactpersonstatuserror}</div>
                                            </div>

                                        }

                                        {selectedOption === 'no' &&
                                            <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3">
                                                        <label for="formFileLg" className="login-text">
                                                            Contact Person Name
                                                        </label>
                                                        <center>
                                                            <InputTextField
                                                                handleInputChange={handleNewcontactperson}
                                                                valueText={newcontactpersonstatus}
                                                                inputName="newcontactpersonstatus"
                                                                inputId="newcontactpersonstatus"
                                                                classname="project-box"
                                                                //  maxLen={20}
                                                                inputType="text"



                                                            ></InputTextField>


                                                        </center>
                                                        <div className="login-error">{newcontactpersonstatuserror}</div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3">
                                                        <label for="formFileLg" className="login-text">
                                                            Contact Number
                                                        </label>
                                                        <center>
                                                            <InputTextField
                                                                handleInputChange={handleNewcontactno}
                                                                valueText={newcontactnostatus}
                                                                inputName="newcontactnostatus"
                                                                inputId="newcontactnostatus"
                                                                classname="project-box"
                                                                 maxLen={10}
                                                                inputType="number"



                                                            ></InputTextField>


                                                        </center>
                                                        <div className="login-error">{newcontactnostatuserror}</div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3">
                                                        <label for="formFileLg" className="login-text">
                                                            Mail Id
                                                        </label>
                                                        <center>
                                                            <InputTextField
                                                                handleInputChange={handleNewcontactmail}
                                                                valueText={newcontactmailstatus}
                                                                inputName="newcontactmailstatus"
                                                                inputId="newcontactmailstatus"
                                                                classname="project-box"
                                                                //  maxLen={20}
                                                                inputType="text"



                                                            ></InputTextField>


                                                        </center>
                                                        <div className="login-error">{newcontactmailtatuserror}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {submitstatus && <div className='greencolor'><center>Work Created</center></div>}

                                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                                        <center>
                                            <button
                                                type="submit"
                                                className="project-btn"
                                                name="submit"

                                                disabled={selectedOption === 'yes' ? ((paymentdateValid && projectstatusValid)
                                                    ? false : true)
                                                    : ((paymentdateValid && projectstatus && newcontactmailstatusValid && newcontactnostatusValid && newcontactpersonstatusValid)
                                                        ? false : true)
                                                }
                                            >
                                                <b>Submit</b>
                                            </button>
                                        </center>{' '}
                                    </div>


                                </form>
                            </div>

                        </div>
                    </div>



                </Modal.Body>
            </Modal>
        </>
    )
}
