import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
 import Workmastericon from '../../assets/brickdemoicons/Workmastericon (1).png'
 import workmasterImage from '../../assets/brickdemoicons/workmaster (1).png'
import './Workmaster.css'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import DocumentView from "../../Component/Modal/DocumentView";
import { connect, useDispatch, useSelector } from "react-redux";
import img from "../../assets/brickdemoicons/pdficon.png";

function FormPage() {
  const userData = useSelector((state) => state.userDetails);
  const [workmasterdetails,setWorkmasterdetails]=useState([])
  const [attach,setAttach]=useState('')
  const [attachstatus,setAttachstatus]=useState(false)
  const [modalStatus, setModalStatus] = useState(false);
  const tocloseModal = () => {
    setModalStatus(false);
  };
  function documentClick() {
    setModalStatus(true);
  }


  useEffect(()=>
  {
  
    axios
      .get(baseurl + '/api/work_master/list?project_id='+userData.projectId, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      })

      .then((response) => {
     ////console.log(response.data)

     const data=response.data
 const details=data.work_details_list||[]

 setWorkmasterdetails(details[0])

       
      })
      .catch((error) => {
        //console.log(error)
        ////console.log(error.response.data.message)
        // setErrormsg(error.response.data.message)
      
      })
      if(!attachstatus)
        {
    
      axios
      
      .get(baseurl + '/api/work_master/get/attachment?project_id='+userData.projectId, {
       // responseType: 'arraybuffer', // Ensure response is in binary format
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },

      })

      .then((response) => {
     ////console.log(response.data)

     const data=response.data
     
  const byteCharacters = atob(data); // Base64 decode if required
  const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  setAttach(url)
setAttachstatus(true)
      
      })
      .catch((error) => {
        //console.log(error)
        ////console.log(error.response.data.message)
        // setErrormsg(error.response.data.message)
      
      })
        }


  })
  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  
    return (

<div className=" container  px-0" style={{position:'absolute',top:'60%',left:'57%',transform:'translate(-50%,-50%)'}}>
      
<div className="row w-75  mb-3 ml-auto">
        <div className="col-8 text-center">
          <h6 className=" text-danger ">{userData.projectName}</h6>
        
        </div>
      </div>
<div className=''
 ><div className='  '>
      <img className=" mr-2 pl-2 " src={Workmastericon} width={35} alt="" />
      <p className=" text-white mb-0 " style={{ fontWeight: "500",fontSize:'18px' }}>
                    
                    &nbsp;Work Master
                  </p></div></div>
         {workmasterdetails.length!=0 &&
         <div className='px-3 ' >
          <div className='row   '>
          {modalStatus && attach!='' &&(
                        <DocumentView
                          status={modalStatus}
                          btnClick={tocloseModal}
                          imageUrl={attach}
                          imageData={workmasterdetails.attachment_url
                            .replace(/width\s*=\s*['"]600['"]/g, 'width="400"')
                            .replace(
                              /height\s*=\s*['"]auto['"]/g,
                              'height="510"'
                            )}
                        />
                      )}
                  
                  <table className=' table table-striped  table-bordered  col-6 ' id="wm1">
                    
        <tbody >
          <tr>
          <td className='serialno bg-white' >1.</td>
            <td className=' t w' >Scheme</td>
             <td className='t1 w1 ' >:</td>
            <td className='t2'>{workmasterdetails.scheme}</td>
          </tr>
          <tr>
          <td className='serialno bg-white' >2.</td>
            <td className=' t w' >Type of Work</td>
             <td className='t1 w1' >:</td>
            <td className='t2'>{workmasterdetails.type_of_work}</td>
          </tr>
          <tr>
            <td className='serialno bg-white' >3.</td>
            <td className=' t w' >Work Code</td>
             <td className='t1 w1' >:</td>
            <td className='t2'>{workmasterdetails.work_code}</td>
          </tr>
          <tr>
            <td className='serialno bg-white' >4.</td>
            <td className=' t w' >Work Name</td>
             <td className='t1 w1' >:</td>
            <td className='t2'>{workmasterdetails.work_name}</td>
          </tr>
          <tr>
            <td className='serialno bg-white' >5.</td>
            <td className=' t w' >Sanctioned Amount</td>
             <td className='t1 w1' >:</td>
            <td className='t2'>{workmasterdetails.sanctioned_amnt}</td>
          </tr>
          <tr>
            <td className='serialno bg-white' >6.</td>
            <td className=' t w' >Lump Sum Amount</td>
             <td className='t1 w1' >:</td>
            <td className='t2'>{workmasterdetails.other_lumpsum_amnt}</td>
          </tr>
          <tr>
            <td className='serialno bg-white' >7.</td>
            <td className=' t w' >Contract Value</td>
            <td className='t1 w1' >:</td>
            <td className='t2'>{workmasterdetails.contract_value}</td>
          </tr>
          <tr>
            <td className='serialno bg-white' >8.</td>
            <td className=' t w' >Attachment</td>
             <td className='t1 w1' >:</td>
            <td className='t2'> <button
                          onClick={documentClick}
                          className="btn btn-success btn-sm d-inline-flex align-items-center"
                        >
                          <img src={img}  alt="img" />
                          &nbsp;&nbsp; View
                        </button></td>
            {/* //attachment_url */}
          </tr>
          <tr>
            <td className='serialno bg-white' >9.</td>
            <td className=' t w' >Description</td>
             <td className='t1 w1' >:</td>
            <td className='t2'>{workmasterdetails.description}</td>
          </tr>
          <tr>
            <td className='serialno bg-white' >10.</td>
            <td className=' t w' >Tags</td>
             <td className='t1 w1' >:</td>
            <td className='t2'>{workmasterdetails.tags}</td>
          </tr></tbody></table>

          <table className=' table table-striped table-bordered   col-6 ' id="wm2">
                    
                    <tbody >
                      <tr>
                      <td className='serialno bg-white' >10.</td>
                        <td className=' t w' >M.L.A Constituency </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2'>{workmasterdetails.mla_constituency}</td>
                      </tr>
                      <tr>
                      <td className='serialno bg-white' >12.</td>
                        <td className=' t w' >M.P Constituency </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2'>{workmasterdetails.mp_constituency}</td>
                      </tr>
                      <tr>
                        <td className='serialno bg-white' >13.</td>
                        <td className=' t w' >GO No.</td>
                         <td className='t1 w1' >:</td>
                        <td className='t2'>{workmasterdetails.go_number}</td>
                      </tr>
                      <tr>
                        <td className='serialno bg-white' >14.</td>
                        <td className=' t w' >GO Issue Date </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2'>{workmasterdetails.go_issue_date}</td>
                      </tr>
                      <tr>
                        <td className='serialno bg-white' >15.</td>
                        <td className=' t w' >Date of Sanction   </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2'>{workmasterdetails.date_of_sanction}</td>
                      </tr>
                      <tr>
                        <td className='serialno bg-white' >16.</td>
                        <td className=' t w' >Actual Start Date      </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2'>{workmasterdetails.actual_start_date}</td>
                      </tr>
                      <tr>
                        <td className='serialno bg-white' >17.</td>
                        <td className=' t w' >Actual Completion Date</td>
                        <td className='t1 w1' >:</td>
                        <td className='t2'>{workmasterdetails.actual_completion_date}</td>
                      </tr>
                      <tr>
                        <td className='serialno bg-white' >18.</td>
                        <td className=' t w' >ECC Date</td>
                         <td className='t1 w1' >:</td>
                        <td className='t2'>{workmasterdetails.ecc_date}</td>
                      </tr>
                      <tr>
                        <td className='serialno bg-white' >19.</td>
                        <td className=' t w' >CC Date   </td>
                         <td className='t1 w1' >:</td>
                        <td className='t2'>{workmasterdetails.cc_date}</td>
                      </tr>
                      <tr >
                        <td className='serialno bg-white' >20.</td>
                        <td className=' t w' >Remarks</td>
                         <td className='t1 w1' >:</td>
                        <td className='t2'>{workmasterdetails.remarks}</td>
                      </tr></tbody></table>

          
      </div>

                  
    </div>
    }
      </div>
       );
    }
    
    export default FormPage;