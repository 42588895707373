import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import 'apexcharts/dist/apexcharts.css';
import './NewRoadReports.css'

const Milestonegraph = ({xaxisval,yaxis1val,yaxis2val}) => {

  
  const [chartData] = useState({
    // series: [
    //   { name: 'Percentage of Work Completed by Estimated Time', type: 'column', data: ["25%","50%","75%","100%"] },
    //   { name: 'Months', type: 'column', data: ["3rd","6th","9th","12th"] },
    // //   { name: 'Revenue', type: 'line', data: [20, 29, 37, 36, 44, 45, 50, 58] },
    // ],
    series: [
        { name: 'Percentage of Work Completed by Estimated Time', type: 'column', data: yaxis1val },
        { name: 'Months', type: 'column', data:yaxis2val},
      //   { name: 'Revenue', type: 'line', data: [20, 29, 37, 36, 44, 45, 50, 58] },
      ],
    options: {
      chart: { height: 350, type: 'line', stacked: false , toolbar: {
        show: false
      },},
      dataLabels: { enabled: false },
      stroke: { width: [1, 1, 4] },
      menu: false,
      colors: ['#00b3fe', '#0074ff'],
      toolbar: {
        show: false, // Shows the toolbar
        tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false, // Disables the zoom tools (+,-, reset)
        }
      },
    //   title: { text: 'Milestones', align: 'left', offsetX: 110 },
      //xaxis: { categories: ["Milestone 1","Milestone 2","Milestone 3","Milestone 4"] },
      xaxis: { categories: xaxisval},
      yaxis: [
        {
          seriesName: 'Percentage of Work Completed by Estimated Time',
          axisTicks: { show: true },
          axisBorder: { show: true, color: '#000000' },
          labels: { 
            style: { colors: '#000000' },
            formatter: function (val) { return Math.round(val); } // Convert label to integer
          },
          title: { text: "Percentage of Work Completed by Estimated Time(%)", style: { color: '#000000',fontSize:'13px' } },
          tooltip: { enabled: true },
          min: 0, // Set the minimum limit for y-axis 1
          max: 100, // Set the maximum limit for y-axis 1
          tickAmount: 4, 
        },
        {
          seriesName: 'Months',
          opposite: true,
          axisTicks: { show: true },
          axisBorder: { show: true, color: '#000000' },
          labels: { 
            style: { colors: '#000000' },
            formatter: function (val) { return Math.round(val); } // Convert label to integer
          },
          title: { text: "Months", style: { color: '#000000' ,fontSize:'13px'} },
          min: 0, // Set the minimum limit for y-axis 1
          max: 12, // Set the maximum limit for y-axis 1
          tickAmount: 4, 
        },
        // {
        //   seriesName: 'Revenue',
        //   opposite: true,
        //   axisTicks: { show: true },
        //   axisBorder: { show: true, color: '#FEB019' },
        //   labels: { style: { colors: '#FEB019' } },
        //   title: { text: "Revenue (thousand crores)", style: { color: '#FEB019' } },
        // },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60,
        },
      },
      legend: {position: 'top', // Position the legend at the top
        horizontalAlign: 'right', // Center the legend horizontally ,  
         markers: {
        width: 12, // Set a visible width for the legend markers
        height: 12, // Set a visible height for the legend markers
      }},
    },
  });

  return (
    <div id="chart">
      <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350} />
    </div>
  );
};

export default Milestonegraph;
