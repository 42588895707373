import React from 'react';
import RadialBar from '../ApexCharts/RadialBar';
import './ProjectProgress.css'
import { useState, useEffect } from 'react'
import Search from '../ProgressSearch/ProgressSearch'
import MultiBarGraph from '../ApexCharts/Multibarchart'
import ThreeBar from '../ApexCharts/Bar3Chart'
import Multibarchart2 from '../ApexCharts/Multibarchart3'
import LineChart2 from '../ApexCharts/LineChart2'
import SingleBar from '../ApexCharts/Singlebarchart'
import SingleBar1 from '../ApexCharts/SingleBarchart1'
import $ from 'jquery';
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import { baseurl } from '../BaseUrl/Baseurl';

const WeareHere = () => {
  const [radialData, setRadialData] = useState('');
  const [fulldatas, setFulldatas] = useState([]);
  const [listdata, setListdata] = useState([]);
  const [btnname, setBtnname] = useState('')
  const [level, setlevel] = useState('')
  const [percentage, setPercentage] = useState(0)
  const [listOfLevels, setListOfLevels] = useState([]);
  const [structureType, setStructureType] = useState([]);
  const [structureCompleted, setStructureCompleted] = useState([]);
  const [structurePending, setStructurePending] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [zaxis, setZaxis] = useState([]);
  const [caxis, setCaxis] = useState([]);
  const [daxis, setDaxis] = useState([]);
  const [resourcedata, setResourcedata] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [graphform, setGraphform] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [itemCompleted, setItemCompleted] = useState([]);
  const [itemPending, setItemPending] = useState([]);
  const [inprogress, setInprogress] = useState([]);
  const [itemOfWork, setItemOfWork] = useState([]);
  const [selectedOption, setSelectedOption] = useState('F0');
  const userData = useSelector((state) => state.userDetails)
  const [tableData, setTableData] = useState([]);

  const [itemOfWorkover, setItemOfWorkover] = useState([]);
  const [itemCompletedover, setItemCompletedover] = useState([]);
  const [itemPendingover, setItemPendingover] = useState([]);
  const [itemofworklist, setItemofworklist] = React.useState([]);
  const [quantityused, setQuantityused] = useState([])
  const [quantitypend, setQuantitypend] = useState([])
  const[levels,setLevels]=useState([]);
  const[levelsprogress,setLevelsprogress]=useState([]);
  const drop = React.useRef(null);
  const cardstatus = "inprogress"

  const overallpercentage = 86
  function btnclick(e) {

    setSelectedOption(e.target.value)


  }


  function btnclick3(levellist1) {
    const levellist2 = levellist1
    setPercentage(0)
    btnclick2(levellist2)

  }
  ///////////////////////radial bar axios//////////////////
  function btnclick2(levellist1) {

    let fData = new FormData()
    fData.append('level', levellist1)
    fData.append('project_id', userData.currentprojectId)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/level_progress?level=' + levellist1 + '&project_id=' + userData.currentprojectId, +fData, {
    //     headers: { 'Content-Type': 'multipart/form-data',

    //    },
    //   })
    axios({
        method: 'GET',

        url: baseurl+'/api/plan/specifications/get/level_progress?level=' + levellist1 + '&project_id=' + userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
        data:fData
      })
      .then((response) => {

        //console.log("second", response.data)
        const dat6 = response.data
        const lel = dat6.current_working_floor

        //console.log("second1", response.data)
        setlevel(lel)


        const per = dat6.progress_completed

        //console.log("second2", per)
        setPercentage(per)

      })
      .catch((error) => {
        //console.log(error)

      })

  }

  const sampleData = ['Beam', 'Lindol', 'Door', 'Wall']
  const sampleData1 = ['Datewise', 'Progress', 'Type', 'Quantity']
  const Beam = [10, 20, 30, 40, 50]
  const Lindol = [20, 40, 60, 80, 100]
  const Datewise = ['Jan', 'feb', 'mar', 'apr', 'may']
  const Progress = ['june', 'July', 'aug', 'sep', 'oct']
  function Creategraph() {

    setGraphform(true);
    setGraphform(!graphform)

  }
  ////drop down code/////




  useEffect(() => {

      //level wise graph
      
      // axios
      // .get(baseurl + '/api/plan/specifications/get/each_level_progress?project_id=' + userData.currentprojectId, {
      //     headers: { 'Content-Type': 'multipart/form-data' },
      // })
      axios({
        method: 'GET',

        url: baseurl+'/api/plan/specifications/get/each_level_progress?project_id=' + userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
      })
      .then((response) => {

          //console.log("second", response.data)
          const dat = response.data
          const lel = dat.level_or_chainage || []
          setLevels(lel)
          const progress=dat.progress || []
          setLevelsprogress(progress)

      })
      .catch((error) => {
          //console.log(error)

      })


    let fDatan2 = new FormData()
    fDatan2.append('project_id', userData.currentprojectId)
    fDatan2.append('level', selectedOption)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/quantity_progress?project_id=' + userData.currentprojectId +
    //     '&level=' + selectedOption, +fDatan2, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

      axios({
        method: 'GET',

        url: baseurl+'/api/plan/specifications/get/quantity_progress?project_id=' + userData.currentprojectId +
        '&level=' + selectedOption, 

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
        data:fDatan2
      })
      .then((response) => {

        //console.log(response.data)
        const dat = response.data
        const item = dat.item_of_works
        setItemofworklist(item)
        const used = dat.quantities_used
        setQuantityused(used)
        const pend = dat.quantities_pending
        setQuantitypend(pend)

        //         setTableData(dat)
        // //console.log('/api/plan/specifications/get/due_date?project_id='+JSON.stringify(dat))

      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })

    let fData = new FormData()
    fData.append('project_id', userData.currentprojectId)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/due_date?project_id=' + userData.currentprojectId, +fData, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

      axios({
        method: 'GET',

        url: baseurl+'/api/plan/specifications/get/due_date?project_id=' + userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
        data:fData
      })
      .then((response) => {

        //console.log(response.data)
        const dat = response.data
        //console.log("%%%%" + response.data)
        //console.log("hello" + dat)
        // ////alert("We Got Calculated Details")
        setTableData(dat)


      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })


    // axios
    //   .get(baseurl + '/api/plan/specifications/get/item_of_work_progress?project_id=' + userData.currentprojectId, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

      axios({
        method: 'GET',

        url: baseurl+'/api/plan/specifications/get/item_of_work_progress?project_id=' + userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
      })
      .then((response) => {

        //console.log(response.data)
        const lldat1 = response.data
        //console.log("level based item graphssss", lldat1)
        const itemwork1 = lldat1.item_of_works ||[]
        setItemOfWorkover(itemwork1)
        const itemcomp1 = lldat1.no_of_structures_completed || []
        setItemCompletedover(itemcomp1)
        const itempend1 = lldat1.no_of_structures_pending || []
        setItemPendingover(itempend1)


      })
      .catch((error
        ) => {
        //console.log("levelerr1", error)

      })



    let structuredata1 = new FormData()

    structuredata1.append('project_id', userData.projectId)
    structuredata1.append('level', selectedOption)
    //console.log(selectedOption)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/item_of_work_progress?project_id=' + userData.currentprojectId + '&level=' + selectedOption, +structuredata1, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })


      axios({
        method: 'GET',

        url: baseurl+'/api/plan/specifications/get/item_of_work_progress?project_id=' + userData.currentprojectId + '&level=' + selectedOption,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
        data:structuredata1
      })
      .then((response) => {

        //console.log(response.data)
        const lldat = response.data
        //console.log("level based item graph", lldat)
        const itemwork = lldat.item_of_works || []
        setItemOfWork(itemwork)
        const itemcomp = lldat.no_of_structures_completed || []
        setItemCompleted(itemcomp)
        const itempend = lldat.no_of_structures_pending || []
        setItemPending(itempend)
      })
      .catch((error) => {
        //console.log("levelerr1", error)

      })


    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/project_progress?project_id='+userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })

      .then((response) => {

        //console.log(response.data)
        const RequestData = response.data
        // setRequestdata1(RequestData)
        //console.log("request", RequestData)

        const xaxis1 = RequestData.actual_completion_date_level_data

        const xax = xaxis1.completed_level
        //  //console.log("hai",xax)



        //       const  arr = xax.map((xax,index)=>{



        // if(xax==null)
        // {
        //   return (null)
        // }
        // else{
        // return (parseInt(xax,10))
        // }
        //                 })

        //         //console.log("new array length "+xax.length);



        setXaxis(xax)

        const yaxis1 = RequestData.due_date_level_data
        const yax = yaxis1.planned_level

        //  const  arrr = yax.map((yax,index)=>{



        //   if(yax==null)
        //   {
        //     return (null)
        //   }
        //   else{
        //   return (parseInt(yax,10))
        //   }
        //                   })




        setYaxis(yax)


        const zaxis1 = RequestData.due_date_level_data
        const zax = zaxis1.planned_date



        setZaxis(zax)
        //console.log(zax)

      })
    ///////////////////////////////////////level drop down axios/////////////////////////
    let leveldata = new FormData()
    leveldata.append('project_id', userData.currentprojectId)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/due_date?project_id=' + userData.currentprojectId, +leveldata, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })
      axios({
        method: 'GET',

        url: baseurl+'/api/plan/specifications/get/due_date?project_id=' + userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
        data:leveldata
      })
      .then((response) => {

        //console.log(response.data)
        const dat = response.data 
        //console.log("dat", dat)
        setListOfLevels(dat)
        //  const level1 = dat.map((dat,index)=>{
        //   return(dat.floor)
        //   console("floor", dat.floor)
        //   setListOfLevels(level1)
        //   //console.log("levels",level1)
        //  })




      })
      .catch((error) => {
        //console.log("levelerr", error)
        //setErrormsg(error.response.data.message)
      })


    //////////////////////////////////////////////////////////////

    let fmData10 = new FormData()


    fmData10.append('project_id', userData.currentprojectId)

    fmData10.append('site_updates', 1)

    //console.log(userData.currentprojectId)

    // axios

    //   .get(baseurl + '/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId + '&site_updates=' + 1, +fmData10, {

    //     headers: { 'Content-Type': 'multipart/form-data' },

    //   })

      axios({
        method: 'GET',

        url: baseurl+'/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId + '&site_updates=' + 1,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
        data:fmData10
      })



      .then((response) => {

        //console.log("carddata", response.data)
        const carddata = response.data
        const cdt = carddata.searched_data

        //console.log(cdt)
        setFulldatas(cdt)



        //  const structureName1 = cdata.structure_item_type
        //   setStructureName(structureName1)
        //   //console.log(structureName1)
        //  const structureId1 = cdata.structure_item_id
        //   setStructureId(structureId1)
        //   const itemName1=cdata.Brickwork
        //   setItemName(itemName1)
        //  const compDate1=cdata.due_date
        //   setCompDate(compDate1)
        //  const itemOfWork1=cdata.item_of_works
        //   setItemOfWork(itemOfWork1)



      })

      .catch((error) => {

        //console.log("carderror", error)

        //setErrormsg(error.response.data.message)

      })
    let structuredata = new FormData()

    structuredata.append('project_id', userData.currentprojectId)
    structuredata.append('level', selectedOption)
    //console.log(selectedOption)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/structure_type_progress?project_id=' + userData.currentprojectId + '&level=' + selectedOption, +structuredata, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })
      axios({
        method: 'GET',

        url: baseurl+'/api/plan/specifications/get/structure_type_progress?project_id=' + userData.currentprojectId + '&level=' + selectedOption,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
        data:structuredata
      })
      .then((response) => {

        //console.log(response.data)
        const ldat = response.data
        //console.log("level based graph", ldat)
        const structuretype1 = ldat.structure_type
        const noOfStructcompleted1 = ldat.no_of_structure_type_completed


        const noOfstructpending1 = ldat.no_of_structure_type_not_yet_started
const inprogres=ldat.no_of_structure_type_pending   
    
        setInprogress(inprogres)
        setStructureType(structuretype1)
        setStructureCompleted(noOfStructcompleted1)
        setStructurePending(noOfstructpending1)
        //console.log(structuretype1)
        //console.log(noOfStructcompleted1)
        //console.log(noOfstructpending1)
        // ////alert(structureType)

      })
      .catch((error) => {
        //console.log("levelerr1", error)

      })
    btnclick3(selectedOption)
    // //console.log("useeffect")
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    // document.addEventListener("click", handleClick);
    // return () => {
    //   document.removeEventListener("click", handleClick);
    // };

    // document.addEventListener("click", handleClick1);
    // return () => {
    //   document.removeEventListener("click", handleClick1);
    // };

    ////////////////////////// api for first graph ////////////////////////////////




    // axios({
    //   method: 'GET',

    //   url: 'http://localhost:8080/api/support/issue/ticket/list',

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   }

    // })
    //   .then((response) => {
    //     //console.log(response.data)
    //     //console.log("result coming")
    //     const TicketData = response.data
    //     setTicketlist(TicketData)
    //   })
    //   .catch((error) => {
    //     //console.log(error)
    //     // setErrormsg(error.response.data.message)
    //   })
    //////////////////////////////level based multiBargraph axios///////////////////////
    axios({
      method: 'GET',

      url: baseurl + '/api/project/get/progress_percentage?project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        //console.log(response.data)
        const radialData1 = response.data
        //console.log("radialbar", radialData1)
        setRadialData(0)
        setRadialData(radialData1.percentage_completed)
      })
      .catch((error) => {
        //console.log(error)
        // setErrormsg(error.response.data.message)
      })


  }, [selectedOption])

  // const toggleModelStatus = () => {

  //   setRequeststatus(false)
  // };
  // const toggleModelStatus1 = () => {

  //   setUpdatestatus(false)
  // };
  // const toggleRenewalStatus = () => {
  //   //console.log("***********")
  //   setRenewalstatus(false)
  // };
  // const toggleViewTicketStatus = () => {
  //   //console.log("***********")
  //   setViewticketstatus(false)
  // };
  // const requestdata = () => {
  //   //console.log("*****" + requeststatus)
  //   setRequeststatus(true)
  // }
  // const Editdata = (id) => {
  //   setProjectid(id)
  //   setUpdatestatus(true)
  // }
  // const ViewticketstatusUpdate = (id) => {
  //   setTicketno(id)
  //   setViewticketstatus(true)
  // }
  function creatinglist(data) {
    const listdata1 = data.join(',')
    //console.log(listdata1)
    setListdata(listdata1)
  }

  return (
    <div class=' col-lg-12 col-md-12 com-xl-12 col-xxl-12 pagetopspace'>

      <table
        class="table"
        readOnly="readOnly"

      >
        <div class='row'>
          <div class="col-xl-12 col-lg-12 col-md-12 pt-2 pb-3 labels ff">
            <b><center>Project Progress</center></b>
          </div>
        </div>
        <div class='row'>
          <div class='col-md-1 col-lg-1 col-xl-1 col-xxl-1'>
          </div>
          <div class='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
          {/* { levels.length != 0 && levelsprogress.length != 0 &&
            < MultiBarGraph
              graph1="CompletedWork"
              graph2="PendingWork"
              graph1data={levelsprogress}
            
              xaxisdata={levels}

              xaxistext="Levels"
              yaxistext="Percentage"

            />} */}
        {  levels.length == 0 && levelsprogress.length == 0 &&
            // !checkduedate &&
            
            <SingleBar1
            
            listdata={levelsprogress}
            xaxisdata={levels}
            type="bar"
            yaxismax={100}

            />}
      { levels.length != 0 && levelsprogress.length != 0 &&
            <SingleBar1
            
            listdata={levelsprogress}
            xaxisdata={levels}
            type="bar"
            yaxismax={100}

            />}
            {/* {xaxis != 0 && yaxis != 0 && zaxis != 0 && <LineChart2
              type="line"
              PlannedProgress={yaxis}
              ExpectedProgress={xaxis}
              xaxisData={zaxis}

              yaxistext="levels"
              xaxistext="Date"
            />
            } */}
            
          </div>
          <div class='col-md-1 col-lg-1 col-xl-1 col-xxl-1'>

            {/* <MultiBarGraph
              graph1="CompletedWork"
              graph2="PendingWork"
              graph1data={itemCompletedover}
              graph2data={itemPendingover}
              xaxisdata={itemOfWorkover}
              xaxistext="ItemOfWork"
              yaxistext="Quantity(units)"
            /> */}
          </div>
          {/* <Multibarchart1 /> */}
          {/* </div> */}
          <div class='col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3'>
            <div className='table3style '>
              <div class='pt-3'>
                <b><center>Project Completion status</center></b>
              </div>
              <center>
                {/* <RadialBar

                        SeriesValue={overallpercentage}

                        radialwidth={245}

                        labelValue={overallpercentage}

                        radialcolor={(overallpercentage < 50) ? '#ff0000' : (overallpercentage >= 50 && overallpercentage <= 80) ? '#ff6600' : '#00e600'}

                      ></RadialBar> */}
                {radialData != '' && <RadialBar

                  SeriesValue={radialData}

                  radialwidth={245}

                  labelValue={radialData}

                  radialcolor={((parseInt(radialData)) < 50) ? '#ff0000' : (parseInt(radialData) >= 50 && parseInt(radialData) <= 80) ? '#ff6600' : '#00e600'}

                ></RadialBar>}

              </center>
            </div>
            <div>
              <center>
<button
                                type="button"
                                className="btnred "
                              
                              ></button>
                              <font className="donutfont">
                                &nbsp;&nbsp;Bad &nbsp;&nbsp; &nbsp;&nbsp;
                              </font>
                              <button
                                type="button"
                                className="btnyellow "
                              ></button>
                              <font className="donutfont">
                                &nbsp;&nbsp;Critical &nbsp;&nbsp; &nbsp;&nbsp;
                              </font>
                              <button
                                type="button"
                                className="btngreen "
                              ></button>
                              <font className="donutfont">
                                &nbsp;&nbsp;Good &nbsp;&nbsp; &nbsp;&nbsp;
                              </font>
                             
                              </center>
                              </div>
          </div>
          <div class='col-md-1 col-lg-1 col-xl-1 col-xxl-1'>
          </div>
        </div>
      </table>

   


      <div class='row'>

        <div class="col-md-12 col-xl-12 col-lg-12  pt-3 pb-3 labels ff">
          <center> <p><b>Levelwise Progress</b></p></center>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-5 col-xl-5  mb-3">
          <select onChange={(e) => btnclick(e)} name="selectedoption" value={selectedOption} className="project-box">
            {/* <option>select level to see the progress</option> */}
            {listOfLevels.length!=0 && listOfLevels.map((item, index) => {
              return (

                <option value={item.floor} >{item.floor}</option>

              )
            })}


          </select>
        </div>
        <div class='col-md-4 col-lg-4 col-xl-4'>
        </div>
        <div class='col-md-3 col-lg-3 col-xl-3'>
          {/* {level != 0 && <div className='table3style '>

            <div class='pl-3 pt-3'>

              <center> {selectedOption} Completion Status</center>

            </div>
            {percentage != 0 && <center><RadialBar

              SeriesValue={percentage}

              radialwidth={200}

              labelValue={percentage}

              radialcolor={(percentage < 50) ? '#ff0000' : (percentage >= 50 && percentage <= 80) ? '#ff6600'
                : '#00e600'}

            ></RadialBar> </center>}
            {percentage == 0 && <center><RadialBar

              SeriesValue={percentage}

              radialwidth={200}

              labelValue={percentage}

              radialcolor={


                '#FBB04B'

              }

            ></RadialBar> </center>}


          </div>} */}

        </div>
      </div>
      <div class='row pt-5'>
        <div class="col-md-4 col-xl-4 ">
        { structureType.length == 0 && structureCompleted.length == 0 && structurePending.length == 0 &&
             < ThreeBar
             graph1="Completed dWork"
             graph2="Pending Work"
             graph3="Inprogress Work"
             graph1data={structureCompleted}
             graph2data={structurePending}
             graph3data={inprogress}
             xaxisdata={structureType}

             xaxistext="StructureType"
             yaxistext="NumberOfStructure"

           />}


          {structureType.length != 0 && structureCompleted.length != 0 && structurePending.length != 0 &&
            < ThreeBar
              graph1="Completed dWork"
              graph2="Pending Work"
              graph3="Inprogress Work"
              graph1data={structureCompleted}
              graph2data={structurePending}
              graph3data={inprogress}
              xaxisdata={structureType}

              xaxistext="StructureType"
              yaxistext="NumberOfStructure"

            />}

          {/* <table
              class="table"
              readOnly="readOnly"
            >
               
             
              <div class = 'row'>
              <div class="pt-2 pb-2 pl-2">
                <b>Level Wise Progress</b>
                </div></div>
                <div class = 'row'>
                <div class="pt-2 pb-2">
                <tr class="trst">
                          
                          <th><center>Level</center></th>
                          <th><center>Status</center></th>
                          <th><center>Percentage of completion</center></th>
                        </tr>
                        {list2 != 0 && list2.map((item, index) => {
                          return (
                            <tr key={index} className='tablefontsize'>
                            
                              
                              <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.key1}</center> </td>
                              <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.key2}</center> </td>
                              <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.key3}</center> </td>


                            </tr>
                          )
                        })}
            </div></div></table> */}
        </div>
        <div class="col-md-12  col-lg-6 col-xl-6 ">
          {/* <select onChange={(e) => btnclick(e.target.value)} className="project-box">
          {listOfLevels.map((item,index)=>{
           return(
            <option value={item.floor} >{item.floor}</option>
            
            )})}
            

          </select>
          */}
  { itemCompleted.length==0 && itemPending.length==0 && itemOfWork.length==0 &&
            < MultiBarGraph
            graph1="Completed Work"
            graph2="Pending Work"
            graph1data={itemCompleted}
            graph2data={itemPending}
            xaxisdata={itemOfWork}

            xaxistext="Item Of Work"
            yaxistext="NumberOfItem"
           colorList= {['#47d147', '#ff1a1a']}
          />
                }

          {itemCompleted.length != 0 && itemPending.length != 0 && itemOfWork.length != 0 &&
            < MultiBarGraph
              graph1="Completed Work"
              graph2="Pending Work"
              graph1data={itemCompleted}
              graph2data={itemPending}
              xaxisdata={itemOfWork}

              xaxistext="Item Of Work"
              yaxistext="NumberOfItem"
              colorList= {['#47d147', '#ff1a1a']}
            />}
        </div>
        <div class="col-md-12 col-lg-2 col-xl-2 pt-5">

          {/* < MultiBarGraph
                    graph1="Completed Amount"
                    graph2="Expected Amount"
                    graph1data={[50,36,78]}
                    graph2data={[57,89,100]}
                    xaxisdata={['Concrete','Brick','Plastering']}

                    xaxistext="Materials"
                    yaxistext="Units"

                  /> */}
          {/* <Multibarchart2 /> */}
          {/* {itemofworklist.length != 0 && quantityused.length != 0 && quantitypend.length != 0 && 
          <Multibarchart2 itemofwork={itemofworklist} used={quantityused} pending={quantitypend} />} */}
          {level != 0 && <div className='table3style '>

            <div class='pt-3'>

              <center> {selectedOption} Completion Status</center>

            </div>
            <div className='padlsd'>
            {percentage != 0 && <center><RadialBar

              SeriesValue={percentage}

              radialwidth={180}

              labelValue={percentage}

              radialcolor={(percentage < 50) ? '#ff0000' : (percentage >= 50 && percentage <= 80) ? '#ff6600'
                : '#00e600'}

            ></RadialBar> </center>}
            </div>
            <div className='padlsd'>
            {percentage == 0 && <center><RadialBar

              SeriesValue={percentage}

              radialwidth={180}

              labelValue={percentage}

              radialcolor={


                '#FBB04B'

              }

            ></RadialBar> </center>}
             </div>


          </div>}
        </div>
      </div>


{/* 
      <div class="row ">
        <div class="col-md-12 col-xl-12 col-lg-12 ">
          <div className=''>
         
            <div class="col-md-12 col-xl-12 col-lg-12 col-lg-12  pt-5 pb-3 labels ff">
              <center> <p><b>Site Updates</b></p></center>
            </div>
            { fulldatas.length == 0 &&
          
            <div className='row '>
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error">
                <center><b><h6>No Data Available</h6></b></center>
                </div>
                </div>}
                {fulldatas.length !== 0 && 
            <div class=' row pt-2 table2style1 ' >
             {fulldatas.map((fulldata1, index) => {
                return (


                  <div class='  col-md-4 col-xl-4  col-lg-4' key={index} >
                    <div className='card_style ml-4 mr-1 mb-5 mt-1 w-100'>
                      <div class='row bgccd '>
                    
                        <div class='col-md-6 col-xl-6 fontcolor'>
                          <b>{fulldata1.structure_item_id}</b>

                        </div>

                        
                        <div class='col-md-6 col-xl-6  col-lg-4'>
                          <p className={(fulldata1.completion_status) == 2 ? 'inprogress' : (fulldata1.completion_status) == 4 ? 'completed1' : (fulldata1.completion_status) == 1 ? 'delayed' : (fulldata1.completion_status == 0) ? 'NotYetStarted' : 'done'}>{(fulldata1.completion_status == 2) ? "inprogress" : (fulldata1.completion_status == 4) ? "completed" : (fulldata1.completion_status == 1) ? "delayed" : (fulldata1.completion_status == 0) ? "NotYetStarted" : "done"}</p>
                        
                        </div>

                      </div>
                      <div className='line-style'>
                        <hr />
                      </div>

                      {fulldata1.completion_status == 4 && <div class='row bgc'>
                        <div class='col-md-5 col-xl-5 col-lg-5 fontcolor'>
                          <b>CompletionDate:</b>
                        </div>
                        <div class='col-md-7 col-xl-7 col-lg-7 fontcolor1'>
                          <p>{fulldata1.completed_on}</p>
                        </div>
                      </div>}


                      {(fulldata1.completion_status == 3 || fulldata1.completion_status == 0 || fulldata1.completion_status == 2 || fulldata1.completion_status == 1) && <div class='row bgccc pb-4'>

                        <div class='col-md-5 col-xl-5 col-lg-5 fontcolor'>
                          <b>Item of work open:</b>
                        </div>
                        <div class='col-md-7 col-xl-7 col-xl-7 fontcolor1'>
                          <p>{fulldata1.item_of_works_pending.join(', ')}</p>
                        </div>
                      </div>}
                   
                      <div className='line-style'>
                        <hr />
                      </div>
                

                      <div className='bgccc'>
                        <div class='row '>
                          <div class='col-md-5 col-xl-5 col-lg-5 fontcolor'>
                            <b>Item of work completed:</b>
                          </div>
                          <div class='col-md-7 col-xl-7 col-lg-7 fontcolor1'>

                            <p>{fulldata1.item_of_works_completed.join(', ')}</p>

                          </div>
                        </div>
                      </div>
                      <div className='line-style'>

                        <hr />

                      </div>

                      <div className='fontcolor'>
                        <b>Resource Available :</b>

                      </div>
                 
                      <div class='row '>

                        {fulldata1.resource_names.map((dat, index) => {

                          return (
                         
                            <div class='col-md-4 col-lg-4 col-xl-4 pb-1 '>
                              <center>
                                <div className={(dat === 'Cement') ? 'bluebtn cement' : (dat === 'Sand') ? 'bluebtn  sand' :
                                  (dat === 'Aggregate') ? 'bluebtn aggregate' : (dat === 'Bricks') ? 'bluebtn  brick' : (dat === 'Stones') ?
                                    'bluebtn  stone' : (dat === 'Tiles') ? 'bluebtn  tiles' : (dat === 'Ironrod') ? 'bluebtn ironrod' : ""}>
                                  {dat}&nbsp;
                                  {fulldata1.quantities_instock[index]}

                                </div>
                              </center>
                            </div>
                          )
                        })}
                      </div>
                    </div>


                  </div>
          

                )
              })}
            </div>
}

          </div>
        </div>


        <div class="col-md-2 col-xl-2">


        </div>


      </div> */}



  
      <div class="col-md-12 col-xl-12 col-lg-12 ">
         
              {/* <div class="pt-2 pb-2 pl-2">
                <b>Work Status</b>
              </div> */}
             
                {tableData.length != 0 && <Search data={tableData} />}
              </div>
      {/* <div className='table1bdstyle'>
          <div class="row table1st">
          <div class="pt-1 pb-1">
                <p><b>Resources</b></p>
              </div>
              <div className='tableContainer' >
            <table
              class="table"
              readOnly="readOnly"
           

            >
              <tr class ='trst'>
                
                <th>Resource Type</th>
                <th>Required pack</th>
                <th>Quantity</th>
                <th>Purpose</th>
                <th>Request Status</th>

              </tr>
              {data1.map((data1,index) =>{
                return(
                  <tr key ={index}>
                 
                    <td className='fontcolor fontsize'><b>{data1.ResourceType}</b></td>
                  
                 
                    <td className='fontcolor1 fontsize1'>{data1.RequiredPack}</td>
                  
                    <td className='fontcolor fontsize'><b>{data1.Quantity}</b></td>
                  
                    <td className='fontcolor1 fontsize1'>{data1.Purpose}</td>
                     

                    <td>{((data1.RequestStatus) === 'Received'? <img src = {received} alt='received' height={30} width={90} />:'')||(data1.RequestStatus === 'Cancelled'? <img src ={cancelled} alt = 'cancelled' height = {30} width = {90} /> : <img src ={ordered} alt = 'ordered' height = {30} width = {90} />)}</td>

                  </tr>
                )
               })
              }
         
              


            </table>
            </div>
          </div>
         
        

          
        </div> */}

      {/* ///////////////////////////////// */}

      {/* <div className='table1bdstyle'>
          <div class="row table1st">
          <div class="pt-1 pb-1">
                <p><b>Work Progress</b></p>
              </div>
              <div className='tableContainer' >
            <table
              class="table"
              readOnly="readOnly"
           

            >
              <tr class ='trst'>
                
                <th>Work Item</th>
                <th>Work Status</th>
                <th>Est.Completed Date</th>
                <th>Resource</th>
                <th>Comment</th>

              </tr>
              {data2.map((data2,index) =>{
                return(
                  <tr key ={index}>
                    <td className='fontsize fontcolor'><b>{data2.Workitem}</b></td>
                    
                    <td>{((data2.Workstatus) === 'Inprogress'? <img src = {inprogress} alt='inprogress' height={30} width={90} />:'')||(data2.Workstatus === 'Completed'? <img src ={completed} alt = 'completed' height = {30} width = {90} />: <img src ={delayed} alt = 'delayed' height = {30} width = {90} />)}</td>
                    
                  
                    <td className='fontsize1 fontcolor1'>{data2.CompletionDate}</td>
                    <td className={(data2.Resource === 'AVAILABLE'?'fontsize color1':'')||(data2.Resource === 'REQUESTED'?'fontsize color2':'fontsize color3')}><b>{data2.Resource}</b></td>
                    <td className='fontsize1 fontcolor1'>{data2.Comment}</td>
                  </tr>
                )
               })
              }
         
              


            </table>
            </div>
          </div>
           */}

      {/* </div> */}

    </div>
  );
}

export default WeareHere;
