import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom"
import './EditContractor.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'

const EditVendor = () => {
  const fileInputRef = useRef(null);

  const [successmsg, setSuccessmsg] = useState('');
  const [successstatus, setSuccessstatus] = useState(false)

  const [companyname, setCompanyname] = useState('')
  const [companynameValid, setCompanynameValid] = useState(false)
  const [companynameerror, setCompanynameerror] = useState('')
  const [email, setEmail] = useState('')

  const [vendortype, setVendortype] = useState('')
  const [vendortypeValid, setVendortypeValid] = useState(false)
  const [vendortypeerror, setVendortypeerror] = useState('')

  const [resourcetype, setResourcetype] = useState('')
  const [resourcetypeValid, setResourcetypeValid] = useState(false)
  const [resourcetypeeerror, setResourcetypeerror] = useState('')

  const [onboardingdate, setOnboardingdate] = useState('')
  const [onboardingdateValid, setOnboardingdateValid] = useState(false)
  const [onboardingdateeerror, setOnboardingdateerror] = useState('')

  const [contactno, setContactno] = useState('')
  const [contactno1, setContactno1] = useState('')
  const [contactnoValid1, setContactnoValid1] = useState(false)
  const [contactnoerror1, setContactnoerror1] = useState('')

  const [contactmail, setContactmail] = useState('')
  const [contactmail1, setContactmail1] = useState('')
  const [contactmailValid1, setContactmailValid1] = useState(false)
  const [contactmailerror1, setContactmailerror1] = useState('')

  const [contactperson, setContactperson] = useState('')
  const [contactperson1, setContactperson1] = useState('')
  const [contactpersonValid1, setContactpersonValid1] = useState(false)
  const [contactpersonerror1, setContactpersonerror1] = useState('')


  const [documentupload, setDocumentupload] = useState('')
  const [documentuploadValid, setDocumentuploadValid] = useState(false)
  const [documentuploaderror, setDocumentuploaderror] = useState('')
  const [editableForm, setEditableForm] = useState(false)
  const [isVisible, setIsVisible] = useState(true);
  const [datass, setDatass] = useState([]);

  const [data11, setData11] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const location = useLocation()
  const contractorId = location.state?.contractorId

  // const vName = location.state?.vendorName
  // const vWork = location.state?.vendorwork
  // const vStatus = location.state?.vendorStatus

  const userData = useSelector((state) => state.userDetails)
  // //////alert(vName)
  // //////alert(vWork)
  // //////alert(vStatus)

  const convertDateFormat1 = (inputDate) => {
    //////alert("Date" + inputDate)
    const [year, month, day] = inputDate.split('-');

    return `${year}-${month}-${day}`;
  };
  useEffect(() => {

    axios({
      method: 'GET',

      url: baseurl + '/api/vendor/list/get/individual_contractor_details?contractor_id=' + contractorId + '&contractor_type=Contractor',

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token,
      }
    })

      .then((response) => {
        const datas = response.data;
        //console.log("dataaas", response.data)
        //console.log('Datasss received111:', datas); // Log the received data
        setDatass(datas)
        //////alert(datas)
        const email1 = datas[0].email
        const phoneno = datas[0].phone_no
        const contactperson = datas[0].contact_person
        //////alert(email1)
        setContactmail1(email1)
        setContactperson1(contactperson)
        setContactno1(phoneno)
   
        


      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }, []);


  function handleEdit(e) {

    setEditableForm(true)
setSuccessmsg('')
    //  setContactperson("")
    //  setContactno("")
    //  setContactmail("")

  }

  function handleCompanyname(e) {
    setCompanyname(e.target.value.replace(/\s+/g, ''))
    validateField('companyname', e.target.value)
  }

  function handleDeleteFile(index) {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);

    // Update the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.files = updatedFiles;
    }
  }

  function handleVendortype(e) {
    setVendortype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleDocumentupload(e) {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setDocumentupload(selectedFile); // Store the selected file
    } else {
      setDocumentupload(null);
    }
  }
  function handleResourceType(e) {

    setResourcetype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleOnboardingdate(e) {
    setOnboardingdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleDeleteFile(index) {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  }

  function handleContactmail(e) {
    setContactmail1(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }


  function handleContactperson(e) {
    setContactperson1(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }


  function handleContactno(e) {
    setContactno1(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {

    if (fieldName === 'companyname') {
      //console.log(value)

      if (value != 0) {
        setCompanynameerror('')
        setCompanynameValid(true)
      } else {
        setCompanynameerror('Please select Company name')
        setCompanynameValid(false)
      }
    }



    if (fieldName === 'uploaddocument') {
      //console.log(value)

      if (value != 0) {
        setDocumentuploaderror('')
        setDocumentuploadValid(true)
      } else {
        setDocumentuploaderror('Please select uploaddocument')
        setDocumentuploadValid(false)
      }
    }


    if (fieldName === 'vendortype') {
      if (value.length != 0) {
        setVendortypeerror('')
        setVendortypeValid(true)
      } else {
        setVendortypeerror('Please enter your Vendor type')
        setVendortypeValid(false)
      }
    }
    if (fieldName === 'onboardingdate') {
      if (value.length != 0) {
        setOnboardingdateerror('')
        setOnboardingdateValid(true)
      } else {
        setOnboardingdateerror('Please select date')
        setOnboardingdateValid(false)
      }
    }

    if (fieldName === 'resourcetype') {
      if (value.length != 0) {
        setResourcetypeerror('')
        setResourcetypeValid(true)
      } else {
        setResourcetypeerror('Please enter your type')
        setResourcetypeValid(false)
      }
    }
    if (fieldName === 'contactmail') {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      if (value.length != 0) {
        //console.log(value.match(regex))
        if (value.match(regex)) {

          setContactmailValid1(true)
          setContactmailerror1('')
        } else {

          setContactmailValid1(false)
          setContactmailerror1('Please enter valid email')
        }
      } else {

        setContactmailValid1(false)
        setContactmailerror1('Please enter valid email')
      }
    }

    if (fieldName === 'contactno') {
      if (value.length != 0 && value.length == 10) {
        setContactnoerror1('')
        setContactnoValid1(true)
      } else {
        setContactnoerror1('Please enter valid Contact number')
        setContactnoValid1(false)
      }
    }

    if (fieldName === 'contactperson') {
      //console.log(value)
      if (value != "Select") {
        setContactpersonerror1('')
        setContactpersonValid1(true)
      } else {
        setContactpersonerror1('Please select  Contact person')
        setContactpersonValid1(false)
      }
    }
  }


  function onSubmitEvent(event) {
    event.preventDefault();

setSuccessmsg('')
    //console.log(contactperson1)
    //console.log(contactno1)

    //console.log(contactmail1)

    if (editableForm) {
      axios({
        method: 'put',

        url: baseurl + '/api/vendor/update/individual_contractor_details?contractor_type=Contractor&contractor_id=' + contractorId + '&contact_person=' + contactperson1 + '&contact_no=' + contactno1 + '&contact_mail=' + contactmail1
        +'&resourcelist=null',

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        }
      })


        .then((response) => {

          setSuccessstatus(true)
          setSuccessmsg("Successfully Updated")
          setEditableForm(false)

        })
        .catch((error) => {

        });
      // }
    }
  }

  return (


    <div className="cont-margin1">
      <div class="cont-margin-latest  ">
        <div className='table1bdstyle'>
          {datass.map((datass, index) => {
            return (
              <div className='row table1st'>
                <div class="row pt-1 padleft">


                  <Link to="/SubContractor">
                    <button
                      type="submit"
                      className="contractor-btn1"
                      name="submit"
                      float="right"

                    >
                      <b>Back</b>
                    </button>
                  </Link>


                </div>

                <div class="row pb-5">


                  <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12 pb-5 ">
                    <form
                      class="row d-flex justify-content-center align-center  blacktext"
                      onSubmit={onSubmitEvent}
                    >

                      <div class="row">

                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            Company Name
                          </label>
                          {editableForm == false && <center>
                            <InputTextField
                              //   handleInputChange={handleCompanyname}
                              valueText={datass.vendor_company_name}
                              inputName="companyname"
                              inputId="companyname"
                              classname="project-box"
                              maxLen={10}
                              inputType="text"
                              placeholderValue="Company Name"
                              disabled

                            ></InputTextField>
                          </center>}

                          {editableForm == true && <center>
                            <InputTextField
                              handleInputChange={handleCompanyname}
                              valueText={datass.vendor_company_name}
                              inputName="companyname"
                              inputId="companyname"
                              classname="project-box"
                              maxLen={10}
                              inputType="text"
                              placeholderValue="Company Name"
                              disabled

                            ></InputTextField>
                          </center>}




                        </div>


                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            On Boarding date
                          </label>
                          <center>

                            <InputTextField
                              //   handleInputChange={handleOnboardingdate}
                              valueText={datass.onboarding_date}
                              inputName="onboardingdate"
                              inputId="onboardingdate"
                              classname="project-box"
                              inputType="date"
                              placeholderValue="Date"
                              disabled
                            // mindate={todayDate}
                            ></InputTextField>




                          </center>
                        </div>
                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            Contact Person
                          </label>
                          <center>
                       
                             <InputTextField
                              handleInputChange={handleContactperson}
                              valueText={contactperson1}
                              inputName="contactperson"
                              inputId="contactperson"
                              classname="project-box"
                          
                              inputType="text"
                              placeholderValue="Contact person"
                      disabled={!editableForm}

                            ></InputTextField>
                          </center>
                          {editableForm == false && <div className="login-error">{contactpersonerror1}</div>}

                        </div>


                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            Contact Number
                          </label>
                          <center>
                          

                          <InputTextField
                              handleInputChange={handleContactno}
                              valueText={contactno1}
                              inputName="contactno"
                              inputId="contactno"
                              classname="project-box"
                              maxLen={10}
                              inputType="text"
                              placeholderValue="Contact number"
                              disabled={!editableForm}
                            ></InputTextField>
                          </center>
                          {editableForm == false && <div className="login-error">{contactnoerror1}</div>}
                        </div>

                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            Contact Email
                          </label>
                          <center>
                          
                           <InputTextField
                              handleInputChange={handleContactmail}
                              valueText={contactmail1}
                              inputName="contactmail"
                              inputId="contactmail"
                              classname="project-box"
                              inputType="text"
                              placeholderValue="Contact email"
                              disabled={!editableForm}
                            ></InputTextField>

                            {editableForm == true && <div className="login-error">{contactmailerror1}</div>}
                          </center>
                        </div>
                        <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            Uploaded Document
                          </label>
                          {/* <input
                            type="file"
                            className="project-box"
                            id="documentUpload"
                            name="documentUpload"
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={handleDocumentupload}
                            disabled
                           

                          /> */}
                          <div class="pt-2">
                          {datass.contract_document_filename}
                          </div>
                        </div>

                        {/* {selectedFiles.length > 0 && (
                          <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                            <label className="login-text">Selected Files</label>
                            <ul>
                              {selectedFiles.map((file, index) => (
                                <li key={index}>
                                  {file.name}
                                  <button
                                    type="button"
                                    onClick={() => handleDeleteFile(index)}
                                    className="delete-button"
                                  >
                                    Delete
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )} */}

                      </div>
                      <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">

                        <center>
                        <div className="success pb-3">{successmsg}</div>
                          {!editableForm && <button
                            type="Edit"
                            className="project-btn"
                            name="Edit"
                            onClick={handleEdit}

                          // disabled={!(companynameValid && resourcetypeValid && onboardingdateValid 
                          //   && vendortypeValid && contactpersonValid && contactmailValid && contactnoValid &&documentuploadValid)}
                          >
                            <b>Edit</b>
                          </button>}

                        </center>{' '}
                      </div>
                      <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                        <center>
                       
                          {editableForm == true && <button
                            type="submit"
                            className="project-btn"
                            name="submit"
                          
                          >
                            <b>Save</b>
                          </button>}

                        </center>{' '}


                      </div>

                    </form>
                  </div>

                </div>
              </div>)
          })}
        </div>

      </div>
    </div>
  );
}

export default EditVendor;

