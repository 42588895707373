import { combineReducers } from 'redux'
const UPDATE_LOGIN_STATUS = 'UPDATE_LOGIN_STATUS'

export function updateLoginStatus(userData) {
  return {
    type: UPDATE_LOGIN_STATUS,
    userData,
  }
}


const defaultUserData = {
  isLoggedIn: false,
  userName: '',
  userId: '',
  role:'',
  token:'',
  Pagename:'Dashboard',
  projectId:'',
  clientId:'',
  dropdownOption:'Project Timeline',
  currentprojectId:'',
  projectType:'',
  companyName:'',
  projectMenuIndex:0,
  resourceMenuKey:"material",
  modalNotificationstatus:false,
  profileImage:'',
  headerMenus:[],
  projectMenus:[],
  sidebartoggleStatus:false,
  sidebarProjectsMenus:[],
  sidebarReportsMenus:[],
  sidebarWorkDetailsMenus:[],
  dropdownViewStatus:false,
  projectStatus:false,
  projectName:'',
  projectsubname:'Dashboard',
  chainageArr:[],
  loginUsername:'',
  engineerLoginStatus:false,
  searchParams:[],
  statename:'',
  circle:'',
  division:'',
  subdivision:'',
  engineertoken:'',
  startChainage:'',
  endChainage:'',

}

function userDetails(state = defaultUserData, action) {
  switch (action.type) {
    case UPDATE_LOGIN_STATUS:
      return {
        ...state,
        isLoggedIn: action.userData.isLoggedIn,
        userName: action.userData.userName,
        userId: action.userData.userId,
        Pagename:action.userData.Pagename,
        role:action.userData.role,
        token:action.userData.token,
        projectId:action.userData.projectId,
        clientId:action.userData.clientId,
        currentprojectId:action.userData.currentprojectId,
        dropdownOption:action.userData.dropdownOption,
        projectType:action.userData.projectType,
        companyName:action.userData.companyName,
        projectName:action.userData.projectName,
        projectMenuIndex:action.userData.projectMenuIndex,
        resourceMenuKey:action.userData.resourceMenuKey,
        modalNotificationstatus:action.userData.modalNotificationstatus,
        profileImage:action.userData.profileImage,
        headerMenus:action.userData.headerMenus,
        projectMenus:action.userData.projectMenus,
        sidebartoggleStatus:action.userData.sidebartoggleStatus,
        sidebarReportsMenus:action.userData.sidebarReportsMenus,
        sidebarWorkDetailsMenus:action.userData.sidebarWorkDetailsMenus,
        dropdownViewStatus:action.userData.dropdownViewStatus,
        projectStatus:action.userData.projectStatus,
        projectName:action.userData.projectName,
        projectsubname:action.userData.projectsubname,
        chainageArr:action.userData.chainageArr,
        loginUsername:action.userData.loginUsername,
        engineerLoginStatus:action.userData.engineerLoginStatus,
        searchParams:action.userData.searchParams,
        statename:action.userData.statename,
        circle:action.userData.circle,
        division:action.userData.division,
        subdivision:action.userData.subdivision,
        engineertoken:action.userData.engineertoken,
        startChainage:action.userData.startChainage,  
        endChainage:action.userData.endChainage
      }
    
    default:
      return state
  }
}

const userDataStore = combineReducers({
  userDetails,
})

export default userDataStore
