import React,{useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal, Form } from 'react-bootstrap'
import './Modal.css'
import CreateRequest from '../CreateRequest/CreateRequest'
import { useState} from 'react'
import InputTextField from '../InputText/InputText'
import {baseurl} from '../BaseUrl/Baseurl'
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
export default function RenewalModel({ btnClick, status ,  ProjectId }) {
  const [projectstatus, setProjectstatus] = useState('')
  const [projectstatusValid, setProjectstatusValid] = useState(false)
  const [projectstatuserror, setProjectstatuserror] = useState('')
  const [paymentdate, setPaymentdate] = useState('')
  const [paymentdateValid, setPaymentdateValid] = useState(false)
  const [paymentdateeerror, setPaymentdateerror] = useState('')
  const [fileattach, setFileattach] = useState('')
  const [fileattacherror, setFileattacherror] = useState('')
  const [fileattachValid, setFileattachValid] = useState(false)
  const [message, setMessage] = useState('')
  const hiddenFileInput = React.useRef(null)
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const userData = useSelector((state) => state.userDetails)
  const [modalVisible, setModalVisible] = useState(status);
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
//console.log("hi");

//////alert(modalVisible)
    }, [status,modalVisible])

  function handleProjectstatus(e) {
   //console.log(e.target.value)
    setProjectstatus(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  
  function handleChangeFile(event) {
    const file = event.target.files[0]
    // let filename = file.name
    setFileattach(file)
    validateField(event.target.name, event.target.files[0])
  }
  function handlepaymentdate(e) {

    setPaymentdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {
    if (fieldName === 'projectstatus') {
   
      if (value.length != 'Select') {
        setProjectstatuserror('')
        setProjectstatusValid(true)
      } else {
        setProjectstatuserror('Please select your project status')
        setProjectstatusValid(false)
      }
    }
    
 
 
    if (fieldName === 'fileattach') {
     const filesize1 = fileattach.size
      if (filesize1 != 0) {
          setFileattacherror('')
          setFileattachValid(true)
        } else {
          setFileattacherror('Please attach your payment file')
          setFileattachValid(false)
        }
      }
  }
  function handlemessage(e) {

    //console.log(message)

    setMessage(e.target.value.replace(/\s+/g, ''))

    validateField(e.target.name, e.target.value)

  }


  function onSubmitEvent(event) {
    event.preventDefault()

  //console.log(projectstatus)
  //console.log(paymentdate)
  //console.log(fileattach)

      // //console.log(name)
      // //console.log(phno)
if(fileattachValid && projectstatusValid)
{
        let userData2 = new FormData()
        userData2.append('project_id',ProjectId )
        userData2.append('payment_proof', fileattach)
        userData2.append('renewal_status', projectstatus)
        userData2.append('comment', message)


          axios({
            method: 'PUT',
          
            url: baseurl+'/api/project/renewal/update/status',
          
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token
            },
            data: userData2
          })
          .then((response) => {
            ////////alert("success renewal"+ProjectId)
          })
          .catch((error) => {
             //console.log(error)
           // setErrormsg(error.response.data.message)
          })
 
        }
    setModalVisible(false)
  }

  return (
    <>
      <Modal show={modalVisible} onHide={btnClick} id="modal-size1">
        <Modal.Body>
          <center>  <div className="model-top"><b>Renewal</b></div></center>
          {/* <CreateRequest /> */}
          
  
          <div className="cont-modalstyle">
           
                <div class="row pb-2">
           
                
                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={(event) => {
                      onSubmitEvent(event);
                      btnClick(); // Close the modal
                    }}
                  >

                    <div class="row">
                    <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Payment date
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handlepaymentdate}
                          valueText={paymentdate}
                          inputName="paymentdate"
                          inputId="paymentdate"
                          classname="project-box"
                          maxLen={20}
                          inputType="date"
                          placeholderValue="payment Date"
                        ></InputTextField>

                        <div className="login-error">{paymentdateeerror}</div>
                        </center>
                      </div>
                     
                      <div class="col-sm-12 col-12 col-lg-12 col-md-12 col-xl-12 pt-2 ">
                      <label className="login-text">
                Payment
              </label>
            </div>
            <div className="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
              <button
                type="button"
                className="project-box "
                onClick={() => handleClick()}
              >
                  {fileattach != undefined && fileattach != ''
                        ? 'Uploaded '
                        : 'Upload'}
              </button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChangeFile}
                style={{ display: 'none' }}
                name="fileattach"
                id="fileattach"
                accept="image/jpeg"
              />
               <div className="login-error">{fileattacherror}</div>
                 </div>
              {/* <div className="login-error">{fieldValidations[index].fileattachError}</div> */}
                    {/* <label className="login-text">
                      Upload screenshot &nbsp;&nbsp;&nbsp;
                    </label>

                    <button
                      type="button"
                      className="formboxpayment"
                      onClick={handleClick}
                    >
                      {fileattach != undefined && fileattach != ''
                        ? 'Uploaded '
                        : 'Upload'}
                    </button>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChangeFile}
                      style={{ display: 'none' }}
                      name="fileattach"
                      id="fileattach"
                      accept="image/jpeg"
                    /> */}

                    {/* <input  type="file" class="upload-box padleftpay" id="news" name="news" multiple accept="image/*"   onChange={handleChangeFile}/> */}
               

                     
                      <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-md-12 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="login-text"
                  >
                    Status
                  </label>
                  <select
                    className="project-box"  
                     id="projectstatus"
                     name="projectstatus"
                    //  defaultValue={companyname}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={projectstatus}
                 onChange={handleProjectstatus}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                    <option value="due_for_renewal">Due for Renewal</option>
                    <option value="suspended">Suspended</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="renewed">Renewed</option>
                  </select>
                  <div className="login-error">{projectstatuserror}</div>
                </div>
                    
                <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pt-4  ">
<center>
                        <div class="textareamargin">              

                          <textarea class="form-control" id="message" name="message" rows="3" placeholder='Message' onChange={handlemessage}></textarea>
                        </div>

                        </center>

                      </div>
 

                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                          disabled={!(projectstatusValid && fileattachValid )}
                        >
                          <b>Submit</b>
                        </button>
                      </center>{' '}
                    </div>
                  
                  </form>
                </div>
              
              </div>
            </div>
           
         
        </Modal.Body>
      </Modal>
    </>
  )
}
