import React, { useRef } from 'react';
import { jsPDF } from "jspdf";

const PDFGenerator = () => {
  const pdfRef = useRef();

  const generatePDF = () => {
    // Create a new jsPDF instance
    const pdfDoc = new jsPDF();

    // Add content to the PDF
    pdfDoc.text('User-Entered Data PDF', 20, 10);

    // Get user-entered data from your form or any source
    const userData = {
      name: 'John Doe',
      email: 'john@example.com',
      
      // Add more user-entered data as needed
    };

    // Convert user-entered data to a string
    const userDataString = JSON.stringify(userData, null, 2);

    // Add the user-entered data to the PDF
    pdfDoc.text(userDataString, 20, 20);

    // Save the PDF
    pdfDoc.save('user_data.pdf');
  };

  return (
    <div>
      <button onClick={generatePDF}>Generate PDF</button>
      <div style={{ display: 'none' }}>
        {/* This hidden div is used as a reference for PDF generation */}
        <div ref={pdfRef}>
          <h1>User-Entered </h1>
          {/* Add more HTML content as needed */}
        </div>
      </div>
    </div>
  );
};

export default PDFGenerator;
