import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import './ResourceSearch.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
// import DuedateModel from '../Modal/SetDueDate'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
function FormPage() {

  const [data3, setData3] = useState([]);
  const [successstatus, setSuccessstatus] = useState(false)

  const [date, setDate] = useState('')
  const [dateValid, setDateValid] = useState(false)
  const [dateerror, setDateerror] = useState('')

  const [materialtype, setMaterialtype] = useState('')
  const [materialtypeValid, setMaterialtypeValid] = useState(false)
  const [materialtypeerror, setMaterialtypeerror] = useState('')

  const [status, setStatus] = useState('')
  const [statusValid, setStatusValid] = useState(false)
  const [Statuserror, setStatuserror] = useState('')
  const [material, setMaterial] = useState([])
  const [date1, setDate1] = useState([])
  const [status1, setStatus1] = useState([])
  const [search, setSearch] = useState([])
  const [todate, setTodate] = useState('')
  const [todateValid, setTodateValid] = useState(false)
  const [todateerror, setTodateerror] = useState('')
  const userData = useSelector((state) => state.userDetails)
  const [errormsg, setErrormsg] = useState('')
  

  function convertDateFormat(date) {
    const parts = date.split('-');
    if (parts.length === 3) {
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return date;
}
  useEffect(() => {
    axios
      .get(`${baseurl}/api/project/list/resources&equipments`, {
        params: {
          project_id: userData.currentprojectId,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        const mt = data.materials;
        setData3(mt);
        //console.log('Data received:', response.data); 
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
   

  function handledate(e) {
    //console.log(e.target.value)
    
    setDate(e.target.value)
    setSuccessstatus(false)
    validateField(e.target.name, e.target.value)
  }

  function handlestatus(e) {
    //console.log(e.target.value)
    setStatus(e.target.value)
    setSuccessstatus(false)
    validateField(e.target.name, e.target.value)
  }

  function handlematerialtype(e) {
    setMaterialtype(e.target.value)
    validateField(e.target.name, e.target.value)
    setSuccessstatus(false)
  }

  const validateField = (fieldName, value) => {
    if (fieldName === 'date') {
      //console.log(value)

      if (value.length != 0) {
        setDateerror('')
        setDateValid(true)
      } else {
        setDateerror('Please select Date')
        setDateValid(false)
      }
    }
    if (fieldName === 'todate') {
      //console.log(value)

      if (value.length!= 0) {
        setTodateerror('')
        setTodateValid(true)
      } else {
        setTodateerror('Please select Date')
        setTodateValid(false)
      }
    }
    if (fieldName === 'materialtype') {
      //console.log(value)

      if (value != 0) {
        setMaterialtypeerror('')
        setMaterialtypeValid(true)
      } else {
        setMaterialtypeerror('Please select materialtype')
        setMaterialtypeValid(false)
      }
    }

    if (fieldName === 'status') {
      //console.log(value)

      if (value != 0) {
        setStatuserror('')
        setStatusValid(true)
      } else {
        setStatuserror('Please select Date')
        setStatusValid(false)
      }
    }
  }

  function onSubmitEvent(event) {
    event.preventDefault();
    const convertedDate = convertDateFormat(date);
    const todate1 = convertDateFormat(todate);

        let userData10 = new FormData()
        userData10.append('material_type',materialtype )
        userData10.append('date', convertedDate)
        userData10.append('status', status)
        userData10.append('project_id', userData.currentprojectId)
        

      
        // axios.get(`${baseurl}/api/material/search?material_type=${materialtype}&date=${convertedDate}&status=${status}&project_id=${userData.currentprojectId}`, userData10, {
        //   headers: { 'Content-Type': 'multipart/form-data' },
        // })
    
        if(dateValid && todateValid)
        {
      
        axios({
          method: 'GET',
  
         // url: baseurl+'/api/material/search?material_type='+materialtype+'&date='+convertedDate+'&status='+status+'&project_id='+userData.currentprojectId,
         url: baseurl + '/api/material/search?material_type='+materialtype+
         '&start_date='+convertedDate+'&end_date='+todate1+'&status='+status+'&project_id='+userData.currentprojectId,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + userData.token
          },
          //data:userData10
        })
          .then((response) => {
        
            const res = response.data
            //console.log("response",res)
            setSearch(res)
            setSuccessstatus(true)
            // setMaterialtype("")
            // setDate("")
            // setStatus("")
            setErrormsg('')
        //////alert(res)
              
          })
          .catch((error) => {
         
            //console.log("search error",error)
           
          });
    //  }
  }
  else{
      setErrormsg('Please fill all the mandatory fields')
  }
  }
  
  function handledate1(e) {
    //console.log(e.target.value)
    
    setTodate(e.target.value)
    setSuccessstatus(false)
    validateField(e.target.name, e.target.value)
  }
  return (
    <>
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12   ">
      <div className="row  ">
                    
                    <div className="labels ff pt-5 pb-5 col-xl-12 col-lg-12 col-md-12  ">
                      <center> <b>Resource Data History</b></center>   </div>
                  </div>
        <form>
          <div className="row">
            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
              <label for="formFileLg" className="login-text ">
               From Date <font className="login-error">{"*"}</font>
              </label>
              <center>
                <InputTextField
                  handleInputChange={handledate}
                  valueText={date}
                  inputName="date"
                  inputId="date"
                  classname="project-box"
                  maxLen={20}
                  inputType="date"
                ></InputTextField>

                <div className="login-error">{dateerror}</div>
              </center>
            </div>
            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
              <label for="formFileLg" className="login-text ">
               To Date <font className="login-error">{"*"}</font>
              </label>
              <center>
                <InputTextField
                  handleInputChange={handledate1}
                  valueText={todate}
                  inputName="todate"
                  inputId="todate"
                  classname="project-box" 
                  inputType="date"
                ></InputTextField>

                {/* <div className="login-error">{dateerror}</div> */}
              </center>
            </div>
            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6  pb-3 ">
              <label for="formFileLg" className="login-text">
                Material Type
              </label>
              <center>
                <select
                  className="project-box"
                  id="materialtype"
                  name="materialtype"
                  defaultValue={materialtype}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  value={materialtype}
                  onChange={handlematerialtype}
                >
                  <option>Select</option>
                  {data3.length !== 0 && data3.map((name, index) => (
                        <option key={index} value={name}>
                          {name.replace(/_/g, ' ')}
                        </option>
                      ))}
                </select>

                <div className="login-error"></div>
              </center>
            </div>
         

          {/* <div className="row">
            <div className='col-xl-3'></div> */}
            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6  pb-3 ">
              <label for="formFileLg" className="login-text">
                Status
              </label>
              <center>
                <select
                  className="project-box"
                  id="status"
                  name="status"
                  defaultValue={status}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  value={status}
                  onChange={handlestatus}
                >
                  <option value="Select" selected>
                    Select
                  </option>
                  <option value="Consumed">Consumed</option>
                  <option value="Received">Received</option>
                  <option value="Instock">Instock</option>
                </select>
                <div className="login-error">{Statuserror}</div>
              </center>
            </div>
          {/* </div> */}
          {/* <div className="row"> */}
           
            <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-4">
         
              <center>
              {
                  <div className="login-error pb-3">
{errormsg}
                  </div>
                }
                <button
                  type="submit"
                  className="project-btn "
                  name="submit"
                  onClick={onSubmitEvent}
                >
                  <b>Search</b>
                </button>

              </center>{' '}
            </div>
            </div>
          {/* </div> */}
        </form>
        <div>
            <div class="row">

           <div class="col-lg-1 col-xl-1"></div>
           <div class="col-xl-10 col-lg-10">

           {successstatus && search.length == 0 &&
            // !checkduedate &&
            <div className='row '>
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error">
                <center><b><h6>No Data Available</h6></b></center>
                </div>
                </div>}
           {search.length != 0 &&  <table
                  class="table"
                  readOnly="readOnly"
                >
                  <tr class="trst">
                    <th><center>Date</center></th>
                    <th><center>Material Type</center></th>
                    <th><center>Status</center></th>
                    <th><center> Quantity</center></th>
                    <th><center> Instock</center></th>
                  </tr>

                  {
                    search.map((item, index) => {
                      return (
                        <tr key={index} className='tablefontsize'>
                          <td><center>{item.date!=undefined?item.date:'NA'}</center> </td>
                          <td><center>{item.material!=undefined?item.material:'NA'}</center> </td>
                          <td><center>{item.status!=undefined?item.status:'NA'}</center> </td>
                          <td><center>{item.quantity!=undefined?item.quantity:'NA'}</center> </td>
                          <td>{item.total_quantity_instock!=undefined && item.total_quantity_instock!=null ? item.total_quantity_instock:'NA'}</td>
                        </tr>
                      )
                    })}
                </table>}
                </div>
                <div class="col-lg-1 col-xl-1"></div>
                </div>
          
        </div>
      </div>
    </>
  )
}

export default FormPage;
