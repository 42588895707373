import { Outlet, Link } from "react-router-dom";
import React from "react";
import { useState, useEffect } from "react";
import "./NewLogin.css";
// import { Navigate } from 'react-router-dom'
import InputTextField from "../InputText/InputText";
import mappic from "../../assets/images/map1.jpg";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateLoginStatus,
  
} from "../../store/userDetails";
import { baseurl } from "../BaseUrl/Baseurl";
import ButtonLink from "../ButtonLink/ButtonLink";
import axios from "axios";
import profile from "../../assets/images/profile.png";
import loginbg from "../../assets/brickdemoicons/loginbg.png";
import bclogo from "../../assets/brickdemoicons/brick_logo_f.png";
import tick from "../../assets/brickdemoicons/tick-icon.png";
import CustomToastContainer from "../ErrorToast/CustomToast";

function Login() {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [emailerror, setEmailerror] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [passworderror, setPassworderror] = useState("");
  const [isLoggedIn, updateLogin] = useState("");
  const [successstatus, setSuccessstatus] = useState("");
  const [uname, setUname] = useState("");
  const [userid, setUserid] = useState("");
  const [projectid, setProjectid] = useState("");
  const [role, setRole] = useState("");
  const [token, setToken] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [clientid, setClientid] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [headerMenus, setHeaderMenus] = useState("");
  const [projectMenus, setProjectMenus] = useState("");
  const [messageList, setMessageList] = useState("");
  const[loginuname,setLoginuname] = useState('')
  const[statename,setStatename] = useState('')
  const[circle,setCircle ]= useState('')
  const[division,setDivision] = useState('')
  const[subdivision,setSubdivision] = useState('')


    const[searchdata,setSearchdata] = useState([])
  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();

  const [id, setId] = React.useState("");
  const [status, setStatus] = useState(false);
  const [projecttype, setProjecttype] = React.useState("");

  const headMenusample = [
    "Dashboard",
    "Projects",
    "Vendor",
    "Subcontractor",
    "Support",
    "Subscription",
  ];

  const projectsamplemenu = [
    "Project Timeline",
    "QS",
    "Work Reference",
    "Project Progress Details",
    "Sub Contractor",
    "Resource",
    "Date Wise Work Progress",
    "Site Updates",
    "Gallery",
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    if (successstatus) {  
      
      //////alert(userid)
      dispatch(
        updateLoginStatus({
          isLoggedIn: true,
          userName:uname,
          userId:userid,
          role:role,
          clientId:clientid,
          companyName:companyname,
          token:token,
          profileImage:profileImg,
          Pagename:headerMenus[0],
          loginUsername:loginuname,
          //Backend
          headerMenus:headerMenus,
          projectMenus:projectMenus,
          projectsubname:'Dashboard',
          searchParams:searchdata,
          engineerLoginStatus:false,
          statename:statename,
          circle:circle,
          division:division,
          subdivision:subdivision

          //sample
          // headerMenus:headMenusample,
          // projectMenus:projectsamplemenu,
        
        })
      )
    }
  }, [successstatus])   

  // useEffect(()=>{
   
  // },[])

  const togglePasswordVisiblity = (event) => {
    setIsPasswordShown(!isPasswordShown);
    event.preventDefault();
  };
  function onpasswordChange(e) {
    setPassword(e.target.value.replace(/\s+/g, ""));
   
    validateField("password", e.target.value);
    // validateField("password", e.target.value);
  }
  function onemailChange(e) {
    setEmail(e.target.value.replace(/\s+/g, ""));
    validateField("email", e.target.value);
    // validateField("email", e.target.value);
  }

  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})"
  );

  const validateField = (fieldName, value) => {
    // //////alert(fieldName)
    // //////alert(value)

    if (fieldName === "password") {

      console.log(messageList)
setMessageList("")
      let passwordValid = value != "" && value.match(strongRegex);
    

      setPasswordValid(passwordValid);
      console.log(messageList)
      if (
        passwordValid === null ||
        passwordValid === undefined 
        
      ) {
        // const pwd_err = "Password should be more than or equal to 6 character and it should contain lowercase,uppercase,numeric and special character"

        const pwd_err =
          "Password must be 6+ chars, incl. upper, lower, numeric and special";
       // setPassworderror(pwd_err);
        setPasswordValid(false);
        setMessageList(pwd_err)
        
      } else {
        setPassworderror("");
        setPasswordValid(true);
        setMessageList("");
      }
    }

    if (fieldName === "email") {
      setMessageList("")
    
      if (value.length == 0 || value === "") {
        const un_err = "Username should be valid";

       // setEmailerror(un_err);
        setEmailValid(false);
        setMessageList(un_err);
      } else {
        setEmailerror("");
        setEmailValid(true);
        setMessageList("");
      }
    }
  };
  function onSubmitEvent(event, email, password) {


    event.preventDefault();
    
   
    if (emailValid && passwordValid) {

   
      ////console.log(email);
      ////console.log(password);

      let userData = new FormData();
      userData.append("username", email);
      userData.append("password", password);

      axios({
        method: "post",

        url: baseurl + "/api/login",

        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: userData,
      })
        .then((response) => {
         // console.log("Hello Login" + response.data);
          const data = response.data;
        // console.log("Hello" + JSON.stringify(data));
          // setUname(data.name)
          setUserid(data.userid);
          setToken(data.token);
          setRole(data.role);
          setProjectid(data.project_id);
          setClientid(data.client_id);
          setCompanyname(data.client_company_name);
          setHeaderMenus(data.default_main_menu);
          setSearchdata(data.project_search_params)
          setLoginuname(data.username)
setCircle(data.circle)
setStatename(data.state)
setDivision(data.division)
setSubdivision(data.sub_division)
          // setProjectMenus(data.sub_menu)
// console.log(data.sub_division );

          const uname = data.name;
          let st = false;
          const spacesCount = uname.split(" ").length - 1;

          // ////////////////////////////////console.log(spacesCount);
          if (parseInt(spacesCount) != 0) {
            var stname = uname.substring(0, uname.indexOf(" "));
            if (stname.length <= 10) {
              setUname(stname);
              st = true;
            } else {
              setUname(uname.substring(0, 10));
              st = true;
            }
          }
          if (!st) {
            setUname(uname.substring(0, 10));
          }

          ////console.log("Spacecount:", spacesCount);
          ////console.log("uname:", uname);
          ////console.log("uname length:", uname.length);
          const unameTrimmed = uname.trim();
          ////console.log("Trimmed uname:", unameTrimmed);
          ////console.log("Trimmed uname length:", unameTrimmed.length);

          // //////alert(data.profile_image)
          if (
            data.profile_image == undefined ||
            data.profile_image == null ||
            data.profile_image == ""
          ) {
            setProfileImg(profile);
          } else {
            setProfileImg(data.profile_image);
          }

          // //////alert(data.client_company_name)
          setSuccessstatus(true);
          // //////alert(data.project_id)
          // //////alert(data.client_id)
        })
        .catch((error) => {
          ////console.log(error);
          ////console.log(error.response.data.message);
          setErrormsg(error.response.data.message);
      
        });
    }
  }
  return (
    <>
      <div
        className="vh-100 d-flex align-items-center "
        style={{ backgroundImage: `url(${loginbg})`, backgroundSize: "cover" }}>

        {(successstatus && userData.role != 'Contractor' && userData.role != undefined && userData.role != null && userData.role != '') 
            ? <ButtonLink btnPath={"/" + headerMenus[0]} /> : 
              (
                (successstatus && userData.role == 'Contractor')  
                    ?  <ButtonLink btnPath={"/ContractorProjectList" }/> : ''
                )}

        <div class="p-5 container bg-white " style={{ borderRadius: "10px" }}>
          <div className="row ">
            <div className="col-12 col-md-6 mx-auto">
              <div className="mr-auto">
                <img src={bclogo} width={200} />
                <p
                  className="mt-3"
                  style={{
                    color: "#002874",
                    fontFamily: "plus jakarta sans",
                    fontWeight: "bold",
                    fontSize: "30px",
                  }}
                >
                  Avoid delays & Save
                  <br />
                  upto 7% on project costs
                </p>

                <ul className="mt-5">
                  <li
                    style={{
                      fontWeight: "500",
                      fontFamily: "plus jakarta sans",
                      fontSize: "14px",
                    }}
                  >
                    <img src={tick} width={40} /> Get better site visibility of
                    your all construction projects
                  </li>{" "}
                  <br />
                  <li
                    style={{
                      fontWeight: "500",
                      fontFamily: "plus jakarta sans",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    <img src={tick} width={40} />
                    Streamline your labour management process
                  </li>{" "}
                  <br />
                  <li
                    style={{
                      fontWeight: "500",
                      fontFamily: "plus jakarta sans",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    <img src={tick} width={40} />
                    Efficient material management
                  </li>
                </ul>
              </div>
            </div>

            <div className=" col-12 col-md-6 ">
              <div
                className=" mx-auto b1 p-5 "
                style={{ borderRadius: "10px" }}
              >
                <div className="">
                  <h6
                    className="text-center"
                    style={{
                      fontFamily: "plus jakarta sans",
                      fontWeight: "600",
                    }}
                  >
                    Login into account
                  </h6>
                  <h6
                    className="text-center"
                    style={{
                      fontFamily: "plus jakarta sans",
                      fontWeight: "500",
                    }}
                  >
                    Use your credentials to access your account
                  </h6>
                </div>
                <br />

                <input
                  className="form-control btn1  bg-transparent  "
                  value={email}
                  onChange={onemailChange}
                  style={{
                    border: "1px solid #C0C0C0",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "15px",
                    borderRadius: "6px",
                    fontFamily: "plus jakarta sans",
                    height: "40px",
                  }}
                  type="text"
                  placeholder="Username"
                />
                <div className="login-error" style={{ fontSize: "12px" }}>
                  {emailerror}
                  <br />
                </div>

                <div
                  class="input-group mt-4 "
                  style={{ borderRadius: "6px", border: "1px solid #C0C0C0" }}
                >
                  <input
                    className="form-control btn1  border-0 bg-transparent  "
                    value={password}
                    onChange={onpasswordChange}
                    type={isPasswordShown ? "text" : "password"}
                    style={{
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "15px",
                      fontFamily: "plus jakarta sans",
                      height: "40px",
                    }}
                    placeholder="Password"
                  />
                  <div class="input-group-prepend">
                    <div
                      class="input-group-text border-0 bg-transparent"
                      id="basic-addon1"
                    >
                      {" "}
                      {isPasswordShown ? (
                        <AiFillEye
                          className=""
                          onClick={togglePasswordVisiblity}
                          size={20}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className=""
                          onClick={togglePasswordVisiblity}
                          size={20}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="login-error" style={{ fontSize: "12px" }}>
                  {passworderror}
                  <br />
                </div>

                <div className="text-center">
                  <CustomToastContainer messageList={messageList} />

                  <button
                    className="btn btn-sm mt-4 w-25 p-2  mx-auto  text-white"
                    style={{
                      background: "linear-gradient(to right,#0ECD67,#00A151)",
                      borderRadius: "8px",
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                    }}
                    onClick={(event) => onSubmitEvent(event, email, password)}
                  >
                    LOGIN{" "}
                  </button>
                </div>
                <br />

                {/* <div >  <center>
                             <a href="/" className="forgot"style={{fontFamily:'plus jakarta sans',
    fontWeight:'500',fontSize:'12px' }}>
                                 Forgot password?
                             </a>
                         </center> <center style={{fontFamily:'plus jakarta sans',
    fontWeight:'500',fontSize:'12px' }}>
           If you don't have an account? <a href="/Registration" class="login-lnk " >
              <u> Register Now</u>
             </a>
            
           </center></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
