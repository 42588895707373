import "./SidebarwithDropdownNew.css";
import phone from "../../assets/images/Icons_and_images/Phone.png";
import { Link } from "react-router-dom";
import dashboard from "../../assets/images1/dashboardwhite.png";
import dashboardhover from "../../assets/images1/dashboardblue.png";
import project from "../../assets/images1/projectwhite.png";
import projecthover from "../../assets/images1/projectblue.png";
import contractor from "../../assets/images1/contractorwhite1.png";
import contractorhover from "../../assets/images1/contractorblue.png";
import inspectionschedule from "../../assets/images1/supportwhite.png";
import inspectionschedulehover from "../../assets/images1/supportblue.png";
import subscription from "../../assets/images1/subscriptionwhite1.png";
import subscriptionhover from "../../assets/images1/subscriptionblue.png";
import db from "../../assets/brickdemoicons/dashboard1.png";
import cd from "../../assets/brickdemoicons/dashboard.png";
import location from "../../assets/brickdemoicons/Vector-3.png";
import wd from "../../assets/brickdemoicons/Vector-2.png";
import reports from "../../assets/brickdemoicons/Vector-1.png";
import login from "../../assets/brickdemoicons/Vector.png";
import db1 from "../../assets/brickdemoicons/dashboardactive.png";
import cd1 from "../../assets/brickdemoicons/contractactive.png";
import location1 from "../../assets/brickdemoicons/locationactive.png";
import wd1 from "../../assets/brickdemoicons/workde.png";
import reports1 from "../../assets/brickdemoicons/workdetailsactive.png";
import login1 from "../../assets/brickdemoicons/engineerlogin.png";
import wm from "../../assets/brickdemoicons/wmicon.png";
import wm1 from "../../assets/brickdemoicons/wmincon1.png";
import rp from "../../assets/brickdemoicons/reportsicon.png";
import rp1 from "../../assets/brickdemoicons/reporticon1.png";

import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import { useState, useEffect } from "react";
import logo1 from "../../assets/images/brick_icons/Logo.png";
import {
  FaPlus,
  FaEdit,
  FaStarAndCrescent,
  FaBook,
  FaSuitcase,
  FaStepBackward,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { Accordion } from "react-bootstrap";

export default function Header() {
  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const [linkname, setLinkname] = useState("");
  const [headMenuList, setHeadMenuList] = useState([]);
  const [submenulist, setSubmenulist] = useState([]);
  const [activeMenu, setActiveMenu] = useState("");
  const [activeSubmenu, setActiveSubmenu] = useState(userData.projectsubname);
  const [dropdownstatus, setDropdownstatus] = useState(false);
  const [dropdownstatus1, setDropdownstatus1] = useState(false);
  const [accordionKey, setAccordionKey] = useState(null);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    // Dynamic Menu

   
    setHeadMenuList(userData.headerMenus);
    // ////alert(userData.projectsubname)
    setSubmenulist(userData.projectMenus);
  }, [userData.projectMenus, userData.dropdownViewStatus,]);

  useEffect(()=>
  {
    
    setActiveMenu(userData.headerMenus[0]);
  },[])

   
 


  useEffect(() => {
    if (userData.dropdownViewStatus && submenulist.length !== 0) {
      setAccordionKey("0"); // This will open the Accordion
    }
  }, [userData.dropdownViewStatus, submenulist]);

  useEffect(() => {
  
  }, [activeMenu]);
  function updatelinkname(menuname) {

    
    setActiveMenu(menuname);
 
    if (menuname === "Projects") {
      setDropdownstatus(true);
      setDropdownstatus1(true);
      setAccordionKey("0");
    } else {
      setDropdownstatus(false);
      setDropdownstatus1(false);
      setAccordionKey(null); // Close the Accordion when navigating to other links
    }
  
    if (menuname === "Subscription" || menuname === "Inspection_Schedule") {
      setDropdownstatus1(false);
    }
  
    // Page name update
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: menuname,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: userData.projectId,
        clientId: userData.clientId,
        profileImage: userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: userData.projectMenus,
        sidebartoggleStatus: toggle,
        companyName: userData.companyName,
        currentprojectId: userData.currentprojectId,
        projectType: userData.projectType,
        projectName: userData.projectName,
        projectMenuIndex: 0,
        dropdownViewStatus: menuname === "Projects",
        projectStatus:userData.projectStatus,
        projectsubname:userData.projectsubname,
        chainageArr:userData.chainageArr,
        loginUsername:userData.loginUsername,
        searchParams:userData.searchParams,
        statename:userData.statename,
        circle:userData.circle,
        division:userData.division,
        subdivision:userData.subdivision
      })
    );
  }
  

  function updatesubmenuname(menuname) {
    setActiveSubmenu(menuname);
    setDropdownstatus(true);
    setDropdownstatus1(true);
  
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: userData.Pagename,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: userData.projectId,
        clientId: userData.clientId,
        profileImage: userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: userData.projectMenus,
        sidebartoggleStatus: toggle,
        companyName: userData.companyName,
        currentprojectId: userData.currentprojectId,
        projectType: userData.projectType,
        projectName: userData.projectName,
        projectMenuIndex: 0,
        dropdownViewStatus: menuname === "Projects",
        projectStatus:userData.projectStatus,
        projectsubname:menuname,
        chainageArr:userData.chainageArr,
        loginUsername:userData.loginUsername,
        searchParams:userData.searchParams,
        statename:userData.statename,
        circle:userData.circle,
        division:userData.division,
        subdivision:userData.subdivision
      })
    );
  }
 useEffect(()=>{

 setActiveSubmenu(userData.projectsubname)
},[userData.projectsubname])





  function Sidebarview() {
    setToggle(!toggle);
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: userData.Pagename,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: userData.projectId,
        clientId: userData.clientId,
        profileImage: userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: userData.projectMenus,
        sidebartoggleStatus: !toggle,
        sidebarReportsMenus: userData.sidebarReportsMenus,
        sidebarWorkDetailsMenus: userData.sidebarWorkDetailsMenus,
        projectName: userData.projectName,
        projectStatus:userData.projectStatus,
        projectsubname:userData.projectsubname,
        chainageArr:userData.chainageArr,
        loginUsername:userData.loginUsername,
        searchParams:userData.searchParams,
        statename:userData.statename,
        circle:userData.circle,
        division:userData.division,
        subdivision:userData.subdivision
      })
    );
  }

  function changestatus() {
    setDropdownstatus(!dropdownstatus);
    setDropdownstatus1(!dropdownstatus1);
    setAccordionKey(dropdownstatus ? null : "0");
  }

  const images = {
    Dashboard: { normal: dashboard, hover: dashboardhover },
    Projects: { normal: project, hover: projecthover },
    Contractor: { normal: contractor, hover: contractorhover },
    Inspection_Schedule: {
      normal: inspectionschedule,
      hover: inspectionschedulehover,
    },
    Subscription: { normal: subscription, hover: subscriptionhover },
    Reports: {normal:rp,hover:rp1}
   
  };

  const images1 = {
    Dashboard: { normal: db, hover: db1 },
    Contract_Details: { normal: cd, hover: cd1 },
    Location: { normal: location, hover: location1 },
    Work_Details: { normal: wd, hover: wd1 },
    Reports: { normal: reports, hover: reports1 },
    Engineer_Login: { normal: login, hover: login1 },
    Work_Master:{normal:wm1,hover:wm}
  };

  return (
    <div className={`newsidebar ${toggle ? "minsidebar" : ""} shadow-lg`}>
      {!toggle && (
        <div>
          {/* <button
            type="button"
            className="close text-white"
            onClick={Sidebarview}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
          <ul className="sidebartop">
            {headMenuList.length !== 0 &&
              headMenuList.map((menu, index) => (
                <li
                  key={index}
                  className={
                    (dropdownstatus &&
                      index === 0 &&
                      dropdownstatus1 &&
                      submenulist.length !== 0) ||
                    (userData.dropdownViewStatus &&
                      submenulist.length !== 0 &&
                      menu === "Projects")
                      ? "submenuheight"
                      : ""
                  }
                >
                  <div
                    className={
                      menu === userData.Pagename
                        ? "linkclickcolor"
                        : "linkclickcolor1"
                    }
                  >
                   
                      <div className="row hi">
                        <div className="col-xl-1 col-lg-1 col-md-1 pl-4">
                        {userData.projectName!='' && menu==="Projects" ?
                        
                        <img className="ml-3  " style={{width:'26px'}}
                            src={
                              
                                 menu === activeMenu
                                 
                                  ? images[menu].hover
                                  : images[menu].normal
                                
                            }
                            alt={menu}
                          /> :
                        (userData.projectName==="" || userData.projectName==undefined || userData.projectName==null) ?     <img className="ml-3  " style={{width:'26px'}}
                            src={
                              
                                 menu === activeMenu
                                 
                                  ? images[menu].hover
                                  : images[menu].normal
                                
                            }
                            alt={menu}
                          />:  ''}
                        </div>
                        {menu !== "Projects" && (
                          <div className="col-xl-1 col-lg-1 col-md-1"></div>
                        )}

                        <div className="col-xl-8 col-lg-8 col-md-8 w-100  pl-0">
                          {menu === "Projects" && (
                            <Accordion
                              className="w-100"
                              activeKey={accordionKey}
                            >
                              <Accordion.Item
                                eventKey="0"
                                className=""
                                style={{
                                  width: "180px",
                                  position: "relative",
                                  zIndex: "99",
                                }}
                              >
                                 <Link
                      to={"/" + menu.replace(/\s+/g, "")}
                      onClick={() => updatelinkname(menu)}
                      className="Linkeffect"
                    >  
                                <Accordion.Button
                                  className={
                                    menu === userData.Pagename
                                      ? "textonclickcolor w-100 bg-transparent p-0"
                                      : "textonclickcolor1 w-100 bg-transparent p-0"
                                  }
                                  //onClick={(e) => changestatus(e)}
                                >
                                  <div className={userData.projectName!=''&&userData.projectName!=undefined &&   userData.projectName!=null ?'':"pr-5"}  style={{
                                    
                                    
                                    fontSize:'15px',fontWeight:'600'      }}>
                                    {/* {menu.replace(/_/g, "\u00A0")} */}
                                     {userData.projectName!=''&& userData.projectName!=undefined &&
                                    
                                    userData.projectName!=null ?'Switch Project':menu.replace(/_/g, "\u00A0")}
                                  </div>
                                </Accordion.Button></Link>
                                {(dropdownstatus && dropdownstatus1) ||
                                userData.dropdownViewStatus ? (
                                  <Accordion.Body
                                    className="mt-2 pb-1 bg-white rounded submenucolor" 
                                    style={{ width: "190px" }}
                                  >
                                   {submenulist.length !== 0 &&
    submenulist.map((item, index) => (
      <li key={item} className="nav-item mb-2 d-flex align-items-center">
        <Link
          to={"/" + item.replace(/\s+/g, "")}
          onClick={() => updatesubmenuname(item)}
          className="w-100 d-flex align-items-center"
        >
          <img
            className="ml-1"
            style={{ width: '15px' }}
            src={
              images1[item]
                ? item === activeSubmenu
                  ? images1[item].hover
                  : images1[item].normal
                : dashboard
            }
            alt={item}
          />
          &nbsp;&nbsp;
          <font
            className={
              item === activeSubmenu
                ? "sublinkcoloractive"
                : "sublinkcolor"
            }
          >
            {item.replace(/_/g, "\u00A0")}
          </font>
        </Link>
      </li>
    ))}
                                  </Accordion.Body>
                                ) : null}
                              </Accordion.Item>
                            </Accordion>
                          )}
                          {menu !== "Projects" && (userData.projectName==="" || userData.projectName==undefined || userData.projectName==null) &&
                          (
                            <Link
                      to={"/" + menu.replace(/\s+/g, "")}
                      onClick={() => updatelinkname(menu)}
                      className="Linkeffect"
                    > <div
                              className={
                                menu === userData.Pagename
                                  ? "textonclickcolor  w-100  pl-0 pt-0"
                                  : "textonclickcolor1 w-100  pl-0 pt"
                              }
                            >
                           <div>{menu.replace(/_/g, "\u00A0")}</div> 
                            </div></Link>
                          )}
                        </div>
                      </div>
                   
                  </div>
                </li>
              ))}
          </ul>
        </div>
      )}

      {/* {toggle && (
        <div className="minsidebar">
          <div className="text-center">
            <button className="btn btn-lg btn-ham" onClick={Sidebarview}>
              <GiHamburgerMenu color="white" />
            </button>
          </div>
          <ul className="text-center">
            {headMenuList.length !== 0 &&
              headMenuList.map((menu, index) => (
                <li key={index}>
                  <div
                    className={
                      menu === userData.Pagename
                        ? "linkclickcolor"
                        : "linkclickcolor1"
                    }
                  >
                    <center>
                      <Link
                        to={"/" + menu.replace(/\s+/g, "")}
                        onClick={() => updatelinkname(menu)}
                        className="Linkeffect"
                      >
                                    {userData.projectName!='' && menu==="Projects" ?
                        
                        <img className="ml-3  " style={{width:'26px'}}
                            src={
                              
                                 menu === activeMenu
                                 
                                  ? images[menu].hover
                                  : images[menu].normal
                                
                            }
                            alt={menu}
                          /> :
                        (userData.projectName==="" || userData.projectName==undefined || userData.projectName==null) ?     <img className="ml-3  " style={{width:'26px'}}
                            src={
                              
                                 menu === activeMenu
                                 
                                  ? images[menu].hover
                                  : images[menu].normal
                                
                            }
                            alt={menu}
                          />:  ''}
                      </Link>
                    </center>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      )} */}
    </div>
  );
}
