import React from 'react';
import RadialBar from '../ApexCharts/RadialBar';
import './Support.css';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from 'react'
import RequestModal from '../Modal/CreateRequest'
import StatusupdateModal from '../Modal/Edit'
import Renewal from '../Modal/Renewal'
import ViewTickets from '../Modal/ViewTickets'
import { baseurl } from '../BaseUrl/Baseurl'

import axios from 'axios'
import close from '../../assets/images/images/closed.png'
import open from '../../assets/images/images/open.png'
import $ from 'jquery';
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'

const WeareHere = () => {
  const [btnname, setBtnname] = useState('')
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [project_renewalid, setProject_renewalid] = useState('');
  const [ticketno, setTicketno] = useState('');
  const [successstatus, setSuccessstatus] = useState(false);
  const userData = useSelector((state) => state.userDetails)
                              
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
 
    axios({
      method: 'GET',

      url: baseurl+'/api/support/issue/ticket/list',

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token

      }
     
    })
      .then((response) => {
        //console.log(response.data)
        const TicketData = response.data
        setTicketlist(TicketData)
        setSuccessstatus(true)
      })
      .catch((error) => {
        //console.log(error)
      })

  }, [modelStatus, updatestatus])

  const toggleModelStatus = () => {

    setRequeststatus(false)
  };
  const toggleModelStatus1 = () => {

    setUpdatestatus(false)

  };
  const toggleRenewalStatus = () => {
    //console.log("***********")
    setRenewalstatus(false)
  };
  const toggleViewTicketStatus = () => {
    //console.log("***********")
    setViewticketstatus(false)
  };
  const requestdata = () => {
    //console.log("*****" + requeststatus)
    setRequeststatus(true)
  }
  const Editdata = (id) => {
    setProjectid(id)
    setUpdatestatus(true)
  }
  const RenewalUpdate = (id) => {
    setProject_renewalid(id)
    setRenewalstatus(true)
  }
  const ViewticketstatusUpdate = (id) => {
    setTicketno(id)
    setViewticketstatus(true)
  }
  
  return (

          <div class="cont-margin1  ">

 <div className='cont-margin-latest pt-5 '>
          <div class="table1bdstyle">
          <div class="row table1st pt-5">
          <div class=" col-xl-4 pt-5 pb-1 ">
              <div className='padheadleft ff'>  <b>Inspection Schedule</b></div>
              </div>
              <div class=" col-xl-4 pt-1 pb-1">
              
              </div>
              <div class=" col-xl-3 pt-1 pb-1 ">
            <Link to="/SupportCreation"> 
            <button 
                    type="button"
                    className="createpro-btn"
                  >
                   &nbsp;Create Ticket</button>
            </Link> 
              </div>
              { ticketlist.length == 0 && successstatus &&
            // !checkduedate &&
           
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3 pt-3">
                <center><b><h6>No Data Available</h6></b></center>
              
                </div>}
                {ticketlist.length != 0 &&
            <table
              class="table tablemargin"
              readOnly="readOnly"

            >
             {/* <div class=" col-xl-12 pb-3 pt-0 pb-0 ">
            <Link to="/SupportCreation"> 
            <button 
                    type="button"
                    className="createpro-btn0"
                  >
                   &nbsp;Create</button>
            </Link> 
              </div> */}
              <tr class="trst">
                <th><div 
                // className='trpad'
                ><center>Project ID</center></div></th>
                {/* <th><center>Contact</center></th> */}
                <th><center>Ticket Number</center></th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;Status</th>
                <th><center>Tickets</center></th>
              
              </tr>
              {viewticketstatus &&
                <ViewTickets status={viewticketstatus} btnClick={toggleViewTicketStatus} TicketNumber={ticketno}/>}
           {ticketlist.length != 0 && ticketlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>

                    <td><div
                    //  className='trpad'
                     ><center>{item.project_id!=null && item.project_id!=undefined ?item.project_id:'NA' }</center></div></td>
                    {/* <td><center>{item.contact_person}</center></td> */}
                    <td><center>{item.ticket_number}</center></td>
                    {item.request_status === "close" ? 
                  <img src={close}  className='leftimg23'/>: 

                       <img src={open}  className='leftimg23'/>} 
                    <td>  <center><button
                      type="button"
                      className="viewtic-btn1"
                      onClick={() => ViewticketstatusUpdate(item.ticket_number)}
                    >
                      <b>View</b>
                    </button></center></td>

                  </tr>
                )
              })}
            </table>
}
            </div>
          </div>
      
        </div>
      </div>



  );
}




export default WeareHere;