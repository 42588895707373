import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import './Tablecss.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
// import DuedateModel from '../Modal/SetDueDate'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
function FormPage() {



const data={
  "chainages": [
      "1/0 - 2/03",
      "2/03 - 4/05",
      "4/05 - 4/5",
      "4/5 - 5/05",
      "5/05 - 5/625",
      "5/625 - 5/825",
      "5/825 - 6"
  ],
  "resources": [
     
      "CEMENT - PPC",
      "CEMENT - OPC",
      "SAND",
      "M-SAND",
      "P-SAND",
      "STONE DUST",
      "8MM AGGREGATE",
      "12MM AGGREGATE",
      "20MM AGGREGATE",
      "40MM AGGREGATE",
      "STEEL - 8MM",
      "STEEL - 10MM",
      "STEEL - 12MM",
      "STEEL - 16MM",
      "STEEL - 20MM",
      "STEEL - 25MM",
      "STEEL - 32MM",
      "STEEL - 36MM",
      "GRAVEL",
      "GSB",
      "WMM",
      "DBM",
      "BITUMEN",
      "PRIME COAT",
      "TACK COAT",
      "PAVER BLOCK",
      "DIESEL"
  ]
}

const chainage=[
  "1/0 - 2/03",
  "2/03 - 4/05",
  "4/05 - 4/5",
  "4/5 - 5/05",
  "5/05 - 5/625",
  "5/625 - 5/825",
  "5/825 - 6"
]

const materials=data.resources
  
const sampleArray = [
  { key: 'Material-A', arrays: [['A1', 'A2', 'A3','A1', 'A2', 'A3',], ['A4', 'A5', 'A6','A1', 'A2', 'A3',], ['A7', 'A8', 'A9','A1', 'A2', 'A3',]] },
  { key: 'Material-B', arrays: [['B1', 'B2', 'B3','A1', 'A2', 'A3',], ['B4', 'B5', 'B6','A1', 'A2', 'A3',], ['B7', 'B8', 'B9','A1', 'A2', 'A3',]] },
  { key: 'Material-C', arrays: [['C1', 'C2', 'C3','A1', 'A2', 'A3',], ['C4', 'C5', 'C6','A1', 'A2', 'A3',], ['C7', 'C8', 'C9','A1', 'A2', 'A3',]] },
];

console.log(sampleArray);


  return (
    <>
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 ">
      <div className="row  ">
      <div className="labels ff pt-5 pb-5 col-xl-12 col-lg-12 col-md-12  ">
                      <center> <b>Resource Data History</b></center>   </div>
                  </div>
      
        <div>
            <div class="row">

           <div class="col-lg-1 col-xl-1"></div>
           <div class="col-xl-10 col-lg-10">
        <div className='divsmallsize'>
        <table id="tablefixed1" readOnly="readOnly">
  <tr class="trst">
    <th>Materials</th>
    {chainage.map((type,index)=>
    {
      return(
<th key={index}><center>{type}</center></th>
      )
    })}

  </tr>
  {/* {materials.map((type,index)=>
    {
      
      return(
        <>
      
  <tr>
    <td rowspan="3" className='bborder'>{type}</td>
    <td >Smith</td>
    <td  >Smith</td>
    <td > Smith</td>
    <td >Smith</td>
    <td >Smith</td>
    <td >Smith</td>
    <td >Smith</td>
  </tr>
  <tr>
    <td >Eve</td>
    <td >Smith</td>
    <td >Smith</td>
    <td >Smith</td>
    <td >Smith</td>
    <td >Smith</td>
    <td >Smith</td>
  </tr>
    <tr>
    <td >hello</td>
    <td >Smith</td>
    <td >Smith</td>
    <td >Smith</td>
    <td >Smith</td>
    <td >Smith</td>
    <td >Smith</td>
  </tr>


  </>)

  
    })
  } */}

{sampleArray.map((type, index) => {
  const { key, arrays } = type;

  return (
    <React.Fragment key={index}>
      {arrays.map((arr, i) => (
        <tr key={`${key}_${i}`}>
          {i === 0 && <td rowSpan="3" className={i==0 ?'bborder':''}><b>{key}</b></td>}
          {arr.map((value, j) => (
            <td key={`${key}_${i}_${j}`} >{value}</td>
          ))}
        </tr>
      ))}
    </React.Fragment>
  );
})}

</table>





                </div>
                </div>
                <div class="col-lg-1 col-xl-1"></div>
                </div>
          
        </div>
      </div>
    </>
  )
}

export default FormPage;
