import React from "react";
import RadialBar from "../ApexCharts/RadialBar";
import { useState, useEffect } from "react";
import Search from "../RoadProgressSearch/Search1";
import MultiBarGraph from "../ApexCharts/Multibarchart";
import Multibarchart2 from "../ApexCharts/RoadQuantityGraph";
import LineChart2 from "../ApexCharts/LineChart2";
import $ from "jquery";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import { baseurl } from "../BaseUrl/Baseurl";
// import Multilinegraph from '../ApexCharts/Chainage3'
import Multilinegraph1 from "../ApexCharts/Chainage4";
import Multilinegraph2 from "../ApexCharts/LevelWiseChainge";
import SingleBar1 from "../ApexCharts/SingleBarchart1";
import { ThreeDots } from "react-loader-spinner";

const WeareHere = () => {
  const [radialData, setRadialData] = useState("");
  const [fulldatas, setFulldatas] = useState([]);
  const [btnname, setBtnname] = useState("");
  const [level, setlevel] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [listOfLevels, setListOfLevels] = useState([]);
  const [structureType, setStructureType] = useState([]);
  const [structureCompleted, setStructureCompleted] = useState([]);
  const [structurePending, setStructurePending] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [zaxis, setZaxis] = useState([]);
  const [caxis, setCaxis] = useState([]);
  const [daxis, setDaxis] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState("");
  const [graphform, setGraphform] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const userData = useSelector((state) => state.userDetails);
  const [tableData, setTableData] = useState([]);
  const drop = React.useRef(null);
  const [completedprogress, setCompletedprogress] = React.useState("");
  const [currentlevel, setCurrentLevel] = React.useState("");
  const [itemofworklist, setItemofworklist] = React.useState([]);
  const [quantityused, setQuantityused] = useState([]);
  const [quantitypend, setQuantitypend] = useState([]);
  const [xaisdata, setXaxisdata] = useState([]);
  const [yaxisdata, setYaxisdata] = useState([]);
  const [structurelist, setStructurelist] = useState([]);
  const [xaisdataps, setXaxisdataps] = useState([]);
  const [yaxisdataps, setYaxisdataps] = useState([]);
  const [structurelistps, setStructurelistps] = useState([]);
  const [xaisdatalevelwise, setXaxisdatalevelwise] = useState([]);
  const [yaxisdatalevelwise, setYaxisdatalevelwise] = useState([]);
  const [structurelistlevelwise, setStructurelistlevelwise] = useState([]);
  const [xaisdatafoot, setXaxisdatafoot] = useState([]);
  const [yaxisdatafoot, setYaxisdatafoot] = useState([]);
  const [structurelistfoot, setStructurelistfoot] = useState([]);
  const [loading, setLoading] = useState(true);
  const [xaisdatalevelwisefoot, setXaxisdatalevelwisefoot] = useState([]);
  const [yaxisdatalevelwisefoot, setYaxisdatalevelwisefoot] = useState([]);
  const [structurelistlevelwisefoot, setStructurelistlevelwisefoot] = useState(
    []
  );
  const [xaisdatalevelwiseps, setXaxisdatalevelwiseps] = useState([]);
  const [yaxisdatalevelwiseps, setYaxisdatalevelwiseps] = useState([]);
  const [structurelistlevelwiseps, setStructurelistlevelwiseps] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [levels, setLevels] = useState([]);
  const [levelsprogress, setLevelsprogress] = useState([]);
  const [chainageindex, setChainageindex] = useState([]);
  const [structureInChainageDataRsp, setStructureInChainageDataRsp] = useState(
    []
  );
  const [structureInChainageDataPs, setStructureInChainageDataps] = useState(
    []
  );
  const [structureInChainageDataFp, setStructureInChainageDataFp] = useState(
    []
  );
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);
  const [loading5, setLoading5] = useState(true);

  const [secondlinelevelwiseroad, setSecondlinelevelwiseroad] = useState([]);
  const [secondlinelevelwisefoot, setSecondlinelevelwisefoot] = useState([]);
  const [chainageindexlevelwiseroad, setChainageindexlevelwiseroad] = useState(
    []
  );
  const [chainageindexlevelwisefoot, setChainageindexlevelwisefoot] = useState(
    []
  );
  const [successstatus1, setSuccessstatus1] = useState(false);
  const [chainagelist, setChainagelist] = useState([]);
  const [pathtype, setPathtype] = useState("FP");
  const [pathTypelist, setPathTypelist] = useState([]);
  // const overallpercentage = 86

 
  

  useEffect(() => {
  

    axios({
      method: "GET",

      url:
        baseurl +
        "/api/plan/specifications/get/project_progress?project_id=" +
        userData.projectId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    }).then((response) => {
      //console.log(response.data)
      const RequestData = response.data;
      // setRequestdata1(RequestData)

      const overallprogress = RequestData.percentage_of_work_completed;
      const completedpro = overallprogress.progress_completed;

      const currentfloor = overallprogress.current_working_floor;

      setCompletedprogress(completedpro);
      setCurrentLevel(currentfloor);
      const xaxis1 = RequestData.actual_completion_date_level_data;

      const xax = xaxis1.completed_level;

      setXaxis(xax);

      const yaxis1 = RequestData.due_date_level_data;
      const yax = yaxis1.planned_level;

      setYaxis(yax);
      const zaxis1 = RequestData.due_date_level_data;
      const zax = zaxis1.planned_date;

      setZaxis(zax);
      //console.log(zax)
    });
   


    axios({
      method: "GET",

      url:
        baseurl +
        "/api/project/get/progress_percentage?project_id=" +
        userData.projectId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        //console.log(response.data)
        const radialData1 = response.data;
        //console.log("radialbar",radialData1)
        setRadialData(0);
        setRadialData(radialData1.percentage_completed);
      })
      .catch((error) => {
        //console.log(error)
        // setErrormsg(error.response.data.message)
      });

    //Foot path

    axios({
      method: "GET",

      url:
        baseurl +
        "/api/plan/specifications/get/structure_type_progress?project_id=" +userData.projectId+
       // userData.currentprojectId +
        "&type=" +
        "FP",
      // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
  

        const res = response.data;
        //////alert("data***"+res)
        const xdata = res.sub_chainage_keys_list || [];
        const ydata = res.completedChainageData || [];
        const structurelist = res.completed_structures_list || [];
        const structureInChainageData = res.structureInChainageData || [];

        //if(xdata!=null && xdata!=undefined && ydata!=null && ydata!=undefined && xdata!='' && ydata!='')
        // {
        setStructureInChainageDataFp(structureInChainageData);
        setStructurelistfoot(structurelist);
        setXaxisdatafoot(xdata);
        setYaxisdatafoot(ydata);
        setLoading4(false);
        //  }
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });
    //Ps

    axios({
      method: "GET",

      url:
        baseurl +
        "/api/plan/specifications/get/structure_type_progress?project_id=" +
        userData.projectId+
        "&type=" +
        "PS",
      // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        //console.log(response.data)
        const res = response.data;
        const xdata = res.sub_chainage_keys_list || [];
        const ydata = res.completedChainageData || [];
        const structurelist = res.completed_structures_list || [];
        const structureInChainageData = res.structureInChainageData || [];
        // if(xdata!=null && xdata!=undefined && ydata!=null && ydata!=undefined && xdata!='' && ydata!='')
        //  {
        setStructureInChainageDataps(structureInChainageData);
        setStructurelistps(structurelist);
        setXaxisdataps(xdata);
        setYaxisdataps(ydata);
        setLoading5(false);
        // }
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    //for level wise chainage graph

    axios({
      method: "GET",

      url:
        baseurl +
        "/api/project/get/progress_percentage?project_id=" +userData.projectId,
      //  userData.currentprojectId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        //console.log(response.data)
        const radialData1 = response.data;
        //console.log("radialbar",radialData1)
        setRadialData(0);
        setRadialData(radialData1.percentage_completed);
      })
      .catch((error) => {
        //console.log(error)
        // setErrormsg(error.response.data.message)
      });

  
  }, []);

 //Road Side
 useEffect(() => {
    axios({
      method: 'GET',
  
      url: baseurl + '/api/plan/specifications/get/structure_type_progress?project_id='+userData.projectId
      // + userData.currentprojectId
        + '&type=' + 'RS',
      // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',
  
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
  
    })
      .then((response) => {
        //console.log(response.data)
        const res = response.data
        const xdata = res.sub_chainage_keys_list || []
        const ydata = res.completedChainageData || {}
        const structurelist = res.completed_structures_list || []
        const wholechainageindexvalue = res.whole_chainage_index || []
        const structureInChainageData = res.structureInChainageData || []
        setStructurelist(structurelist)
        setXaxisdata(xdata)
        setYaxisdata(ydata)
        setStructureInChainageDataRsp(structureInChainageData)
        setChainageindex(wholechainageindexvalue)
        setLoading3(false)
      
  
      })
      .catch((error) => {
        console.log(error)
        // setErrormsg(error.response.data.message)
      })
    }, [])






  return (
    <div className=" ">
     
      <div class="row">
        
        <div class="col-md-1 col-lg-1 col-xl-1"></div>

       
      

        <div class='col-md-12 col-lg-12 col-xl-12'>


          <div class="col-xl-12 col-lg-12 col-md-12 pt-3 pb-3 labels ff">
            <b><center>Road Side Progress</center></b>
          </div>
          {/* <MultiBarGraph
                      graph1="CompletedWork"
                      graph2="PendingWork"
                      graph1data={[23, 45, 67, 89]}
                      graph2data={[56, 34, 23, 42]}
                      xaxisdata={['Brick', 'Cement', 'Sh', 'Plastering']}
                      xaxistext="ItemOfWork"
                      yaxistext="Quantity(units)"
                    />  */}

          <div >

            {structurelist.length == 0 && xaisdata.length == 0 &&
              yaxisdata.length == 0 &&
              <Multilinegraph1 itemofworkdata={structurelist} xaxisdata={xaisdata} yaxisdata={yaxisdata} chainageindexrange={chainageindex} />
            }
            {loading3  && (
              <div className=" center-spinner">
            <ThreeDots
              visible={true}
              height="20"
              width="40"
              color="#3369a6"
              radius="7"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass="text-center"
            />
            </div>
          )
            }
            {structurelist.length == 0 && !loading3 &&

              <div className="login-error pt-3 pb-3">
                <center><b>No Data is Available </b> </center>
              </div>}
            {structurelist.length != 0 && xaisdata.length != 0 &&
              yaxisdata.length != 0 && structureInChainageDataRsp.length != 0 &&
              <Multilinegraph1 itemofworkdata={structurelist} xaxisdata={xaisdata} yaxisdata={yaxisdata} chainageindexrange={chainageindex}
                structureInChainageData={structureInChainageDataRsp}
              />
            }

          </div>
        </div>

        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="col-xl-12 col-lg-12 col-md-12 ff mt-3 ">
            <b>
              <center>Foot Path Progress</center>
            </b>
          </div>
          {loading4 && (
            <div className=" center-spinner">
            <ThreeDots
              visible={true}
              height="20"
              width="40"
              color="#3369a6"
              radius="7"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass="text-center"
            />
            </div>
          )}
          {structureInChainageDataFp.length == 0 && !loading4 && (
            <div className="login-error ">
              <center>
                <b>No Data is Available </b>{" "}
              </center>
            </div>
          )}

          {structurelistfoot.length == 0 &&
            xaisdatafoot.length == 0 &&
            yaxisdatafoot.length == 0 &&
            structureInChainageDataFp.length == 0 && (
              <Multilinegraph1
                itemofworkdata={structurelistfoot}
                xaxisdata={xaisdatafoot}
                yaxisdata={yaxisdatafoot}
                chainageindexrange={chainageindex}
                structureInChainageData={structureInChainageDataFp}
              />
            )}

          {structurelistfoot.length != 0 &&
            xaisdatafoot.length != 0 &&
            yaxisdatafoot.length != 0 &&
            structureInChainageDataFp.length != 0 && (
              <Multilinegraph1
                itemofworkdata={structurelistfoot}
                xaxisdata={xaisdatafoot}
                yaxisdata={yaxisdatafoot}
                chainageindexrange={chainageindex}
                structureInChainageData={structureInChainageDataFp}
              />
            )}
        </div>


        
        <div class='col-md-12 col-lg-12 col-xl-12'>

          <div class="col-xl-12 col-lg-12 col-md-12 pt-2 pb-3 labels ff">
            <b><center>Paved Shoulder Progress</center></b>
          </div>
          {loading5 && <div className=" center-spinner">
            <ThreeDots
              visible={true}
              height="20"
              width="40"
              color="#3369a6"
              radius="7"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass="text-center"
            />
            </div>
          }
          {structurelistps.length == 0 && !loading5 &&

            <div className="login-error pt-3 pb-3">
              <center><b>No Data is Available </b> </center>
            </div>}
          {structurelistps.length == 0 && xaisdataps.length == 0 &&
            yaxisdataps.length == 0 && structureInChainageDataPs.length != 0 &&
            <Multilinegraph1 itemofworkdata={structurelistps} xaxisdata={xaisdataps} yaxisdata={yaxisdataps}
              chainageindexrange={chainageindex} structureInChainageData={structureInChainageDataPs} />
          }


          {structurelistps.length != 0 && xaisdataps.length != 0 &&
            yaxisdataps.length != 0 && structureInChainageDataPs.length != 0 &&
            <Multilinegraph1 itemofworkdata={structurelistps} xaxisdata={xaisdataps} yaxisdata={yaxisdataps}
              chainageindexrange={chainageindex} structureInChainageData={structureInChainageDataPs} />
          }

        </div>
      </div>


    </div>
  );
};

export default WeareHere;
