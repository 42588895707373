import React from 'react';
import RadialBar from '../ApexCharts/RadialBar';
import './RoadProjectProgress.css'
import { useState, useEffect } from 'react'
import Search from '../RoadProgressSearch/Search1'
import MultiBarGraph from '../ApexCharts/Multibarchart'
import Multibarchart2 from '../ApexCharts/RoadQuantityGraph'
import LineChart2 from '../ApexCharts/LineChart2'
import $ from 'jquery';
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import { baseurl } from '../BaseUrl/Baseurl';
// import Multilinegraph from '../ApexCharts/Chainage3'
import Multilinegraph1 from '../ApexCharts/Chainage4'
import Multilinegraph2 from '../ApexCharts/LevelWiseChainge'
import SingleBar1 from '../ApexCharts/SingleBarchart1'
import { ThreeDots } from 'react-loader-spinner';
const WeareHere = (data) => {
  const chainage = data.data
  const [radialData, setRadialData] = useState('');
  const [fulldatas, setFulldatas] = useState([]);
  const [btnname, setBtnname] = useState('')
  const [level, setlevel] = useState('')
  const [percentage, setPercentage] = useState(0)
  const [listOfLevels, setListOfLevels] = useState([]);
  const [structureType, setStructureType] = useState([]);
  const [structureCompleted, setStructureCompleted] = useState([]);
  const [structurePending, setStructurePending] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [zaxis, setZaxis] = useState([]);
  const [caxis, setCaxis] = useState([]);
  const [daxis, setDaxis] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [graphform, setGraphform] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const userData = useSelector((state) => state.userDetails)
  const [tableData, setTableData] = useState([]);
  const drop = React.useRef(null);
  const [completedprogress, setCompletedprogress] = React.useState('');
  const [currentlevel, setCurrentLevel] = React.useState('');
  const [itemofworklist, setItemofworklist] = React.useState([]);
  const [quantityused, setQuantityused] = useState([])
  const [quantitypend, setQuantitypend] = useState([])
  const [xaisdata, setXaxisdata] = useState([])
  const [yaxisdata, setYaxisdata] = useState([])
  const [structurelist, setStructurelist] = useState([])
  const [xaisdataps, setXaxisdataps] = useState([])
  const [yaxisdataps, setYaxisdataps] = useState([])
  const [structurelistps, setStructurelistps] = useState([])
  const [xaisdatalevelwise, setXaxisdatalevelwise] = useState([])
  const [yaxisdatalevelwise, setYaxisdatalevelwise] = useState([])
  const [structurelistlevelwise, setStructurelistlevelwise] = useState([])
  const [xaisdatafoot, setXaxisdatafoot] = useState([])
  const [yaxisdatafoot, setYaxisdatafoot] = useState([])
  const [structurelistfoot, setStructurelistfoot] = useState([])
  const [loading, setLoading] = useState(true);
  const [xaisdatalevelwisefoot, setXaxisdatalevelwisefoot] = useState([])
  const [yaxisdatalevelwisefoot, setYaxisdatalevelwisefoot] = useState([])
  const [structurelistlevelwisefoot, setStructurelistlevelwisefoot] = useState([])
  const [xaisdatalevelwiseps, setXaxisdatalevelwiseps] = useState([])
  const [yaxisdatalevelwiseps, setYaxisdatalevelwiseps] = useState([])
  const [structurelistlevelwiseps, setStructurelistlevelwiseps] = useState([])
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [levels, setLevels] = useState([]);
  const [levelsprogress, setLevelsprogress] = useState([]);
  const [chainageindex, setChainageindex] = useState([]);
  const [structureInChainageDataRsp, setStructureInChainageDataRsp] = useState([]);
  const [structureInChainageDataPs, setStructureInChainageDataps] = useState([]);
  const [structureInChainageDataFp, setStructureInChainageDataFp] = useState([]);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);
  const [loading5, setLoading5] = useState(true);

  const [secondlinelevelwiseroad, setSecondlinelevelwiseroad] = useState([]);
  const [secondlinelevelwisefoot, setSecondlinelevelwisefoot] = useState([]);
  const [chainageindexlevelwiseroad, setChainageindexlevelwiseroad] = useState([]);
  const [chainageindexlevelwisefoot, setChainageindexlevelwisefoot] = useState([]);
  const [successstatus1, setSuccessstatus1] = useState(false);
  const [chainagelist, setChainagelist] = useState([]);
  const [pathtype, setPathtype] = useState('FP');
  const [pathTypelist, setPathTypelist] = useState([]);
  // const overallpercentage = 86
  function btnclick(e) {

    setSelectedOption(e.target.value)

  }


  function btnclick3(levellist1) {
    const levellist2 = levellist1
    setPercentage(0)
    btnclick2(levellist2)

  }
  ///////////////////////radial bar axios//////////////////
  function btnclick2(levellist1) {

    const firstHyphenIndex = levellist1.indexOf('-');

    // Extract the text after the first hyphen
    const extractedText = firstHyphenIndex !== -1 ? levellist1.slice(firstHyphenIndex + 1).trim() : null;
    ////////////alert(extractedText)
    ////////////////alert(type1)
    let fDatan1 = new FormData()
    fDatan1.append('level', extractedText)
    fDatan1.append('project_id', userData.currentprojectId)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/level_progress?level=' + extractedText + '&project_id=' + userData.currentprojectId, +fDatan1, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })
    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/level_progress?level=' + extractedText + '&project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userData.token}`,

      },
      data: fDatan1

    })
      .then((response) => {

        //////console.log("/api/plan/specifications/get/level_progress?level=", response.data)
        const dat6 = response.data
        const lel = dat6.current_working_floor
        //////////////////////alert(JSON.stringify(dat6))
        //////console.log("second1", response.data)
        setlevel(lel)


        const per = dat6.progress_completed
       
        ////////////alert(per)
        //////console.log("second2", per)
        ////////////////alert(per)
        setPercentage(per)


      })
      .catch((error) => {
        //////console.log(error)

      })


  }






  useEffect(() => {

    //level wise graph

    // axios
    //   .get(baseurl + '/api/plan/specifications/get/each_level_progress?project_id=' + userData.currentprojectId, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })
    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/each_level_progress?project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token

      }

    })
      .then((response) => {

        ////console.log("second", response.data)
        const dat = response.data
        const lel = dat.level_or_chainage
        setLevels(lel)
        const progress = dat.progress
        setLevelsprogress(progress)
        setLoading2(false)
      })
      .catch((error) => {
        ////console.log(error)

      })


    let fDatan2 = new FormData()
    fDatan2.append('project_id', userData.currentprojectId)
    fDatan2.append('level', selectedOption)
  
    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/quantity_progress?project_id=' + userData.currentprojectId +
        '&level=' + selectedOption,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })

      .then((response) => {

        //////console.log(response.data)
        const dat = response.data
        const item = dat.item_of_works
        setItemofworklist(item)
        const used = dat.quantities_used
        setQuantityused(used)
        const pend = dat.quantities_pending
        setQuantitypend(pend)

        //         setTableData(dat)
        // //////console.log('/api/plan/specifications/get/due_date?project_id='+JSON.stringify(dat))

      })
      .catch((error) => {
        //////console.log(error)
        //setErrormsg(error.response.data.message)
      })

    let fData = new FormData()
    fData.append('project_id', userData.currentprojectId)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/due_date?project_id=' + userData.currentprojectId, +fData, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/due_date?project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })
      .then((response) => {

        //////console.log(response.data)
        const dat = response.data
        //////console.log("%%%%" + response.data)
        //////console.log("hello" + dat)
        // //////////////////////alert("We Got Calculated Details")
        setTableData(dat)
        //////console.log('/api/plan/specifications/get/due_date?project_id='+JSON.stringify(dat))

      })
      .catch((error) => {
        //////console.log(error)
        //setErrormsg(error.response.data.message)
      })

    // const type=selectedOption.replace(/-.*$/,'')
    // const type1=type.trim()
    //       const firstHyphenIndex = selectedOption.indexOf('-');

    //       // Extract the text after the first hyphen
    //       const extractedText = firstHyphenIndex !== -1 ? selectedOption.slice(firstHyphenIndex + 1).trim() : null;
    //       ////////////alert(extractedText)
    //  ////////////////alert(type1)
    //       let fDatan1 = new FormData()
    //       fDatan1.append('level',extractedText )
    //       fDatan1.append('project_id', userData.currentprojectId)
    //       axios
    //         .get(baseurl+'/api/plan/specifications/get/level_progress?level=' + extractedText + '&project_id=' + userData.currentprojectId, +fDatan1, {
    //           headers: { 'Content-Type': 'multipart/form-data' },
    //         })

    //         .then((response) => {

    //           //////console.log("/api/plan/specifications/get/level_progress?level=", response.data)
    //           const dat6 = response.data
    //           const lel = dat6.current_working_floor
    //           //////////////////////alert(JSON.stringify(dat6))
    //           //////console.log("second1", response.data)
    //           setlevel(lel)


    //           const per = dat6.progress_completed
    //         ////////////alert(per)
    //           //////console.log("second2", per)
    //         ////////////////alert(per)
    //           setPercentage(per)


    //         })
    //         .catch((error) => {
    //           //////console.log(error)

    //         })




    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/project_progress?project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })

      .then((response) => {

        //////console.log(response.data)
        const RequestData = response.data
        // setRequestdata1(RequestData)


        const overallprogress = RequestData.percentage_of_work_completed
        const completedpro = overallprogress.progress_completed

        const currentfloor = overallprogress.current_working_floor

        setCompletedprogress(completedpro)
        setCurrentLevel(currentfloor)
        const xaxis1 = RequestData.actual_completion_date_level_data

        const xax = xaxis1.completed_level




        setXaxis(xax)

        const yaxis1 = RequestData.due_date_level_data
        const yax = yaxis1.planned_level

        setYaxis(yax)
        const zaxis1 = RequestData.due_date_level_data
        const zax = zaxis1.planned_date


        setZaxis(zax)
        //////console.log(zax)

      })
    ///////////////////////////////////////level drop down axios/////////////////////////
    let leveldata = new FormData()
    leveldata.append('project_id', userData.currentprojectId)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/due_date?project_id=' + userData.currentprojectId, +leveldata, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })
    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/due_date?project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })
      .then((response) => {

        //////console.log(response.data)
        const dat = response.data || []
        //////console.log("dat", dat)
        setListOfLevels(dat)
if(dat.length!=0)
{
        setSelectedOption(dat[0].floor)
      }

        //////console.log('/api/plan/specifications/get/due_date?project_id=',JSON.stringify(dat))





      })
      .catch((error) => {
        //////console.log("levelerr", error)
        //setErrormsg(error.response.data.message)
      })


    //////////////////////////////////////////////////////////////

    let fmData10 = new FormData()


    // fmData10.append('project_id', userData.currentprojectId)

    // fmData10.append('site_updates', 1)


  //Site Update

    // axios({
    //   method: 'GET',

    //   url: baseurl + '/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId + '&site_updates=' + 1,

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     Authorization: 'Bearer ' + userData.token
    //   }
    // })


    //   .then((response) => {

    //     //////console.log("carddata", response.data)
    //     const carddata = response.data
    //     const cdt = carddata.searched_data
    //     //////////////////////alert("Success"+JSON.stringify(carddata))
    //     //////console.log(cdt)
    //     setFulldatas(cdt)
    //     setSuccessstatus1(true)


    //     //  const structureName1 = cdata.structure_item_type
    //     //   setStructureName(structureName1)
    //     //   //////console.log(structureName1)
    //     //  const structureId1 = cdata.structure_item_id
    //     //   setStructureId(structureId1)
    //     //   const itemName1=cdata.Brickwork
    //     //   setItemName(itemName1)
    //     //  const compDate1=cdata.due_date
    //     //   setCompDate(compDate1)
    //     //  const itemOfWork1=cdata.item_of_works
    //     //   setItemOfWork(itemOfWork1)



    //   })

    //   .catch((error) => {

    //     //////console.log("carderror", error)

    //     //setErrormsg(error.response.data.message)

    //   })


    
    let structuredata = new FormData()

    structuredata.append('project_id', userData.currentprojectId)
    structuredata.append('level', selectedOption)
    const lv = selectedOption.replace(/-.*$/, '')
    //////console.log(selectedOption)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/structure_type_progress?project_id=' + userData.currentprojectId + '&level=' + lv, +structuredata, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })
    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/structure_type_progress?project_id=' + userData.currentprojectId + '&level=' + lv,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })
      .then((response) => {

        //////console.log(response.data)
        const ldat = response.data
        //////console.log("level based graph", ldat)
        //////console.log('/api/plan/specifications/get/structure_type_progress?project_id='+JSON.stringify(ldat))
        const structuretype1 = ldat.structure_type
        const noOfStructcompleted1 = ldat.no_of_structure_type_completed


        const noOfstructpending1 = ldat.no_of_structure_type_pending

        //////console.log("pending", noOfstructpending1)
        setStructureType(structuretype1)
        setStructureCompleted(noOfStructcompleted1)
        setStructurePending(noOfstructpending1)
        //////console.log(structuretype1)
        //////console.log(noOfStructcompleted1)
        //////console.log(noOfstructpending1)
        // //////////////////////alert(structureType)

      })
      .catch((error) => {
        //////console.log("levelerr1", error)

      })





    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/item_of_work_progress?project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        //////console.log(response.data)
        const data = response.data
        //////////////////alert(JSON.stringify(data))
        // //////console.log(JSON.stringify(data))
        const itemofwork = data.item_of_works
        //////////////////alert(JSON.stringify(itemofwork))
        setItemofworklist(itemofwork)
      })
      .catch((error) => {
        //////console.log(error)
        // setErrormsg(error.response.data.message)
      })
    ////////////////////////////level based multiBargraph axios///////////////////////

    axios({
      method: 'GET',

      url: baseurl + '/api/project/get/progress_percentage?project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        //////console.log(response.data)
        const radialData1 = response.data
        //////console.log("radialbar",radialData1)
        setRadialData(0)
        setRadialData(radialData1.percentage_completed)
      })
      .catch((error) => {
        //////console.log(error)
        // setErrormsg(error.response.data.message)
      })

  



    //Foot path

    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/structure_type_progress?project_id=' + userData.currentprojectId
        + '&type=' + 'FP',
      // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        // ////console.log(response.data)

        const res = response.data
        //////alert("data***"+res)
        const xdata = res.sub_chainage_keys_list || []
        const ydata = res.completedChainageData || []
        const structurelist = res.completed_structures_list || []
        const structureInChainageData = res.structureInChainageData || []

        //if(xdata!=null && xdata!=undefined && ydata!=null && ydata!=undefined && xdata!='' && ydata!='')
        // {
        setStructureInChainageDataFp(structureInChainageData)
        setStructurelistfoot(structurelist)
        setXaxisdatafoot(xdata)
        setYaxisdatafoot(ydata)
        setLoading4(false)
        //  }

      })
      .catch((error) => {
        ////console.log(error)
        // setErrormsg(error.response.data.message)
      })
    //Ps


    axios({
      method: 'GET',

      url: baseurl + '/api/plan/specifications/get/structure_type_progress?project_id=' + userData.currentprojectId
        + '&type=' + 'PS',
      // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        //////console.log(response.data)
        const res = response.data
        const xdata = res.sub_chainage_keys_list || []
        const ydata = res.completedChainageData || []
        const structurelist = res.completed_structures_list || []
        const structureInChainageData = res.structureInChainageData || []
        // if(xdata!=null && xdata!=undefined && ydata!=null && ydata!=undefined && xdata!='' && ydata!='')
        //  {
        setStructureInChainageDataps(structureInChainageData)
        setStructurelistps(structurelist)
        setXaxisdataps(xdata)
        setYaxisdataps(ydata)
        setLoading5(false)
        // }

      })
      .catch((error) => {
        ////console.log(error)
        // setErrormsg(error.response.data.message)
      })



    //for level wise chainage graph

    axios({
      method: 'GET',

      url: baseurl + '/api/project/get/progress_percentage?project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        //////console.log(response.data)
        const radialData1 = response.data
        //////console.log("radialbar",radialData1)
        setRadialData(0)
        setRadialData(radialData1.percentage_completed)
      })
      .catch((error) => {
        //////console.log(error)
        // setErrormsg(error.response.data.message)
      })

      // axios({
      //   method: 'GET',
  
      //   url: baseurl + '/api/plan/list/path_type?project_id=' + userData.currentprojectId,
  
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     Authorization: 'Bearer ' + userData.token
      //   }
  
      // })
      //   .then((response) => {
      //     //////console.log(response.data)
      //     const pt = response.data
      //   const pathlist=pt.path_type
      //   setPathTypelist(pathlist)
      //   setPathtype(pathlist)
      //   })
      //   .catch((error) => {
      //     //////console.log(error)
      //     // setErrormsg(error.response.data.message)
      //   })


    //level wise foot graph

    //  if (start_chainage != '' && end_chainage != '') {
    //   setLoading1(true)
    //   axios({
    //     method: 'GET',

    //     url: baseurl + '/api/plan/specifications/get/structure_type_progress?project_id=' + userData.currentprojectId
    //       + '&type=' + 'PS' + '&start_chainage=' + start_chainage + '&end_chainage=' + end_chainage,
    //     // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',

    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     }

    //   })
    //     .then((response) => {
    //       //////console.log(response.data)
    //       const res = response.data
    //       const xdata = res.sub_chainage_keys_list || []
    //       const ydata = res.completedChainageData || []
    //       const structurelist = res.completed_structures_list || []
    //      // if(xdata!=null && xdata!=undefined && ydata!=null && ydata!=undefined && xdata!='' && ydata!='')
    //      // {
    //       setStructurelistlevelwiseps(structurelist)
    //       setXaxisdatalevelwiseps(xdata)
    //       setYaxisdatalevelwiseps(ydata)
    //      // }
    //       setLoading1(false)

    //     })
    //     .catch((error) => {
    //       ////console.log(error)
    //       // setErrormsg(error.response.data.message)
    //     })
    // }

  }, [])


  useEffect(() => {
    setStructurelistlevelwise([])
    setXaxisdatalevelwise([])
    setYaxisdatalevelwise([])
    setSecondlinelevelwiseroad([])
    setChainageindexlevelwiseroad([])
    setYaxisdatalevelwisefoot([])
    setStructurelistlevelwisefoot([])
    setXaxisdatalevelwisefoot([])
    setSecondlinelevelwisefoot([])
    setChainageindexlevelwisefoot([])


    //LevelWise Progress
    btnclick3(selectedOption)
    //

    //LevelwiseRoad
    let start_chainage = ''
    let end_chainage = ''
    //////////////alert("outside"+selectedOption)
    if (selectedOption) {
      //////////////alert("inside"+selectedOption)
      let parts = selectedOption.split('-').map(part => part.trim());
      // Extract the second and third elements of the resulting array
      start_chainage = parts[1]
      end_chainage = parts[2]
    }
    //  ////alert("start"+start_chainage)
    //  ////alert("end"+end_chainage)
    if (start_chainage != '' && end_chainage != '') {
      setLoading(true)
      axios({
        method: 'GET',

        url: baseurl + '/api/plan/specifications/get/structure_type_progress?project_id=' + userData.currentprojectId
          + '&type=' + 'RS' + '&start_chainage=' + start_chainage + '&end_chainage=' + end_chainage,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }

      })
        .then((response) => {
          // ////console.log("response dats-----------"+response.data)
          const res = response.data
          const xdata = res.sub_chainage_keys_list || []
          const ydata = res.completedChainageData || []
          const structurelist = res.completed_structures_list || []
          const wholechainageindexvalue = res.whole_chainage_index || []
          const structureInChainageData = res.structureInChainageData || []
          // if(xdata!=null && xdata!=undefined && ydata!=null && ydata!=undefined && xdata!='' && ydata!='')
          // {
          setStructurelistlevelwise(structurelist)
          setXaxisdatalevelwise(xdata)
          setYaxisdatalevelwise(ydata)
          setSecondlinelevelwiseroad(structureInChainageData)
          setChainageindexlevelwiseroad(wholechainageindexvalue)
         
          setLoading(false)
        })
        .catch((error) => {
          ////console.log(error)
          // setErrormsg(error.response.data.message)
        })

    }

    //
    //level wise foot graph

    if (start_chainage != '' && end_chainage != '') {


      setLoading1(true)
      axios({
        method: 'GET',

        url: baseurl + '/api/plan/specifications/get/structure_type_progress?project_id=' + userData.currentprojectId
          + '&type=' + 'FP' + '&start_chainage=' + start_chainage + '&end_chainage=' + end_chainage,
        // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }

      })
        .then((response) => {
          ////console.log(response.data)
          const res = response.data
          ////console.log("step1")
          const xdata = res.sub_chainage_keys_list || []
          ////console.log("step2")
          const ydata = res.completedChainageData || []
          ////console.log("step3")
          const structurelist = res.completed_structures_list || []
          ////console.log("step4")
          const wholechainageindexvalue = res.whole_chainage_index || []
          ////console.log("step5")
          const structureInChainageData = res.structureInChainageData || []
          ////console.log("step6" + ydata)
          setYaxisdatalevelwisefoot(ydata)
          //if(xdata!=null && xdata!=undefined && ydata!=null && ydata!=undefined && xdata!='' && ydata!='')
          // {
          ////console.log("step9" + structurelist)
          setStructurelistlevelwisefoot(structurelist)
          ////console.log("step7" + xdata)
          setXaxisdatalevelwisefoot(xdata)
          ////console.log("step8" + structureInChainageData)


          setSecondlinelevelwisefoot(structureInChainageData)
          ////console.log("step10" + wholechainageindexvalue)
          setChainageindexlevelwisefoot(wholechainageindexvalue)
          ////console.log("step11")

          //  }
          setLoading1(false)
          //           ////console.log("---------------")
          // ////console.log(xdata+"Xaxis")
          // ////console.log(ydata+"Yaxis")
          // ////console.log(structurelist+"Structure")
          // ////console.log("---------------")
        })
        .catch((error) => {
          ////console.log(error)
          // setErrormsg(error.response.data.message)
        })
    }

  }, [selectedOption])

  //Path Type
  function PathTypeUpdate(e) {
    setChainagelist([])
    setPathtype(e.target.value)

  }

  //Chainage Table
  useEffect(() => {

    axios({
      method: 'GET',

      url: baseurl + '/api/plan/list/progress_table_details?project_id=' + userData.projectId + '&path_type=' + pathtype,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })

      .then((response) => {

        ////console.log("PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP" + response.data)
        const res = response.data
        const dt = res.progress_table_details

        setChainagelist(dt)
        setSuccessstatus1(true)
      })
      .catch((error) => {

        ////console.log(error)

      })

  }, [pathtype])


  //Road Side
  useEffect(() => {
  axios({
    method: 'GET',

    url: baseurl + '/api/plan/specifications/get/structure_type_progress?project_id=' + userData.currentprojectId
      + '&type=' + 'RS',
    // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + userData.token
    }

  })
    .then((response) => {
      //////console.log(response.data)
      const res = response.data
      const xdata = res.sub_chainage_keys_list || []
      const ydata = res.completedChainageData || {}
      const structurelist = res.completed_structures_list || []
      const wholechainageindexvalue = res.whole_chainage_index || []
      const structureInChainageData = res.structureInChainageData || []
      setStructurelist(structurelist)
      setXaxisdata(xdata)
      setYaxisdata(ydata)
      setStructureInChainageDataRsp(structureInChainageData)
      setChainageindex(wholechainageindexvalue)
      setLoading3(false)
    

    })
    .catch((error) => {
      ////console.log(error)
      // setErrormsg(error.response.data.message)
    })
  }, [])
  return (
    <div className='pagetopspace'>

      <div class='row'>
        <div class="col-xl-12 col-lg-12 col-md-12 pt-4 pb-3 labels ff">
          <b><center>Project Progress</center></b>
        </div>

      </div>
      <div class='row'>

        <div class='col-md-1 col-lg-1 col-xl-1'></div>
        <div class='col-md-5 col-lg-5 col-xl-5'>
          {loading2 && <div class="pt-5 ff"> <h5><b><center>Please wait....</center></b></h5></div>}
          {levels.length == 0 && levelsprogress.length == 0 && !loading2 &&
            <SingleBar1

              listdata={levelsprogress}
              xaxisdata={levels}
              type="bar"
              yaxismax={100}

            />}

          {levels.length != 0 && levelsprogress.length != 0 && !loading2 &&
            <SingleBar1

              listdata={levelsprogress}
              xaxisdata={levels}
              type="bar"
              yaxismax={100}

            />}

          {/* { levels.length != 0 && levelsprogress.length != 0 &&
            < MultiBarGraph
              graph1="CompletedWork"
              graph2="PendingWork"
              graph1data={levelsprogress}
            
              xaxisdata={levels}

              xaxistext="Levels"
              yaxistext="Percentage"

            />} */}
          {/* {xaxis != 0 && yaxis != 0 && zaxis != 0 && <LineChart2
                      type="line"
                      PlannedProgress={yaxis}
                      ExpectedProgress={xaxis}
                      xaxisData={zaxis}

                      yaxistext="Chainage"
                      xaxistext="Date"
                    />
                    } */}
          {/* {structureType.length != 0 && structureCompleted.length != 0 && structurePending.length != 0 &&
            < MultiBarGraph
              graph1="CompletedWork"
              graph2="PendingWork"
              graph1data={structureCompleted}
              graph2data={structurePending}
              xaxisdata={structureType}

              xaxistext="Level"
              yaxistext="Percentage"

            />} */}
        </div>
        <div class='col-md-1 col-lg-1 col-xl-1'></div>
        {/* <div class='col-md-4 col-lg-4 col-xl-4'> */}

        {/* <MultiBarGraph
                      graph1="CompletedWork"
                      graph2="PendingWork"
                      graph1data={[23, 45, 67, 89]}
                      graph2data={[56, 34, 23, 42]}
                      xaxisdata={['Brick', 'Cement', 'Sh', 'Plastering']}
                      xaxistext="ItemOfWork"
                      yaxistext="Quantity(units)"
                    />  */}
        {/* <Multilinegraph data={chainage}/> */}
        {/* <Multibarchart2 /> */}
        {/* </div> */}
        <div class='col-md-4 col-lg-4 col-xl-4 '>
          <div className='table3style '>
            <div class='pt-2 pb-1 pl-3 mt-5'>
              <b><center>Project Completion Status</center></b>
            </div>
            <center>
              {radialData != 0 && <RadialBar

                SeriesValue={radialData}

                radialwidth={245}

                labelValue={radialData}

                radialcolor={((parseInt(radialData)) < 50) ? '#ff0000' : (parseInt(radialData) >= 50 && parseInt(radialData) <= 80) ? '#009933' : '#00e600'}

              ></RadialBar>}
              {radialData == 0 && <RadialBar

                SeriesValue={0}

                radialwidth={245}

                labelValue={radialData}

                radialcolor={((parseInt(radialData)) < 50) ? '#ff0000' : (parseInt(radialData) >= 50 &&
                  parseInt(radialData) <= 80) ? '#ff6600' : '#00e600'}

              ></RadialBar>}
            </center>
          </div>
          <div>
            <center>
              <button
                type="button"
                className="btnred "

              ></button>
              <font className="donutfont">
                &nbsp;&nbsp;Bad &nbsp;&nbsp; &nbsp;&nbsp;
              </font>
              <button
                type="button"
                className="btnyellow "
              ></button>
              <font className="donutfont">
                &nbsp;&nbsp;Critical &nbsp;&nbsp; &nbsp;&nbsp;
              </font>
              <button
                type="button"
                className="btngreen "
              ></button>
              <font className="donutfont">
                &nbsp;&nbsp;Good &nbsp;&nbsp; &nbsp;&nbsp;
              </font>

            </center>
          </div>
        </div>
        <div class='col-md-1 col-lg-1 col-xl-1'></div>

        <div class='col-md-12 col-lg-12 col-xl-12'>


          <div class="col-xl-12 col-lg-12 col-md-12 pt-3 pb-3 labels ff">
            <b><center>Road Side Progress</center></b>
          </div>
          {/* <MultiBarGraph
                      graph1="CompletedWork"
                      graph2="PendingWork"
                      graph1data={[23, 45, 67, 89]}
                      graph2data={[56, 34, 23, 42]}
                      xaxisdata={['Brick', 'Cement', 'Sh', 'Plastering']}
                      xaxistext="ItemOfWork"
                      yaxistext="Quantity(units)"
                    />  */}

          <div >

            {structurelist.length == 0 && xaisdata.length == 0 &&
              yaxisdata.length == 0 &&
              <Multilinegraph1 itemofworkdata={structurelist} xaxisdata={xaisdata} yaxisdata={yaxisdata} chainageindexrange={chainageindex} />
            }
            {loading3 && <div className=" center-spinner">
            <ThreeDots
              visible={true}
              height="20"
              width="40"
              color="#3369a6"
              radius="7"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass="text-center"
            />
            </div>
            }
            {structurelist.length == 0 && !loading3 &&

              <div className="login-error pt-3 pb-3">
                <center><b>No Data is Available </b> </center>
              </div>}
            {structurelist.length != 0 && xaisdata.length != 0 &&
              yaxisdata.length != 0 && structureInChainageDataRsp.length != 0 &&
              <Multilinegraph1 itemofworkdata={structurelist} xaxisdata={xaisdata} yaxisdata={yaxisdata} chainageindexrange={chainageindex}
                structureInChainageData={structureInChainageDataRsp}
              />
            }

          </div>
        </div>
        <div class='col-md-12 col-lg-12 col-xl-12'>

          <div class="col-xl-12 col-lg-12 col-md-12 pt-2 pb-3 labels ff">
            <b><center>Foot Path Progress</center></b>
          </div>
          {loading4 && <div className=" center-spinner">
            <ThreeDots
              visible={true}
              height="20"
              width="40"
              color="#3369a6"
              radius="7"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass="text-center"
            />
            </div>
          }
          {structureInChainageDataFp.length == 0 && !loading4 &&

            <div className="login-error pt-3 pb-3">
              <center><b>No Data is Available </b> </center>
            </div>}
            
          {structurelistfoot.length == 0 && xaisdatafoot.length == 0 &&
            yaxisdatafoot.length == 0 && structureInChainageDataFp.length == 0 &&
            <Multilinegraph1 itemofworkdata={structurelistfoot} xaxisdata={xaisdatafoot} yaxisdata={yaxisdatafoot}
              chainageindexrange={chainageindex} structureInChainageData={structureInChainageDataFp} />
          }


          {structurelistfoot.length != 0 && xaisdatafoot.length != 0 &&
            yaxisdatafoot.length != 0 && structureInChainageDataFp.length != 0 &&
            <Multilinegraph1 itemofworkdata={structurelistfoot} xaxisdata={xaisdatafoot} yaxisdata={yaxisdatafoot}
              chainageindexrange={chainageindex} structureInChainageData={structureInChainageDataFp} />
          }

        </div>

        <div class='col-md-12 col-lg-12 col-xl-12'>

          <div class="col-xl-12 col-lg-12 col-md-12 pt-2 pb-3 labels ff">
            <b><center>Paved Shoulder Progress</center></b>
          </div>
          {loading5 && <div className=" center-spinner">
            <ThreeDots
              visible={true}
              height="20"
              width="40"
              color="#3369a6"
              radius="7"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass="text-center"
            />
            </div>
          }
          {structurelistps.length == 0 && !loading5 &&

            <div className="login-error pt-3 pb-3">
              <center><b>No Data is Available </b> </center>
            </div>}
          {structurelistps.length == 0 && xaisdataps.length == 0 &&
            yaxisdataps.length == 0 && structureInChainageDataPs.length != 0 &&
            <Multilinegraph1 itemofworkdata={structurelistps} xaxisdata={xaisdataps} yaxisdata={yaxisdataps}
              chainageindexrange={chainageindex} structureInChainageData={structureInChainageDataPs} />
          }


          {structurelistps.length != 0 && xaisdataps.length != 0 &&
            yaxisdataps.length != 0 && structureInChainageDataPs.length != 0 &&
            <Multilinegraph1 itemofworkdata={structurelistps} xaxisdata={xaisdataps} yaxisdata={yaxisdataps}
              chainageindexrange={chainageindex} structureInChainageData={structureInChainageDataPs} />
          }

        </div>

      </div>

      {/* //Chainage table  */}

      <div class='row'>
        <div class="col-xl-12 col-lg-12 col-md-12 pt-5 pb-3 labels ff">
          <b><center>Progress Table</center></b>
        </div>
        <div class="col-md-5 col-xl-5  mb-3">


          <select onChange={(e) => PathTypeUpdate(e)} name="pathtype" value={pathtype} className="project-box">

          <option value="FP" selected>Foot Path</option>
            <option value="RS" >Road Side</option>
            <option value="PS" >Paver Shoulder</option> 
           {/*    {pathTypelist.map((item, index) => {

return (

  <option value={item[index]} >{item[index]}</option>

)
})}*/}
          </select>
        </div>









        {/* Chainage table */}

        <div class="col-xl-12 col-lg-12 col-md-12 ">
          <div className='marginleftrighttable'>
            {chainagelist.length != 0 &&

              <table
                class="table"
                readOnly="readOnly"
              >
                <tr class='trst'>

                  <th>Structure</th>
                  <th>Actual Chainage</th>
                  <th>Completed Chainage</th>
                  <th>Completed Chainage Length</th>
                  <th style={{ width: '130px' }}>Pending Chainage</th>
                  <th>Pending Length</th>

                </tr>
                {chainagelist.length != 0 && chainagelist.map((item, index) => {

                  return (
                    <tr key={index} className='tablefontsize'>

                      <td className='fontcolor tablefont'><b>{item.structure != null && item.structure != undefined ? item.structure : 'NA'}</b></td>

                      <td className='fontcolor1 tablefont'>
                        {item.actual_chainages.length != 0 && item.actual_chainages != undefined ?
                          <ul>
                            {item.actual_chainages.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul> : 'NA'}
                      </td>

                      <td className='fontcolor tablefont'>

                        {item.completed_chainages.length != 0 ?
                          <ul>
                            {item.completed_chainages.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul> : 'NA'}

                      </td>

                      <td className='fontcolor1 tablefont'> {item.completed_length != null && item.completed_length != undefined ? item.completed_length : 'NA'}</td>

                      <td className='fontcolor1 tablefont'>

                        {item.pending_chainages.length != 0 ?
                          <ul>
                            {item.pending_chainages.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul> : 'NA'}
                      </td>

                      <td className='fontcolor1 tablefont'>

                        {item.pending_length != null && item.pending_length != undefined ? item.pending_length : 'NA'}
                      </td>



                    </tr>
                  )
                })
                }



              </table>

            }
          </div>
        </div>

      </div>

      <div class='row'>
        <div class="col-xl-12 col-lg-12 col-md-12 pt-5 pb-3 labels ff">
          <b><center>Levelwise Progress</center></b>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-5 col-xl-5  mb-3">
          <select onChange={(e) => btnclick(e)} name="selectedoption" value={selectedOption} className="project-box">
            {/* <option>select level to see the progress</option> */}
            {listOfLevels.map((item, index) => {

              return (

                <option value={item.floor} >{item.floor.replace(/\./g, '/')}</option>

              )
            })}


          </select>
        </div>
      </div>




      <div class='row'>
        <div class="col-md-5 col-xl-5 ">



          {/* {structureType.length != 0 && structureCompleted.length != 0 && structurePending.length != 0 && < MultiBarGraph
                    graph1="CompletedWork"
                    graph2="PendingWork"
                    graph1data={structureCompleted}
                    graph2data={structurePending}
                    xaxisdata={structureType}

                    xaxistext="StructureType"
                    yaxistext="NumberOfStructure"

                  />} */}

          <div class="col-xl-12 col-lg-12 col-md-12 pt-2 pb-3 labels ff">
            <b><center>Road</center></b>
          </div>

          {loading && <div class="pt-5 ff"> <h5><b><center>Please wait....</center></b></h5></div>
          }

          {structurelistlevelwise.length == 0 && !loading &&

            <div className="login-error pt-3 pb-3">
              <center><b>No Data is Available </b> </center>
            </div>}

          {/* {structurelistlevelwise.length == 0 && 
            yaxisdatalevelwise.length == 0  &&
            // ////console.log("HHHHHHHHHHHH")
            <Multilinegraph2 itemofworkdata={structurelistlevelwise} xaxisdata={xaisdatalevelwise} yaxisdata={yaxisdatalevelwise} />
          } */}
          {structurelistlevelwise.length != 0 && xaisdatalevelwise.length != 0 &&


            yaxisdatalevelwise.length != 0 &&
            !loading && secondlinelevelwiseroad.length != 0 && chainageindexlevelwiseroad.length != 0 &&
            // ////console.log("HHHHHHHHHHHH")
            <Multilinegraph2 itemofworkdata={structurelistlevelwise} xaxisdata={xaisdatalevelwise}

              yaxisdata={yaxisdatalevelwise} chainageindexrange={chainageindexlevelwiseroad}
              structureInChainageData={secondlinelevelwiseroad} />
          }
          {/* <table
              class="table"
              readOnly="readOnly"
            >
               
             
              <div class = 'row'>
              <div class="pt-2 pb-2 pl-2">
                <b>Level Wise Progress</b>
                </div></div>
                <div class = 'row'>
                <div class="pt-2 pb-2">
                <tr class="trst">
                          
                          <th><center>Level</center></th>
                          <th><center>Status</center></th>
                          <th><center>Percentage of completion</center></th>
                        </tr>
                        {list2 != 0 && list2.map((item, index) => {
                          return (
                            <tr key={index} className='tablefontsize'>
                            
                              
                              <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.key1}</center> </td>
                              <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.key2}</center> </td>
                              <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.key3}</center> </td>


                            </tr>
                          )
                        })}
            </div></div></table> */}
        </div>
        <div class="col-md-5 col-xl-5  col-lg-5">

          <div class="col-xl-12 col-lg-12 col-md-12 pt-2 pb-3 labels ff">
            <b><center>Foot Path </center></b>
          </div>
          {loading1 && <div class="pt-5 ff"> <h5><b><center>Please wait....</center></b></h5></div>
          }
          {/* 
{structurelistlevelwisefoot.length == 0
              && !loading1 &&
              <div className="login-error pt-3 pb-3"> 
              <center><b>No Data is Available </b> </center>
                </div>    }
{structurelistlevelwisefoot.length == 0 && xaisdatalevelwisefoot.length == 0 && 
            yaxisdatalevelwisefoot.length == 0 &&

            <Multilinegraph2 itemofworkdata={structurelistlevelwisefoot} 
            xaxisdata={xaisdatalevelwisefoot} yaxisdata={yaxisdatalevelwisefoot}

            chainageindexrange={chainageindexlevelwisefoot}  structureInChainageData={secondlinelevelwisefoot}
             />
          } */}



          {structurelistlevelwisefoot.length != 0 && xaisdatalevelwisefoot.length != 0
            &&

            yaxisdatalevelwisefoot.length != 0 && !loading1 &&

            secondlinelevelwisefoot.length != 0 && chainageindexlevelwisefoot.length != 0 &&

            <Multilinegraph2 itemofworkdata={structurelistlevelwisefoot} xaxisdata={xaisdatalevelwisefoot}
              yaxisdata={yaxisdatalevelwisefoot} chainageindexrange={chainageindexlevelwisefoot} structureInChainageData={secondlinelevelwisefoot} />
          }

        </div>

        <div class="col-md-2 col-xl-2 col-lg-2">
          <div className="progressgap">
          {level != 0 &&
        
            <div className='table3style'>
              <div class='pt-2 pb-1 pl-3 mt-5'>
                <b><center>Project Completion status</center></b>
              </div>
              <center>
                {percentage != 0 && <RadialBar

                  SeriesValue={percentage}

                  radialwidth={160}

                  labelValue={percentage}

                  radialcolor={((parseInt(percentage)) < 50) ? '#ff0000' : (parseInt(percentage) >= 50 && parseInt(percentage) <= 80) ? '#ff6600' : '#00e600'}

                ></RadialBar>}
                {percentage == 0 && <RadialBar

                  SeriesValue={0}

                  radialwidth={160}

                  labelValue={percentage}

                  radialcolor={((parseInt(percentage)) < 50) ? '#ff0000' : (parseInt(percentage) >= 50 && parseInt(percentage) <= 80) ? '#ff6600' : '#00e600'}

                ></RadialBar>}

              </center>
            </div>
          }

        </div>
        </div>
      </div>


      <div class="row ">
        <div class="col-md-12 col-xl-12 ">
          <div className='  '>
            {/* <div class="pt-4 pb-2 pl-2">
                  <p><b>Site Updates</b></p>
                </div> */}


            {/* <div class="col-xl-12 col-lg-12 col-md-12 pt-2 pb-3 labels ff">
              <b><center>Site Updates</center></b>
              {fulldatas.length == 0 && successstatus1 &&
                <div class=" login-error pt-3"><center><h6><b>No Data Available</b></h6></center></div>}
            </div> */}

            {/* {fulldatas.length != 0 && <div class=' row pt-2 table2style1 ' >

              {fulldatas.map((fulldata1, index) => {
                return (


                  <div class='  col-md-4 col-xl-4 ' key={index} >
                    <div className='card_style ml-4 mr-1 mb-5 mt-1 w-100'>
                      <div class='row bgccd '>
                   
                        <div class='col-md-6 col-xl-6 fontcolor'>
                          <b>{fulldata1.structure_item_id}</b>

                        </div>

                     
                        <div class='col-md-6 col-xl-6  '>
                          <p className={(fulldata1.completion_status) == 2 ? 
                            'inprogress' : (fulldata1.completion_status) == 4 ? 
                            'completed1' : (fulldata1.completion_status) == 1 ? 'delayed' :
                             (fulldata1.completion_status == 0) ? 'NotYetStarted' : 'done'}>

                             {(fulldata1.completion_status == 2) ? "inprogress" : 
                             (fulldata1.completion_status == 4) ? "completed" : 
                             (fulldata1.completion_status == 1) ? "delayed" : 
                             (fulldata1.completion_status == 0) ? "NotYetStarted" : "done"}
                             </p>
                        
                        </div>

                      </div>
                      <div className='line-style'>
                        <hr />
                      </div>

                      {fulldata1.completion_status == 4 && <div class='row bgc'>
                        <div class='col-md-5 col-xl-5 fontcolor'>
                          <b>CompletionDate:</b>
                        </div>
                        <div class='col-md-7 col-xl-7 fontcolor1'>
                          <p>{fulldata1.completed_on}</p>
                        </div>
                      </div>}


                      {(fulldata1.completion_status == 3
                         || fulldata1.completion_status == 0 || fulldata1.completion_status == 2 ||
                          fulldata1.completion_status == 1) 
                          && <div class='row bgccc pb-4'>

                        <div class='col-md-5 col-xl-5 fontcolor'>
                          <b>Item of work open:</b>
                        </div>
                        <div class='col-md-7 col-xl-7 fontcolor1'>
                          <p>{fulldata1.item_of_works_pending.join(', ')}</p>
                        </div>
                      </div>}
                    
                      <div className='line-style'>
                        <hr />
                      </div>
                    
                      <div className='bgccc'>
                        <div class='row '>
                          <div class='col-md-5 col-xl-5 fontcolor'>
                            <b>Item of work completed:</b>
                          </div>
                          <div class='col-md-7 col-xl-7 fontcolor1'>

                            <p>{fulldata1.item_of_works_completed.join(', ')}</p>

                          </div>
                        </div>
                      </div>
                      <div className='line-style'>

                        <hr />

                      </div>

                      <div className='fontcolor'>
                        <b>Resource Available :</b>

                      </div>
                  
                      <div class='row '>

                        {fulldata1.resource_names.map((dat, index) => {

                          return (
                            // <div className={(dat === 'Cement')?'cement':(dat === 'sand')?'sand':''}>dat</div>
                            <div class='col-md-4 col-lg-4 col-xl-4 pb-1 '>
                              <center>
                                <div className={(dat === 'Cement') ? 'bluebtn cement' : (dat === 'Sand') ? 'bluebtn  sand' :
                                  (dat === 'Aggregate') ? 'bluebtn aggregate' : (dat === 'Bricks') ? 'bluebtn  brick' : (dat === 'Stones') ?
                                    'bluebtn  stone' : (dat === 'Tiles') ? 'bluebtn  tiles' : (dat === 'Ironrod') ? 'bluebtn ironrod' : ""}>
                                  {dat}&nbsp;
                                  {fulldata1.quantities_instock[index]}

                                </div>
                              </center>
                            </div>
                          )
                        })}
                      </div>
                    </div>


                  </div>
                  // </div>

                )
              })}
            </div>
            } */}



          </div>
        </div>


        <div class="col-md-2 col-xl-2">


        </div>


      </div>



      <div >
        <div class=" table1st">
          <table
            class="table"
            readOnly="readOnly"
          >
            <div class='row'>

              <div class="pt-2 pb-2 col-xl-12 col-lg-12 col-md-12">
                 <Search data={tableData} />
              </div></div></table></div></div>
      {/* <div className='table1bdstyle'>
          <div class="row table1st">
          <div class="pt-1 pb-1">
                <p><b>Resources</b></p>
              </div>
              <div className='tableContainer' >
            <table
              class="table"
              readOnly="readOnly"
           

            >
              <tr class ='trst'>
                
                <th>Resource Type</th>
                <th>Required pack</th>
                <th>Quantity</th>
                <th>Purpose</th>
                <th>Request Status</th>

              </tr>
              {data1.map((data1,index) =>{
                return(
                  <tr key ={index}>
                 
                    <td className='fontcolor fontsize'><b>{data1.ResourceType}</b></td>
                  
                 
                    <td className='fontcolor1 fontsize1'>{data1.RequiredPack}</td>
                  
                    <td className='fontcolor fontsize'><b>{data1.Quantity}</b></td>
                  
                    <td className='fontcolor1 fontsize1'>{data1.Purpose}</td>
                     

                    <td>{((data1.RequestStatus) === 'Received'? <img src = {received} alt='received' height={30} width={90} />:'')||(data1.RequestStatus === 'Cancelled'? <img src ={cancelled} alt = 'cancelled' height = {30} width = {90} /> : <img src ={ordered} alt = 'ordered' height = {30} width = {90} />)}</td>

                  </tr>
                )
               })
              }
         
              


            </table>
            </div>
          </div>
         
        

          
        </div> */}

      {/* ///////////////////////////////// */}

      {/* <div className='table1bdstyle'>
          <div class="row table1st">
          <div class="pt-1 pb-1">
                <p><b>Work Progress</b></p>
              </div>
              <div className='tableContainer' >
            <table
              class="table"
              readOnly="readOnly"
           

            >
              <tr class ='trst'>
                
                <th>Work Item</th>
                <th>Work Status</th>
                <th>Est.Completed Date</th>
                <th>Resource</th>
                <th>Comment</th>

              </tr>
              {data2.map((data2,index) =>{
                return(
                  <tr key ={index}>
                    <td className='fontsize fontcolor'><b>{data2.Workitem}</b></td>
                    
                    <td>{((data2.Workstatus) === 'Inprogress'? <img src = {inprogress} alt='inprogress' height={30} width={90} />:'')||(data2.Workstatus === 'Completed'? <img src ={completed} alt = 'completed' height = {30} width = {90} />: <img src ={delayed} alt = 'delayed' height = {30} width = {90} />)}</td>
                    
                  
                    <td className='fontsize1 fontcolor1'>{data2.CompletionDate}</td>
                    <td className={(data2.Resource === 'AVAILABLE'?'fontsize color1':'')||(data2.Resource === 'REQUESTED'?'fontsize color2':'fontsize color3')}><b>{data2.Resource}</b></td>
                    <td className='fontsize1 fontcolor1'>{data2.Comment}</td>
                  </tr>
                )
               })
              }
         
              


            </table>
            </div>
          </div>
           */}

      {/* </div> */}

    </div>
  );
}

export default WeareHere;
