import React from 'react';
import RadialBar from '../ApexCharts/RadialBar';
import './RoadSubcontractor.css';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaStarAndCrescent } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from 'react'
import RequestModal from '../Modal/CreateRequest'
import StatusupdateModal from '../Modal/Edit'
import Renewal from '../Modal/Renewal'
import ViewTickets from '../Modal/ViewTickets'
import { baseurl } from '../BaseUrl/Baseurl'
import completed from '../../assets/images/Completed.png'
import inprogress from '../../assets/images/InProgimage.png'
import notyetstarted from '../../assets/images/not_yet_started.png'
import done from '../../assets/images/done.png'
import paused from '../../assets/images/paused.png'
import $ from 'jquery';
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import ButtonLink from '../ButtonLink/ButtonLink';
const WeareHere = () => {
  const [structureName , setStructureName ] = useState('')
  const [structureId, setStructureId] = useState('')
  const [itemName, setItemName] = useState('')
  const [compDate, setCompDate] = useState('')
  const [itemOfWork, setItemOfWork] = useState('')
  const [fulldata, setFulldata] = useState([]);
  const [btnname, setBtnname] = useState('')
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [caxis, setCaxis] = useState([]);
  const [daxis, setDaxis] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [graphform, setGraphform] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [contractorname, setContractorname] = useState('')
  const userData = useSelector((state) => state.userDetails)
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState(false);
  const drop = React.useRef(null);
  const [successstatus, setSuccessstatus] = useState(false);
 ///////////////drop down card for drop downbox ///////////////////////////////////////
function OnView(contractor){
//////////alert(contractor)
  setContractorname(contractor)
  setStatus(true)
}
 
  
                              
  useEffect(() => {

    let fData = new FormData()
    fData.append('project_id', userData.currentprojectId)
    // axios
    // .get(baseurl + '/api/vendor/management/details?project_id='+userData.currentprojectId, {
    //   headers: { 'Content-Type': 'multipart/form-data' },
    // })

    axios({

      method: 'GET',

      url:baseurl + '/api/vendor/management/details?project_id='+userData.currentprojectId,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {

//console.log(response.data)
        const dat = response.data
        //console.log("%%%%" + response.data)
        //console.log("hello" + dat)
        // //////////alert("We Got Calculated Details")
        setTableData(dat)

        setSuccessstatus(true)
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })
      
  },[])
   
  return (
    
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 pagetopspace ">

    {status &&<ButtonLink  btnPath="/SubContractorDetails" contractor={contractorname}/>}
{/* <div class="row">
              <div class=" col-xl-8 col-lg-8">
              
              </div>
              <div class=" col-xl-4 col-lg-4  pb-2">
            <Link to="/AddSubContractor"> 
            <button 
                    type="button"
                    className="createpro-btn"
                  >
                   &nbsp;Add</button>
            </Link> 
              </div>
            </div> */}
              {tableData.length==0  && successstatus &&
            // !checkduedate &&
           
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-5 pt-5">
                <center><h6><b>No Data Available</b></h6></center>
              
                </div>}
            <div>
            {tableData.length!=0  && <table
    class="table"
    readOnly="readOnly"
 

  >
    <tr class ='trst'>
      
      <th>Contractor Name </th>
      <th>Item of Work</th>
      <th>Progress %</th>
      <th>Level</th>
      <th>Status </th>
      <th> </th>
    </tr>
    {tableData.length!=0 && tableData.map((item,index) =>{
      return(
        <tr key ={index} className='tablefontsize'>
          <td className='fontsize fontcolor'>{item.contractor_name!=null && item.contractor_name!=undefined ? item.contractor_name:'NA'} </td>
          
       
          
        
          <td className='fontsize1 fontcolor1'>{item.item_of_work!=null && item.item_of_work!=undefined ? item.item_of_work:'NA'}</td>
          <td >{item.progress}</td>
          <td className='fontsize1 fontcolor1'>{item.level!=null && item.level!=undefined ? item.level:'NA'} </td>
          <td>{(item.status) === 'Inprogress'? <img src = {inprogress} alt='inprogress' height={50} width={120} />:
          (item.status === 'Completed'? <img src ={completed} alt = 'completed' height = {50}
           width = {120} />: 
           item.status === 'Paused' 
           ?
           <img src ={paused} alt = 'Paused' height = {50} width = {120} />
           :  item.status === 'Done' 
           ?
           <img src ={done} alt = 'Done' height = {50} width = {120} />
           : <img src ={notyetstarted} alt = 'NotYetStarted' height = {50} width = {120} />
           )}</td>
      
          {/* <td>  <center>
         
              <button
                      type="button"
                      className="viewtic-btn1"
                      onClick={() => OnView(item.contractor_name)}
                    >
                      <b>View</b>
                    </button>
                  
                    </center></td> */}
        </tr>
      )
     })
    }

    


  </table>}
</div>


{/* <div class="pt-3">
  <table
    class="table"
    readOnly="readOnly"
 

  >
    <tr class ='trst'>
      
      <th>Contractor Name</th>
      <th>Item of Work</th>
      <th>Completed Chainage</th>
      <th>Percentage of Work Completed / Chainage Wise</th>
      <th>Percentage of Work Completed / Structure Wise</th>
      <th>Status </th>
      <th>Payment</th>
      <th> </th>
    </tr>
    {tableData.length!=0 && tableData.map((item,index) =>{
      return(
        <tr key ={index}>
        <td className='fontsize fontcolor'>{item.contractor_name!=null && item.contractor_name!=undefined ? item.contractor_name:'NA'} </td>
        
     
        
      
        <td className='fontsize1 fontcolor1'>{item.item_of_work!=null && item.item_of_work!=undefined ? item.item_of_work:'NA'}</td>
        <td >{item.progress}</td>
        <td className='fontsize1 fontcolor1'>{item.level!=null && item.level!=undefined ? item.level:'NA'} </td>
        <td>{((item.status) === 'Inprogress'? <img src = {inprogress} alt='inprogress' height={30} width={90} />:'')||(item.status === 'Completed'? <img src ={completed} alt = 'completed' height = {30} width = {90} />: <img src ={delayed} alt = 'delayed' height = {30} width = {90} />)}</td>
    
        <td>  <center>
       
            <button
                    type="button"
                    className="viewtic-btn1"
                    onClick={() => OnView(item.contractor_name)}
                  >
                    <b>View</b>
                  </button>
                
                  </center></td>
      </tr>
      )
     })
    }

    


  </table>
  </div> */}
  </div>
// </div>




// </div>
// </div>

// </div>
 );
}

export default WeareHere;