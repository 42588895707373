import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ButtonLink(btnDetails) {
  const navigate = useNavigate()

  useEffect(() => {

    navigate(btnDetails.btnPath, {
      state: {
        id: btnDetails.id,
        contractor: btnDetails.contractor,
        vendorName:btnDetails.vendorName,
        vendorwork:btnDetails.vendorwork,
        vendorStatus:btnDetails.vendorStatus,
        contractorStatus:btnDetails.contractorStatus,
        contractorId:btnDetails.contractorId,
        vendorID:btnDetails.vendorID,
        projectName:btnDetails.projectName,
        projectType:btnDetails.projectType,
        projectId:btnDetails.projectId
       
      },
    })
  }, [
    navigate,
     btnDetails.btnPath,
     btnDetails.id,
    btnDetails.contractor,
    btnDetails.vendorName,
    btnDetails.vendorStatus,
    btnDetails.vendorwork,
    btnDetails.contractorStatus,
   btnDetails.contractorId,
   btnDetails.vendorID,
   btnDetails.projectName,
   btnDetails.projectType,
   btnDetails.projectId
  ])

  return null
}
