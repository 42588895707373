import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import '../Boq/Boq.css'
import View from '../Modal/View'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import cancelledimg from '../../assets/images/brick_icons/Support.png'
import acceptedimg from '../../assets/images/brick_icons/Support.png'
import pendingimg from '../../assets/images/brick_icons/Dashboard.png'
import { connect, useDispatch, useSelector } from 'react-redux'
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }

  const [calculatedlist, setCalculatedlist] = useState([])
  const userData = useSelector((state) => state.userDetails)
  const [successstatus, setSuccessstatus] = useState(false);
  const [data1, setData1] = useState([]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    let fData = new FormData()
    fData.append('project_id', userData.currentprojectId)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get?project_id=' + userData.currentprojectId, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

      axios({
        method: 'GET',

        url: baseurl+'/api/plan/specifications/get?project_id=' + userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
      })
      .then((response) => {

        const dat = response.data
        //console.log(response.data)
        //////alert("We Got Calculated Details")
        //console.log(dat)
        const dt = dat.data
        //////alert(JSON.stringify(dt))
        setCalculatedlist(dt)
        const head = dat.heading
        //////alert(JSON.stringify(head))
        setData1(head)
        setSuccessstatus(true)
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })



  }, [])





  const list1 = [
    { key1: "add", key2: "fdg", key3: "sds" },
    { key1: "add", key2: "fdg", key3: "sds" }
  ];

  return (
    <>

      {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
      {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}


      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  pb-5 pagetopspace">
      {calculatedlist.length == 0 && successstatus &&
                <div class=" login-error pt-3"><center><h6><b>No Data Available</b></h6></center></div>}
         {calculatedlist.length != 0 && 
              <div className=' scrollabletable'>
        <table
          class="table "
          readOnly="readOnly"

        >
          <tr class="trst">
            {/* <th>Level</th>
                <th>Sand</th>
                <th>Stone</th>
                <th>Cement</th> */}

            {data1.map((heading, index) =>
            (

              <th key={index}>{heading}</th>
            ))}

          </tr>

          {calculatedlist.length != 0 && calculatedlist.map((item, index) => {
            // const ppc_cement=item.total_ppc_cement_to_be_used
            // ppclen=ppc_cement.length

            return (
              <tr key={index} className='tablefontsize'>
                  {item.chainage!=undefined && item.chainage !=null && item.chainage!='' && 
                <td>
               {item.chainage.replace(/\./g, '/')}</td>}
               {item.level!=undefined && item.level !=null && item.level!='' && 
                <td>
               {item.level}</td>}
                {/* <td>{item.level!=undefined  ?item.level.replace(/\./g, '/'):''}</td> */}
                {item.road_type!=null && item.road_type!=undefined && item.road_type!='' && <td><div>{item.road_type}</div></td>}

                {item.total_ppc_cement_to_be_used!=null && item.total_ppc_cement_to_be_used!=undefined &&
               
               item.total_ppc_cement_to_be_used!=''  &&    <td>{item.total_ppc_cement_to_be_used}</td>}

               {item.total_opc_cement_to_be_used!=null && item.total_opc_cement_to_be_used!=undefined &&
               
               item.total_opc_cement_to_be_used!=''  && <td>{item.total_opc_cement_to_be_used}</td>}

               {item.total_sand_to_be_used!=null && item.total_sand_to_be_used!=undefined &&
               
               item.total_sand_to_be_used!=''  &&<td>{item.total_sand_to_be_used}</td>}


                {item.total_m_sand_to_be_used != '' && item.total_m_sand_to_be_used != undefined
                  && item.total_m_sand_to_be_used != null &&
                  <td>{item.total_m_sand_to_be_used}</td>}

                {item.total_p_sand_to_be_used != '' && item.total_p_sand_to_be_used != undefined
                  && item.total_p_sand_to_be_used != null &&
                  <td>{item.total_p_sand_to_be_used}</td>}

                {item.total_stone_dust_to_be_used != '' && item.total_stone_dust_to_be_used != undefined
                  && item.total_stone_dust_to_be_used != null &&
                  <td>{item.total_stone_dust_to_be_used}</td>}

                {item.total_8mm_aggregate_to_be_used != '' && item.total_8mm_aggregate_to_be_used != undefined
                  && item.total_8mm_aggregate_to_be_used != null &&
                  <td>{item.total_8mm_aggregate_to_be_used}</td>}


                {item.total_12mm_aggregate_to_be_used != '' && item.total_12mm_aggregate_to_be_used!= undefined
                  && item.total_12mm_aggregate_to_be_used != null &&
                  <td>{item.total_12mm_aggregate_to_be_used}</td>}

                {item.total_20mm_aggregate_to_be_used != '' && item.total_20mm_aggregate_to_be_used != undefined
                  && item.total_20mm_aggregate_to_be_used != null &&
                  <td>{item.total_20mm_aggregate_to_be_used}</td>}

                {item.total_40mm_aggregate_to_be_used != '' && item.total_40mm_aggregate_to_be_used != undefined
                  && item.total_40mm_aggregate_to_be_used != null &&
                  <td>{item.total_40mm_aggregate_to_be_used}</td>}


                {item.total_8mm_rods_to_be_used!= '' && item.total_8mm_rods_to_be_used != undefined
                  && item.total_8mm_rods_to_be_used != null &&
                  <td>{item.total_8mm_rods_to_be_used}</td>}

                {item.total_10mm_rods_to_be_used != '' && item.total_10mm_rods_to_be_used != undefined
                  && item.total_10mm_rods_to_be_used != null &&
                  <td>{item.total_10mm_rods_to_be_used}</td>}

                {item.total_12mm_rods_to_be_used != '' && item.total_12mm_rods_to_be_used != undefined
                  && item.total_12mm_rods_to_be_used != null &&
                  <td>{item.total_12mm_rods_to_be_used}</td>}

                {/* {item.total_10mm_rods_to_be_used != '' && item.total_10mm_rods_to_be_used != undefined
                  && item.total_10mm_rods_to_be_used != null &&
                  <td>{item.total_10mm_rods_to_be_used}</td>} */}
{/* 
{item.total_12mm_rods_to_be_used != '' && item.total_12mm_rods_to_be_used != undefined
                  && item.total_12mm_rods_to_be_used!= null &&
                  <td>{item.total_12mm_rods_to_be_used}</td>} */}

{item.total_16mm_rods_to_be_used != '' && item.total_16mm_rods_to_be_used != undefined
                  && item.total_16mm_rods_to_be_used!= null &&
                  <td>{item.total_16mm_rods_to_be_used}</td>}

{item.total_20mm_rods_to_be_used != '' && item.total_20mm_rods_to_be_used != undefined
                  && item.total_20mm_rods_to_be_used!= null &&
                  <td>{item.total_20mm_rods_to_be_used}</td>}

                  {item.total_25mm_rods_to_be_used != '' && item.total_25mm_rods_to_be_used != undefined
                  && item.total_25mm_rods_to_be_used!= null &&
                  <td>{item.total_25mm_rods_to_be_used}</td>}
{/* 
{item.total_25mm_rods_to_be_used != '' && item.total_25mm_rods_to_be_used != undefined
                  && item.total_25mm_rods_to_be_used!= null &&
                  <td>{item.total_25mm_rods_to_be_used}</td>} */}

{item.total_32mm_rods_to_be_used!= '' && item.total_32mm_rods_to_be_used != undefined
                  && item.total_32mm_rods_to_be_used!= null &&
                  <td>{item.total_32mm_rods_to_be_used}</td>}  

{item.total_36mm_rods_to_be_used!= '' && item.total_36mm_rods_to_be_used != undefined
                  && item.total_36mm_rods_to_be_used!= null &&
                  <td>{item.total_36mm_rods_to_be_used}</td>}  

{item.total_gravel_to_be_used!= '' && item.total_gravel_to_be_used != undefined
                  && item.total_gravel_to_be_used!= null &&
                  <td>{item.total_gravel_to_be_used}</td>}  

                  {item.total_gsb_to_be_used!= '' && item.total_gsb_to_be_used != undefined
                  && item.total_gsb_to_be_used!= null &&
                  <td>{item.total_gsb_to_be_used}</td>}  

{item.total_wmm_to_be_used!= '' && item.total_wmm_to_be_used != undefined
                  && item.total_wmm_to_be_used!= null &&
                  <td>{item.total_wmm_to_be_used}</td>}  


                 {item.total_dbm_to_be_used!= '' && item.total_dbm_to_be_used != undefined
                  && item.total_dbm_to_be_used!= null &&
                  <td>{item.total_dbm_to_be_used}</td>}   

                 {item.total_bituminous_concrete_to_be_used!= '' && item.total_bituminous_concrete_to_be_used != undefined
                  && item.total_bituminous_concrete_to_be_used!= null &&
                  <td>{item.total_bituminous_concrete_to_be_used}</td>}     

                    {item.total_prime_coat_to_be_used!= '' && item.total_prime_coat_to_be_used!= undefined
                  && item.total_prime_coat_to_be_used!= null &&
                  <td>{item.total_prime_coat_to_be_used}</td>}     

{item.total_tack_coat_to_be_used!= '' && item.total_tack_coat_to_be_used!= undefined
                  && item.total_tack_coat_to_be_used!= null &&
                  <td>{item.total_tack_coat_to_be_used}</td>}     

{item.total_paver_block_to_be_used!= '' && item.total_paver_block_to_be_used!= undefined
                  && item.total_paver_block_to_be_used!= null &&
                  <td>{item.total_paver_block_to_be_used}</td>} 

                  {item.total_diesel_to_be_used!= '' && item.total_diesel_to_be_used!= undefined
                  && item.total_diesel_to_be_used!= null &&
                  <td>{item.total_diesel_to_be_used}</td>}       

  {item.total_no_of_bricks_to_be_used!= '' && item.total_no_of_bricks_to_be_used!= undefined
                  && item.total_no_of_bricks_to_be_used!= null &&
                  <td>{item.total_no_of_bricks_to_be_used}</td>}      

{item.total_no_of_admixtures_to_be_used!= '' && item.total_no_of_admixtures_to_be_used!= undefined
                  && item.total_no_of_admixtures_to_be_used!= null &&
                  <td>{item.total_no_of_admixtures_to_be_used}</td>}      
                {/*    
            
                    <td></td>
                    <td></td>
                    
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td> */}

              </tr>
            )
          })}
        </table>
</div>}
        {/* <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                          <center>
                            <Link to="/UserCreation">
                            <button
                              type="submit"
                              className="user-btn"
                              name="submit"
                            // disabled={!(projectstatusValid )}
                            >
                              <b>Next</b>
                            </button>
                        
                          </Link>
                          </center>{' '}
                        </div> */}

      </div>





    </>
  )
}

export default FormPage
