import React from 'react';
import './Notification.css'; // Assuming styles are in a separate CSS file
import {Link} from 'react-router-dom';
import { useState,useEffect } from 'react';
import axios from 'axios'
import { baseurl } from '../BaseUrl/Baseurl'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import ButtonLink from '../ButtonLink/ButtonLink'
const Model1 = ({ isOpen, onClose, children1,notiupdate}) => {
  const userData = useSelector((state) => state.userDetails)
const [menuname, setMenuname] = useState('')
const dispatch = useDispatch()
const [notificationstatus,setNotificationstatus]=useState(false)

  if (!isOpen) {
    return null;
  }
 


const deletetable = (e,clientId,notitype,notificationno,project_id,message) => {
 
   e.preventDefault();

  if(notificationno === undefined)
   {
    notificationno = null;
   }

   //console.log("deleting",clientId)
   //console.log("deleting",notificationno)

    axios({
      method: 'DELETE',
    
      url: baseurl+'/api/notification/delete-all?client_id='+userData.clientId+'&notification_no='+notificationno,
    
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })
    .then((response) => {

    })


var projectmenuname=""
var resoucenemuname=""


if(message==="Resource Request")
{

resoucenemuname="material"
}
if(message==="Equipment Request")
{
  resoucenemuname="equipment"
}
if(message==="Site Update")
{

//projectmenuname="Project Progress Details"

projectmenuname="Site Updates"
//  menuname1="Project Progress details"
}


notiupdate(projectmenuname,project_id,message,resoucenemuname,notitype)


//onClose()
  }



  return (
    <div className="modal-overlay1" >
       {/* {notificationstatus && <ButtonLink btnPath="/Road" />} */}
      <div className="modal-content1" onClick={(e) => e.stopPropagation()}>
        <div class="pt-2 pb-2">
      <font className='noti_color'>NOTIFICATIONS</font>&nbsp;&nbsp;&nbsp;&nbsp;
      <button className='markall ' 
             
              onClick={(e) => deletetable(e,userData.clientId,"markallasread")}
            >Mark as Read</button>
            </div>
      
       <ul>
            { children1.length!=0 && children1.map((item,index) =>{
                return(
                  <li key ={index} className='borderdesign'    onClick={(e)=>deletetable(e,userData.clientId,"markoneasread",item.notification_no,item.project_id,item.messages)} >
             <div className={item.messages==="Site Update"? 'tile1':(item.messages==="Resource Request"?'tile2':'tile3')}>
                 <b>{item.messages}</b>&nbsp;for&nbsp;<font className='itemcolor'><b>{item.requested_for}</b>
               
                 </font>&nbsp;on&nbsp;{item.requested_date}</div>
                 </li>
                )})}
                
                </ul>
                {/* { children1.length!=0 && <div><button className='markall' 
            //  onClick={markAllAsRead}
            >Mark All as Read</button></div>} */}
      </div>
    </div>
  );
};

export default Model1;
