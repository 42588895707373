import React from 'react';
import './Level.css';
import { Outlet, Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import InputTextField from '../InputText/InputText';
import ButtonLink from '../ButtonLink/ButtonLink';
import { connect, useDispatch, useSelector } from 'react-redux'
import {
    updateLoginStatus,
    setUserName,
    setUserId,
} from '../../store/userDetails'
import Search from '../LevelSearch/Search'
import Thirdpartycont from '../ThirdPartyContractorDetails/ThirdPartyControctor'
import Equipmentmgnt from '../EquipmentMgnt/EquipmentMgnt'
import Thirdparty from '../ThirdParty/ThirdParty'
import PlanView from '../Modal/PlanView'
import parse from 'html-react-parser'
const WeareHere = (data) => {
    const [btnname, setBtnname] = useState('')
    const [modelStatus, setModelStatus] = useState(false);
    const [requeststatus, setRequeststatus] = useState(false);
    const [updatestatus, setUpdatestatus] = useState(false);
    const [renewalstatus, setRenewalstatus] = useState(false);
    const [viewticketstatus, setViewticketstatus] = useState(false);
    const [requestlist, setRequestlist] = useState([]);
    const [renewallist, setRenewallist] = useState([]);
    const [ticketlist, setTicketlist] = useState([]);
    const [projectid, setProjectid] = useState('');
    const [project_renewalid, setProject_renewalid] = useState('');
    const [ticketno, setTicketno] = useState('');
    const [itemlist, setItemlist] = useState([]);
    const [fulllist, setfulllist] = useState([]);
    const [successstatus, setSuccessstatus] = useState(false)
    const [error, setError] = useState('')
    const [successmsg, setSuccessmsg] = useState('');
    const [viewimagestatus, setViewimagestatus] = useState(false);
    const [modalstatus, setModalstatus] = useState(false);
    const [currentimg, setCurrentimg] = useState('')
    const [planimgnamelist, setPlanimgnamelist] = useState('')
    const [planimg, setPlanimg] = useState('')
    const [mindt, setMindt] = useState('')
    const [mindt1, setMindt1] = useState('')
    const [fulllist1, setfulllist1] = useState([]);
    const [fulllist2, setfulllist2] = useState([]);
    const [fulllist3, setfulllist3] = useState([]);
    const [newlist, setNewlist] = useState([]);
    

    const data1 = data.data
  
    const [selectedOption, setSelectedOption] = useState('Project_Timeline');
    const [tableData, setTableData] = useState([]);

    const formatDate = (inputDate) => {
        const parts = inputDate.split('-');
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];

        // Create a Date object
        const date = new Date(`${year}-${month}-${day}`);

        // Format the date as yyyy-mm-dd
        const formattedDate = date.toISOString().split('T')[0];
     
        return formattedDate;
    };

    const dueDatesArray = data1.map(item => {
      
        if (item != null || item != '' || item != undefined) {
            if (item.actual_start_date != null && item.actual_start_date != undefined && item.actual_start_date != '') {
              //  const formattedDate = formatDate(item.actual_start_date );
                return item.actual_start_date
            }
            else {
                return ''
            }
        }
        else return ''
    });

    const dueDatesArray1 = data1.map(item => {
        if (item != null || item != '' || item != undefined) {
            if (item.actual_completion_date != null && item.actual_completion_date != undefined && item.actual_completion_date != '') {
                // Return the original date if it's not in the expected format
               // const formattedDate = formatDate(item.actual_completion_date);
                return item.actual_completion_date
            }
            else {
                return ''
            }
        }
        else return ''
    });

    const dueDatesArray2 = data1.map(item => {
        if (item != null || item != '' || item != undefined) {
            if (item.due_date != null && item.due_date != undefined && item.due_date != '') {
                // Return the original date if it's not in the expected format
               // const formattedDate = formatDate(item.due_date);
                return item.due_date
            }
            else {
                return ''
            }
        }
        else return ''
    });
    const dueDatesArray3 = data1.map(item => {
        if (item != null || item != '' || item != undefined) {
            if (item.start_date != null && item.start_date != undefined && item.start_date != '') {
               // const formattedDate = formatDate(item.start_date);
                return item.start_date
            }
            else {
                return ''
            }
        }
        else return ''
    });
    // Now, you can assign it to your 'duedates' state
    const [duedates, setDuedates] = useState(dueDatesArray);
    const [duedates1, setDuedates1] = useState(dueDatesArray1);
    const [duedates2, setDuedates2] = useState(dueDatesArray2);
    const [duedates3, setDuedates3] = useState(dueDatesArray3);
    const [allDueDatesSelected, setAllDueDatesSelected] = useState(false); // New state variable
    const userData = useSelector((state) => state.userDetails)
    const dispatch = useDispatch()

    function handleDuedate(e, index, fl, qid) {
        setSuccessmsg('')
        duedates[index] = e.target.value
        setMindt(e.target.value)
        if (index < fulllist.length) {
            // Update existing object at the specified index
            fulllist[index].actual_start_date= e.target.value
        } else {
            // Index doesn't exist, push a new object
            fulllist.push({
                floor: fl,
                plan_spec_id: qid,
                actual_start_date: e.target.value,
                actual_completion_date:null,
                due_date:null,
                start_date:null,
                project_id: userData.currentprojectId,
               
            }
            )
        }
       
        validateDuedate(index);
    }

    function handleDuedate1(e, index, fl, qid) {
        setSuccessmsg('') 
        duedates1[index] = e.target.value
        setMindt1(e.target.value)
        if (index < fulllist.length) {
            // Update existing object at the specified index
            fulllist[index].actual_completion_date= e.target.value
        } else {
        fulllist.push({
            floor: fl,
            plan_spec_id: qid,
            actual_start_date:null,
            actual_completion_date: e.target.value,
            due_date:null,
            start_date:null,
            project_id: userData.currentprojectId,

        }
        )
    }
        validateDuedate(index);
       
    }

    
    function handleDuedate2(e, index, fl, qid) {
        setSuccessmsg('')
        duedates2[index] = e.target.value
        setMindt(e.target.value)
        if (index < fulllist.length) {
            // Update existing object at the specified index
            fulllist[index].due_date= e.target.value
        } else {
        fulllist.push({
            floor: fl,
            plan_spec_id: qid,
            actual_start_date:null,
            actual_completion_date:null,
            due_date: e.target.value,
            start_date:null,
            project_id: userData.currentprojectId
        }
        )
    }
        validateDuedate(index);
    }

    
    function handleDuedate3(e, index, fl, qid) {
        setSuccessmsg('')
        duedates3[index] = e.target.value
        setMindt(e.target.value)
        if (index < fulllist.length) {
            // Update existing object at the specified index
            fulllist[index].start_date= e.target.value
        } else {
        fulllist.push({
            floor: fl,
            plan_spec_id: qid,
            actual_start_date:null,
            actual_completion_date:null,
            due_date: null,
            start_date: e.target.value,  
            project_id: userData.currentprojectId
        }
        )
    }
        validateDuedate(index);
    }
   
    const [duedateErrors, setDuedateErrors] = useState(Array(data1.length).fill(''));

    function validateDuedate(index) {
        // Add your validation logic here
        // //console.log(index, index + 1)
        // if (duedates[index + 1] != "" && duedates[index + 1] != undefined) {
        //     //console.log(duedates[index + 1])
        //     setError("Please select proper duedates")
        //     const newDuedates = duedates.map((value, i) => (i > index ? ' ' : value));
        //     setDuedates(newDuedates);
        //     const newDuedates1 = duedates1.map((value, i) => (i > index ? ' ' : value));
        //     setDuedates1(newDuedates1);
        // }
        // else {
        //     setError("")
        // }
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        let formobj = new FormData()

        formobj.append('level', 'TYPE')
        formobj.append('structure_type', 'ALL')

        axios({
            method: 'get',
    
            url: baseurl + '/api/plan/view?level=' + 'FLOOR' + '&structure_type=' + 'SECTIONS'+'&project_id='+userData.currentprojectId,
    
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token,
            }
          })
            .then((response) => {

                const data = response.data
                const imglist = data.image_data
                setPlanimg(imglist)
                const namelist = data.filenames
                setPlanimgnamelist(namelist)

            })
            .catch((error) => {
                //console.log(error)
                //setErrormsg(error.response.data.message)
            })

    }, [duedates])
    const convertDateFormat = (inputDate) => {
        const [year, month, day] = inputDate.split('-');
        return `${day}-${month}-${year}`;
    };
    const convertDateFormat1 = (inputDate) => {
        const [year, month, day] = inputDate.split('-');
        return `${day}-${month}-${year}`;
    };

    function onSubmitEvent(event) {
        event.preventDefault()


// if(fulllist.length!=0 && fulllist1.length!=0)
// {
//         fulllist.forEach(function (item, index) {
//             fulllist1.forEach(function (item1, index) {
//                ////////alert("sdsdff"+item)

//                 const st = convertDateFormat(item.start_date);
//                 const ed = convertDateFormat1(item1.due_date);
//                 if ((item.floor === item1.floor) && (item.plan_spec_id === item1.plan_spec_id)) {

//                     newlist.push({
//                         floor: item.floor,
//                         plan_spec_id: item.plan_spec_id,
//                         start_date: st,
//                         project_id: item.project_id,
//                         due_date: ed
//                     })
                  
//                 }
//                 else {
                  
//                 }
//             })
//         })
//     }


//     if(fulllist.length!=0 && fulllist1.length==0)
//     {
         
//                 fulllist.forEach(function (item, index) {
//                    ////////alert("sdsdff"+item)
    
//                     const st = convertDateFormat(item.start_date);
                   
                   
    
//                         newlist.push({
//                             floor: item.floor,
//                             plan_spec_id: item.plan_spec_id,
//                             start_date: st,
//                             project_id: item.project_id,
//                             due_date: null
                      
//                     })
                   
//                 })
         
//         }


//         if(fulllist.length==0 && fulllist1.length!=0)
//         {
             
//                     fulllist1.forEach(function (item, index) {
//                        ////////alert("sdsdff"+item)
        
//                         const et = convertDateFormat(item.due_date);
                       
                       
        
//                             newlist.push({
//                                 floor: item.floor,
//                                 plan_spec_id: item.plan_spec_id,
//                                 start_date: null,
//                                 project_id: item.project_id,
//                                 due_date: et
                          
//                         })
                       
//                     })
             
//             }





// Iterate through each item in any one of the lists (assuming they have the same length)
// for (let i = 0; i < fulllist.length; i++) {
    // Combine date properties from all lists

    // const plannedstartdate = convertDateFormat(fulllist[i].start_date);
    // const plannedduedate = convertDateFormat1(fulllist[i].due_date);
    // const actualstartdate = convertDateFormat(fulllist[i].actual_start_date);
    // const actualduedate = convertDateFormat1(fulllist[i].actual_completion_date);

    // let mergedItem = {
    //     floor: fulllist[i].floor,
    //     plan_spec_id: fulllist[i].plan_spec_id,
    //     start_date: plannedstartdate,
    //     actual_start_date: actualstartdate, 
    //     due_date: plannedduedate,
    //     actual_completion_date:actualduedate,
    //     project_id: fulllist[i].project_id
    // };

    // Add the merged item to the final list
   // newlist.push(mergedItem);
//}

// //console.log("^^^^^"+JSON.stringify(fulllist))
       
      
        
if(fulllist.length!=0)
{
    const queryParams = encodeURIComponent(JSON.stringify(fulllist));

    const apiUrl = `${baseurl}/api/plan/specifications/update/due_date`;
    
        axios
            .put(apiUrl, fulllist, {
                headers: { 'Content-Type': 'application/json' ,
                'Authorization': 'Bearer' +' ' +userData.token
            },
            })

            .then((response) => {

                const dat = response.data
                //console.log(response.data)
                setSuccessstatus(true)
             
                if(fulllist.length!=0)
                {
                setSuccessmsg("Successfully Updated")
                }

            })
            .catch((error) => {
                //console.log(error)
                //setErrormsg(error.response.data.message)
            })
        }
        // }
    }
    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        //console.log(year)
        //console.log(month)
        //console.log(day)
        return `${year}-${month}-${day}`;
    }
    const todayDate = getTodayDate();

    function checkDueDateEntry() {
        var st = false
        duedates.map((val, index) => {
            if (val == '' || val == undefined) {
                st = true
            }

        })
        return st
    }


    function Viewimages() {
        setViewimagestatus(!viewimagestatus)

    }

    return (
        <div class="row pagetopspace1">
            <div class="col-xl-6 col-lg-6 "></div>
            <div class="col-lg-6 col-xl-6 g-0 pt-5">

                <h4><b><center>{planimgnamelist}</center></b></h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-xxl-6 pt-3">
                {/* {successstatus && <ButtonLink btnPath="/LevelSearch"
 
      />} */}
                {/* <div className='table1bdstyle'>
        <div class="row table1st "> */}


                <table
                    class="table  tablemargin"
                    readOnly="readOnly"

                >

                    <tr class="trst">
                        
                        <th><center>Level</center></th>
                        <th><center>Start date</center></th>
                        <th><center>End Date</center></th>


                    </tr>

                    {
                        data1.length != 0 &&
                        data1.map((item, index) => {
                            const parts = item.floor.split('-');
                            const result = parts.slice(1).join('-').trim();
                            const chainage = result.replace(/\./g, '/');
                            return (
                                <tr key={index} className='tablefontsize'>
                                    {/* <td>{index + 1}</td> */}
                                   
                                    <td ><center>{item.floor.split('-')[0].trim()}</center></td>
                                    <td>
                                        <div class="row">
                                            {/* <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 ">
                                             {duedates[index]}
                                        </div> */}
                                            <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 ">

                                            <center>
                                                {/* Planned Start date */}
                                                    <InputTextField
                                                        handleInputChange={(e) => handleDuedate3(e, index, item.floor, item.plan_spec_id
                                                        )}
                                                        valueText={duedates3[index]}
                                                        inputName={`duedate${index}`}
                                                        inputId={`duedate${index}`}
                                                        classname="date-box"
                                                        inputType="date"
                                                        placeholderValue="Set Due Date"

                                                    //  mindate={duedates[index]!=''?duedates[index]:''}
                                                    // disabled={
                                                    //     duedates[index - 1] != '' ? false : true}
                                                    disabled={duedates3[index]!=''?true:false}
                                                    ></InputTextField>

                                                 
                                                </center>

                                                <center>
                                                    {/* Actual start State */}
                                                    <InputTextField
                                                        handleInputChange={(e) => handleDuedate(e, index, item.floor, item.plan_spec_id
                                                        )}
                                                        valueText={duedates[index]}
                                                        inputName={`duedate${index}`}
                                                        inputId={`duedate${index}`}
                                                        classname="date-box"
                                                        inputType="date"
                                                        placeholderValue="Set Due Date"

                                                    //  mindate={duedates[index]!=''?duedates[index]:''}
                                                    // disabled={
                                                    //     duedates[index - 1] != '' ? false : true}
                                                    ></InputTextField>

                                                    <div className="login-error">{duedateErrors[index]} </div>
                                                </center>
                                                
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="row">
                                            {/* <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 ">
                                             {duedates[index]}
                                        </div> */}
                                            <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 ">
                                            <center>
                                                {/* Planned end date */}
                                                    <InputTextField
                                                        handleInputChange={(e) => handleDuedate2(e, index, item.floor, item.plan_spec_id
                                                        )}
                                                        valueText={duedates2[index]}
                                                        inputName={`duedate${index}`}
                                                        inputId={`duedate${index}`}
                                                        classname="date-box"
                                                        inputType="date"
                                                        placeholderValue="Set Due Date"
                                                        disabled={duedates2[index]!=''?true:false}
                                                    //  mindate={duedates[index]!=''?duedates[index]:''}
                                                    // disabled={
                                                    //     duedates[index - 1] != '' ? false : true}
                                                    ></InputTextField>

                                                   
                                                </center>
                                                <center>
                                                    {/* Actual end date */}
                                                    <InputTextField
                                                        handleInputChange={(e) => handleDuedate1(e, index, item.floor, item.plan_spec_id
                                                        )}
                                                        valueText={duedates1[index]}
                                                        inputName={`duedate${index}`}
                                                        inputId={`duedate${index}`}
                                                        classname="date-box"
                                                        inputType="date"
                                                        placeholderValue="Set Due Date"

                                                        // mindate={duedates[index] != '' ? duedates[index] : ''}

                                                    // disabled={
                                                    //     duedates1[index - 1] != '' ? false : true}
                                                    ></InputTextField>

                                                    {/* <div className="login-error">{duedateErrors[index]} </div> */}
                                                </center>

                                              
                                            </div>
                                        </div>
                                    </td>


                                </tr>
                            )
                        })}
                </table>

                <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-2 pb-5">

                    <center>
                        <div className="success">{successmsg}</div>
                        <button
                            type="submit"
                            className="user-btn1"
                            name="submit"
                            onClick={onSubmitEvent}
                            // disabled={checkDueDateEntry()}
                        >
                            <b>Submit</b>
                        </button>

                    </center>{' '}
                </div>
                {/*Assign_Work*/}

                {/*  */}
            </div>

            <div class="col-lg-1 col-xl-1 col-xxl-1 "></div>
            <div class="col-lg-5 col-xl-5 col-xxl-5 ">
                {/*        
        <center> <button
          type="submit"
          className="project-btn mb-5 "
          name="submit"
          onClick={Viewimages}
        >
          <b>View Images</b>
        </button></center>
        {
          viewimagestatus && planimg.length != 0 && planimgnamelist.length != 0 &&
          planimg.map((item, index) => {
 
 
            return (
              <>
                <center>
                  <button
                    key={index}
                    className=" imageview mb-2"
                    name="submit"
                    onClick={() => planModel(item)}
             
                  >
                    {planimgnamelist[index]}
                  </button>
 
                  <br></br>
                </center>
              </>
            )
 
          })
 
        }
        {
          modalstatus && currentimg != '' && <PlanView planimg={currentimg} status={modalstatus} btnClick={toggleModelStatus1} />
        } */}

                <div id="plan">

               {/* {parse(planimg.toString())} */}
               <img src={planimg} />
                </div>
            </div>

        </div>
    );
}

export default WeareHere;
