// import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import './Workreference.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import DuedateModel from '../Modal/SetDueDate'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import plan from '../../assets/images/ALL_FLOOR_PLAN.jpg'
import parse from 'html-react-parser'
import View from '../Modal/View'
import PlanView from '../Modal/PlanView'
function FormPage(data) {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  } const tabledata = data.data
  const userData = useSelector((state) => state.userDetails)
  const [level, setlevel] = useState('')
  const [levelValid, setlevelValid] = useState(false)
  const [levelerror, setlevelerror] = useState('')
  const [itemofwork, setItemofwork] = useState('')
  const [itemofworkValid, setItemofworkValid] = useState(false)
  const [itemofworkerror, setItemofworkerror] = useState('')
  const [structuretype, setStructuretype] = useState('ALL')
  const [structuretypeValid, setStructuretypeValid] = useState(false)
  const [structuretypeerror, setStructuretypekerror] = useState('')
  const [date, setDate] = useState('')
  const [dateValid, setDateValid] = useState(false)
  const [dateerror, setDateerror] = useState('')
  const [owner, setOwner] = useState('')
  const [ownerValid, setOwnerValid] = useState(false)
  const [ownererror, setOwnererror] = useState('')
  const [requeststatus, setRequeststatus] = useState(false);
  //   const [itemofworkValid, setProjecttypeValid] = useState(false)
  //   const [projecttypeerror, setProjecttypeerror] = useState('')
  const [successstatus, setSuccessstatus] = useState(false)
  const [reslist1, setReslist1] = useState([])
  const [reslist2, setReslist2] = useState([])
  const [itemofworklist, setItemofworklist] = useState([])
  const [structurelist, setStructurelist] = useState([])
  const [checkboxval, setCheckboxval] = useState(false);
  const [checklist, setChecklist] = useState([])
  const [checkduedate, setCheckduedate] = useState(false);
  const [viewimagestatus, setViewimagestatus] = useState(false);
  const [modalstatus, setModalstatus] = useState(false);
  const [currentimg, setCurrentimg] = useState('')
  const [planimgnamelist, setPlanimgnamelist] = useState([])
  const [planimg, setPlanimg] = useState('')
  const [structureitem, setStructureitem] = useState('')
  const [structuretype1, setStructuretype1] = useState('')
  const [viewstatus, setViewstatus] = useState(false)
  const [buttonColor, setButtonColor] = useState('blue');
  const [isClicked, setIsClicked] = useState(false);
  const [index, setIndex] = useState(0);
  const [btnIndex, setBtnIndex] = useState(null);
  // const list1 = [
  //   { key1: "add", key2: "fdg" },
  //   { key1: "add", key2: "fdg" }
  // ];
  // const list2 = [
  //   { key1: "add", key2: "fdg", key3: "sds", key4: "sds" },
  //   { key1: "add", key2: "fdg", key3: "sds", key4: "sds" }
  // ];
  const [checkboxes, setCheckboxes] = useState(Array(reslist1.length).fill(false));
  const [checkboxErrors, setCheckboxErrors] = useState(Array(reslist1.length).fill(''));

  useEffect(() => {

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    // axios
    //   .get(baseurl + '/api/plan/list/structure_items&work_items?project_id=' + userData.currentprojectId, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

    //   .then((response) => {

    //     const dat = response.data
    //     //console.log(response.data)

    //     const itemwork = dat.item_of_works

    //     // setItemofworklist(itemwork)
    //     const modifiedList = itemwork.map(item => item.replace(/ /g, '_'));
    //     setItemofworklist(modifiedList)
    //     const struc = dat.structure_types
    //     const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
    //     setStructurelist(modifiedList1)

    //   })
    //   .catch((error) => {
    //     //console.log(error)
    //     //setErrormsg(error.response.data.message)
    //   })

    // let fData = new FormData()
    // fData.append('project_id', userData.projectId)
    // axios
    //   .get(baseurl + '/api/plan/specifications/get/current_level?project_id=' + userData.projectId, +fData, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

    //   .then((response) => {

    //     //console.log(response.data)
    //     const dat = response.data
    //     const lel = dat.current_progress_level
    //     // setlevel(lel)

    //   })
    //   .catch((error) => {
    //     //console.log(error)
    //     //setErrormsg(error.response.data.message)
    //   })



  }, [successstatus, checkboxes, checklist, structuretype])

  function handlelevel(e) {
    const val6 = e.target.value.replace(/-.*$/, '')
    setSuccessstatus(false)
    setlevel(e.target.value)
    validateField(e.target.name, e.target.value)

    setStructuretype('ALL')
    setItemofworkValid('')
    const leveldata = e.target.value
   
    let fData = new FormData()
    fData.append('project_id', userData.currentprojectId)
    fData.append('level', val6)

    // axios
    //   .get(baseurl + '/api/plan/list/structure_type?project_id=' + userData.currentprojectId + '&level=' + val6, +fData, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

    axios({
      method: 'GET',

      url: baseurl + '/api/plan/list/structure_type?project_id=' + userData.currentprojectId + '&level=' + val6,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {

        //console.log(response.data)
        const dat = response.data
        const struc = dat.structure_types
        // const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
        //console.log(struc)
        setStructurelist(struc)

      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })
  }
  function handleitemofwork(e) {
    //console.log(e.target.value)
    setItemofwork(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handlestructuretype(e) {

    //console.log(e.target.value)
    setStructuretype(e.target.value.replace(/\s+/g, ''))
    setSuccessstatus(false)
    validateField(e.target.name, e.target.value)
  }
  function handledate(e) {
    //console.log(e.target.value)
    setDate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleOwner(e) {
    //console.log(e.target.value)
    setOwner(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  //    function handleCheckboxChange(e,item) {
  //     //console.log(e.target.value)
  //      setCheckboxval(e.target.value.replace(/\s+/g, ''))
  //      validateField(e.target.name, e.target.value)
  //    }
  const validateField = (fieldName, value) => {
    if (fieldName === 'itemofwork') {

      if (value != 'Select') {
        setItemofworkerror('')
        setItemofworkValid(true)
      } else {
        setItemofworkerror('Please select')
        setItemofworkValid(false)
      }
    }
    if (fieldName === 'structuretype') {

      if (value != 'Select') {
        setStructuretypekerror('')
        setStructuretypeValid(true)
      } else {
        setStructuretypekerror('Please select')
        setStructuretypeValid(false)
      }
    }
    if (fieldName === 'owner') {

      if (value != 'Select') {
        setOwnererror('')
        setOwnerValid(true)
      } else {
        setOwnererror('Please select')
        setOwnerValid(false)
      }
    }
    if (fieldName === 'date') {

      if (value != '') {
        setDateerror('')
        setDateValid(true)
      } else {
        setDateerror('Please select')
        setDateValid(false)
      }
    }
  }

  const updateList1 = () => {

    // setReslist2(checklist);
    // //console.log("SEARCH"+JSON.stringify(checklist))
    // if(newList1.length!=0)
    // {

    // }
    // else{
    //   setReslist2(reslist1);
    // }
  };

  const handleCheckboxChange = (index, item1, item2, item3) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setCheckboxes(updatedCheckboxes);

    // You can add validation logic here for the specific checkbox
    // const error = validateCheckbox(updatedCheckboxes[index], index);
    // const updatedErrors = [...checkboxErrors];
    // updatedErrors[index] = error;
    // setCheckboxErrors(updatedErrors);
    // //console.log(item1, item2, item3)

    const found = checklist.find((obj) => {
      return obj.structure_item == item1
    })
    if (found === undefined) {
      //console.log("Notfound running")
      checklist.push({
        structure_item: item1,
        structure_item_type: item2,
        t_id: item3
      }
      )
    }
    else {
      //console.log("found running")
      if (!updatedCheckboxes[index]) {
        const updatedChecklist = checklist.filter(item => item.structure_item != item1);
        setChecklist(updatedChecklist);
      }
    }

  };

  const validateCheckbox = (isChecked, index) => {
    // Add your validation logic here for the specific checkbox
    if (!isChecked) {
      return 'Checkbox is required.';
    }

    return '';
  };
  function onSubmitEvent(event) {
    event.preventDefault()
    //console.log(level)
    //console.log(itemofwork)

    //console.log(date)
    //console.log(owner)
    setSuccessstatus(false)
    setReslist1([])
    setReslist2([])
    setCheckduedate(false)
    setPlanimg([])
    setViewimagestatus(false)
    setBtnIndex(null)
    setCurrentimg('')
    const stype = structuretype.replace(/_/g, ' ')

    //console.log(stype)
    //used for take type alone from chainage
    const lv = level.replace(/-.*$/, '')
    const firstHyphenIndex = level.indexOf('-');

    // Extract the text after the first hyphen
    const extractedText = firstHyphenIndex !== -1 ? level.slice(firstHyphenIndex + 1).trim() : null;
    //////////alert(extractedText)

    // if (itemofworkValid && structuretypeValid && dateValid && ownerValid) {

    const d = date;
    const currentDate = new Date(d)
    const day = String(currentDate.getDate()).padStart(2, '0')
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const year = currentDate.getFullYear()

    //  // Format the current date as 'DD-MM-YYYY'
    const currentDateString1 = `${day}-${month}-${year}`
    //  //console.log(currentDateString1)

    let fmData5 = new FormData()
    fmData5.append('project_id', userData.currentprojectId)
    fmData5.append('item_of_work', itemofwork)
    fmData5.append('structure_type', stype)
    fmData5.append('due_date', null)
    fmData5.append('owner', owner)
    fmData5.append('level', extractedText)

    // axios
    //   .get(baseurl + '/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId +
    //     '&item_of_work=' + itemofwork + '&structure_type=' + stype + '&owner=' + owner
    //     + '&level=' + extractedText, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //     // data: fmData5,
    //   })

    axios({
      method: 'GET',

      url: baseurl + '/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId +
        '&item_of_work=' + itemofwork + '&structure_type=' + stype + '&owner=' + owner
        + '&level=' + extractedText,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        //console.log(response.data)
        // //////////////alert("Run")
        const data = response.data
        const dt = data.searched_data
        //console.log(data)
        setReslist1(dt)
        setSuccessstatus(true)
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })

    //}
    let formobj = new FormData()

    // //////////////alert(structuretype)
    //console.log(lv)
    //console.log(stype)
    formobj.append('level', lv)
    formobj.append('structure_type', stype)
    //////////////alert(lv)
    //////////////alert(stype)
    // axios
    //   .get(baseurl + '/api/plan/view?level=' + lv+'&structure_type='+stype+'&project_id='+userData.currentprojectId, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //     // data: fmData5,
    //   })


    axios({
      method: 'GET',

      url: baseurl + '/api/plan/view?level=' + lv + '&structure_type=' + stype + '&project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {

        // //////////////alert("Run")
        const data = response.data
        //console.log(JSON.stringify(data))
        // const dataUrl = `data:image/png;base64,${btoa(
        //   new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
        // )}`;
        // Set the data URL in state
        // setImageData(dataUrl);
        const imglist = data.image_data
        //////////////alert(imglist)
        setPlanimg(imglist)
        //console.log("madhu" + data)
        // //////////////alert("ImageData"+data)

        const namelist = data.filenames
        setPlanimgnamelist(namelist)
        // //////////////alert(imglist.length)
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })

  }
  const toggleModelStatus = () => {
    setRequeststatus(false)
    setCheckduedate(true)
    setReslist1([])
    setChecklist([])
    const d = date;
    const y = 'f0'
    const currentDate = new Date(d)
    const day = String(currentDate.getDate()).padStart(2, '0')
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const year = currentDate.getFullYear()

    //  // Format the current date as 'DD-MM-YYYY'
    const currentDateString1 = `${day}-${month}-${year}`
    //  //console.log(currentDateString1)
    let fmData6 = new FormData()
    fmData6.append('project_id', userData.currentprojectId)
    fmData6.append('item_of_work', itemofwork)
    fmData6.append('structure_type', structuretype)
    fmData6.append('due_date', null)
    fmData6.append('owner', owner)
    fmData6.append('level', level)

    // axios
    //   .get(baseurl + '/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId +
    //     '&item_of_work=' + itemofwork + '&structure_type=' + structuretype + '&owner=' + owner
    //     + '&level=' + level, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //     // data: fmData5,
    //   })

    axios({
      method: 'GET',

      url: baseurl + '/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId +
        '&item_of_work=' + itemofwork + '&structure_type=' + structuretype + '&owner=' + owner
        + '&level=' + level,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        //console.log(response.data)
        const data = response.data
        //console.log(data)
        setReslist1(data)
        setSuccessstatus(true)
        setCheckboxes(Array(data.length).fill(false))
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })
  };

  const [checkedItems, setCheckedItems] = useState(new Array(reslist1.length).fill(false));

  //   const handleCheckboxChange = (index) => {
  //     const updatedCheckedItems = [...checkedItems];
  //     updatedCheckedItems[index] = !updatedCheckedItems[index];
  //     setCheckedItems(updatedCheckedItems);
  //   };
  const requestdata = () => {
    //console.log("*****" + requeststatus)
    setRequeststatus(true)

  }
  const l = []
  function Viewimages() {
    setViewimagestatus(!viewimagestatus)

  }

  function planModel(imgval, idx) {
    setModalstatus(true)
    setCurrentimg(imgval)
    setIsClicked(!isClicked);
    setBtnIndex(idx)

  }
  function toggleModelStatus1() {

    setModalstatus(false)
  }
  const toggleModelStatus2 = () => {

    setViewstatus(false)
  };
  const onclickdata = (sitem, stype) => {
    setStructureitem(sitem)
    setStructuretype1(stype)
    setViewstatus(true)
  }
  return (
    <>

      {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
      {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}
      {/* <div className="cont-margin tmar">
        <div className='table1bdstyle'>
          <div class='row pb-2 table1st '> */}
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  pagetopspace">

        <form
          class="row d-flex justify-content-center align-center  pt-5 "

        >
         
          <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Chainage
            </label>
            <select
              className="project-box"
              id="level"
              name="level"
              defaultValue={level}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={level}
              onChange={handlelevel}
            >
              <option value="All" selected>
                Select
              </option>

              {tabledata.map((item, index) => {
                const parts = item.floor.split('-');
                const result = parts.slice(1).join('-').trim();
                const chainage = result.replace(/\./g, '/');
                return (
                  <option key={index} value={item.floor}>
                    {chainage}
                  </option>)
              })}
            </select>
            <div className="login-error"></div>
          </div>

          <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Structures&nbsp;and&nbsp;Layer
            </label>
            <select
              className="project-box"
              id="structuretype"
              name="structuretype"
              defaultValue="All"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={structuretype}
              onChange={handlestructuretype}
            >
              <option value="ALL" selected>
                Select
              </option>
              {/* <option value="0">Project</option>
                          <option value="1">Payment</option> */}
              {structurelist.map((name, index) => (
                <option key={index} value={name.replace(/ /g, '_')}>
                  {name}
                </option>
              ))}
            </select>
            <div className="login-error"></div>
          </div>
         
          <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-5 ">
            <center>
              <button
                type="submit"
                className="project-btn"
                name="submit"
                onClick={onSubmitEvent}
              // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
              //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
              // disabled={!(itemofworkValid && structuretypeValid && dateValid && ownerValid)}
              >
                <b>Search</b>
              </button>
            </center>{' '}
          </div>

        </form>

       
        {successstatus && reslist1.length == 0 &&
        
          <div className='row '>
            <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error " >
              <center><b><h6>No Data Available</h6></b></center>
            </div>
          </div>}
        {successstatus && reslist1.length != 0 &&
        

          <div class="row ">

            <div class="col-xl-5 col-lg-5">
            <table
                class="table"
                readOnly="readOnly"
              >

                <tr class="trst">
               
                  <th><center>Structure Id</center></th>
                  <th><center>Item of Work</center></th>
                
                </tr>
                {viewstatus &&
                  <View status={viewstatus} btnClick={toggleModelStatus2} structureitem={structureitem}
                    structuretype={structuretype1}
                  />}

                {reslist1 != 0 && reslist1.map((item, index) => {
                  return (
                    <tr key={index} className='tablefontsize'>     
                      <td><center> &nbsp;&nbsp;&nbsp;{item.structure_item_id}</center> </td>
                  
                      <td><center> 
                        <button
                        onClick={() => onclickdata(item.structure_item_id, item.structure_item_type)}
                        type="button"
                        className="user-btn"
                      >View
                      </button></center> </td>
                    
                    </tr>
                  )
                })}
              </table>
            </div>
            <div class="col-xl-7 col-lg-7 ">

              <center> <button
                type="submit"
                className="project-btn5 mb-5 "
                name="submit"
                onClick={Viewimages}
              // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
              //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
              // disabled={!(itemofworkValid && structuretypeValid && dateValid && ownerValid)}
              >
                <b>View Images</b>
              </button>

                {
                  viewimagestatus && planimg.length == 0 && planimgnamelist.length == 0 &&
                  <div class=" login-error pt-1"><center><h6><b>No Data Available</b></h6></center></div>
                }


              </center>
              <div class="row">
                {
                  viewimagestatus && planimg.length != 0 && planimgnamelist.length != 0 &&
                  planimg.map((item, index) => {


                    return (
                      <>
                        <div class="col-lg-4 col-xl-4 col-md-4 col-4"  >
                          <center>
                            <button
                              key={index}
                              //  className=" imageview mb-2"
                              name="submit"
                              onClick={() => planModel(item, index)}
                              className={(btnIndex === index) ? ' imagebtn mb-2 clicked' : ' imagebtn mb-2 normal'}
                            // className={`my-button ${(isClicked && index==index) ? 'clicked' : ''}`}

                            // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
                            //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                            // disabled={!(itemofworkValid && structuretypeValid && dateValid && ownerValid)}
                            >
                              {planimgnamelist[index]}
                            </button>

                            <br></br>
                          </center>
                        </div>
                      </>
                    )

                  })

                }
                 <div class="col-lg-12 col-xl-12 col-md-12 g-0  ">

{viewimagestatus && <img src={currentimg} width="100%" height="auto" />}

</div>
              </div>
            </div>
         
           
          </div>

        }




      </div>




    </>
  )
}

export default FormPage
