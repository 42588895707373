import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ChartComponent = () => {
  const xValues = [1, 2, 3, 4, 5];
  const data8YValues = [0, 5, null, 10, 23];
  const data7YValues = [5, 10, 15, null, 30];

  // Replace null values with the previous non-null value in data8YValues
  for (let i = 1; i < data8YValues.length; i++) {
    if (data8YValues[i] === null) {
      data8YValues[i] = data8YValues[i - 1];
    }
  }

  // Replace null values with the previous non-null value in data7YValues
  for (let i = 1; i < data7YValues.length; i++) {
    if (data7YValues[i] === null) {
      data7YValues[i] = data7YValues[i - 1];
    }
  }

  const chartData = {
    options: {
      chart: {
        type: 'line',
        menu: false, // Hide the context menu
        toolbar: 
        { show: true, 
         tools: { 
           download: false, 
           selection: false,
            zoom: false, 
            zoomin: false,
             zoomout: false,
              pan: false,
              reset: false }, },
      },
      xaxis: {
        type: 'category',
        categories: xValues,
        title: {
          text: 'Date',
        },

      },
      yaxis: {
        title: {
          text: 'No of Work Completed',
        },
      },
    },
    series: [
      {
        name: 'Planned',
        data: data8YValues.map((y, index) => ({
          x: xValues[index],
          y: y,
        })),
      },
      {
        name: 'Actual',
        data: data7YValues.map((y, index) => ({
          x: xValues[index],
          y: y,
        })),
      },
    ],
  };

  return (
    <div >
      <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350} />
    </div>
  );
};

export default ChartComponent;

