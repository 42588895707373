import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import './BuildingSiteUpdates.css';

import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import '../Boq/Boq.css'

import View from '../Modal/View'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import cancelledimg from '../../assets/images/brick_icons/Support.png'
import acceptedimg from '../../assets/images/brick_icons/Support.png'
import pendingimg from '../../assets/images/brick_icons/Dashboard.png'
import { connect, useDispatch, useSelector } from 'react-redux'
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const [date, setDate] = useState('')
  const [fulldatas, setFulldatas] = useState([]);
  const [successstatus1, setSuccessstatus1] = useState(false);

  const [dateValid, setDateValid] = useState(false)
  const [dateerror, setDateerror] = useState('')
  const [todate, setTodate] = useState('')
  const [todateValid, setTodateValid] = useState(false)
  const [todateerror, setTodateerror] = useState('')
  const [calculatedlist, setCalculatedlist] = useState([])
  const userData = useSelector((state) => state.userDetails)
  const [data1, setData1] = useState([]);
  const [status, setStatus] = useState(false)

  const [siteUpdatesuccessstatus,setSiteUpdatesuccessstatus] = useState(false)
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      let fmData10 = new FormData()
      fmData10.append('project_id', userData.currentprojectId)
      fmData10.append('site_updates', 1)
  
        axios({
          method: 'GET',
  
          url: baseurl+'/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId + '&site_updates=' + 1,
  
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + userData.token
          },
          data:fmData10
        })
  
  
  
        .then((response) => {
  
          //console.log("carddata", response.data)
          const carddata = response.data
          const cdt = carddata.searched_data
  
          //console.log(cdt)
          setFulldatas(cdt)
           setSiteUpdatesuccessstatus(true)
  
  
          //  const structureName1 = cdata.structure_item_type
          //   setStructureName(structureName1)
          //   //console.log(structureName1)
          //  const structureId1 = cdata.structure_item_id
          //   setStructureId(structureId1)
          //   const itemName1=cdata.Brickwork
          //   setItemName(itemName1)
          //  const compDate1=cdata.due_date
          //   setCompDate(compDate1)
          //  const itemOfWork1=cdata.item_of_works
          //   setItemOfWork(itemOfWork1)
  
  
  
        })
  
        .catch((error) => {
  
          //console.log("carderror", error)
  
          //setErrormsg(error.response.data.message)
  
        })
    }, [])


    function handledate(e) {
        //console.log(e.target.value)
        
        setDate(e.target.value.replace(/\s+/g, ''))
      }

      function handletodate(e) {
        //console.log(e.target.value)
        
        setTodate(e.target.value.replace(/\s+/g, ''))
      }

  function onSubmitEvent(event) {
    event.preventDefault();
    
  
  
  }
  return (
    <>
      
     

      <div class="row pagetopspace ">
        <div class="col-md-12 col-xl-12 col-lg-12 ">
          <div className=''>
          
            <div class="col-md-12 col-xl-12 col-lg-12 col-lg-12  pt-5 pb-3 labels ff">
              <center> <p><b>Site Updates</b></p></center>
            </div>
            { fulldatas.length == 0 && siteUpdatesuccessstatus &&
            <div className='row '>
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error">
                <center><b><h6>No Data Available</h6></b></center>
                </div>
                </div>}
                {fulldatas.length !== 0 && 
            <div class=' row pt-2  ' >
             {fulldatas.map((fulldata1, index) => {
                return (


                  <div class='  col-md-4 col-xl-4  col-lg-4' key={index} >
                    <div className='card_style ml-4 mr-1 mb-5 mt-1 w-100'>
                      <div class='row bgccd '>
                        {/* <div className='bgccc'> */}
                        <div class='col-md-6 col-xl-6 fontcolor'>
                          <b>{fulldata1.structure_item_id}</b>

                        </div>

                        {/* <div class ='col-md-4 col-xl-4 inprogress'> */}
                        <div class='col-md-6 col-xl-6  col-lg-4'>
                        <p className={
                            
                            (fulldata1.completion_status) == 1 ? 'inprogress1' :
                            (fulldata1.completion_status) == 2 ? 'paused' :
                            (fulldata1.completion_status) == 3 ? 'done' :
                              (fulldata1.completion_status == 0) ? 'NotYetStarted' :
                            'completed1'
                          }>
                          
                            {(fulldata1.completion_status == 1) ? "In Progress" :
                             (fulldata1.completion_status == 2) ? "Paused" :
                             (fulldata1.completion_status == 3) ? "Done" :
                             (fulldata1.completion_status == 0) ? "Not Yet Started" :
                             "Completed"}
                          </p>
                          {/* <p className="inprogress">inprogress</p> */}
                        </div>

                      </div>
                      <div className='line-style'>
                        <hr />
                      </div>

                      {fulldata1.completion_status == 4 && 
                      <div class='row bgc'>
                        <div class='col-md-5 col-xl-5 col-lg-5 fontcolor'>
                          <b>CompletionDate:</b>
                        </div>
                        <div class='col-md-7 col-xl-7 col-lg-7 fontcolor1'>
                          <p>{fulldata1.completed_on}</p>
                        </div>
                        <div className='line-style'>
                        <hr />
                      </div>
                      </div>
                      
                     } 

                    
                      {(fulldata1.completion_status == 3 || fulldata1.completion_status == 0 || fulldata1.completion_status == 2 || fulldata1.completion_status == 1) && 
                     
                     <div class='row bgccc pb-4'>

                        <div class='col-md-5 col-xl-5 col-lg-5 fontcolor'>
                          <b>Item of work open:</b>
                        </div>
                        <div class='col-md-7 col-xl-7 col-xl-7 fontcolor1'>
                          <p>{fulldata1.item_of_works_pending.length!=0 ?fulldata1.item_of_works_pending.join(', '):'NA'}</p>
                        </div>
                      </div>
                       } 
                      {/* {fulldata1.completion_status == 0 && fulldata1.completion_status == 1   && fulldata1.completion_status == 2 && fulldata1.completion_status == 3 &&      */}
                      <div className='line-style'>
                        <hr />
                      </div>
                      {/* // } */}


                      <div className='bgccc'>
                        <div class='row '>
                          <div class='col-md-5 col-xl-5 col-lg-5 fontcolor'>
                            <b>Item of work completed:</b>
                          </div>
                          <div class='col-md-7 col-xl-7 col-lg-7 fontcolor1'>

                  
                            <p>{fulldata1.item_of_works_completed.length > 0 ? fulldata1.item_of_works_completed.join(', ') : 'NA'}</p>

                          </div>

                        </div>
                      </div>
                      <div className='line-style'>
                        <hr />
                      </div>
                      <div className='bgccc'>
                        <div class='row '>
                          <div class='col-md-5 col-xl-5 fontcolor'>
                            <b>Workers:</b>
                          </div>
                          <div class='col-md-7 col-xl-7 fontcolor1'>

                            <p>{fulldata1.workers_list.length > 0 ? fulldata1.workers_list.join(', ') : 'NA'}</p>

                          </div>
                        </div>
                      </div>

                      <div className='line-style'>

                        <hr />

                      </div>

                      {/* <div className='fontcolor'>
                        <b>Resource Used:</b>

                      </div> */}
                      {/* <div class='row'> */}
                      <div class='row '>

                        {fulldata1.resource_names.map((dat, index) => {

                          return (
                            // <div className={(dat === 'Cement')?'cement':(dat === 'sand')?'sand':''}>dat</div>
                            <div class='col-md-4 col-lg-4 col-xl-4 pb-1 '>
                              <center>
                                <div className={(dat === 'Cement') ? 'bluebtn cement' : (dat === 'Sand') ? 'bluebtn  sand' :
                                  (dat === 'Aggregate') ? 'bluebtn aggregate' : (dat === 'Bricks') ? 'bluebtn  brick' : (dat === 'Stones') ?
                                    'bluebtn  stone' : (dat === 'Tiles') ? 'bluebtn  tiles' : (dat === 'Ironrod') ? 'bluebtn ironrod' : ""}>
                                  {dat}&nbsp;
                                  {fulldata1.quantities_instock[index]}

                                </div>
                              </center>
                            </div>
                          )
                        })}
                      </div>
                    </div>


                  </div>
                  // </div>

                )
              })}
            </div>
}

          </div>
        </div>


        <div class="col-md-2 col-xl-2">


        </div>


      </div>
    </>
  )
}

export default FormPage
