import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import './ProjectConfirmation.css'
import { Outlet, Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { updateLoginStatus, setUserName, setUserId } from '../../store/userDetails'

export default function Model({status,handleClick,onExit
 
}) {
  //// console.log('UUUUUUU' + isOnline)
   const userData = useSelector((state) => state.userDetails)
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    // if (userData.usertype === 'paid_user') {
    //   dispatch(
    //     updateLoginStatus({
    //       isLoggedIn: isLoggedIn,
    //       // isLoggedInDemoUser: isLoggedInDemoUser,
    //       userName: uname,
          
    //     })
    //   )
    // }

    
    
  }, [])


  return (
    <>
     <Modal
  show={status}
  onHide={onExit}
  id="modal-size1"
  backdrop="static"
>
  <Modal.Body className="modal-custom">
    <div className="modal-header-custom">
      Are you sure, you want to move on another project?
    </div>
    <br />
    <center>
      <Button className="modal-button-yes" onClick={onExit}>
        Yes
      </Button>
      <Button className="modal-button-no" onClick={handleClick}>
        No
      </Button>
    </center>
  </Modal.Body>
</Modal>

    </>
  )
}
