import React, { useState, useEffect } from 'react';
import Resourcemanagementline from '../ApexCharts/Resourcemanagementline';
import ResourceReceivedgraph from '../ApexCharts/ResourceReceivedgraph';
import ResourceInstockgraph from '../ApexCharts/ResourceInstockgraph';

import Graphresourcemgmt from '../ApexCharts/Resourcebar';
import sandcorrect from '../../assets/images/buttons/sandcorrect.png';
import brickcorrect from '../../assets/images/buttons/brickcorrect.png';
import stonescorrect from '../../assets/images/buttons/stonescorrect.png';
import ironrodcorrect from '../../assets/images/buttons/ironrodcorrect.png';
import cementcorrect from '../../assets/images/buttons/cementcorrect.png';
import ResourceSearch from '../ResourceSearch/ResourceSearch2';
import Equipmentmgmt from '../EquipmentMgnt/EquipmentMgnt';
import WorkerMgmt from '../WorkerMgmt/WorkerMgmt1'
import axios from 'axios';
import './BuildingMaterialMgmt.css';
import $ from 'jquery';
import { baseurl } from '../BaseUrl/Baseurl';
import { Tab, Tabs } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import MaterialRquest from '../Modal/MaterialRequest'
import DocumentView from '../Modal/DocumentView'
import { FaPlus, FaEdit, FaStarAndCrescent } from "react-icons/fa";
import MultiBarGraph from '../ApexCharts/Multibarchart'
const WeareHere = () => {
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [yaxis, setYaxis] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [xaxis2, setXaxis2] = useState([]);

  const userData = useSelector((state) => state.userDetails)
  const [yaxis1, setYaxis1] = useState([]);
  const [chainage, setChainage] = useState([]);
  const [matrialArray, setMaterialArray] = useState([]);
  const [matriallist, setMateriallist] = useState([]);
  const [successstatus1, setSuccessstatus1] = useState(false);

  const [yaxis2, setYaxis2] = useState([]);

  const [xaxis1, setXaxis1] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(true)
  const [materialRequestId, setMaterialRequestId] = useState('');
  const [materialRequestprojectid, setMaterialRequestprojectid] = useState('');
  const [materialRequeststatus, setMaterialRequeststatus] = useState(false)
  const [materialRequestedstatus, setMaterialRequestedstatus] = useState(false)
  const [imagelist, setImagelist] = useState('');
  const [modalStatus, setModalStatus] = useState(false);
  const [purchaseorder, setPurchaseorder] = useState('');
  const [purchaseorderurldownload, setPurchaseorderurldownload] = useState('');
  const [successstatus, setSuccessstatus] = useState(false);
  const [expectedresource, setExpectedresource] = useState([]);
  const [actualresource, setActualresource] = useState([]);
  const [levels, setLevels] = useState([]);


  useEffect(() => {

    //Level wise resource Graph
    axios({
      method: 'GET',

      url: baseurl + '/api/material/list/graph/usage_data?project_id=' + userData.currentprojectId + '&material_type=' + selectedMaterial,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      },

    })
      .then((response) => {

        //console.log(response.data)
        const lldat = response.data
        //console.log("level based item graph", lldat)
        const level = lldat.levels_or_chainages || []
        setLevels(level)
        const planned = lldat.planned_quantities || []
        setExpectedresource(planned)
        const actual = lldat.actual_used_quantities || []
        setActualresource(actual)
      })
      .catch((error) => {
        //console.log("levelerr1", error)

      })
  }, [selectedMaterial])

  function handlestatus(e, rid) {
    //console.log(e.target.value)
    setStatus(e.target.value.replace(/\s+/g, ''))
    const st = e.target.value
    axios
      .put(`${baseurl}/api/request/resource/update?project_id=` + userData.currentprojectId
        + '&resource_requested_id=' + rid + '&requested_status=' + st, {

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userData.token}`,
        },

      })
      .then((response) => {
        const data = response.data;

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  const handleMaterialChange = (event) => {
    setSelectedMaterial(event.target.value);
  };
  const [resourceData, setResourceData] = useState({
    material_type_list: [],
    total_quantity_instock_list: [],
  });

  const [materialUsedData, setMaterialUsedData] = useState({
    totalQuantityUsedList: [],
    formattedDateList: [],
  });
  const tocloseModal = () => {
    setModalStatus(false);
  };


  const requestEdit = (projectid, requestid, status) => {

    setMaterialRequestId(requestid)
    setMaterialRequestprojectid(projectid)
    setMaterialRequestedstatus(status)
    setMaterialRequeststatus(true);

  };


  function documentClick(reqid, purchaseorderurl) {

    setPurchaseorderurldownload(purchaseorderurl)

    axios({
      method: 'GET',

      url: baseurl + '/api/purchase_order/view_report?request_id=' + reqid,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        const res = response.data

        const doc = res.purchase_order

        setPurchaseorder(doc)
      })
      .catch((error) => {
        //console.log(error)
      })
    setModalStatus(true)
  };


  function goscroll(direction) {
    if (direction === 1) {
    } else {
    }
    const far = $('.image-container').width() / 2 * direction;
    const pos = $('.image-container').scrollLeft() + far;
    $('.image-container').animate({ scrollLeft: pos }, 1000);
  }


  const closeMaterialRequestModal = () => {
    setMaterialRequeststatus(false);

    //To refresh the table
    axios
      .get(`${baseurl}/api/request/list/resources_requested?project_id=` + userData.currentprojectId, {

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        const mt = data.resources_requested;

        setData2(mt)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };



  useEffect(() => {


    //material usage analysis


    axios({
      method: 'GET',

      url: baseurl + '/api/material/search?project_id=' + userData.currentprojectId + '&status=ALL',

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {

        const dat = response.data
        setMateriallist(dat)
        setSuccessstatus1(true)
      })
      .catch((error) => {
        //console.log(error)
      })




    //mATERIAL RQUEST

    axios
      .get(`${baseurl}/api/request/list/resources_requested?project_id=` + userData.currentprojectId, {

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userData.token}`,
        },

      })
      .then((response) => {
        const data = response.data;
        const mt = data.resources_requested;
        setData2(mt)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });


    //DropDownlist
    axios({
      method: 'GET',

      // url: baseurl + '/api/project/list/resources&equipments?project_id=' + userData.currentprojectId,
      url: baseurl + '/api/material/list/used_resources?project_id=' + userData.currentprojectId,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })

      .then((response) => {
        const data = response.data;
        const mt = data.used_resources_list || [];
        setData1(mt);
        setSelectedMaterial(mt[0])
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    axios
      .get(`${baseurl}/api/material/list/instock`, {
        params: {
          project_id: userData.currentprojectId,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userData.token}`,
        },

      })
      .then((response) => {
        const data6 = response.data;
        setResourceData(data6);
        //console.log('Data received3:', data6);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    setLoading(true)
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/material/list/used`, {
          params: {
            project_id: userData.currentprojectId,
            material_type: selectedMaterial,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userData.token}`,
          },

        });

        const data = response.data;


        setXaxis(data.formattedDateList)
        setXaxis2(data.formattedDateList)

        setYaxis(data.totalQuantityUsedList)
        setYaxis2(data.quantityinstockList)


        setLoading(false)
        //console.log('Data received10:', response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();



    setLoading(true)
    const fetchData1 = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/material/list/received`, {
          params: {
            project_id: userData.currentprojectId,
            material_type: selectedMaterial,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userData.token}`,
          },

        });

        const data = response.data;

        setXaxis1(data.formattedDateList)
        setYaxis1(data.quantityreceivedList)

        setLoading(false)
        //console.log('Data received11:', response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData1();

    axios({
      method: 'GET',

      url: baseurl + '/api/material/list/usage_data?project_id=' + userData.currentprojectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })
      .then((response) => {

        const dat = response.data

        const chainage = dat.levels
        const materials = dat.materials

        setChainage(chainage)
        setMaterialArray(materials)
      })
      .catch((error) => {
        //console.log(error)
      })

  }, [selectedMaterial]);




  return (
    <div className="container pagetopspace">
      <div className="col-lg-12 col-md-12 col-xl-12 pt-2 pl-5">





        <div className="row">
          <div className=" col-xl-12 col-lg-12 pt-3 pb-3">

            <div>

              <div className="labels ff pt-2 pb-3"> <center> <b>Material Usage Report</b></center>  </div>
            </div>
            <div class="pb-5">
              <table id="tablenewstyle" readOnly="readOnly">
                <tr class="">
                  <th>Materials</th>
                  {chainage != undefined && chainage.map((type, index) => {
                    return (
                      <th key={index}><center>{type}</center></th>
                    )
                  })}

                </tr>


                {matrialArray != undefined && matrialArray.map((type, index) => {
                  const { material_name, data } = type;

                  return (
                    <React.Fragment key={index}>
                      {data.map((arr, i) => (
                        <tr key={`${material_name}_${i}`} className='tablefontsize' >
                          {i === 0 && <td rowSpan="3" className={i == 0 ? 'bborder' : ''}><b>{material_name}</b></td>}
                          {arr.map((value, j) => (
                            <td key={`${material_name}_${i}_${j}`} className={i == 0 ? 'firstrowfont bluerow' : i == 2 ? 'bborder blackrow' : 'greenrow'} >{value}</td>
                          ))}
                        </tr>
                      ))}
                    </React.Fragment>
                  );
                })}


              </table>
            </div>


            <div>

              <div className="labels ff pt-2 pb-3"> <center> <b>Material Requested</b></center>  </div>
            </div>
            {data2.length == 0 && successstatus && <div class="pt-3 login-error">
              <center><h6>No New Request</h6></center>
            </div>}
            {
              data2.length != 0

              &&
              <table
                class="table "
                readOnly="readOnly"
              >
                <tr class="trst1">
                  <th><div className='trpad'>Material</div></th>
                  <th>Quantity</th>
                  <th>Date</th>
                  <th>Level</th>
                  <th>Status</th>
                  <th>Edit</th>
                  <th>Purchase Order</th>
                </tr>
                {materialRequeststatus && (
                  <MaterialRquest
                    status={materialRequeststatus}
                    btnClick={closeMaterialRequestModal}
                    ProjectId={materialRequestprojectid}
                    RequestId={materialRequestId}
                    RequestStatus={materialRequestedstatus}
                  />

                )}

                {modalStatus && (
                  <DocumentView
                    status={modalStatus}
                    btnClick={tocloseModal}
                    imageData={purchaseorder}
                    imageUrl={purchaseorderurldownload}



                  />
                )}
                {data2.length != 0 && data2.map((item, index) => {

                  return (
                    <tr key={index} className='tablefontsize'>

                      <td><div className='trpad'>{item.material_name != undefined ? item.material_name : 'NA'}</div></td>
                      <td>{item.quantity_required != undefined ? item.quantity_required : 'NA'}</td>
                      <td>{item.date != undefined ? item.date : 'NA'}</td>
                      <td>{item.level != undefined ? item.level : 'NA'}</td>
                      <td>
                        {item.status != undefined ? item.status : 'NA'}
                      </td>
                      <td>
                        <center>
                          <button
                            type="button"
                            className="modalbtn"
                            onClick={() => requestEdit(userData.currentprojectId, item.resource_request_id, item.status)}
                            disabled={item.status === "DELIVERED" ? true : false}
                          >
                            <b>
                              <FaEdit />
                            </b>
                          </button>
                        </center>
                      </td>
                      <td>
                        <center>
                          {item.purchase_order_report_url != 'NA' ?
                            <button
                              type="button"
                              className="modalbutton"
                              onClick={() => documentClick(item.resource_request_id, item.purchase_order_report_url)}
                            >

                              Document

                            </button> : 'NA'}
                        </center>
                      </td>

                    </tr>
                  )
                })}
              </table>}
          </div>
          <div className="labels ff pt-3 pb-1 col-xl-12 col-lg-12 col-md-12  "><center> <b>Material Usage Analysis</b></center>   </div>
          {matriallist.length == 0 && successstatus1 && <div class=" col-xl-12 col-lg-12 col-md-12   pt-3   pb-3 login-error">
            <center><h6>No Data Available </h6></center>
          </div>}
          {matriallist.length != 0 && <table
            class="table "
            readOnly="readOnly"
          >
            <tr class="trst1">
            <th>Date</th>
              <th><div className='trpad'>Material Name</div></th>
              <th>Total Received</th>
              <th>Total Used</th>
              <th>Instock</th>


            </tr>

            {matriallist.map((item, index) => {

              return (
                <tr key={index} className='tablefontsize'>
<td><center>{item.date!=undefined?item.date:'NA'}</center> </td>
                  <td><div className='trpad'>{item.material != null && item.material != undefined ? item.material : 'NA'}</div></td>
                  <td>{item.total_quantity_received != null && item.total_quantity_received != undefined ? item.total_quantity_received : 'NA'}</td>
                  <td> {item.total_quantity_used != null && item.total_quantity_used != undefined ? item.total_quantity_used : 'NA'}</td>
                  <td> {item.total_quantity_instock != null && item.total_quantity_instock != undefined ? item.total_quantity_instock : 'NA'}</td>


                </tr>
              )
            })}
          </table>}
        </div>


        {data1.length !== 0 && <div className="pl-5 col-xl-4 col-lg-4  ">
          <div className="custom-dropdown pt-4 pb-5 ">


            &nbsp; &nbsp; &nbsp;
            <select
              id="materialSelect"
              onChange={handleMaterialChange}
              value={selectedMaterial}
              className="project-box"
            >
              {data1.length !== 0 && data1.map((name, index) => (
                <option key={index} value={name}>
                  {name.replace(/_/g, ' ')}
                </option>
              ))}
            </select>
          </div>
        </div>
        }



        <div className="col-lg-12 col-xl-12">
          {expectedresource.length != 0 && actualresource.length != 0 && levels.length != 0 &&
            < MultiBarGraph
              graph1="Planned Resource"
              graph2="Actual Resource"
              graph1data={expectedresource}
              graph2data={actualresource}
              xaxisdata={levels}
              xaxistext="Levels"
              yaxistext="Quantities"
              colorList={['#0066ff', '#00e600']}
            />}
        </div>

        <div className="col-lg-12 col-xl-12">
          <ResourceSearch />
        </div>




        <br></br>



      </div>

    </div>
  );
};

export default WeareHere;
