import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';

const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState([]);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setImgSrc((prevImgSrc) => [...prevImgSrc, imageSrc]);
    }
  };
  
  const videoConstraints = {
    facingMode: { exact: "environment" } // Request the back camera
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div style={{ marginBottom: '20px' }}>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
         // videoConstraints={videoConstraints}
          style={{
            borderRadius: '8px',
            width: '100%',
            // maxWidth: '400px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
          }}
        />
      </div>
      <button 
        onClick={capture} 
        style={{
          padding: '10px 20px',
          borderRadius: '5px',
          backgroundColor: '#007BFF',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          marginBottom: '20px'
        }}
      >
        Capture photo
      </button>
      <h2>Captured Image</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
        {imgSrc.map((src, index) => (
          <img 
            key={index}
            src={src} 
            alt={`captured-${index}`} 
            style={{
              width: '100%',
              maxWidth: '200px',
              borderRadius: '8px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default WebcamCapture;
