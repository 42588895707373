import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import './ThirdPartyControctor.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import DuedateModel from '../Modal/SetDueDate'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import Twoline from '../ApexCharts/Twoline'
import Twobar from '../ApexCharts/TwoBar'
import SubcontSearch from '../ThirdPartyContractorDetails/SubontractorSearch'
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const userData = useSelector((state) => state.userDetails)

  const [structuretype, setStructuretype] = useState('')
  const [structuretypeValid, setStructuretypeValid] = useState(false)
  const [structuretypeerror, setStructuretypekerror] = useState('')
  const [projectstatus, setProjectstatus] = useState('')
  const [projectstatusValid, setProjectstatusValid] = useState(false)
  const [projectstatuserror, setProjectstatuserror] = useState('')

  const [date, setDate] = useState('')
  const [requeststatus, setRequeststatus] = useState(false);
  const [successstatus, setSuccessstatus] = useState(false)
  const [reslist1, setReslist1] = useState([])
  const [structurelist, setStructurelist] = useState([])
  const [checklist, setChecklist] = useState([])
  const [reslist2, setReslist2] = useState([])
  const [tableData, setTableData] = useState([]);
  // const list1 = [
  //   { key1: "add", key2: "fdg" },
  //   { key1: "add", key2: "fdg" }
  // ];
  const list2 = [
    { key1: "add", key2: "fdg", key3: "sds", key4: "sds" },
    { key1: "add", key2: "fdg", key3: "sds", key4: "sds" }
  ];
//   const [checkboxes, setCheckboxes] = useState(Array(reslist1.length).fill(false));
//   const [checkboxErrors, setCheckboxErrors] = useState(Array(reslist1.length).fill(''));

  useEffect(() => {

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    axios
      .get(baseurl + '/api/plan/list/structure_items&work_items', {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      .then((response) => {

        const dat = response.data
        //console.log(response.data)

        const struc = dat.structure_types

        setStructurelist(struc)

      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })

   
      let fData = new FormData()
      fData.append('project_id', userData.currentprojectId)
      axios
      .get(baseurl + '/api/plan/specifications/get/due_date?project_id=' + userData.currentprojectId, +fData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
  
        .then((response) => {
  
  //console.log(response.data)
          const dat1 = response.data
          //console.log("%%%%" + response.data)
          //console.log("hello" + dat1)
          // ////alert("We Got Calculated Details")
          setTableData(dat1)
  
       
        })
        .catch((error) => {
          //console.log(error)
          //setErrormsg(error.response.data.message)
        })
  }, [successstatus])


  function handlestructuretype(e) {
    //console.log(e.target.value)
    setStructuretype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleProjectstatus(e) {
    //console.log(e.target.value)
     setProjectstatus(e.target.value.replace(/\s+/g, ''))
     validateField(e.target.name, e.target.value)
   }

  //    function handleCheckboxChange(e,item) {
  //     //console.log(e.target.value)
  //      setCheckboxval(e.target.value.replace(/\s+/g, ''))
  //      validateField(e.target.name, e.target.value)
  //    }
  const validateField = (fieldName, value) => {
  
    if (fieldName === 'structuretype') {

      if (value != 'Select') {
        setStructuretypekerror('')
        setStructuretypeValid(true)
      } else {
        setStructuretypekerror('Please select')
        setStructuretypeValid(false)
      }
    }
  
  }

  function onSubmitEvent(event) {
    event.preventDefault()
   //console.log(projectstatus)
   //console.log(structuretype)

    // if (itemofworkValid && structuretypeValid && dateValid && ownerValid) {


    //  //console.log(currentDateString1)
    // let fmData5 = new FormData()
    // fmData5.append('project_id', userData.projectId)
    // fmData5.append('item_of_work', itemofwork)
    // fmData5.append('structure_type', structuretype)
    // fmData5.append('due_date', '22-10-2023')
    // fmData5.append('owner', owner)
    // fmData5.append('level', y)

    // axios
    //   .get(baseurl + '/api/plan/list/item_of_work/search?project_id=' + userData.projectId +
    //     '&item_of_work=' + itemofwork + '&structure_type=' + structuretype + '&due_date=' + currentDateString1 + '&owner=' + owner
    //     + '&level=' + y, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //     // data: fmData5,
    //   })

    //   .then((response) => {
    //     //console.log(response.data)
    //     ////alert("Run")
    //     const data = response.data
    //     //console.log(data)
    //     setReslist1(data)
        setSuccessstatus(true)
    //   })
    //   .catch((error) => {
    //     //console.log(error)
    //     //setErrormsg(error.response.data.message)
    //   })

    //}


  }
  

  return (
    <>

      {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
      {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}
      <div className="cont-margin-latest padpagetop">
        <div className='table1bdstyle'>
          <div class='row pb-2 table1st '>
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  ">
             
          
                  
                        <Link to="/Building">
                        <button
                          type="submit"
                          className="thirdparty-btn1"
                          name="submit"  
                          float="right"   
                                     
                        >
                          <b>Back</b>
                        </button>
                        </Link>
                    
             
                <div class='row'>
                <div class="col-lg-3 col-xl-3 pt-5 pb-3 ">
{/* <Twobar /> */}
</div>
            <div class="col-lg-6 col-xl-6 pt-5 pb-3 ">
<Twoline />
</div>
<div class="col-lg-3 col-xl-3 pt-5 pb-3 ">
{/* <Twobar /> */}
</div>
</div>
<div class="col-lg-12 col-xl-12 pt-5 pb-3 ">
<SubcontSearch data={tableData}/>
</div>
              
              {/* <div >
          
                {successstatus && list2.length != 0 &&
                  // !checkduedate &&
                  <div className='table1bdstyle '>
                    <div class="row table1st">

                      <table
                        class="table"
                        readOnly="readOnly"

                      >

                        <tr class="trst">
                          
                          <th><center>Structure Id</center></th>
                          <th><center>Status</center></th>
                          <th><center>Comment</center></th>
                
                        </tr>

                        {list2 != 0 && list2.map((item, index) => {
                          return (
                            <tr key={index} className='tablefontsize'>
                            
                              
                              <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.key1}</center> </td>
                              <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.key1}</center> </td>
                              <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.key1}</center> </td>
                            </tr>
                          )
                        })}
                      </table>

                    </div>

                  </div>}
             
               
              
              </div> */}
            </div>


            </div>
            </div>
            </div>
          




    </>
  )
}

export default FormPage
