import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import '../ProgressSearch/ProgressSearch.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
// import DuedateModel from '../Modal/SetDueDate'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'

import completed from '../../assets/images/comp.png'
import inprogress from '../../assets/images/buttons/inprogress.png'
import  received from '../../assets/images/buttons/received.png'
import delayed from '../../assets/images/buttons/delayed.png'
import  cancelled from '../../assets/images/buttons/cancelled.png'
function FormPage(data) {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const userData = useSelector((state) => state.userDetails)
  const [level, setlevel] = useState('')
  const [level1, setlevel1] = useState('')
  const [levelValid, setlevelValid] = useState(false)
  const [levelerror, setlevelerror] = useState('')
  const [itemofwork, setItemofwork] = useState('')
  const [itemofworkValid, setItemofworkValid] = useState(false)
  const [itemofworkerror, setItemofworkerror] = useState('')
  const [structuretype, setStructuretype] = useState('')
  const [structuretypeValid, setStructuretypeValid] = useState(false)
  const [structuretypeerror, setStructuretypekerror] = useState('')
  const [date, setDate] = useState('')
  const [dateValid, setDateValid] = useState(false)
  const [dateerror, setDateerror] = useState('')
  const [owner, setOwner] = useState('')
  const [ownerValid, setOwnerValid] = useState(false)
  const [ownererror, setOwnererror] = useState('')
  const [requeststatus, setRequeststatus] = useState(false);
  const [successstatus, setSuccessstatus] = useState(false)
  const [reslist1, setReslist1] = useState([])
  const [reslist2, setReslist2] = useState([])
  const [itemofworklist, setItemofworklist] = useState([])
  const [structurelist, setStructurelist] = useState([])
  const [checkboxval, setCheckboxval] = useState(false);
  const [checklist, setChecklist] = useState([])
  const [checkduedate, setCheckduedate] = useState(false);

  const [checkboxes, setCheckboxes] = useState(Array(reslist1.length).fill(false));
  const [checkboxErrors, setCheckboxErrors] = useState(Array(reslist1.length).fill(''));
  const [strucliststatus, setStrucliststatus] = useState(false);
  const [projectstatus, setProjectstatus] = useState('')
  const [projectstatusValid, setProjectstatusValid] = useState(false)
  const [projectstatuserror, setProjectstatuserror] = useState('')

  const leveldata = data.data
  useEffect(() => {

if(level=='' && level1=='')
{
    //  axios
    //  .get(baseurl + '/api/plan/list/structure_type?project_id=' + userData.currentprojectId ,
    //    //+fData,
    //    {
    //      headers: { 'Content-Type': 'multipart/form-data' },
    //    })
       axios({
        method: 'GET',
  
        url: baseurl + '/api/plan/list/structure_type?project_id=' + userData.currentprojectId ,
  
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
  
      })
     .then((response) => {

      //  //console.log(response.data)
       const dat = response.data

       const struc = dat.structure_types
       // const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
    
     
       setStructurelist(struc)
     })
     .catch((error) => {
       //console.log(error)
       //setErrormsg(error.response.data.message)
     })
    }
    // // axios
    //   .get(baseurl+'/api/plan/list/structure_items&work_items?project_id='+userData.currentprojectId, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

    //   .then((response) => {

    //     const dat = response.data
    //     //console.log(response.data)

    //     const itemwork = dat.item_of_works

    //     // setItemofworklist(itemwork)
    //     const modifiedList = itemwork.map(item => item.replace(/ /g, '_'));
    //     setItemofworklist(modifiedList)
    //     const struc = dat.structure_types
    //     const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
    //     setStructurelist(modifiedList1)

    //   })
    //   .catch((error) => {
    //     //console.log(error)
    //     //setErrormsg(error.response.data.message)
    //   })





  
   


  }, [successstatus, checkboxes, checklist, strucliststatus])
  const resultList = [];
  function generateList() {
   
  
    for (let i = 1; i <= 6; i++) {
      for (let j = 0; j <= 99; j++) {
        const item = `${i}/${j}`;
        resultList.push(item);
      }
    }
  
    return resultList;
  }
   
  const myList = generateList();

  // function getStructuretype(levelval)
  // {

  //   if(!strucliststatus)
  //   {

  //     let fDatanew = new FormData()
  //     fDatanew.append('project_id', userData.currentprojectId)
  //     fDatanew.append('level', levelval)
  //     axios
  //       .get(baseurl + '/api/plan/structure_type?project_id=' + userData.currentprojectId+'&level='+levelval, +fDatanew, {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //       })

  //       .then((response) => {

  //         //console.log(response.data)
  //         const dat = response.data
  //         const struc = dat.structure_types      
  //         setStructurelist(struc)
  //         setStrucliststatus(true)

  //       })
  //       .catch((error) => {
  //         //console.log(error)
  //         //setErrormsg(error.response.data.message)
  //       })
  //   }
  // }
  function handlelevel(e) {
    //console.log(e.target.value)
    setlevel(e.target.value)
    setStructuretype('')
   // validateField(e.target.name, e.target.value)
    let chainage = e.target.value
    //////alert(chainage)
    // let schainage = ''
    // let echainage = ''
  //////////alert(e.target.value)
    // if (level != undefined && level!=='') {
   
    //   schainage = schainage1.replace(/-.*$/, '')
   
    // }
    // if (level1 != undefined && level1!=='') {
   
    //   echainage = level1.replace(/-.*$/, '')
    // }
 
//      if ( schainage1!='') {
// //////////alert("Yes not empty")
//       schainage = schainage1.replace('/', '.')
   
//     }
//     if (level1!='') {
   
//       echainage = level1.replace('/', '.')
//     }
   //////////alert(schainage)
   //////////alert(echainage)
    // let fData1 = new FormData()
    // fData1.append('project_id', userData.currentprojectId)
    // fData1.append('start_chainage', leveldata)
    // fData1.append('end_chainage', "Type3")
    const firstHyphenIndex = chainage.indexOf('-');

    // Extract the text after the first hyphen
    const extractedText = firstHyphenIndex !== -1 ? chainage.slice(firstHyphenIndex + 1).trim() : null;
    //////alert(extractedText)
    // axios
    //   .get(baseurl + '/api/plan/list/structure_type?project_id=' + userData.currentprojectId + '&level=' +extractedText ,
    //     //   +fData1,
    //     {
    //       headers: { 'Content-Type': 'multipart/form-data' },
    //     })

        axios({
          method: 'GET',
  
          url: baseurl+'/api/plan/list/structure_type?project_id=' + userData.currentprojectId + '&level=' +extractedText ,
  
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + userData.token
          }
        })

      .then((response) => {
      
        //console.log(response.data)
        const dat = response.data
        const struc = dat.structure_types
       // //////////alert("Back Data" + JSON.stringify(struc))
        // const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
        //////alert("Data is coming")
        //console.log(struc)
        setStructurelist(struc)
        //////////alert("level"+struc)
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })

  }
  function handlelevel1(e) {
    //console.log("level" + e.target.value)
    setlevel1(e.target.value)
    setStructuretype('')
    validateField(e.target.name, e.target.value)
    let echainage1 = e.target.value;
    let schainage = '';
    let echainage = '';

    // if (echainage1 != undefined && echainage1!=='') {
    //   echainage = echainage1.replace(/-.*$/, '');
    // }

    // if (level != undefined && level!=='' ){
    //   schainage = level.replace(/-.*$/, '');
    // }
    if (echainage1!=='') {
      echainage = echainage1.replace('/', '.');
    }

    if (level!=='' ){
      schainage = level.replace('/', '.');
    }
 //////////alert(echainage)
 //////////alert(schainage)
    // setStructuretype('ALL')   
    // let fData = new FormData()
    // fData.append('project_id', userData.currentprojectId)
    // fData.append('start_chainage', leveldata)
    // fData.append('end_chainage', level1)
    
//     axios
//       .get(baseurl + '/api/plan/list/structure_type?project_id=' + userData.currentprojectId + '&start_chainage=' + schainage
//         + '&end_chainage=' + echainage,
//         //+fData,
//         {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         })

//       .then((response) => {

//         //console.log(response.data)
//         const dat = response.data
// //////////alert("lvel1"+struc)
//         const struc = dat.structure_types
//         // const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
//         //// //////////alert(JSON.stringify(struc))
//         //console.log(struc)
//         setStructurelist(struc)
//       })
//       .catch((error) => {
//         //console.log(error)
//         //setErrormsg(error.response.data.message)
//       })

  }
  function handleitemofwork(e) {
    //console.log(e.target.value)
    setItemofwork(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handlestructuretype(e) {

    //console.log(e.target.value)
    setStructuretype(e.target.value)


    validateField(e.target.name, e.target.value)
  }
  function handledate(e) {
    //console.log(e.target.value)
    setDate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleOwner(e) {
    //console.log(e.target.value)
    setOwner(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)

  }
  function handleProjectstatus(e) {
    //console.log(e.target.value)
    setProjectstatus(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  //    function handleCheckboxChange(e,item) {
  //     //console.log(e.target.value)
  //      setCheckboxval(e.target.value.replace(/\s+/g, ''))
  //      validateField(e.target.name, e.target.value)
  //    }
  const validateField = (fieldName, value) => {
    if (fieldName === 'itemofwork') {

      if (value != 'Select') {
        setItemofworkerror('')
        setItemofworkValid(true)
      } else {
        setItemofworkerror('Please select')
        setItemofworkValid(false)
      }
    }
    if (fieldName === 'structuretype') {

      if (value != 'Select') {
        setStructuretypekerror('')
        setStructuretypeValid(true)
      } else {
        setStructuretypekerror('Please select')
        setStructuretypeValid(false)
      }
    }
    if (fieldName === 'owner') {

      if (value != 'Select') {
        setOwnererror('')
        setOwnerValid(true)
      } else {
        setOwnererror('Please select')
        setOwnerValid(false)
      }
    }
    if (fieldName === 'date') {

      if (value != '') {
        setDateerror('')
        setDateValid(true)
      } else {
        setDateerror('Please select')
        setDateValid(false)
      }
    }
  }


  function onSubmitEvent(event) {
    event.preventDefault()
    setSuccessstatus(false)
    setReslist1([])
    const firstHyphenIndex = level.indexOf('-');

    // Extract the text after the first hyphen
    const extractedText = firstHyphenIndex !== -1 ? level.slice(firstHyphenIndex + 1).trim() : null;
    //////alert(extractedText)

    // if (itemofworkValid && structuretypeValid && dateValid && ownerValid) {

    // const d = date;
    // const y = 'f0'
    // const currentDate = new Date(d)
    // const day = String(currentDate.getDate()).padStart(2, '0')
    // const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    // const year = currentDate.getFullYear()
    // const currentDateString1 = `${day}-${month}-${year}`

    // let fmData5 = new FormData()
    // fmData5.append('project_id', userData.currentprojectId)
    // fmData5.append('floor', level)
    // fmData5.append('structure_type', structuretype)
    // fmData5.append('structure_item_completion_status', projectstatus)


    // let schainage = '';
    // let echainage = '';

    // if (level1 != undefined && level1!=='') {
    //  const echainage2 = level1.replace(/-.*$/, '');
    //   echainage=echainage2.replace(/\s+/g, '')
    // }

    // if (level != undefined && level!=='') {
    // const  schainage2 = level.replace(/-.*$/, '');
    // schainage=schainage2.replace(/\s+/g, '')
    // }

//     if (level1 != undefined && level1!=='') {
//       //const echainage2 = level1.replace('/', '.');
//        echainage=level1.replace('/', '.');
//      }
 
//      if (level != undefined && level!=='') {
//   //   const  schainage2 = level.replace(/-.*$/, '');
//      schainage=level.replace('/', '.');
    // }
//////////alert(schainage)
//////////alert(echainage)
//////////alert(userData.currentprojectId)
//////////alert(projectstatus)
//////////alert(structuretype)

    // axios
    //   .get(baseurl + '/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId +
    //     '&level=' + extractedText
    //     + '&structure_item_completion_status=' + projectstatus + '&structure_type=' + structuretype, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //     // data: fmData5,
    //   })
      axios({
        method: 'GET',

        url: baseurl+ '/api/plan/list/item_of_work/search?project_id=' + userData.currentprojectId +
        '&level=' + extractedText
        + '&structure_item_completion_status=' + projectstatus + '&structure_type=' + structuretype,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
      })
      .then((response) => {
        //console.log(response.data)

        const data = response.data
      
        const dt = data.searched_data
         ////////////alert("data" + JSON.stringify(dt))
        //console.log("response ***********"+JSON.stringify(data))
        setReslist1(dt)
        //////alert("success")
        setSuccessstatus(true)
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })


    //}


  }

  return (
    <>

      {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
      {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}

      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  pt-3">
       
        <div class="col-xl-12 col-lg-12 col-md-12 pt-2  labels ff">
            <b><center>Work Status</center></b>
          </div>
        <form
          class="row d-flex justify-content-center align-center  blacktext "

        >

          <div class="col-12 col-sm-12  col-md-6 col-lg-6 col-md-6 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Chainage
            </label>
            <select
              className="project-box"
              id="level"
              name="level"
              defaultValue={level}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={level}
              onChange={handlelevel}
            >
              <option value="" selected>
                Select
              </option>
              {/* <option value="0">Project</option>
                          <option value="1">Payment</option> */}
              {leveldata.map((item, index) => {
                const parts = item.floor.split('-');
                const result = parts.slice(1).join('-').trim();
                const chainage = result.replace(/\./g, '/');
                return (
                  <option key={index} value={item.floor}>
                    {chainage}
                  </option>)
              })}
               {/* {myList.map((item, index) => {
             
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>)
              })} */}
            </select>
            <div className="login-error"></div>
          </div>
        
          {/* <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="login-text"
                  >
                    Item of Work
                  </label>
                  <select
                    className="project-box"
                    id="itemofwork"
                    name="itemofwork"
                    defaultValue={itemofwork}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={itemofwork}
                    onChange={handleitemofwork}
                  >
                    <option value={null} selected>
                      Select
                    </option>
                 
                    {itemofworklist.map((name, index) => (
                      <option key={index} value={name}>
                        {name.replace(/_/g, ' ')}
                      </option>
                    ))}
                  </select>
                  <div className="login-error"></div>
                </div> */}
          <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Structures &nbsp;/&nbsp;Level
            </label>
            <select
              className="project-box"
              id="structuretype"
              name="structuretype"
              defaultValue={structuretype}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={structuretype}
              onChange={handlestructuretype}
            >
              <option value={null} selected>
                Select
              </option>
              {/* <option value="0">Project</option>
                          <option value="1">Payment</option> */}
              {structurelist.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
            <div className="login-error"></div>
          </div>
          <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-md-12 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Status 
            </label>
            <select
              className="project-box"
              id="projectstatus"
              name="projectstatus"
              //  defaultValue={companyname}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={projectstatus}
              onChange={handleProjectstatus}
            >
              <option value={null} selected>
                Select
              </option>
              <option value="0">Not Yet Started</option>
              <option value="2">Paused</option>
              <option value="1">InProgress</option>
              <option value="3">Done</option>
              <option value="4">Completed</option>
            </select>
            <div className="login-error">{projectstatuserror}</div>
          </div>

          {/* <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3 ">
                  <label for="formFileLg" className="login-text">
                    Owner
                  </label>
                  <center>
                   
                    <select
                      className="project-box"
                      id="owner"
                      name="owner"
                      defaultValue={owner}
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                      value={owner}
                      onChange={handleOwner}
                    >
                      <option value={null} selected>
                        Select
                      </option>
                      <option value="contractor">Contractor</option>
                      <option value="owner">Owner</option>

                    </select>

                    <div className="login-error"></div>
                  </center>
                </div> */}

          <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 ">
            <center>
              <button
                type="submit"
                className="project-btn"
                name="submit"
                onClick={onSubmitEvent}

              >
                <b>Search</b>
              </button>
            </center>{' '}
          </div>

        </form>
        <div >
         
          {successstatus && reslist1.length == 0 &&
            // !checkduedate &&
            <div className='row '>
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error">
                <center><b><h6>No Data Available</h6></b></center>
                </div>
                </div>}
          {successstatus && reslist1.length != 0 &&
            // !checkduedate &&
            <div className='table1bdstyle '>
              <div class="row table1st">

                <table
                  class="table"
                  readOnly="readOnly"

                >

                  <tr class="trst">
                    <th><center>S.NO</center></th>
                    <th><center>Structure Id</center></th>
                    <th><center>Item of Work</center></th>
                    <th><center>Owner</center></th>
                    <th><center> item of works completed</center></th> 
                    <th><center> item of works pending</center></th> 
                    <th><center> Status</center></th>       
                    <th><center>Attachment</center></th> 
                    <th><center>Remark</center></th> 
                  </tr>

                  {reslist1 != 0 && reslist1.map((item, index) => {
                     const own=item.owner || []
                     const ownlen=own.length
                     const comp=item.item_of_works_completed || []
                     const complen=comp.length
                    const itemOfWorksPending = item.item_of_works_pending || []
                    const pendlen=itemOfWorksPending.length
                    const quan=item.quantities_instock || []
                    const quanlen=quan.length
                    return (
                      <tr key={index} className='tablefontsize'>
                        <td>

                          <center>{index+1}</center>
                        </td>

                        <td><center> &nbsp;&nbsp;&nbsp;&nbsp;{item.structure_item_id}</center> </td>
                        <td><center>{item.item_of_works.join(', ')} </center></td>
                         <td><center>
                        {ownlen!=0 ?item.owners.join(', '):'NA'}
                        </center></td> 
                        <td><center>
                     {complen!=0 ?item.item_of_works_completed.join(', '):'NA'}
                          </center></td>
                        <td><center>
                          {pendlen!=0 ?item.item_of_works_pending.join(', '):'NA'}
                        
                          </center></td>
                          <td><center>
                       
                          <td>{(item.completion_status === '4'? <img src = {completed} alt='completed' height={30} width={90} />
                          :(item.completion_status === '2' ?
                          <img src = {inprogress} alt='completed' height={30} width={90} />
                          : (item.completion_status === '0' ?
                          <img src = {inprogress} alt='completed' height={30} width={90} />:
                          (item.completion_status === '1' ?
                          <img src = {inprogress} alt='completed' height={30} width={90} />:
                         
                          <img src = {inprogress} alt='completed' height={30} width={90} />)
                          
                          
                  )))}</td>
                          </center></td>
                        <td><center>
                          NA
                          </center></td>
                          <td><center>
                          NA
                          </center></td>
                      </tr>
                    )
                  })}
                </table>

              </div>

            </div>}
          {/*  */}



        </div>
      </div>







    </>
  )
}

export default FormPage
