import React from "react";
import RadialBar from "../ApexCharts/RadialBar";
import { useState, useEffect } from "react";
import Search from "../RoadProgressSearch/Search1";
import MultiBarGraph from "../ApexCharts/Multibarchart";
import Multibarchart2 from "../ApexCharts/RoadQuantityGraph";
import LineChart2 from "../ApexCharts/LineChart2";
import $ from "jquery";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import { baseurl } from "../BaseUrl/Baseurl";
// import Multilinegraph from '../ApexCharts/Chainage3'
import Multilinegraph1 from "../ApexCharts/Chainage4";
import Multilinegraph2 from "../ApexCharts/LevelWiseChainge";
import SingleBar1 from "../ApexCharts/SingleBarchart1";
import Milestonegraph from './Milestonegraph'
const WeareHere = () => {
  const [radialData, setRadialData] = useState("");
  const [fulldatas, setFulldatas] = useState([]);
  const [btnname, setBtnname] = useState("");
  const [level, setlevel] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [listOfLevels, setListOfLevels] = useState([]);
  const [structureType, setStructureType] = useState([]);
  const [structureCompleted, setStructureCompleted] = useState([]);
  const [structurePending, setStructurePending] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [zaxis, setZaxis] = useState([]);
  const [caxis, setCaxis] = useState([]);
  const [daxis, setDaxis] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState("");
  const [graphform, setGraphform] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const userData = useSelector((state) => state.userDetails);
  const [tableData, setTableData] = useState([]);
  const drop = React.useRef(null);
  const [completedprogress, setCompletedprogress] = React.useState("");
  const [currentlevel, setCurrentLevel] = React.useState("");
  const [itemofworklist, setItemofworklist] = React.useState([]);
  const [quantityused, setQuantityused] = useState([]);
  const [quantitypend, setQuantitypend] = useState([]);
  const [xaisdata, setXaxisdata] = useState([]);
  const [yaxisdata, setYaxisdata] = useState([]);
  const [structurelist, setStructurelist] = useState([]);
  const [xaisdataps, setXaxisdataps] = useState([]);
  const [yaxisdataps, setYaxisdataps] = useState([]);
  const [structurelistps, setStructurelistps] = useState([]);
  const [xaisdatalevelwise, setXaxisdatalevelwise] = useState([]);
  const [yaxisdatalevelwise, setYaxisdatalevelwise] = useState([]);
  const [structurelistlevelwise, setStructurelistlevelwise] = useState([]);
  const [xaisdatafoot, setXaxisdatafoot] = useState([]);
  const [yaxisdatafoot, setYaxisdatafoot] = useState([]);
  const [structurelistfoot, setStructurelistfoot] = useState([]);
  const [loading, setLoading] = useState(true);
  const [xaisdatalevelwisefoot, setXaxisdatalevelwisefoot] = useState([]);
  const [yaxisdatalevelwisefoot, setYaxisdatalevelwisefoot] = useState([]);
  const [structurelistlevelwisefoot, setStructurelistlevelwisefoot] = useState(
    []
  );
  const [xaisdatalevelwiseps, setXaxisdatalevelwiseps] = useState([]);
  const [yaxisdatalevelwiseps, setYaxisdatalevelwiseps] = useState([]);
  const [structurelistlevelwiseps, setStructurelistlevelwiseps] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [levels, setLevels] = useState([]);
  const [levelsprogress, setLevelsprogress] = useState([]);
  const [chainageindex, setChainageindex] = useState([]);
  const [structureInChainageDataRsp, setStructureInChainageDataRsp] = useState(
    []
  );
  const [loadinggraph, setLoadinggraph] = useState(false);
  const [xaxisvalues, setXaxisvalues] = useState([]);
  const [yaxis1, setYaxis1] = useState([]);
  const [yaxis2, setYaxis2] = useState([]);
  const [structureInChainageDataPs, setStructureInChainageDataps] = useState(
    []
  );
  const [structureInChainageDataFp, setStructureInChainageDataFp] = useState(
    []
  );
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);
  const [loading5, setLoading5] = useState(true);

  const [secondlinelevelwiseroad, setSecondlinelevelwiseroad] = useState([]);
  const [secondlinelevelwisefoot, setSecondlinelevelwisefoot] = useState([]);
  const [chainageindexlevelwiseroad, setChainageindexlevelwiseroad] = useState(
    []
  );
  const [chainageindexlevelwisefoot, setChainageindexlevelwisefoot] = useState(
    []
  );
  const [successstatus1, setSuccessstatus1] = useState(false);
  const [chainagelist, setChainagelist] = useState([]);
  const [pathtype, setPathtype] = useState("FP");
  const [pathTypelist, setPathTypelist] = useState([]);
  // const overallpercentage = 86

 
  

  useEffect(() => {
  

    axios({
      method: "GET",

      url:
        baseurl +
        "/api/plan/specifications/get/project_progress?project_id="+userData.projectId,
        // +
       // userData.currentprojectId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    }).then((response) => {
    //  console.log(JSON.stringify(response.data))
      const RequestData = response.data;
      // setRequestdata1(RequestData)

      const overallprogress = RequestData.percentage_of_work_completed;
      const completedpro = overallprogress.progress_completed;

      const currentfloor = overallprogress.current_working_floor;

      setCompletedprogress(completedpro);
      setCurrentLevel(currentfloor);
      const xaxis1 = RequestData.actual_completion_date_level_data;

      const xax = xaxis1.completed_level;

      setXaxis(xax);

      const yaxis1 = RequestData.due_date_level_data;
      const yax = yaxis1.planned_level;

      setYaxis(yax);
      const zaxis1 = RequestData.due_date_level_data;
      const zax = zaxis1.planned_date;

      setZaxis(zax);
      ////console.log(zax)
    })
    .catch((error) => {
    console.log(error)
      // setErrormsg(error.response.data.message)
    });
   


    axios({
      method: "GET",

      url:
        baseurl +
        "/api/project/get/progress_percentage?project_id=" +
        userData.projectId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        ////console.log(response.data)
        const radialData1 = response.data;
        ////console.log("radialbar",radialData1)
        setRadialData(0);
        setRadialData(radialData1.percentage_completed);
      })
      .catch((error) => {
        ////console.log(error)
        // setErrormsg(error.response.data.message)
      });

    //Foot path

    axios({
      method: "GET",

      url:
        baseurl +
        "/api/plan/specifications/get/structure_type_progress?project_id=" +userData.projectId+
       // userData.currentprojectId +
        "&type=" +
        "FP",
      // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        // //console.log(response.data)

        const res = response.data;
        //////alert("data***"+res)
        const xdata = res.sub_chainage_keys_list || [];
        const ydata = res.completedChainageData || [];
        const structurelist = res.completed_structures_list || [];
        const structureInChainageData = res.structureInChainageData || [];

        //if(xdata!=null && xdata!=undefined && ydata!=null && ydata!=undefined && xdata!='' && ydata!='')
        // {
        setStructureInChainageDataFp(structureInChainageData);
        setStructurelistfoot(structurelist);
        setXaxisdatafoot(xdata);
        setYaxisdatafoot(ydata);
        setLoading4(false);
        //  }
      })
      .catch((error) => {
        //console.log(error);
        // setErrormsg(error.response.data.message)
      });
    //Ps

    // axios({
    //   method: "GET",

    //   url:
    //     baseurl +
    //     "/api/plan/specifications/get/structure_type_progress?project_id=" +
    //     userData.currentprojectId +
    //     "&type=" +
    //     "PS",
    //   // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',

    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     Authorization: "Bearer " + userData.token,
    //   },
    // })
    //   .then((response) => {
    //     ////console.log(response.data)
    //     const res = response.data;
    //     const xdata = res.sub_chainage_keys_list || [];
    //     const ydata = res.completedChainageData || [];
    //     const structurelist = res.completed_structures_list || [];
    //     const structureInChainageData = res.structureInChainageData || [];
    //     // if(xdata!=null && xdata!=undefined && ydata!=null && ydata!=undefined && xdata!='' && ydata!='')
    //     //  {
    //     setStructureInChainageDataps(structureInChainageData);
    //     setStructurelistps(structurelist);
    //     setXaxisdataps(xdata);
    //     setYaxisdataps(ydata);
    //     setLoading5(false);
    //     // }
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //     // setErrormsg(error.response.data.message)
    //   });

    //for level wise chainage graph

    axios({
      method: "GET",

      url:
        baseurl +
        "/api/project/get/progress_percentage?project_id=" +userData.projectId,
      //  userData.currentprojectId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        ////console.log(response.data)
        const radialData1 = response.data;
        ////console.log("radialbar",radialData1)
        setRadialData(0);
        setRadialData(radialData1.percentage_completed);
      })
      .catch((error) => {
        ////console.log(error)
        // setErrormsg(error.response.data.message)
      });

  
  }, []);

 //Road Side
 useEffect(() => {
    axios({
      method: 'GET',
  
      url: baseurl + '/api/plan/specifications/get/structure_type_progress?project_id='+userData.projectId
      // + userData.currentprojectId
        + '&type=' + 'RS',
      // '&start_chainage='+'1.00'+'&end_chainage='+'2.03',
  
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
  
    })
      .then((response) => {
        ////console.log(response.data)
        const res = response.data
        const xdata = res.sub_chainage_keys_list || []
        const ydata = res.completedChainageData || {}
        const structurelist = res.completed_structures_list || []
        const wholechainageindexvalue = res.whole_chainage_index || []
        const structureInChainageData = res.structureInChainageData || []
        setStructurelist(structurelist)
        setXaxisdata(xdata)
        setYaxisdata(ydata)
        setStructureInChainageDataRsp(structureInChainageData)
        setChainageindex(wholechainageindexvalue)
        setLoading3(false)
      
  
      })
      .catch((error) => {
        //console.log(error)
        // setErrormsg(error.response.data.message)
      })

      axios({
        method: 'GET',
  
        url: baseurl + '/api/plan/specifications/get/each_level_progress?project_id=' + userData.projectId,
  
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
  
        }
  
      })
        .then((response) => {

          //console.log("secondtttttttttttttttttttt", response.data)
          const dat = response.data
          const lel = dat.level_or_chainage
          setLevels(lel)
          const progress = dat.progress
          setLevelsprogress(progress)
          setLoading2(false)
        })
        .catch((error) => {
          //console.log(error)
  
        })
    }, [])


    useEffect(() => {

      axios({
        method: 'GET',
    
        url: baseurl + '/api/get/milestone_status?project_id=PR-2',
       
    
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
    
      })
        .then((response) => {
          console.log(JSON.stringify(response.data))
          const res = response.data
          
         // const xdata = res.estimated_month || []
          //const yaxis2= res.actual_completed_month || []
          const yaxis2= res.estimated_month || {}
          const yaxis1 = res.progress_percentage || []
          const xaxisdata = res.milestone_name || []
          setXaxisvalues(xaxisdata)
          setYaxis1(yaxis1)
          setYaxis2(yaxis2)
          setLoadinggraph(true)
        
    
        })
        .catch((error) => {
        console.log(error)
          // setErrormsg(error.response.data.message)
        })
      },[])



  return (
    <div className="">
      <div class="row mt-3   ">
      <div class="col-xl-9 col-lg-12 col-md-12 pt-2 labels mx-auto ff" style={{fontFamily:'plus jakrta sans',fontSize:'20px',fontWeight:'600'}}>
            <center>Milestone Status</center>
         
      {/* {loading2 && <div class=" mt-2 ff"> <h5><b><center>Please wait....</center></b></h5></div>}
          {levels.length == 0 && levelsprogress.length == 0 && !loading2 &&
            <SingleBar1

              listdata={levelsprogress}
              xaxisdata={levels}
              type="bar"
              yaxismax={100}

            />} */}
{loadinggraph && xaxisvalues.length!=0 && yaxis1.length!=0 && yaxis2.length!=0 && <Milestonegraph  xaxisval={xaxisvalues} yaxis1val={yaxis1} yaxis2val={yaxis2}/>}


          {/* {levels.length != 0 && levelsprogress.length != 0 && !loading2 &&
            <SingleBar1

              listdata={levelsprogress}
              xaxisdata={levels}
              type="bar"
              yaxismax={100}

            />} */}
        </div>

        {/* <div class="col-xl-12 col-lg-12 col-md-12 pt-4 pb-3 mx-auto labels ff">
          <b>
            <center>Project Progress</center>
          </b>
        </div> */}
      </div>
      <div class="row">
        <div class="col-md-4 col-lg-4 col-xl-4 col-sm-3 mx-auto ">
          <div className="table3style1 ">
            <div className="pt-2 mt-5" style={{fontFamily:'plus jakrta sans'}}>
              <b>
                <center>Project Completion Status</center>
              </b>
            </div>
            <center>
              {radialData != 0 && (
                <RadialBar
                  SeriesValue={radialData}
                  radialwidth={245}
                  labelValue={radialData}
                  radialcolor={
                    parseInt(radialData) < 50
                      ? "#ff0000"
                      : parseInt(radialData) >= 50 && parseInt(radialData) <= 80
                      ? "#009933"
                      : "#00e600"
                  }
                ></RadialBar>
              )}
              {radialData == 0 && (
                <RadialBar
                  SeriesValue={0}
                  radialwidth={245}
                  labelValue={radialData}
                  radialcolor={
                    parseInt(radialData) < 50
                      ? "#ff0000"
                      : parseInt(radialData) >= 50 && parseInt(radialData) <= 80
                      ? "#ff6600"
                      : "#00e600"
                  }
                ></RadialBar>
              )}
            </center>
          </div>
          <div>
            <center>
              <button type="button" className="btnred "></button>
              <font className="donutfont">
                &nbsp;&nbsp;Bad &nbsp;&nbsp; &nbsp;&nbsp;
              </font>
              <button type="button" className="btnyellow "></button>
              <font className="donutfont">
                &nbsp;&nbsp;Critical &nbsp;&nbsp; &nbsp;&nbsp;
              </font>
              <button type="button" className="btngreen "></button>
              <font className="donutfont">
                &nbsp;&nbsp;Good &nbsp;&nbsp; &nbsp;&nbsp;
              </font>
            </center>
          </div>
        </div>
        

       
       
      </div>

  
    </div>
  );
};

export default WeareHere;
