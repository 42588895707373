import React from 'react'
import { Link } from 'react-router-dom'
import { Component, useState , useEffect} from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import Chart from 'react-apexcharts'
const Charts = ({
 listdata,
 type,
 yaxismax,
 xaxisdata
}) => {

    const [stateOverall, setStateOverall] = useState({
        options: {
          colors: [
            "#4a95f7",
           
          ],
          tooltip: {  
            enabled: true,
            enabledOnSeries:xaxisdata
        },
          chart: {
            type: 'bar',
         height: 350,
         menu: false, // Hide the context menu
            toolbar:
            {
              show: true,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false
              },
            },
          },
         
          legend: {
            show: false
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // borderRadius: 20,
              distributed: true,
              // columnWidth: '30%'
            },   
          },
          xaxis: {
            title: {
              text: "Levels",
              fontSize:'40px'
            },
            categories: xaxisdata,
            labels: {
              show: true,
            },
            // annotation: false,   
            //   type: 'numeric'  
            axisBorder: {
              show: true,
              color: '#000000', // Set the desired color for the x-axis line
              height: 2, // Set the desired height for the x-axis line
            }, 
          },
          
         
          yaxis: 
    {
      title: {
        text: "Percentage",
        fontSize:'40px'
      },
      min: 0,
      max: yaxismax,
      axisBorder: {
        show: true,
        color: '#000000', // Set the desired color for the y-axis line
        width: 2, // Set the desired width for the y-axis line
      },
    },
        },
        series: [
          {
        //    name:"Correct Answer",
            data:listdata,
          
          },
        ],
      })
  return (
    <div className="line-chart">
     <Chart
                          options={stateOverall.options}
                          series={stateOverall.series}
                          // width="100%"
                          height="200%"
                          type={type} />    
                          </div> 

  )
}

export default Charts
