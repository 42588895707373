import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ChartComponent = (data) => {
  const xValues = [0,0.2,0.4,0.6,0.8,1.0,1.2,1.4,1.6,1.8,2.0,2.2,2.4,2.6,2.8,3.0,3.2,3.4,3.6,3.8,4.0,4.2,4.4,4.6,4.8,5.0,5.2,5.4,5.6,5.8,6.0];
  // const xValues = [1.0,2.03,4.05,4.5,5.05,5.625,5.825,6.0];
  const data1=data.data
  const itemofwork=["Filling","Roading"]
  //console.log(data1)
  const chainage= data1.map(item => {
    if(item!=null || item!='' || item!=undefined)
    {
     const originalString =item.floor

  // Split the original string by '-' and trim the parts
  const parts = originalString.split('-').map(part => part.trim());

  // Join the parts with a comma and space
  const modifiedString = parts.slice(1).join(', ');
  return modifiedString

    }
    return ''
          });
          //console.log(chainage)
  const data8YValues = [null, null, null,null,null,1, 1,1,1,1,1,null, null, null,null,null,null, null, null,null,null,null,null,null,null,null,null,null,null,null,null];
  const data7YValues = [null, null, null,null,null,null, null,null,null,null,2, 2, 2, 2, 2,2, 2, 2, 2, 2];
  const data9YValues = [3, 3, 3, 3, null];
  const data1YValues = [4, 4, 4, null, 4];
  const data2YValues = [5, 5, null, 5, 5];
  const data3YValues = [6, 6, 6, 6, null];
  const data4YValues = [7, 7, 7, null, 7];

const len=chainage.length
  const chartData = {
    options: {
      chart: {
        type: 'line',
        menu: false, // Hide the context menu
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: xValues,
        // min: 0,
      },
      yaxis: {
        title: {
          text: 'Item of Work',
        },
        min: 0,
        labels: {
          formatter: function (value, index) {
            //   if (index === 1) {
            //   return 'Type-1';
            // } 
            // else if (index === 2) {
            //   return 'Type-2';
            // }
            // else if (index === 3) {
            //   return 'Type-3';
            // }
            // else if (index === 3) {
            //   return 'Type-4';
            // }
            // else if (index === 3) {
            //   return 'Type-5';
            // }
            // for(var i=1;i<=len;i++){
            //   if (index === i) {
            //     return chainage[index];
            //   } 
            // }
            // if (index === 1) {
            //   return chainage[0];
            // } 
            // else if (index === 2) {
            //   return chainage[1];
            // }
            // else if (index === 3) {
            //   return chainage[2];
            // }
            // else if (index === 4) {
            //   return chainage[3];
            // }
            // else if (index === 5) {
            //   return chainage[4];
            // }
            // else if (index === 6) {
            //   return chainage[5];
            // }
            // else if (index === 7) {
            //   return chainage[6];
            // }
            // Add more conditions for other series if needed
            return value;
          },
        },
      },
    },
    series: [
      {
        name: 'Type 1',
        data: data8YValues.map((y, index) => ({
          x: xValues[index],
          y: y,
        })),
      },
      {
        name: 'Type 2',
        data: data7YValues.map((y, index) => ({
          x: xValues[index],
          y: y,
        })),
      },
      {
        name: 'Type 3',
        data: data9YValues.map((y, index) => ({
          x: xValues[index],
          y: y,
        })),
      },
      {
        name: 'Type 4',
        data: data1YValues.map((y, index) => ({
          x: xValues[index],
          y: y,
        })),
      },
      {
        name: 'Type 5',
        data: data2YValues.map((y, index) => ({
          x: xValues[index],
          y: y,
        })),
      },
      {
        name: 'Type 6',
        data: data3YValues.map((y, index) => ({
          x: xValues[index],
          y: y,
        })),
      },
      {
        name: 'Type 7',
        data: data4YValues.map((y, index) => ({
          x: xValues[index],
          y: y,
        })),
      },

    ],
  };

  return (
    <div>
      <ReactApexChart options={chartData.options} series={chartData.series} type="line" />
    </div>
  );
};

export default ChartComponent;
// import React from 'react';
// import ReactApexChart from 'react-apexcharts';

// const ChartComponent = (data) => {
//   const segments = [
//     { start: 1.0, end: 2.03, yValues: [1, 2, 3] },
//     { start: 2.03, end: 4.05, yValues: [4, 5, 6] },
//     { start: 4.05, end: 4.5, yValues: [7, 8, 9] },
//     // Add more segments here
//   ];

//   const seriesData = segments.map((segment, index) => ({
//     name: `Type ${index + 1}`,
//     data: segment.yValues.map((y, subIndex) => ({
//       x: segment.start + subIndex * 0.01, // Adjust the increment as needed
//       y: y,
//     })),
//   }));

//   const xValues = seriesData.flatMap(series => series.data.map(point => point.x));

//   const chartData = {
//     options: {
//       chart: {
//         type: 'line',
//         menu: false,
//         toolbar: {
//           show: true,
//           tools: {
//             download: false,
//             selection: false,
//             zoom: false,
//             zoomin: false,
//             zoomout: false,
//             pan: false,
//             reset: false,
//           },
//         },
//       },
//       xaxis: {
//         type: 'category',
//         categories: xValues,
//       },
//       yaxis: {
//         title: {
//           text: 'No of Work Completed',
//         },
//         min: 0,
//       },
//     },
//     series: seriesData,
//   };

//   return (
//     <div>
//       <ReactApexChart options={chartData.options} series={chartData.series} type="line" />
//     </div>
//   );
// };

// export default ChartComponent;
