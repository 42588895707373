import React from "react";
import RadialBar from "../ApexCharts/RadialBar";
import { Link } from "react-router-dom";
import { FaPlus, FaEdit, FaStarAndCrescent } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
import iconimg from "../../assets/images1/Projectstatus.png";
import completed from "../../assets/images/completed-5.png";
import inprogress from "../../assets/images/buttons/inprogress.png";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import ButtonLink from "../ButtonLink/ButtonLink";
import banner from "../../assets/images1/backgroundabstractwhite.png";
import search from "../../assets/images1/Vector.png";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import './NewRoadReports.css'
import LocationMap from './Location1'
import PeriodicProgress from './PeriodicProgress'
import Milestone1 from './Milestone'
import Piechart from '../ApexCharts/PieChart'

const WeareHere = () => {
  const userData = useSelector((state) => state.userDetails);
  const [id, setId] = React.useState("");
  const [status, setStatus] = useState(false);
  const [projecttype, setProjecttype] = React.useState("");

  const [Period, setPeriod] = useState(true);
  const [Location, setLocation] = useState(false);
  const [Milestone, setMilestone] = useState(false);
  const [Piechartst, setPiechartst] = useState(false);
  const [delayed, setDelayed] = useState('');
  const [pending, setPending] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handlePeriodClick=()=>{
  setLocation(false)
  setPeriod(true)
  setMilestone(false)
  setPiechartst(false)

  }
const handleLocationClick=()=>{
  setLocation(true)
  setPeriod(false)
  setMilestone(false)
  setPiechartst(false)
  }

  const handleMilestoneClick=()=>{
    setLocation(false)
    setPeriod(false)
    setMilestone(true)
    setPiechartst(false)
    }
  
    const handlePiechartClick=()=>{
      setLocation(false)
      setPeriod(false)
      setMilestone(false)
      setPiechartst(true)
      }

useEffect(()=>
{

   axios
      .get(baseurl + '/api/report/work_delay_status?project_id='+userData.projectId, {
        headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + userData.token,
              }
        // data: fmData5,
      })

      .then((response) => {
      
        const data = response.data
        setDelayed(data.delayed_percentage)
        setCompleted(data.completed_percentage)
        setPending(data.pending_percentage)
        //console.log(data)
      
   
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })


},[])

  return (
    <div
      className=
        "cont-margin1"
      
      // style={{
      //   backgroundImage: `url(${banner})`,
      //   backgroundRepeat:'no-repeat',
      //   backgroundSize: "cover",
      // }}
    >
      {status && projecttype === "Building" && (
        <ButtonLink id={id} btnPath="/Building" />
      )}
      {status && projecttype === "Road" && (
        <ButtonLink id={id} btnPath="/Road" />
      )}
      <div class="margindiv   pl-3 ">
       
          <div className="">
            <h5
              className="text-center pb-2"
              style={{
                fontFamily: "plus jakarta sans",
                color: "#BB1245",
                fontWeight: "600",
              }}
            >
            {userData.projectName}
            </h5>
            <div className="row w-50 mx-auto  mt-2">
              <div className="col-3 text-center ">
                <button onClick={handlePeriodClick}
                  className="btn f mx-auto sub"
                  style={ { backgroundColor: Period ? '#2F619C' : '#ffffff',color: Period ? '#ffffff' : '#2F619C',fontSize:'12px' } }
                >
                  Periodical Work Completion
                </button>
              </div>

              <div className="col-3 text-center ">
                <button onClick={handleLocationClick}
                  className="btn f  mx-auto sub "
                  style={ { backgroundColor: Location ? '#2F619C' : '#ffffff',color: Location ? '#ffffff' : '#2F619C', fontSize:'12px' } }
                >
                  Location Progress
                </button>
              </div>

              <div className="col-3 text-center">
                <button onClick={handleMilestoneClick}
                  className="btn f  mx-auto sub"
                  style={ { backgroundColor: Milestone ? '#2F619C' : '#ffffff',color: Milestone ? '#ffffff' : '#2F619C',fontSize:'12px' } }
                >
                  Milestone Status
                </button>
              </div>
              <div className="col-3 text-center">
                <button onClick={handlePiechartClick}
                  className="btn f  mx-auto sub"
                  style={ { backgroundColor: Piechartst ? '#2F619C' : '#ffffff',color: Piechartst ? '#ffffff' : '#2F619C',fontSize:'12px' } }
                >
                  Work Delay Status
                </button>
              </div>
            </div>
           <div className=" container-fluid shadow-lg p-2 mt-2" style={{backgroundColor:'white',
            borderRadius:'10px'
           }}> {Period && <div><PeriodicProgress /></div>}
{Location && <div><LocationMap /></div>}
{Milestone && <div><Milestone1 /></div>}
{Piechartst && completed!='' && pending!='' && delayed!=''&& <div><Piechart  chartval={[parseFloat(completed),parseFloat(pending),parseFloat(delayed)]}/></div>}</div>
</div> 
        </div>



      </div>
   
  );
};

export default WeareHere;
