import React from "react";

function TestingDependency() {
  return (
    <div
      className="container w-75  ml-5 "
      style={{
        position: "absolute",
        top: "50%",
        left: "65%",
        transform: "translate(-50%,-50%)",        
      }}
    >
      <p style={{fontWeight:"bold",color:"#D10C47"}}>As  Per  Employer  Requirement  Testing Dependency Added  Between
      Structures  And  Layers.</p>
    </div>
  );
}

export default TestingDependency;
