import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import './CreateProject.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import { connect, useDispatch, useSelector } from 'react-redux'
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }

  const userData = useSelector((state) => state.userDetails)
  const [projectname, setProjectname] = useState('')
  const [projectnameValid, setProjectnameValid] = useState(false)
  const [projectnameerror, setProjectnameerror] = useState('')
  const [projecttype, setProjecttype] = useState('')
  const [projecttypeValid, setProjecttypeValid] = useState(false)
  const [projecttypeerror, setProjecttypeerror] = useState('')
  const [startdate, setStartdate] = useState('')
  const [startdateValid, setStartdateValid] = useState(false)
  const [startdateeerror, setStartdateerror] = useState('')
  const [enddate, setEnddate] = useState('')
  const [enddateValid, setEnddateValid] = useState(false)
  const [enddateeerror, setEnddateerror] = useState('')
  const [budget, setBudget] = useState('')
  const [budgetValid, setBudgetValid] = useState(false)
  const [budgeterror, setBudgeterror] = useState('')
  const [contactno, setContactno] = useState('')
  const [contactnoValid, setContactnoValid] = useState(false)
  const [contactnoerror, setContactnoerror] = useState('')
  const [contactmail, setContactmail] = useState('')
  const [contactmailValid, setContactmailValid] = useState(false)
  const [contactmailerror, setContactmailerror] = useState('')
  const [companyname, setCompanyname] = useState('')
  const [companynameValid, setCompanynameValid] = useState(false)
  const [companynameerror, setCompanynameerror] = useState('')
  const [contactperson, setContactperson] = useState('')
  const [contactpersonValid, setContactpersonValid] = useState(false)
  const [contactpersonerror, setContactpersonerror] = useState('')
  const [successstatus, setSuccessstatus] = useState(false)
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })//
//////alert("Projects"+userData.loginUsername)
    }, [])

  function handleProjectname(e) {
   //console.log(e.target.value)
    setProjectname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleprojecttype(e) {
    setProjecttype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleStartdate(e) {

    setStartdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleEnddate(e) {

    setEnddate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleContactmail(e) {
    setContactmail(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  
  function handleBudget(e) {
    setBudget(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleCompanyname(e) {
    setCompanyname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleContactperson(e) {
    setContactperson(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {
    if (fieldName === 'projectname') {
      //console.log(value)
      if (value.length != 0) {
        setProjectnameerror('')
        setProjectnameValid(true)
      } else {
        setProjectnameerror('Please enter your Project name')
        setProjectnameValid(false)
      }
    }
    if (fieldName === 'projecttype') {
      if (value.length != 0) {
        setProjecttypeerror('')
        setProjecttypeValid(true)
      } else {
        setProjecttypeerror('Please enter your Project type')
        setProjecttypeValid(false)
      }
    }
    if (fieldName === 'startdate') {
      if (value.length!=0) {
        setStartdateerror('')
        setStartdateValid(true)
      } else {
        setStartdateerror('Please select Start date')
        setStartdateValid(false)
      }
    }
    if (fieldName === 'enddate') {
        if (value.length!=0) {
          setEnddateerror('')
          setEnddateValid(true)
        } else {
          setEnddateerror('Please select End date')
          setEnddateValid(false)
        }
      }
      if (fieldName === 'projecttype') {
        if (value.length != 0) {
          setProjecttypeerror('')
          setProjecttypeValid(true)
        } else {
          setProjecttypeerror('Please enter your Project type')
          setProjecttypeValid(false)
        }
      }
    if (fieldName === 'contactmail') {
      const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      if (value.length != 0) {
        //console.log(value.match(regex))
        if (value.match(regex)) {
    
          setContactmailValid(true)
          setContactmailerror('')
        } else {
     
          setContactmailValid(false)
          setContactmailerror('Please enter valid email')
        }
      } else {
        
        setContactmailValid(false)
        setContactmailerror('Please enter valid email')
      }
    }
    if (fieldName === 'budget') {
      if (value.length != 0) {
        setBudgeterror('')
        setBudgetValid(true)
      } else {
        setBudgeterror('Please enter your Budget')
        setBudgetValid(false)
      }
    }
    if (fieldName === 'contactno') {
        if (value.length != 0 && value.length==10) {
          setContactnoerror('')
          setContactnoValid(true)
        } else {
          setContactnoerror('Please enter valid Contact number')
          setContactnoValid(false)
        }
      }
      if (fieldName === 'companyname') {
        //console.log(value)
    
        if (value != "Select") {
          setCompanynameerror('')
          setCompanynameValid(true)
        } else {
          setCompanynameerror('Please select Company name')
          setCompanynameValid(false)
        }
      }
      if (fieldName === 'contactperson') {
        //console.log(value)
        if (value != "Select") {
          setContactpersonerror('')
          setContactpersonValid(true)
        } else {
          setContactpersonerror('Please select Client Contact person')
          setContactpersonValid(false)
        }
      }
  }
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    //console.log(year)
    //console.log(month)
    //console.log(day)
    return `${year}-${month}-${day}`;
  }
  
  function onSubmitEvent(event) {
    event.preventDefault()
    
  //console.log(projectname)
  //console.log(projecttype)


    if (projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
        && companynameValid && contactpersonValid && contactmailValid && contactnoValid) {
  
     

        let userData = new FormData()
        userData.append('project_name',projectname )
        userData.append('client_contact_person', contactperson)
        userData.append('company_name', companyname)
        userData.append('contact_number',contactno )
        userData.append('contact_email',contactmail)
        userData.append('est_start_date',startdate )
        userData.append('est_end_date', enddate)
        userData.append('budget', budget)

          axios({
            method: 'post',
    
            url: baseurl + '/api/project/create', userData,
    
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token,
            }
          })
    

          .then((response) => {
              setSuccessstatus(true)
          })
          .catch((error) => {
            // //console.log(error)
            //setErrormsg(error.response.data.message)
          })
    }
  }
  const todayDate = getTodayDate();
  //console.log(todayDate)
  
  return (
    <>
      
        {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
        {successstatus && <ButtonLink btnPath="/PlanUpdate" />}
        <div className="cont-margin1">
            <div class="cont-margin-latest pt-2 pb-2">
                <div className='table1style'>
              <div className='table1st'>

                <div class="row pb-5">
           
                
                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12 pb-5 ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={onSubmitEvent}
                  >

                    <div class="row">

                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6  pb-3">
                      <label for="formFileLg" className="login-text">
                    Project Name
                  </label>
                      <center>
                        <InputTextField
                        
                          valueText={projectname}
                          inputName="projectname"
                          inputId="projectname"
                          classname="project-box"
                          inputType="text"
                          placeholderValue="Project name"
                          handleInputChange={handleProjectname}
                          PreventEnter={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                       
                         
                        ></InputTextField>

                        <div className="login-error">{projectnameerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                    Project Type
                  </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleprojecttype}
                          valueText={projecttype}
                          inputName="projecttype"
                          inputId="projecttype"
                          classname="project-box"
                          inputType="text"
                          placeholderValue="Project type"
                        ></InputTextField>

                        <div className="login-error">{projecttypeerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                      Estimate Start date
                  </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleStartdate}
                          valueText={startdate}
                          inputName="startdate"
                          inputId="startdate"
                          classname="formboxnew"
                          inputType="date"
                          placeholderValue="Start Date"
                          mindate={todayDate}
                        ></InputTextField>

                        <div className="login-error">{startdateeerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                      Estimate End date
                  </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleEnddate}
                          valueText={enddate}
                          inputName="enddate"
                          inputId="enddate"
                          classname="formboxnew"
                          inputType="date"
                          placeholderValue="End date"
                          mindate={todayDate}
                        ></InputTextField>

                        <div className="login-error">{enddateeerror}</div>
                        </center>
                      </div>
                      {/* <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3 ">
                  <label
                    for="dob"
        
                    className="login-text"
                  >
                 
                  </label>
                  <center>
                  <input
                    type="date"
                    id="dob"
                    classname="formboxnew"
                     max={values.maxyear}
                    // min={values.minyear}
                    name="dob"
                    placeholder=""
                    // onChange={handleChange('dob')}
                    // defaultValue={values.dob}
                    // value={values.dob}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                  />
                  </center>
                   <div className="sign-error"> {values.doberror}</div> }
                </div> */}
              
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                    Budget
                  </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleBudget}
                          valueText={budget}
                          inputName="budget"
                          inputId="budget"
                          classname="project-box"                 
                          inputType="text"
                          placeholderValue="Budget"
                        ></InputTextField>
                        </center>
                        <div className="login-error">{budgeterror}</div>
                      </div>
                      <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast"
                  >
                    Company Name
                  </label>
                  <select
                    className="project-box"  
                     id="companyname"
                     name="companyname"
                    //  defaultValue={companyname}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={companyname}
                 onChange={handleCompanyname}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                    <option value="AIS">AIS</option>
                    <option value="AtheesGroup">Athees Group</option>
                    <option value="AtheesBlueMetal">Athees Blue Metal</option>
                  </select>
                  <div className="login-error">{companynameerror}</div>
                </div>
                <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast"
                  >
                    Client Contact Person
                  </label>
                  <select
                    className="project-box"  
                     id="contactperson"
                     name="contactperson"
                    defaultValue={contactperson}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                     value={contactperson}
                    onChange={handleContactperson}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                    <option value="Devi">Devi</option>
                    <option value="Vageshwari">Vageshwari</option>
                    <option value="Thanalaskhmi">Thanalaskhmi</option>
                  </select>
                  <div className="login-error">{contactpersonerror}</div>
                </div>
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                    Contact Number
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleContactno}
                          valueText={contactno}
                          inputName="contactno"
                          inputId="contactno"
                          classname="project-box"
                          maxLen={10}
                          inputType="text"
                          placeholderValue="Contact number"

                        ></InputTextField>
     </center>
                        <div className="login-error">{contactnoerror}</div>
                   
                      </div>
                     
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                    Contact Email
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleContactmail}
                          valueText={contactmail}
                          inputName="contactmail"
                          inputId="contactmail"
                          classname="project-box"                          
                          inputType="text"
                          placeholderValue="Contact email"

                        ></InputTextField>

                        <div className="login-error">{contactmailerror}</div>
                        </center>
                      </div>
                    

                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                          disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
                            && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                        >
                          <b>Submit</b>
                        </button>
                      </center>{' '}
                    </div>
                  
                  </form>
                </div>
              
              </div>
            </div>
            </div>
              
              </div>
            </div>
  

    
    </>
  )
}

export default FormPage
