import React from 'react';
import RadialBar from '../ApexCharts/RadialBar';
import './ContractorList.css';
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from 'react'
import RequestModal from '../Modal/CreateRequest'
import ButtonLink from '../ButtonLink/ButtonLink';
import StatusupdateModal from '../Modal/AssignProject'
import Renewal from '../Modal/Renewal'
import ViewTickets from '../Modal/ViewTickets'
import { baseurl } from '../BaseUrl/Baseurl'
import completed from '../../assets/images/completed-5.png'
import inprogress from '../../assets/images/buttons/inprogress.png'
import  received from '../../assets/images/buttons/completed.png'
import delayed from '../../assets/images/buttons/delayed.png'
// import  cancelled from '../../assets/images/buttons/cancelled.png'
import sandcorrect from '../../assets/images/buttons/sandcorrect.png'
import brickcorrect from '../../assets/images/buttons/brickcorrect.png'
import stonescorrect from '../../assets/images/buttons/stonescorrect.png'
import ironrodcorrect from '../../assets/images/buttons/ironrodcorrect.png'
import cementcorrect from '../../assets/images/buttons/cementcorrect.png'
import ordered from '../../assets/images/buttons/ordered.png'
import sandhead from '../../assets/images/resources/sandhead.png'
import brick from '../../assets/images/resources/brick.png'
import cement from '../../assets/images/resources/cement.png'
import ironrod from '../../assets/images/resources/ironrod.png'
import sand from '../../assets/images/resources/sand.png'
import stones from '../../assets/images/resources/stones.png'
import tiles from '../../assets/images/resources/tiles.png'
import LineChart from '../ApexCharts/LineChart'
import LineChart1 from '../ApexCharts/LineChart1'
import $ from 'jquery';
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
const WeareHere = () => {


  const userData = useSelector((state) => state.userDetails)

  const [list, setList] = useState([]);
      
  const [updatestatus, setUpdatestatus] = useState(false);
  const [contractorid, setContractorid] = useState('');
  const[contractorStatus, setContractorStatus] =useState(false);
  const[contractorId, setContractorId] =useState('');
  const[viewstatus, setViewstatus] =useState('');
  const [successstatus, setSuccessstatus] = useState(false);
    useEffect(() => {
  
    // //////alert(userData.projectId)
      axios({
        method: 'GET',

        url: baseurl+'/api/vendor/list/get/vendor_details?client_id='+userData.clientId + '&contractor_type=Contractor',

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        }
      })

        .then((response) => {
         
          console.log(response.data)
          const RequestData = response.data
          // setRequestdata1(RequestData)
        
         const dt=RequestData
         setList(dt)
         setSuccessstatus(true)
        })
        .catch((error) => {
          
      //    console.log(error)
          // setErrormsg(error.response.data.message)
        })

      //   let fmData5 = new FormData()

      // fmData5.append('project_id', userData.projectId)



    
 

 

      
    // console.log("useeffect")
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    // document.addEventListener("click", handleClick);
    // return () => {
    //   document.removeEventListener("click", handleClick);
    // };

    // document.addEventListener("click", handleClick1);
    // return () => {
    //   document.removeEventListener("click", handleClick1);
    // };
	
////////////////////////// api for first graph ////////////////////////////////

    

  
    // axios({
    //   method: 'GET',

    //   url: 'http://localhost:8080/api/support/issue/ticket/list',

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   }
     
    // })
    //   .then((response) => {
    //     console.log(response.data)
    //     console.log("result coming")
    //     const TicketData = response.data
    //     setTicketlist(TicketData)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     // setErrormsg(error.response.data.message)
    //   })

  }, [])
  function  EditContractor1(contractorID)
 {
     setContractorid(contractorID)
      setContractorStatus(true)
      
 }

  const Editdata = (id) => {

    //////alert("Before"+updatestatus)
    setContractorid(id)
    setUpdatestatus(true)

    //////alert("After"+updatestatus)
  }
  const OnViewClick= (id) => {
    setContractorid(id)
    setViewstatus(true)
  
  }

  // const toggleModelStatus = () => {

  //   setRequeststatus(false)
  // };
  // const toggleModelStatus1 = () => {

  //   setUpdatestatus(false)
  // };
  // const toggleRenewalStatus = () => {
  //   console.log("***********")
  //   setRenewalstatus(false)
  // };
  // const toggleViewTicketStatus = () => {
  //   console.log("***********")
  //   setViewticketstatus(false)
  // };
  // const requestdata = () => {
  //   console.log("*****" + requeststatus)
  //   setRequeststatus(true)
  // }
  // const Editdata = (id) => {
  //   setProjectid(id)
  //   setUpdatestatus(true)
  // }
  // const ViewticketstatusUpdate = (id) => {
  //   setTicketno(id)
  //   setViewticketstatus(true)
  // }
  const toggleModelStatus1 = () => {

    setUpdatestatus(false)

    //////alert("status false is running")
  };


  return (

    <>
    {contractorStatus && <ButtonLink btnPath="/EditContractor"contractorStatus={contractorStatus} contractorId={contractorid}/>}
   
    {viewstatus && <ButtonLink btnPath='/SubcontractorViewDetails'  contractorId={contractorid}/>}
    <div className="cont-margin1">
    <div class="cont-margin-latest pt-1">
    <div className='table1bdstyle'>
          <div class="row table1st">
          {/* <div class="pt-1 pb-1 col-xl-6 col-lg-6">
                <p><b>Overallvendorlist</b></p>
                </div>
                <div class="pt-1 pb-1 col-xl-6 col-lg-6">
                <Link to ='/ContractorCreation'>
                <button className='view4'>Create</button></Link>
           

                     


              </div> */}
                 <div class=" col-xl-4 pt-1 pb-1 ">
              <div className='ff'>  <b>Sub Contractors</b></div>
              </div>
              <div class=" col-xl-4 pt-1 pb-1">
              
              </div>
              <div class=" col-xl-3 pt-1 pb-1 ">
            <Link to="/ContractorCreation"> 
            <button 
                    type="button"
                    className="createpro-btn"
                  >
                   &nbsp;Create</button>
            </Link> 
              </div>
              <div class=" col-xl-1 pt-1 pb-1">
              
              </div>
              { list.length == 0 && successstatus &&
            // !checkduedate &&
           
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pt-3 pb-3">
                <center><b><h6>No Data Available</h6></b></center>
              
                </div>}
                { list.length !== 0 &&    <div className='tableContainer1' >


            <table
              class="table"
              readOnly="readOnly"
           

            >
              <tr class ='trst'>
                
                <th>Company Name</th>
                <th>Agreement Date</th>
                {/* <th>Item of Work</th> */}
                <th>Contact Person</th>
                <th>Contact Mail</th>
                <th>Contact Phone Number</th>
            
                <th>View Project</th>
               
                <th>Ratings</th>
                <th>Assign Project</th>
                <th>Edit</th>

              </tr>
              {updatestatus &&
                <StatusupdateModal status={updatestatus} btnClick={toggleModelStatus1} contractorid={contractorid} />}
              {list.map((dt,index) =>{
                return(
                  <tr key ={index} className='tablefontsize'>
                 
                    <td className='fontcolor fontsize'>{dt.vendor_company_name!=undefined?dt.vendor_company_name:'NA'}<b></b></td>
                 
                 
                  
                    <td className='fontcolor fontsize'>
                    {dt.onboarding_date!=undefined?dt.onboarding_date:'NA'}
                    </td>
                    {/* <td> </td> */}
                    <td>  {dt.contact_person!=undefined?dt.contact_person:'NA'}</td>
                    <td>  {dt.email!=undefined?dt.email:'NA'}</td>
                    <td>  {dt.phone_no!=undefined?dt.phone_no:'NA'}</td>
                 
                    <td ><center> 
                      {/* <Link to ='/SubcontractorViewDetails'> */}
                        <button
                      type="button"
                      className="viewnew"
                   onClick={() => OnViewClick(dt.contractor_id)}
                    >
                      <b>View</b>
                    </button> 
                    
                    {/* </Link> */}
                    
                    </center></td>
                    {/* <td>{(dt.project_status === 'completed'? <img src = {completed} alt='completed' height={30} width={90} />:<img src = {inprogress} alt='completed' height={30} width={90} />)}</td> */}

                    <td className='fontcolor1 fontsize1'>  {dt.rating!=undefined?dt.rating:'NA'}</td>
                  


                    <td><button
                      onClick={() => Editdata(dt.contractor_id)}
                      type="button"
                      className="modalbtn"
                    ><FaEdit /></button>

                    </td>

                    <td><button
                      // onClick={() => Editdata("123")}
                      onClick={() => EditContractor1(dt.contractor_id)}
                      type="button"
                      className="modalbtn"
                    ><FaEdit /></button>

                    </td>   

                  </tr>
                )
               })
              }
         
              
{/*               
              {viewticketstatus &&
                <ViewTickets status={viewticketstatus} btnClick={toggleViewTicketStatus} TicketNumber={ticketno}/>}
           {ticketlist.length != 0 && ticketlist.map((item, index) => {
                return (
                  <tr key={index}>

                    <td>{item.project_id}</td>
                    <td>{item.contact_person}</td>
                    <td>{item.ticket_number}</td>
                    <td>  <button
                      type="button"
                      className="project-btn1"
                      onClick={() => ViewticketstatusUpdate(item.ticket_number)}
                    >
                      <b>View Tickets</b>
                    </button></td>

                  </tr>
                )
              })} */}

            </table>
            </div>
}
          </div>
          {/* <div class="modal fade pt-5" id="myModal">
            <div class="modal-dialog modal-md ">
              <button
                type="button"
                className="demobtn2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x"></i>
              </button>
              <div class="modal-content">
                <div class="modal-header">
                  <div className="header-text">
                    <b>{btnname}</b>
                  </div>
                </div>

              </div>
              <div className="modal-body">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-12 pt-3 pb-3 ">
                    <ul class="list-unstyled">
                      {btnname === 'Edit' && (
                        <>
                        
                          <h1>Hello</h1>
                        </>
                      )}


                    </ul>
                  </div>
                </div>
              
              </div>
            </div>
          </div> */}
        

          
        </div>
        </div>

        </div>

</>
);
};

export default WeareHere;