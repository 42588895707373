import React, { useEffect, useState } from 'react';
import './Gallery.css'; // Import the CSS file for styling
import Photo1 from '../../assets/images/road1.png'
import Photo2 from '../../assets/images/road2.png'
import Photo3 from '../../assets/images/road3.png'
import Photo4 from '../../assets/images/road4.png'
import Photo5 from '../../assets/images/road5.png'
import parse from 'html-react-parser'
import axios from 'axios';
import { baseurl } from '../BaseUrl/Baseurl'
import { connect, useDispatch, useSelector } from 'react-redux'
const Gallery = () => {
  // State to track the index of the currently selected image
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [galleryimglist, setGalleryimglist] = useState([]);
  const userData = useSelector((state) => state.userDetails);

  // Array of image URLs
  const images = [
    Photo1,
    Photo2,
    Photo3,
    Photo4,
    Photo5,

  ];

  useEffect(() => {
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    axios({
      method: 'GET',

      url: baseurl + '/api/project/gallery?project_id=' + userData.projectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })

      .then((response) => {

        //console.log(response.data)
        const Datalist = response.data
        const imagelist = Datalist.gallery
        //console.log("request", Datalist)

        setGalleryimglist(imagelist)

      })
      .catch((error) => {

        //console.log(error)
        // setErrormsg(error.response.data.message)
      })

  })

  // Function to handle image click and display the selected image
  // const handleImageClick = (index) => {
  //   setSelectedImageIndex(index);
  // };

  // Function to handle closing the selected image
  const handleClose = () => {
    setSelectedImageIndex(null);
  };

  return (
    <div className="gallery pagetopspace">
      <div className="gallery-grid pt-2">
        {/* Map over the images array and render each image */}
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            // onClick={() => handleImageClick(index)}
          />
        ))}

        {/* {galleryimglist.map((image, index) => (

        parse(image.toString())

        ))} */}

      </div>

      {/* Modal to display the selected image */}
      {/* {selectedImageIndex !== null && (
        <div className="modal-overlay" onClick={handleClose}>
          <div className="modal-content">
            <img
              src={images[selectedImageIndex]}
              alt={`Image ${selectedImageIndex + 1}`}
            />
            <button onClick={handleClose}>Close</button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Gallery;
