import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../index.css';

export default function CustomToastContainer({ messageList = ""}) {
  useEffect(() => {
    if (messageList) {
      // Show toast with error message and make it persistent
      toast.error(messageList, {
        autoClose: false // Keeps toast open until dismissed
      });
    } else {
      // Dismiss all toasts if messageList is empty
      toast.dismiss();
    }
  }, [messageList]);

  return (
    <ToastContainer
      className="custom-toast"
      position="top-right"
      autoClose={false}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover={false}
    />
  );
}
