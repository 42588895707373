import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import '../ThirdPartyContractorDetails/ThirdPartyControctor.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import DuedateModel from '../Modal/SetDueDate'
import { Tab, Tabs } from 'react-bootstrap';
import Equipmentgraph from '../ApexCharts/Equipmentgraph'
import Twoline from '../ApexCharts/Twoline'
import Twobar from '../ApexCharts/TwoBar'
import { connect, useDispatch, useSelector } from 'react-redux'

import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'

function FormPage() {
  const userData = useSelector((state) => state.userDetails)
  const [date, setdate] = useState('')
  const [dateValid, setdateValid] = useState(false)
  const [dateeerror, setdateerror] = useState('')
  const [equipmenttype, setEquipmenttype] = useState('')
  const [equipmenttypeValid, setEquipmenttypeValid] = useState(false)
  const [equipmenttypeerror, setEquipmenttypeerror] = useState('')
  const [successstatus, setSuccessstatus] = useState(false)
  const [reslist1, setReslist1] = useState([])
  const [structurelist, setStructurelist] = useState([])
  const [data12, setData12] = useState([]);
  const [search, setSearch] = useState([])
  const convertedDate = convertDateFormat(date);
  const [graphData, setGraphData] = useState([]);
  const [data, setData] = useState([]);
  const [usedquip, setUsedequip] = useState([]);
  const [successstatus2, setSuccessstatus2] = useState(false);
  useEffect(() => {

// equipment usage report

axios
.get(`${baseurl}/api/report/list/equipments_used`, {
  params: {
    project_id: userData.currentprojectId,
  },
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${userData.token}`,
  },
})
.then((response) => {
  const data = response.data;
 const res=data.equipments_used
  setUsedequip(res);
  setSuccessstatus2(true)
  //console.log('Data received:', response.data); 
})
.catch((error) => {
  console.error('Error fetching data:', error);
});


      axios({
        method: 'get',

        url: baseurl + '/api/project/list/resources&equipments?project_id=' + userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        }
      })
      .then((response) => {
        const data = response.data;
        const mt = data.equipments;
        setData12(mt);
        //console.log('Data received:', response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      axios({
        method: 'get',

        url: baseurl + '/api/report/equipments/graph?project_id=' + userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        }
      })

    // axios
    //   .get(`${baseurl}/api/report/equipments/graph`, {
    //     params: {
    //       project_id: userData.currentprojectId,
    //     },
    //   })
      .then((response) => {
        const data = response.data;
        const mt = data.equipment_graph_data;
        setGraphData(mt);
        //console.log('Data received:', response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });


      axios({
        method: 'get',

        url: baseurl + '/api/request/list/equipments_requested?project_id=' + userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        }
      })

      // axios
      // .get(`${baseurl}/api/request/list/equipments_requested`, {
      //   params: {
      //     project_id: userData.currentprojectId,
      //   },
      // })
      .then((response) => {
        const data = response.data;
        const mt = data.equipments_requested

        setData(mt);
        //console.log('Data received:', response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });


  }, []);




  function handledate(e) {

    setdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
    setSuccessstatus(false)
  }
  function handleequipmenttype(e) {
    //console.log(e.target.value)
    setEquipmenttype(e.target.value)
    validateField(e.target.name, e.target.value)
    setSuccessstatus(false)
  }



  function convertDateFormat(date) {
    const parts = date.split('-');
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return date;
  }


  const validateField = (fieldName, value) => {

    if (fieldName === 'date') {

      if (value != 0) {
        setdateerror('')
        setdateValid(true)
      } else {
        setdateerror('Please select date')
        setdateValid(false)
      }
    }


    if (fieldName === 'equipmenttype') {
      if (value.length != 0) {
        setEquipmenttypeerror('')
        setEquipmenttypeValid(true)
      } else {
        setEquipmenttypeerror('Please enter your Equipment type')
        setEquipmenttypeValid(false)
      }
    }

  }

  function onSubmitEvent(event) {
    event.preventDefault();

    //console.log(date)
    //console.log(equipmenttype)

    let userData12 = new FormData()
    userData12.append('date', date)
    userData12.append('equipment_type', equipmenttype)
    userData12.append('project_id', userData.currentprojectId)

    // axios
    //   .get(`${baseurl}/api/report/list/search/equipments?equipment_type=${equipmenttype}&date=${convertedDate}&project_id=${userData.currentprojectId}`, userData12, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

      axios({
        method: 'GET',

        url: baseurl+'/api/report/list/search/equipments?equipment_type='+equipmenttype+'&date='+convertedDate+'&project_id='+userData.currentprojectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        },
     // data:userData12
      })
      .then((response) => {
        const res = response.data.equipments_search_data;
        //console.log("response", res);






        setSearch(res);
        setSuccessstatus(true);
      })
      .catch((error) => {
        console.error("Error:", error.response ? error.response.data : error.message);

      });


  }

  return (
    <>
      <div className='row pagetopspace'>
        <div class="col-lg-1 col-xl-1"></div>
        <div class="col-lg-10 col-xl-10 pt-5 pb-3 ">
        {graphData.length==0 &&
          <Equipmentgraph graphData={graphData} />}

          {graphData.length!=0 &&
          <Equipmentgraph graphData={graphData} />}

        </div>
        
              <div className=" col-xl-12 col-lg-12 pt-3 pb-3">
                <div>
                  <div className="labels ff pt-2 pb-3"> <center> <b>Equipment Requested</b></center>  </div>
                </div>
                {data.length == 0 &&
                  <div class="pt-2 login-error">
                    <center><h6>No New Request</h6></center>
                  </div>}
                {
                    data.length != 0 

                   &&
                  <table
                    class="table "
                    readOnly="readOnly"
                  >
                    <tr
                    // class="trst1"
                    >
                   <th><div className='trpad'>Equipment Name</div></th>
                      <th>Requested</th>
                      <th>Dispatch</th>
                      <th>Level</th>

                    </tr>

                    {data.map((item, index) => {

                      return (
                        <tr key={index}
                         className='tablefontsize'
                        >

                          <td><div className='trpad'>{item.equipment_name!=undefined?item.equipment_name:'NA'}</div></td>
                          <td>{item.requested_date!=undefined?item.requested_date:'NA'}</td>
                          <td>{item.dispatched_date!=undefined?item.dispatched_date:'NA'}</td>
                          <td>{item.level!=undefined?item.level:'NA'}</td>

                        </tr>
                      )
                    })}
                  </table>}                
      </div>

      {/*Equipment usage report  */}
      <div className=" col-xl-12 col-lg-12 pt-3 pb-3">
                <div>
                  <div className="labels ff pt-2 pb-3 pt-3"> <center> <b>Equipment Usage Report</b></center>  </div>
                </div>
                {usedquip.length == 0 && successstatus2 &&
                  <div class="pt-3 login-error">
                    <center><h6>No Data Available</h6></center>
                  </div>}
                  {
                    usedquip.length != 0 

                   &&
                  <table
                    class="table "
                    readOnly="readOnly"
                  >
                    <tr
                    // class="trst1"
                    >
                       <th><div className='trpad'>Date</div></th>
                      <th><div>Equipment Name</div></th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Hours</th>
                  

                    </tr>
                    {
                    usedquip.length != 0 

                   &&
                usedquip.map((item, index) => {

                      return (
                        <tr key={index}
                         className='tablefontsize'
                        >

                          <td><div className='trpad'>{item.date!=undefined?item.date:'NA'}</div></td>
                          <td><div >{item.equipment_name!=undefined?item.equipment_name:'NA'}</div></td>
                          <td>{item.started_time!=undefined?item.started_time:'NA'}</td>
                          <td>{item.ended_time!=undefined?item.ended_time:'NA'}</td>
                          <td>{item.total_used!=undefined?item.total_used:'NA'}</td>
                          {/* <td>
                            <select
                              className="select-box"
                              id="status"
                              name="status"
                              // defaultValue={status}
                              onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault()
                              }}
                              value={status}
                              onChange={(e) => handlestatus(e, item.resource_request_id)}
                            >
                              <option value={item.status} selected>
                                {item.status}
                              </option>
                              <option value="APPROVED">APPROVED</option>
                              <option value="ORDERED">ORDERED</option>
                              <option value="DISPATCHED">DISPATCHED</option>
                            </select>
                          </td> */}


                        </tr>
                      )
                    })}
                  </table>
}




      </div>
      {/*  */}
      </div>
      <form
      >
        <div className='row pt-5'>
          <div class="col-lg-3 col-xl-3"></div>
          <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3 ">
            <label for="formFileLg" className="login-text">
              Date
            </label>
            <center>
              <InputTextField
                handleInputChange={handledate}
                valueText={date}
                inputName="date"
                inputId="date"
                classname="project-box"
                maxLen={20}
                inputType="date"

              ></InputTextField>

              {/* <div className="login-error">{dateeerror}</div> */}
            </center>
          </div>
        </div>
        <div className='row'>
          <div class="col-lg-3 col-xl-3"></div>
          <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6  pb-3 ">
            <label for="formFileLg" className="login-text">
              Equipment Type
            </label>
            <center>

              <select
                className="project-box"
                id="equipmenttype"
                name="equipmenttype"
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                defaultValue={equipmenttype}
                value={equipmenttype}
                onChange={handleequipmenttype}
              >
                <option>
                  Select
                </option>
                {data12.length !== 0 && data12.map((name, index) => (
                  <option key={index} value={name}>
                    {name.replace(/_/g, ' ')}
                  </option>
                ))}
              </select>


              <div className="login-error"></div>
            </center>
          </div>
        </div>
        <div className='row'>
          <div class="col-lg-3 col-xl-3"></div>
          <div class="col-sm-12 col-lg-6 col-12 col-md-12 col-xl-6 pt-4 ">
            <center>
              <button
                type="submit"
                className="project-btn"
                name="submit"
                onClick={onSubmitEvent}
              >
                <b>Search</b>
              </button>
            </center>{' '}
          </div>
        </div>
      </form>
      <div  >
      {successstatus && search.length == 0 &&
            // !checkduedate &&
            <div className='row pt-5'>
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error">
                <center><b><h6>No Data Available</h6></b></center>
                </div>
                </div>}
        <div className='table1bdstyle '>
          <div class="row table1st">
        
          {search.length != 0 &&
            <table
              class="table"
              readOnly="readOnly"
            >
              <tr class="trst">
                <th style={{ width: 150 }} ><center>Date</center></th>
                <th><center>Equipment Type</center></th>
                <th style={{ width: 150 }}><center>Start time</center></th>
                <th ><center>End time</center></th>
                <th><center>Total Hours Used</center></th>
                <th ><center>Total Hours Idle</center></th>
              </tr>

              {search.length != 0 &&
                search.map((item, index) => {
                  return (
                    <tr key={index} className='tablefontsize'>
                      <td><center>{item.date!=undefined?item.date:'NA'}</center> </td>
                      <td><center>{item.equipment_name!=undefined?item.equipment_name:'NA'}</center> </td>
                      <td><center>{item.started_time!=undefined?item.started_time:'NA'}</center> </td>
                      <td><center>{item.ended_time!=undefined?item.ended_time:'NA'}</center> </td>
                      <td><center>{item.total_used!=undefined?item.total_used:'NA'}</center> </td>
                      <td><center>{item.total_idle!=undefined?item.total_idle:'NA'}</center> </td>


                    </tr>
                  )
                })}
            </table>}

          </div>

        </div>

      </div>

    </>
  )
}

export default FormPage
