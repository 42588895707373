import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom"
import './EditVendor1.css'
// import { Navigate } from 'react-router-dom'
import plus from '../../assets/images/plus.png'
import minus from '../../assets/images/minus.png'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import PDF from '../Doc/SarasuPrivacyPolicy.pdf'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
function EditVendor() {
  const fileInputRef = useRef(null);

  const [successstatus, setSuccessstatus] = useState(false)
  const [successmsg, setSuccessmsg] = useState('');
  const [companyname, setCompanyname] = useState('')
  const [companynameValid, setCompanynameValid] = useState(false)
  const [companynameerror, setCompanynameerror] = useState('')

  const [vendortype, setVendortype] = useState('')
  const [vendortypeValid, setVendortypeValid] = useState(false)
  const [vendortypeerror, setVendortypeerror] = useState('')

  const [resourcetype, setResourcetype] = useState('')
  const [resourcetypeValid, setResourcetypeValid] = useState(false)
  const [resourcetypeeerror, setResourcetypeerror] = useState('')
  

  const [onboardingdate, setOnboardingdate] = useState('')
  const [onboardingdateValid, setOnboardingdateValid] = useState(false)
  const [onboardingdateeerror, setOnboardingdateerror] = useState('')

  const [contactno, setContactno] = useState('')
  const [contactnoValid, setContactnoValid] = useState(false)
  const [contactnoerror, setContactnoerror] = useState('')

  const [contactmail, setContactmail] = useState('')
  const [contactmailValid, setContactmailValid] = useState(false)
  const [contactmailerror, setContactmailerror] = useState('')

  const [contactperson, setContactperson] = useState('')
  const [contactpersonValid, setContactpersonValid] = useState(false)
  const [contactpersonerror, setContactpersonerror] = useState('')


  const [documentupload, setDocumentupload] = useState('')
  const [documentuploadValid, setDocumentuploadValid] = useState(false)
  const [documentuploaderror, setDocumentuploaderror] = useState('')
  const [editableForm, setEditableForm] = useState(false)
  const [isVisible, setIsVisible] = useState(true);

  const [data11, setData11] = useState([]);
  const [datta, setDatta] = useState([]);
  const [dropdowns, setDropdowns] = useState([{ id: 0, value: '' }]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [addtext, setAddtext] = useState(false)
  const location = useLocation()

  const vendorID = location.state?.vendorID


  const userData = useSelector((state) => state.userDetails)

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);



  useEffect(() => {
    axios({
      method: 'GET',

      url: baseurl + '/api/vendor/list/get/individual_contractor_details?contractor_id=' + vendorID + '&contractor_type=Vendor',

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token

      }
    })

      .then((response) => {

        const datas = response.data
        setDatta(datas)
//console.log(datas)
        const email1 = datas[0].email
        const phoneno = datas[0].phone_no
        const contactperson = datas[0].contact_person
        const resource_type1 = datas[0].type_of_resource
       const resource_type= JSON.stringify(resource_type1).replace(/[\[\]"]/g, '').replace(/\\/g, '');
        //console.log(resource_type)
             
        //const resource_type = resource_type1.join(',')
        ////////alert(resource_type)
        const company=datas[0].vendor_company_name
        const onBoardingDate = datas[0].onboarding_date



        setContactmail(email1)
        setContactno(phoneno)
        setContactperson(contactperson)
        setResourcetype(resource_type)
        setCompanyname(company)
        setOnboardingdate(onBoardingDate)
      })
      .catch((error) => {

      });

      axios
      .get(`${baseurl}/api/project/list/resources&equipments`, {
        params: {
          project_id:userData.projectId,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        const mt = data.materials;
        setData11(mt);
        //console.log('Data received:', response.data); // Log the received data
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      const newDropdowns = dropdowns.map((dropdown) =>
      dropdown.id === 0 ? { ...dropdown, value: resourcetype } : dropdown
    );
    setDropdowns(newDropdowns);
   

  }, [resourcetype]);

  function handleEdit(e) {
  
    setSuccessmsg('')
    setEditableForm(true)
  }

  function handleCompanyname(e) {
    setCompanyname(e.target.value)
    validateField('companyname', e.target.value)
  }

  const handleDropdownChange = (e, id) => {
    const newDropdowns = dropdowns.map((dropdown) =>
      dropdown.id === id ? { ...dropdown, value: e.target.value } : dropdown
    );
    setDropdowns(newDropdowns);
    //////alert(newDropdowns)
    // setResourcetype(newDropdowns.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  };

  const handleRemoveDropdown = (id) => {
    if (id === 0) return;
    const updatedDropdowns = dropdowns.filter((dropdown) => dropdown.id !== id);
    setDropdowns(updatedDropdowns);
  };

  function handleDeleteFile(index) {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);

    // Update the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.files = updatedFiles;
    }
  }

  function handleVendortype(e) {
    setVendortype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleDocumentupload(e) {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setDocumentupload(selectedFile); // Store the selected file
    } else {
      setDocumentupload(null);
    }
  }
  function handleResourceType(e) {

    setResourcetype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleOnboardingdate(e) {
    setOnboardingdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleDeleteFile(index) {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  }

  function handleContactmail(e) {
    setContactmail(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function addTextbox()
  { 
    const newDropdowns = [...dropdowns];
    newDropdowns.push({ id: dropdowns.length + 1, value: '' });
    setDropdowns(newDropdowns);

  }


  function handleContactperson(e) {
    setContactperson(e.target.value)
    validateField(e.target.name, e.target.value)
  }


  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {

    if (fieldName === 'companyname') {
      //console.log(value)

      if (value != 0) {
        setCompanynameerror('')
        setCompanynameValid(true)
      } else {
        setCompanynameerror('Please select Company name')
        setCompanynameValid(false)
      }
    }



    if (fieldName === 'uploaddocument') {
      //console.log(value)

      if (value != 0) {
        setDocumentuploaderror('')
        setDocumentuploadValid(true)
      } else {
        setDocumentuploaderror('Please select uploaddocument')
        setDocumentuploadValid(false)
      }
    }


    if (fieldName === 'vendortype') {
      if (value.length != 0) {
        setVendortypeerror('')
        setVendortypeValid(true)
      } else {
        setVendortypeerror('Please enter your Vendor type')
        setVendortypeValid(false)
      }
    }
    if (fieldName === 'onboardingdate') {
      if (value.length != 0) {
        setOnboardingdateerror('')
        setOnboardingdateValid(true)
      } else {
        setOnboardingdateerror('Please select date')
        setOnboardingdateValid(false)
      }
    }

    if (fieldName === 'resourcetype') {
      if (value.length != 0) {
        setResourcetypeerror('')
        setResourcetypeValid(true)
      } else {
        setResourcetypeerror('Please enter your type')
        setResourcetypeValid(false)
      }
    }
    if (fieldName === 'contactmail') {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      if (value.length != 0) {
        //console.log(value.match(regex))
        if (value.match(regex)) {

          setContactmailValid(true)
          setContactmailerror('')
        } else {

          setContactmailValid(false)
          setContactmailerror('Please enter valid email')
        }
      } else {

        setContactmailValid(false)
        setContactmailerror('Please enter valid email')
      }
    }

    if (fieldName === 'contactno') {
      if (value.length != 0 && value.length == 10) {
        setContactnoerror('')
        setContactnoValid(true)
      } else {
        setContactnoerror('Please enter valid Contact number')
        setContactnoValid(false)
      }
    }

    if (fieldName === 'contactperson') {
      //console.log(value)
      if (value != "Select") {
        setContactpersonerror('')
        setContactpersonValid(true)
      } else {
        setContactpersonerror('Please select  Contact person')
        setContactpersonValid(false)
      }
    }
  }


  function onSubmitEvent(event) {
    event.preventDefault();


    //console.log(contactperson)
    //console.log(contactno)

    //////alert(JSON.stringify(dropdowns))
    const dropdownValues1 = dropdowns.map((dropdown) => dropdown.value);
    //////alert(dropdownValues1)
    const dropdownValues = dropdownValues1.flatMap(item => item.split(','))
    .map(item => item.trim());
    //////alert(dropdownValues)
  //  const dropdownValues = JSON.stringify(dropdownValuess)
    axios({
      method: 'put',

      url: baseurl + '/api/vendor/update/individual_contractor_details?contractor_type=Vendor&contractor_id=' + vendorID + '&contact_person=' + contactperson + '&contact_no=' + contactno + '&contact_mail=' + contactmail + '&resourcelist=' + dropdownValues,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })


      .then((response) => {
       // //////alert("updated")
        setSuccessstatus(true)
        setSuccessmsg("Successfully Updated")
        setEditableForm(false)

      })
      .catch((error) => {

      });
    // }

  }

  
  return (


    <div className="cont-margin1">

      <div class="cont-margin-latest  ">
        <div className='table1bdstyle'>
          {datta.map((datta, index) => {
            return (
              <div className='row table1st'>
                <div class="row pt-1 padleft">


                  <Link to="/Vendor">
                    <button
                      type="submit"
                      className="contractor-btn1"
                      name="submit"
                      float="right"

                    >
                      <b>Back</b>
                    </button>
                  </Link>


                </div>

                <div class="row pb-5">


                  <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 pb-5 ">
                    <form
                      class="row d-flex justify-content-center align-center  blacktext"
                      onSubmit={onSubmitEvent}
                    >

                      <div class="row">

                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            Company Name
                          </label>


                          <center>
                            <InputTextField
                              handleInputChange={handleCompanyname}
                              valueText={companyname}
                              inputName="companyname"
                              inputId="companyname"
                              classname="project-box"

                              inputType="text"
                              placeholderValue="Company Name"
                              disabled

                            ></InputTextField>
                          </center>




                        </div>




                        <div class="col-12 col-sm-12  col-md-6 col-lg-6 col-md-6 pb-3">
                    
                         
                         
                          <label
                            for="formFileLg"
                            class="form-label"
                            className="formfontst ast= login-text"
                          >
                            Resource Type
                          </label>
                          <div class = 'row'>
                            
                        <div class="col-10 col-sm-10  col-md-10 col-lg-10 col-md-10 ">
                        
                          {dropdowns.map((dropdown) => (
                            <div>
        <div key={dropdown.id}>
          <div class = 'row'>
          <div class="col-10 col-sm-10  col-md-10 col-lg-10 col-md-10 mb-1 ">
       
                     
<select
                    className="project-box"  
                     id="resourcetype"
                     name="resourcetype"
                    //  defaultValue={companyname}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={dropdown.value}
                    onChange={(e) => handleDropdownChange(e, dropdown.id)}
                 disabled={!editableForm}
                  >
                    <option value={dropdown.value} selected>
                  {dropdown.value}
                    </option>
                    {data11.length !== 0 && data11.map((name, index) => (
                        <option key={index} value={name}>
                          {name.replace(/_/g, ' ')}
                        </option>
                      ))}
                  </select>
</div>
                        
<div class="col-2 col-sm-2  col-md-2 col-lg-2 col-md-2 pt-2 mb-1">     
                          <img
            src={minus}
            alt="Remove"
           disabled={!editableForm}
            onClick={() => handleRemoveDropdown(dropdown.id)}
            style={{ cursor: editableForm ? 'pointer' : 'not-allowed',
            opacity: editableForm ? 1 : 0.5,}}
            height={20}
            width={20}
          />

         </div>                 </div>
                          </div>
                          </div>

                          
                          ))}
                          
                          <div className="login-error">{resourcetypeeerror}</div>

            
                        </div>
                        <div class="col-2 col-sm-2  col-md-2 col-lg-2 col-md-2 pt-2 ">
                          <img src={plus} onClick={addTextbox}  style={{ cursor: editableForm ? 'pointer' : 'not-allowed',
                opacity: editableForm ? 1 : 0.5,}} height={20} width={20} alt='plus' />
                          </div>
                  </div>
                    
                      
                        </div>


                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            On Boarding date
                          </label>
                          <center>


                            <InputTextField
                              //handleInputChange={handleOnboardingdate}
                              valueText={onboardingdate}
                              inputName="onboardingdate"
                              inputId="onboardingdate"
                              classname="project-box"
                              inputType="date"
                              placeholderValue="Date"
                              disabled

                            ></InputTextField>


                            <div className="login-error">{onboardingdateeerror}</div>
                          </center>
                        </div>
                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            Contact Person
                          </label>
                          <center>

                            <InputTextField
                              handleInputChange={handleContactperson}
                              valueText={contactperson}
                              inputName="contactperson"
                              inputId="contactperson"
                              classname="project-box"

                              inputType="text"
                              placeholderValue="Contact person"
                              disabled={!editableForm}
                            ></InputTextField>
                          </center>
                          <div className="login-error">{contactpersonerror}</div>

                        </div>


                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            Contact Number
                          </label>
                          <center>

                            <InputTextField
                              handleInputChange={handleContactno}
                              valueText={contactno}
                              inputName="contactno"
                              inputId="contactno"
                              classname="project-box"
                              maxLen={10}
                              inputType="text"
                              placeholderValue="Contact number"
                              disabled={!editableForm}
                            ></InputTextField>
                          </center>
                          <div className="login-error">{contactnoerror}</div>

                        </div>

                        <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            Contact Email
                          </label>
                          <center>

                            <InputTextField
                              handleInputChange={handleContactmail}
                              valueText={contactmail}
                              inputName="contactmail"
                              inputId="contactmail"
                              classname="project-box"
                              inputType="text"
                              placeholderValue="Contact email"
                              disabled={!editableForm}
                            ></InputTextField>

                            <div className="login-error">{contactmailerror}</div>
                          </center>
                        </div>
                        <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                          <label for="formFileLg" className="login-text">
                            Uploaded Document
                          </label>
                          {/* <input
                            type="file"
                            className="project-box"
                            id="documentupload"
                            name="documentupload"
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={handleDocumentupload}
                            disabled
                          /> */}
                            <div class="pt-2">
                          {datta.contract_document_filename}
                          </div>
                        </div>

                        {/* {selectedFiles.length > 0 && (
                          <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                            <label className="login-text">Selected Files</label>
                            <ul>
                              {selectedFiles.map((file, index) => (
                                <li key={index}>
                                  {file.name}
                                  <button
                                    type="button"
                                    onClick={() => handleDeleteFile(index)}
                                    className="delete-button"
                                  >
                                    Delete
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )} */}

                      </div>
                  
                      <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-3 pb-3">
                    
                        <center>
                        <div className="success pb-3">{successmsg}</div>
                          {!editableForm && <button
                            type="Edit"
                            className="project-btn"
                            name="Edit"
                            onClick={handleEdit}
                          >
                            <b>Edit</b>
                          </button>}

                        </center>{' '}
                      </div>
                      <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                        <center>
                          {editableForm == true && <button
                            type="submit"
                            className="project-btn"
                            name="submit"
                          >
                            <b>Save</b>
                          </button>}

                        </center>{' '}


                      </div>

                    </form>

                  </div>

                </div>
                {/* <div>
      <Document
        file="C:/Users/ATHIMUTHAN.N/Downloads/Sham%20Resume.pdf" // Replace 'path_to_your_document.pdf' with your document URL or path
        onLoadSuccess={onDocumentLoadSuccess}
      >   {[...Array(numPages).keys()].map((page) => (
        <Page key={page + 1} pageNumber={page + 1} />
      ))}
      </Document>
      <p>Page {pageNumber} of {numPages}</p>
    </div> */}

              </div>)
          })}
        </div>

      </div>
    </div>
  );
}

export default EditVendor;

