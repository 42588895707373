import React from 'react';
import RadialBar from '../ApexCharts/RadialBar';
import './ThirdParty.css';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaStarAndCrescent } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from 'react'
import RequestModal from '../Modal/CreateRequest'
import StatusupdateModal from '../Modal/Edit'
import Renewal from '../Modal/Renewal'
import ViewTickets from '../Modal/ViewTickets'
import { baseurl } from '../BaseUrl/Baseurl'
import completed from '../../assets/images/completed-5.png'
import inprogress from '../../assets/images/buttons/inprogress.png'
import  received from '../../assets/images/buttons/received.png'
import delayed from '../../assets/images/buttons/delayed.png'
import  cancelled from '../../assets/images/buttons/cancelled.png'
import sandcorrect from '../../assets/images/buttons/sandcorrect.png'
import brickcorrect from '../../assets/images/buttons/brickcorrect.png'
import stonescorrect from '../../assets/images/buttons/stonescorrect.png'
import ironrodcorrect from '../../assets/images/buttons/ironrodcorrect.png'
import cementcorrect from '../../assets/images/buttons/cementcorrect.png'
import ordered from '../../assets/images/buttons/ordered.png'
import sandhead from '../../assets/images/resources/sandhead.png'
import brick from '../../assets/images/resources/brick.png'
import cement from '../../assets/images/resources/cement.png'
import ironrod from '../../assets/images/resources/ironrod.png'
import sand from '../../assets/images/resources/sand.png'
import stones from '../../assets/images/resources/stones.png'
import tiles from '../../assets/images/resources/tiles.png'
import LineChart from '../ApexCharts/LineChart'
import LineChart1 from '../ApexCharts/LineChart1'
import $ from 'jquery';
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'

const WeareHere = () => {
  const [structureName , setStructureName ] = useState('')
  const [structureId, setStructureId] = useState('')
  const [itemName, setItemName] = useState('')
  const [compDate, setCompDate] = useState('')
  const [itemOfWork, setItemOfWork] = useState('')
  const [fulldata, setFulldata] = useState([]);
  const [btnname, setBtnname] = useState('')
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [caxis, setCaxis] = useState([]);
  const [daxis, setDaxis] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [graphform, setGraphform] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const userData = useSelector((state) => state.userDetails)
  const [tableData, setTableData] = useState([]);
  const [successstatus, setSuccessstatus] = useState(false);

  const drop = React.useRef(null);
 ///////////////drop down card for drop downbox ///////////////////////////////////////

 
  
                              
  useEffect(() => {

 
    axios({
      method: 'GET',

      url: 'http://www.brick-erp.com/brick'+'/api/vendor/management/details?project_id='+userData.currentprojectId, 

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      },

    })
      .then((response) => {

//console.log(response.data)
        const dat = response.data
        //console.log("%%%%" + response.data)
        //console.log("hello" + dat)
        // ////alert("We Got Calculated Details")
        setTableData(dat)
           setSuccessstatus(true)
     
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })
      
  },[])
   
  return (
    
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 pagetopspace ">
      {/* <div class="cont-margin pt-2 "> */}

{/* <div className='table1bdstyle'>
<div class="row table1st"> */}
{/* <div class="pt-1 pb-1">
      <p><b>Work Progress</b></p>
    </div> */}
    {/* <div className='tableContainer' > */}
{/* <div class="row">
              <div class=" col-xl-8 col-lg-8">
              
              </div>
              <div class=" col-xl-4 col-lg-4  pb-2">
            <Link to="/SubContractorCreation"> 
            <button 
                    type="button"
                    className="createpro-btn"
                  >
                   &nbsp;Add</button>
            </Link> 
              </div>
            </div> */}

{ tableData.length == 0 && successstatus &&
            
           
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pt-5 pb-3">
                <center><b><h6>No Data Available</h6></b></center>
              
                </div>}
            
     {tableData.length!=0 && <table
    class="table"
    readOnly="readOnly"
 

  >
    <tr class ='trst'>
      
      <th>Contractor Name</th>
      <th>Item of Work</th>
      <th>Progress %</th>
      <th>Level</th>
      <th>Status </th>
      {/* <th> </th> */}
    </tr>
    {tableData.length!=0 && tableData.map((item,index) =>{
      return(
        <tr key ={index} className='tablefontsize'>
          <td 
          >{item.contractor_name!=undefined && item.contractor_name!=null  ?item.contractor_name:'NA' }</td>
          <td >{item.item_of_work!=undefined && item.item_of_work!=null  ?item.item_of_work:'NA' }</td>
          <td > {item.progress!=undefined && item.progress!=null  ?item.progress:'NA' }</td>
          <td >{item.level!=undefined && item.level!=null  ?item.level:'NA' } </td>
          <td>{((item.status) === 'Inprogress'?
           <img src = {inprogress} alt='inprogress' height={30} width={90} />:'')||
           (item.status === 'Completed'? <img src ={completed} alt = 'completed'
            height = {30} width = {90} />: <img src ={delayed} alt = 'delayed' 
            height = {30} width = {90} />)}</td>
      
          {/* <td>  <center>
            <Link to="/ThirdPartyContractorDetails"><button
                      type="button"
                      className="viewtic-btn1"
                    //   onClick={() => ViewticketstatusUpdate(item.ticket_number)}
                    >
                      <b>View</b>
                    </button></Link></center></td> */}
        </tr>
      )
     })
    }
  </table>}

{/* <div class="pt-3">
<table
    class="table"
    readOnly="readOnly"
 

  >
    <tr class ='trst'>
    <th>Contractor Name</th>
      <th>Item of Work</th>
      <th>Completed Chainage</th>
      <th>Percentage of Work Completed / Chainage Wise</th>
      <th>Percentage of Work Completed / Structure Wise</th>
      <th>Status </th>
      <th>Payment</th>
      <th> </th>
    </tr>
    {tableData.length!=0 && tableData.map((item,index) =>{
      return(
        <tr key ={index}>
          <td 
         
          >{item.contractor_name}</td>
          
       
          
        
          <td >{item.item_of_work}</td>
          <td >{item.progress}</td>
          <td >{item.level} </td>
          <td>{((item.status) === 'Inprogress'?
           <img src = {inprogress} alt='inprogress' height={30} width={90} />:'')||
           (item.status === 'Completed'? <img src ={completed} alt = 'completed'
            height = {30} width = {90} />: <img src ={delayed} alt = 'delayed' 
            height = {30} width = {90} />)}</td>
      
       
        </tr>
      )
     })
    }

    


  </table>
  
  </div> */}


</div>




// </div>
// </div>

// </div>
 );
}

export default WeareHere;