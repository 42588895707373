import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect, useRef } from 'react';
import './VendorCreation.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'


import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
function FormPage() {
  const fileInputRef = useRef(null);

  const [successstatus, setSuccessstatus] = useState(false)

  const [companyname, setCompanyname] = useState('')
  const [companynameValid, setCompanynameValid] = useState(false)
  const [companynameerror, setCompanynameerror] = useState('')

  const [vendortype, setVendortype] = useState('')
  const [vendortypeValid, setVendortypeValid] = useState(false)
  const [vendortypeerror, setVendortypeerror] = useState('')

  const [resourcetype, setResourcetype] = useState('')
  const [resourcetypeValid, setResourcetypeValid] = useState(false)
  const [resourcetypeeerror, setResourcetypeerror] = useState('')
  
  const [onboardingdate, setOnboardingdate] = useState('')
  const [onboardingdateValid, setOnboardingdateValid] = useState(false)
  const [onboardingdateeerror, setOnboardingdateerror] = useState('')

  const [contactno, setContactno] = useState('')
  const [contactnoValid, setContactnoValid] = useState(false)
  const [contactnoerror, setContactnoerror] = useState('')

  const [contactmail, setContactmail] = useState('')
  const [contactmailValid, setContactmailValid] = useState(false)
  const [contactmailerror, setContactmailerror] = useState('')
 
  const [contactperson, setContactperson] = useState('')
  const [contactpersonValid, setContactpersonValid] = useState(false)
  const [contactpersonerror, setContactpersonerror] = useState('')


  const [documentupload, setDocumentupload] = useState('')
  const [documentuploadValid, setDocumentuploadValid] = useState(false)
  const [documentuploaderror, setDocumentuploaderror] = useState('')

  const [data11, setData11] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const userData = useSelector((state) => state.userDetails)
  const [filename, setFilename] = useState('')
  const [errormsg, setErrormsg] = useState('')
    useEffect(() => {
      axios
      .get(`${baseurl}/api/project/list/resources&equipments`, {
        params: {
          project_id: userData.projectId,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        const mt = data.materials;
        setData11(mt);
        //console.log('Data received:', response.data); // Log the received data
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    }, []);

    function handleCompanyname(e) {
      setCompanyname(e.target.value)
      validateField('companyname', e.target.value)
      setSuccessstatus(false)
      setErrormsg('')
    }
   
    function handleDeleteFile(index) {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index, 1);
      setSelectedFiles(updatedFiles);
    
      // Update the file input value
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
        fileInputRef.current.files = updatedFiles;
      }
      setSuccessstatus(false)
    }
    
  function handleVendortype(e) {
    setVendortype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleDocumentupload(e) {
    const selectedFile = e.target.files[0];
  
    if (selectedFile) {

        let filename = selectedFile.name
        let extension = filename.split('.').pop()
        setErrormsg('')
        if (extension === 'pdf') {

          setDocumentuploaderror('')
          setDocumentuploadValid(true)
          setDocumentupload(selectedFile);
          setFilename(filename)

        } else {
          setDocumentuploaderror('Please upload only pdf document')
          setDocumentuploadValid(false)
          setDocumentupload(null);
          setFilename('')
        }
     
    } else {
      setDocumentupload(null);
    }
    setSuccessstatus(false)
  
  }
  function handleResourceType(e) {

    setResourcetype(e.target.value)
    validateField(e.target.name, e.target.value)
    setSuccessstatus(false)
    setErrormsg('')
  }

  function handleOnboardingdate(e) {
    setOnboardingdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
    setSuccessstatus(false)
    setErrormsg('')
  }

  function handleDeleteFile(index) {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    setSuccessstatus(false)
  }
 
  function handleContactmail(e) {
    setContactmail(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
    setSuccessstatus(false)
    setErrormsg('')
  }
  
  
  function handleContactperson(e) {
    setContactperson(e.target.value)
    validateField(e.target.name, e.target.value)
    setSuccessstatus(false)
    setErrormsg('')
  }


  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
    setSuccessstatus(false)
    setErrormsg('')
  }
  const validateField = (fieldName, value) => {

  if (fieldName === 'companyname') {
    //console.log(value)

    if (value != 0) {
      setCompanynameerror('')
      setCompanynameValid(true)
    } else {
      setCompanynameerror('Please select Company name')
      setCompanynameValid(false)
    }
  }




 
    if (fieldName === 'onboardingdate') {
      if (value.length!=0) {
        setOnboardingdateerror('')
        setOnboardingdateValid(true)
      } else {
        setOnboardingdateerror('Please select date')
        setOnboardingdateValid(false)
      }
    }
    
      if (fieldName === 'resourcetype') {
        if (value.length != 0) {
          setResourcetypeerror('')
          setResourcetypeValid(true)
        } else {
          setResourcetypeerror('Please enter your type')
          setResourcetypeValid(false)
        }
      }
    if (fieldName === 'contactmail') {
      const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      if (value.length != 0) {
        //console.log(value.match(regex))
        if (value.match(regex)) {
    
          setContactmailValid(true)
          setContactmailerror('')
        } else {
     
          setContactmailValid(false)
          setContactmailerror('Please enter valid email')
        }
      } else {
        
        setContactmailValid(false)
        setContactmailerror('Please enter valid email')
      }
    }
  
    if (fieldName === 'contactno') {
        if (value.length != 0 && value.length==10) {
          setContactnoerror('')
          setContactnoValid(true)
        } else {
          setContactnoerror('Please enter valid Contact number')
          setContactnoValid(false)
        }
      }
    
      if (fieldName === 'contactperson') {
        //console.log(value)
        if (value.length !=0) {
          setContactpersonerror('')
          setContactpersonValid(true)
        } else {
          setContactpersonerror('Please select  Contact person')
          setContactpersonValid(false)
        }
      }
  }
  

  function onSubmitEvent(event) {
    event.preventDefault();
    
   //console.log(companynameValid)
 //console.log(resourcetypeValid)
 //console.log(onboardingdateValid)
 //console.log(contactpersonValid)
 //console.log(contactnoValid)
 //console.log(contactmailValid)
 //console.log(documentupload)



    if (companynameValid &&resourcetypeValid && onboardingdateValid  
      && contactpersonValid && contactmailValid && contactnoValid && documentuploadValid ) {

     

        let userData0 = new FormData()
        userData0.append('company_name',companyname )
        userData0.append('vendor_type', 'Vendor')
        userData0.append('type_of_resource', resourcetype)
        userData0.append('phone_no',contactno )
        userData0.append('onboarding_date',onboardingdate)
        userData0.append('email', contactmail)
        userData0.append('contact_person', contactperson)
        userData0.append('contract_document',documentupload)
        userData0.append('project_id', userData.projectId)
        userData0.append('filename', filename)
        userData0.append('client_id', userData.clientId)
        // axios.post(baseurl + '/api/vendor/create', userData0, {
        //   headers: { 'Content-Type': 'multipart/form-data' },
        // })

        axios({
          method: 'POST',
  
          url: baseurl+ '/api/vendor/create',
  
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + userData.token
          },
          data:userData0
        })
          .then((response) => {
            setErrormsg('')
            setSuccessstatus(true)
            setCompanyname('')
            setContactmail('')
            setContactperson('')
            setOnboardingdate('')
            setVendortype('')
            setResourcetype('')
            setContactno('')
            setDocumentupload(null)
            setCompanynameValid(false) 
            setResourcetypeValid(false)
            setOnboardingdateValid(false)
            setContactpersonValid(false)
            setContactmailValid(false)
            
            setContactnoValid(false)
            setDocumentuploadValid(false)

             
          })
          .catch((error) => {
           const er=error.response.data.message
           setErrormsg(er)
          });
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
    }
  
  }
  
  return (

    
        <div className="cont-margin1">

            <div class="cont-margin-latest pt-2 ">
                <div className='table1bdstyle'>
              <div className='row table1st'>
              <div class="row pt-3 padleft">
               
               
                <Link to="/Vendor">
                        <button
                          type="submit"
                          className="contractor-btn1"
                          name="submit"  
                          float="right"   
                                     
                        >
                          <b>Back</b>
                        </button>
                        </Link>
                        
                      
                    </div>

                <div class="row pb-3">
           
                
                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12 pb-3">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={onSubmitEvent}
                  >

                    <div class="row">

                    <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast login-text" 
                  >
                    Company Name
                  </label>
                  <InputTextField
                          handleInputChange={handleCompanyname}
                          valueText={companyname}
                          inputName="companyname"
                          inputId="companyname"
                          classname="project-box"
                          inputType="companyname"
                          placeholderValue="companyname"
                        // mindate={todayDate}
                        ></InputTextField>
                  <div className="login-error">{companynameerror}</div>
                </div>
                {/* <div class="col-12 col-sm-12  col-md-6 col-lg-6 col-md-6 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast login-text"
                  >
                    Vendor Type
                  </label>
                  <select
                    className="project-box"  
                     id="vendortype"
                     name="vendortype"
                    //  defaultValue={companyname}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={vendortype}
                 onChange={handleVendortype}
                  >
                    
                    <option value="Vendor">Vendor</option>
           
                  </select>
                  <div className="login-error">{vendortypeerror}</div>
                </div> */}
                
                <div class="col-12 col-sm-12  col-md-6 col-lg-6 col-md-6 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast= login-text"
                  >
                    Resource Type
                  </label>
                  <select
                    className="project-box"  
                     id="resourcetype"
                     name="resourcetype"
                    //  defaultValue={companyname}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={resourcetype}
                 onChange={handleResourceType}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                    {data11.length !== 0 && data11.map((name, index) => (
                        <option key={index} value={name}>
                          {name.replace(/_/g, ' ')}
                        </option>
                      ))}
                  </select>
                  <div className="login-error">{resourcetypeeerror}</div>
                </div>
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                      On Boarding date
                  </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleOnboardingdate}
                          valueText={onboardingdate}
                          inputName="onboardingdate"
                          inputId="onboardingdate"
                          classname="project-box" 
                          inputType="date"
                          placeholderValue=" Date"
                          // mindate={todayDate}
                        ></InputTextField>

                      <div className="login-error">{onboardingdateeerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                    Contact Person
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleContactperson}
                          valueText={contactperson}
                          inputName="contactperson"
                          inputId="contactperson"
                          classname="project-box"
                        
                          inputType="text"
                          placeholderValue="Contact person"

                        ></InputTextField>
     </center>
                        <div className="login-error">{contactpersonerror}</div>
                   
                      </div>
                     
               
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                    Contact Number
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleContactno}
                          valueText={contactno}
                          inputName="contactno"
                          inputId="contactno"
                          classname="project-box"
                          maxLen={10}
                          inputType="text"
                          placeholderValue="Contact number"

                        ></InputTextField>
     </center>
                        <div className="login-error">{contactnoerror}</div>
                   
                      </div>
                     
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <label for="formFileLg" className="login-text">
                    Contact Email
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleContactmail}
                          valueText={contactmail}
                          inputName="contactmail"
                          inputId="contactmail"
                          classname="project-box"                          
                          inputType="text"
                          placeholderValue="Contact email"

                        ></InputTextField>

                        <div className="login-error">{contactmailerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-4 col-xl-4 col-md-6 pb-3">
        <label for="formFileLg" className="login-text">
          Upload Document
        </label>
        <input
          type="file"
          className="project-box"
          id="documentupload"
          name="documentupload"
          accept=".pdf"
          onChange={handleDocumentupload}
          ref={fileInputRef}
        />
       <center>  <div className="login-error pt-2">{documentuploaderror}</div></center>
      </div>
     
    {/* {selectedFiles.length > 0 && (
      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
        <label className="login-text">Selected Files</label>
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <button
                type="button"
                onClick={() => handleDeleteFile(index)}
                className="delete-button"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    )} */}

                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4">
                    {successstatus && 
            // !checkduedate &&
          
              <div class=" success pb-2">
                <center><b><h6>Created SuccessFully </h6></b></center>
                </div>
                }
                  {errormsg.length!=0 && 
         
          
              <div class=" login-error pb-2">
                <center><b><h6>{errormsg}</h6></b></center>
                </div>
                }
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                          disabled={!(companynameValid && resourcetypeValid && onboardingdateValid && contactpersonValid && contactmailValid && contactnoValid && documentuploadValid)}
                        >
                          <b>Submit</b>
                        </button>
                       
                      </center>{' '}
                     
                        
                    </div>
                  
                  </form>
                </div>
              
              </div>
            </div>
            </div>
              
              </div>
            </div>
    );
                          }
  
  export default FormPage;

