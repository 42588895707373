import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'

// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
// import DuedateModel from '../Modal/SetDueDate'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'

function FormPage(data) {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const userData = useSelector((state) => state.userDetails)
  const [level, setlevel] = useState('')
  const [levelValid, setlevelValid] = useState(false)
  const [levelerror, setlevelerror] = useState('')
  const [itemofwork, setItemofwork] = useState('')
  const [itemofworkValid, setItemofworkValid] = useState(false)
  const [itemofworkerror, setItemofworkerror] = useState('')
  const [structuretype, setStructuretype] = useState('')
  const [structuretypeValid, setStructuretypeValid] = useState(false)
  const [structuretypeerror, setStructuretypekerror] = useState('')
  const [date, setDate] = useState('')
  const [dateValid, setDateValid] = useState(false)
  const [dateerror, setDateerror] = useState('')
  const [owner, setOwner] = useState('')
  const [ownerValid, setOwnerValid] = useState(false)
  const [ownererror, setOwnererror] = useState('')
  const [requeststatus, setRequeststatus] = useState(false);
  const [successstatus, setSuccessstatus] = useState(false)
  const [strid, setStrid] = useState([])
  const [reslist1, setReslist1] = useState([])
  const [reslist2, setReslist2] = useState([])
  const [itemofworklist, setItemofworklist] = useState([])
  const [structurelist, setStructurelist] = useState([])
  const [checkboxval, setCheckboxval] = useState(false);
  const [checklist, setChecklist] = useState([])
  const [checkduedate, setCheckduedate] = useState(false);

  const [checkboxes, setCheckboxes] = useState(Array(reslist1.length).fill(false));
  const [checkboxErrors, setCheckboxErrors] = useState(Array(reslist1.length).fill(''));
const [strucliststatus,setStrucliststatus]= useState(false);
const [projectstatus, setProjectstatus] = useState('')
const [projectstatusValid, setProjectstatusValid] = useState(false)
const [projectstatuserror, setProjectstatuserror] = useState('')

const leveldata=data.data
useEffect(() => {

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    // axios
    //   .get(baseurl+'/api/plan/list/structure_items&work_items?project_id='+userData.currentprojectId, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

    //   .then((response) => {

    //     const dat = response.data
    //     //console.log(response.data)

    //     const itemwork = dat.item_of_works

    //     // setItemofworklist(itemwork)
    //     const modifiedList = itemwork.map(item => item.replace(/ /g, '_'));
    //     setItemofworklist(modifiedList)
    //     const struc = dat.structure_types
    //     const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
    //     setStructurelist(modifiedList1)

    //   })
    //   .catch((error) => {
    //     //console.log(error)
    //     //setErrormsg(error.response.data.message)
    //   })

//       let fData = new FormData()
//       fData.append('project_id', userData.currentprojectId)
//       axios
//       .get(baseurl+'/api/plan/specifications/get/current_level?project_id=' + userData.currentprojectId, +fData, {
//         headers: { 'Content-Type': 'multipart/form-data' },
//       })
  
//         .then((response) => {
  
//   //console.log(response.data)
//           const dat = response.data
//    const lel=dat.current_progress_level
//   // setlevel(lel)
// // getStructuretype(lel)
//         })
//         .catch((error) => {
//           //console.log(error)
//           //setErrormsg(error.response.data.message)
//         })
    



  }, [successstatus,checkboxes,checklist,strucliststatus])
// function getStructuretype(levelval)
// {

//   if(!strucliststatus)
//   {
  
//     let fDatanew = new FormData()
//     fDatanew.append('project_id', userData.currentprojectId)
//     fDatanew.append('level', levelval)
//     axios
//       .get(baseurl + '/api/plan/structure_type?project_id=' + userData.currentprojectId+'&level='+levelval, +fDatanew, {
//         headers: { 'Content-Type': 'multipart/form-data' },
//       })
  
//       .then((response) => {
  
//         //console.log(response.data)
//         const dat = response.data
//         const struc = dat.structure_types      
//         setStructurelist(struc)
//         setStrucliststatus(true)
  
//       })
//       .catch((error) => {
//         //console.log(error)
//         //setErrormsg(error.response.data.message)
//       })
//   }
// }
  function handlelevel(e) {
    //console.log(e.target.value)
    setlevel(e.target.value)
    validateField(e.target.name, e.target.value)
////////alert(e.target.value)
    // setStructuretype('ALL')
     const leveldata = e.target.value
    // let fData = new FormData()
    // fData.append('project_id', userData.currentprojectId)
    // fData.append('level', leveldata)
    // axios
    //   .get(baseurl + '/api/plan/list/item_of_work?project_id=' + userData.currentprojectId + '&level=' + leveldata, +fData, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   })

    //   .then((response) => {

    //     //console.log(response.data)
    //     const dat = response.data
    //     // const struc = dat.structure_types
    //     // // const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
    //     // //console.log(struc)
    //     // setStructurelist(struc)
    //     setItemofworklist(dat)
    //   })
    //   .catch((error) => {
    //     //console.log(error)
    //     //setErrormsg(error.response.data.message)
    //   })
    axios({
      method: 'GET',

      url: baseurl+'/api/plan/specifications/get/item_of_work_progress?project_id='+userData.currentprojectId+'&level='+leveldata,

      headers: {
        'Content-Type': 'multipart/form-data',
      }

    })
      .then((response) => {
        //console.log(response.data)
      const data=response.data
     // ////////alert(JSON.stringify(data))
      // //console.log(JSON.stringify(data))
      const itemofwork=data.item_of_works
   //   ////////alert(JSON.stringify(itemofwork))
       setItemofworklist(itemofwork)
      })
      .catch((error) => {
        //console.log(error)
        // setErrormsg(error.response.data.message)
      })
    ////////////////////////////level based multiBargraph axios///////////////////////

  }
  function handleitemofwork(e) {
    ////////alert(e.target.value)
    //console.log(e.target.value)
    const itemwk = e.target.value
    ////////alert(itemwk)
    setItemofwork(e.target.value)
    validateField(e.target.name, e.target.value)
  }
  function handlestructuretype(e) {
   
    //console.log(e.target.value)
    setStructuretype(e.target.value.replace(/\s+/g, ''))
   
   
    validateField(e.target.name, e.target.value)
  }
  function handledate(e) {
    //console.log(e.target.value)
    setDate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleOwner(e) {
    //console.log(e.target.value)
    setOwner(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  

  }
  function handleProjectstatus(e) {
 
    //console.log(e.target.value)
    ////////alert(userData.currentprojectId)
     setProjectstatus(e.target.value.replace(/\s+/g, ''))
     validateField(e.target.name, e.target.value)
     ////////alert(e.target.value)
   }

  //    function handleCheckboxChange(e,item) {
  //     //console.log(e.target.value)
  //      setCheckboxval(e.target.value.replace(/\s+/g, ''))
  //      validateField(e.target.name, e.target.value)
  //    }
  const validateField = (fieldName, value) => {
    if (fieldName === 'itemofwork') {

      if (value != 'Select') {
        setItemofworkerror('')
        setItemofworkValid(true)
      } else {
        setItemofworkerror('Please select')
        setItemofworkValid(false)
      }
    }
    if (fieldName === 'structuretype') {

      if (value != 'Select') {
        setStructuretypekerror('')
        setStructuretypeValid(true)
      } else {
        setStructuretypekerror('Please select')
        setStructuretypeValid(false)
      }
    }
    if (fieldName === 'owner') {

      if (value != 'Select') {
        setOwnererror('')
        setOwnerValid(true)
      } else {
        setOwnererror('Please select')
        setOwnerValid(false)
      }
    }
    if (fieldName === 'date') {

      if (value != '') {
        setDateerror('')
        setDateValid(true)
      } else {
        setDateerror('Please select')
        setDateValid(false)
      }
    }
  }


  function onSubmitEvent(event) {
    event.preventDefault()
    setSuccessstatus(false)
   
    

    // if (itemofworkValid && structuretypeValid && dateValid && ownerValid) {

    // const d = date;
    // const y = 'f0'
    // const currentDate = new Date(d)
    // const day = String(currentDate.getDate()).padStart(2, '0')
    // const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    // const year = currentDate.getFullYear()
    // const currentDateString1 = `${day}-${month}-${year}`

//     let fmData5 = new FormData()
//     fmData5.append('project_id', userData.currentprojectId)
//     fmData5.append('item_of_work', 'ALL')
//     fmData5.append('structure_type', structuretype)
//     fmData5.append('due_date', null)
//     fmData5.append('owner', owner)
//     fmData5.append('level', level)
 ////////alert(itemofwork)
////////alert(projectstatus)
    axios
      .get(
       baseurl+'/api/plan/specifications/get/search/details/item_of_work?project_id=' + userData.currentprojectId +'&item_of_work=' +itemofwork +'&structure_item_completed_status='+projectstatus 
        // baseurl + '/api/plan/specifications/get/search/details/item_of_work?project_id=' + userData.currentprojectId +
        // '&item_of_work='  +itemofwork + '&structure_item_completion_status=' +projectstatus 
        , {
        headers: { 'Content-Type': 'multipart/form-data' },
        // data: fmData5,
      })

      .then((response) => {
        //console.log(response.data)
        
         ////////alert("Run")
        const data1 = response.data
        //console.log("search",data1)
        setReslist1(data1)
        //console.log(data1)
        // const structid =data1.structure_ids
        
        // setStrid(structid)
        setSuccessstatus(true)
      })
      .catch((error) => {
        //console.log(error)
        //setErrormsg(error.response.data.message)
      })


    //}


  }

  return (
    <>

      {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
      {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}
    
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 pt-5  ">
            <form
                class="row d-flex justify-content-center align-center  blacktext "

              >
                      <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Chainage 
            </label>
            <select
              className="project-box"
              id="level"
              name="level"
              defaultValue={level}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={level}
              onChange={handlelevel}
            >
              <option value={null} selected>
                Select
              </option>
              {/* <option value="0">Project</option>
                          <option value="1">Payment</option> */}
              {leveldata.map((item, index) => (
                <option key={index} value={item.floor.replace(/_/g, ' ')}>
                  {item.floor.replace(/_/g, ' ')}
                </option>
              ))}
            </select>
            <div className="login-error"></div>
          </div>
                <div class="col-12 col-sm-12  col-md-6 col-lg-6 col-md-6 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast"
                  >
                    Status
                  </label>
                  <select
                    className="project-box"  
                     id="projectstatus"
                     name="projectstatus"
                    //  defaultValue={companyname}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={projectstatus}
                 onChange={handleProjectstatus}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                    <option value="0">All</option>
                    <option value="1">InProgress</option>
                    <option value="2">Delayed</option>
                    <option value="3">Done</option>
                    <option value="4">Not Yet Started</option>
           
                  </select>
                  <div className="login-error">{projectstatuserror}</div>
                </div>
                  <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
              Item of Work
            </label>
            <select
              className="project-box"
              id="itemofwork"
              name="itemofwork"
              defaultValue={itemofwork}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={itemofwork}
              onChange={handleitemofwork}
            >
              <option value={null} selected>
                Select
              </option>
            
              {itemofworklist.map((item, index) => (
                <option key={index} value={item}>
                  {item.replace(/_/g, ' ')}
                </option>
              ))}
            </select>
            <div className="login-error"></div>
          </div>
                <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 ">
                  <center>
                    <button
                      type="submit"
                      className="project-btn"
                      name="submit"
                      onClick={onSubmitEvent}
                    // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
                    //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                    // disabled={!(itemofworkValid && structuretypeValid && dateValid && ownerValid)}
                    >
                      <b>Search</b>
                    </button>
                  </center>{' '}
                </div>

              </form>
              <div >
                <div class="row">
                  <div class=" col-xl-8 col-lg-8 col-md-8 pt-1 pb-1">

                  </div>
                  <div class=" col-xl-3 pt-1 pb-1 ">
                  
                  </div>
                  <div class=" col-xl-1 pt-1 pb-1">

                  </div>
                </div>
                
                {successstatus && reslist1.length != 0 && 
                  // !checkduedate &&
                  <div className='table1bdstyle '>
                    <div class="row table1st">

                      <table
                        class="table"
                        readOnly="readOnly"

                      >

                        <tr class="trst">
                          <th><center>S.NO</center></th>
                          <th><center>Structure Id</center></th>
                          <th><center>Item of Work</center></th>                     
                          <th><center>Quantities Used</center></th>
                          <th><center>Quantities Pending</center></th>
                          <th><center>No Of Structures Completed</center></th>
                          <th><center>No Of Structures Pending</center></th>
                        </tr>
                            
                        { reslist1.length != 0  && 
                         reslist1.map((items1, index) => {
                          
                          return (
                            <tr> 
                            {/* // className='tablefontsize'> */}
                              <td> 
                               
                             <center>{index+1}</center> 
                               </td>
                            
                             
                              <td><center>{items1.structure_id}</center></td>
                              <td><center> {items1.item_of_works==null ? "null":items1.item_of_works.join(', ')}</center> </td>
                              <td><center>{items1.quantities_used == null ? "null":items1.quantities_used}</center></td>
                              <td><center>{items1.quantities_pending == null ? "null": items1.quantities_pending}</center></td>
                              <td><center>{items1.no_of_structures_completed == null ? "null": items1.no_of_structures_complete}</center></td>
                              <td><center>{items1.no_of_structures_pending == null ? "null": items1.no_of_structures_pending}</center></td>
                            </tr>
                          )
                        })
                        
                        
                        
                        }
                      </table>

                    </div>

                  </div>}
                {/*  */}
              
              
          
              </div>
            </div>


 




    </>
  )
}

export default FormPage
