import React, { useState, useEffect } from 'react';
import Resourcemanagementline from '../ApexCharts/Resourcemanagementline';
import ResourceReceivedgraph from '../ApexCharts/ResourceReceivedgraph';
import ResourceInstockgraph from '../ApexCharts/ResourceInstockgraph';

import Graphresourcemgmt from '../ApexCharts/Resourcebar';
import sandcorrect from '../../assets/images/buttons/sandcorrect.png';
import brickcorrect from '../../assets/images/buttons/brickcorrect.png';
import stonescorrect from '../../assets/images/buttons/stonescorrect.png';
import ironrodcorrect from '../../assets/images/buttons/ironrodcorrect.png';
import cementcorrect from '../../assets/images/buttons/cementcorrect.png';
import ResourceSearch from '../RoadResourceSearch/Search';
import Equipmentmgmt from '../RoadEquipmentMgnt/RoadEquipment';
import WorkerMgmt from '../RoadWorkerMgnt/RoadWorkerMgnt'
import MaterialMgmt from '../RoadMaterialMgmt/RoadMaterialMgmt'

import axios from 'axios';
import './ResourceMgnt.css';
import $ from 'jquery';
import { baseurl } from '../BaseUrl/Baseurl';
import { Tab, Tabs } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import MaterialRquest from '../Modal/MaterialRequest'
import DocumentView from '../Modal/DocumentView'
import { FaPlus, FaEdit, FaStarAndCrescent } from "react-icons/fa";
const WeareHere = () => {
  
const [dataclear, setDataclear] = useState('material');


  const handleTabSelect = (key) => {
    setDataclear(key)
    
   // console.log(`Tab selected: ${key}`);
   

  };




  
    

  return (
 
    <div className="col-lg-12 col-md-12 col-xl-12 toppagespaceroad ">
      {/* <center>

    <Tabs defaultActiveKey="material" id="sectionTabs"  className="custom-tabs tabstyle tabmargintop pt-0 pb-1 "       
        onSelect={handleTabSelect} 
>
        <Tab eventKey="material" title="Material Management">
        {dataclear === "material" && (

<MaterialMgmt />

        )}
          </Tab>
          <br></br>
        
  
    <Tab eventKey="equipment" title="Equipment Management">
    {dataclear === "equipment" && (
      <Equipmentmgmt />
      )}

    </Tab>


          <Tab eventKey="addWorker" title="Worker Management">
          {dataclear === "addWorker" && (
          <WorkerMgmt/>
          )}
          </Tab>
       
        </Tabs>
        </center> */}
      
    </div>
  );
};

export default WeareHere;
