import React from "react";
import logo1 from "../../assets/brickdemoicons/brick_logo_f.png";
import "./NewHeader.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { Outlet, Link, useLocation } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import { useState, useEffect } from "react";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import ButtonLink from "../ButtonLink/ButtonLink";
import { VscHome } from "react-icons/vsc";
import { PiNotepad } from "react-icons/pi";

export default function ContractorHeader() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationnumber, setNotificationnumber] = useState("");
  const [notificationmessage, setNotificationmessage] = useState("");
  const [notificationrequestedfor, setNotificationrequestedfor] = useState("");
  const [notificationdate, setNotificationdate] = useState("");
  const [notificationstate, setNotificationstate] = useState(false);
  const [status, setStatus] = useState(false);
  const [headMenuList, setHeadMenuList] = useState([]);
  const [activeMenu, setActiveMenu] = useState("");
  const [logoutstatus, setLogoutstatus] = useState(false);
  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const [request, setRequest] = useState("");
  const [active, setActive] = useState("Siteupdates");

  const handleItemClick = (item) => {
    setActive(item);
  };

  const backtoproject = () => {
    if (userData.chainageArr && Array.isArray(userData.chainageArr)) {
      userData.chainageArr.splice(0, userData.chainageArr.length);
    }
  
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: userData.Pagename,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: userData.projectId,
        companyName: userData.companyName,
        clientId: userData.clientId,
        projectName: userData.projectName,
        projectMenuIndex: userData.projectMenuIndex,
        profileImage: userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: userData.projectMenus,
        sidebarReportsMenus: userData.sidebarReportsMenus,
        sidebarWorkDetailsMenus: userData.sidebarWorkDetailsMenus,
        dropdownViewStatus: userData.dropdownViewStatus,
        chainageArr: [],
        projectStatus: true,
        projectsubname: 'Dashboard',
        loginUsername: userData.loginUsername,
        engineerLoginStatus: false,
        searchParams: userData.searchParams,
        statename: userData.statename,
        circle: userData.circle,
        division: userData.division,
        subdivision: userData.subdivision,
      })
    );
    
    setActive('Siteupdates')
  };
  

  function notiupdate(projectmenu, id, message, resourcemenu, notitype) {
    setRequest(message);

    if (notitype === "markoneasread") {
      if (message === "Site Update") {
        dispatch(
          updateLoginStatus({
            isLoggedIn: true,
            Pagename: "Projects",
            userName: userData.userName,
            userId: userData.userId,
            role: userData.role,
            token: userData.token,
            projectId: userData.projectId,
            clientId: userData.clientId,
            currentprojectId: id,
            dropdownOption: projectmenu,
            projectType: userData.projectType,
            companyName: userData.companyName,
            projectName: userData.projectName,
            projectMenuIndex: 7,
            resourceMenuKey: "material",
            modalNotificationstatus: true,
            profileImage: userData.profileImage,
            headerMenus: userData.headerMenus,
            projectMenus: userData.projectMenus,
            sidebartoggleStatus: userData.sidebartoggleStatus,
            loginUsername: userData.loginUsername,
            engineerLoginStatus: false,
            subdivision:userData.subdivision

          })
        );
      } else {
        dispatch(
          updateLoginStatus({
            isLoggedIn: true,
            Pagename: "Dashboard",
            userName: userData.userName,
            userId: userData.userId,
            role: userData.role,
            token: userData.token,
            projectId: userData.projectId,
            clientId: userData.clientId,
            currentprojectId: id,
            dropdownOption: "Project Timeline",
            projectType: userData.projectType,
            companyName: userData.companyName,
            projectName: userData.projectName,
            projectMenuIndex: 0,
            resourceMenuKey: "material",
            modalNotificationstatus: true,
            profileImage: userData.profileImage,
            headerMenus: userData.headerMenus,
            projectMenus: userData.projectMenus,
            sidebartoggleStatus: userData.sidebartoggleStatus,
            loginUsername: userData.loginUsername,
            engineerLoginStatus: false,
            subdivision:userData.subdivision

          })
        );
      }
    }
  }

  console.log(userData.chainageArr);

  useEffect(() => {
    setHeadMenuList(userData.headerMenus);
    setActiveMenu(userData.headerMenus[0]);

   

    
  }, []);


  const [linkname, setLinkname] = useState("");

  function logoutfunc(e) {
    axios({
      method: "PUT",
      url: baseurl + "/api/logout?user_id=" + userData.userId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {})
      .catch((error) => {});
    dispatch(
      updateLoginStatus({
        isLoggedIn: false,
      })
    );
    localStorage.clear();
    window.location.href = "/";
  }


  const location = useLocation();


  return (
    <div className=" position-sticky" style={{ top: "0", zIndex: "999" }}>
      {userData.modalNotificationstatus && request === "Site Update" && (
        <ButtonLink btnPath="/Road" />
      )}
      {userData.modalNotificationstatus && request != "Site Update" && (
        <ButtonLink btnPath="/Dashboard" />
      )}
      <div className=" ">
        <nav
          className="navbar navbar-expand-xl  p-0"
          style={{ backgroundColor: "#07424F" }}
        >
          <div className="container-fluid d-flex justify-content-around">
            <div>
              <button
                className="navbar-toggler expandbtn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent1"
                aria-controls="navbarSupportedContent1"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <center className="padlh">
                  {" "}
                  <span className="navbar-toggler-icon"></span>
                </center>
              </button>
            </div>
            <div
              className="collapse navbar-collapse d-flex justify-content-between align-items-center p-2"
              id="navbarSupportedContent1"
            >
              <img
                src={logo1}
                width={130}
                className="rounded img-fluid"
                style={{ border: "1px solid #f6fa00" }}
              />

              <ul className="navbar-nav me-auto d-flex align-items-center  p-0">
                <li>
                  <img src={userData.profileImage} width={55} className="  " />
                </li>
                <li className="ml-2">
                  <div style={{ width: "150px" }}>
                    <font className="text-white" style={{ fontSize: "14px" }}>
                      <b> {userData.userName}</b>{" "}
                    </font>
                    <br></br>
                    <font className="text-white" style={{ fontSize: "14px" }}>
                      {userData.role}
                    </font>
                  </div>
                </li>

                <li className="">
                  <center>
                    <Link
                      onClick={(e) => logoutfunc(e)}
                      className="btn d-inline-flex align-items-center text-white border"
                    >
                      <FiLogOut className="mr-1" />
                      Logout
                    </Link>
                  </center>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {(location.pathname === '/Siteupdates' || location.pathname === '/Completedworklist') && 
       ( <div className="shadow-sm bg-white">
          <ul
            className="d-flex justify-content-end align-items-center pb-0 mb-0 mr-5 "
            style={{ fontWeight: "500" }}
          >            
            <li
              onClick={() => handleItemClick("Siteupdates")}
              className={
                active === "Siteupdates"
                  ? "activetabs mr-4 nav-item"
                  : "mr-4 nav-item"
              }
            >
              <Link className="nav-link text-dark" to="/Siteupdates">
                Site Updates
              </Link>
            </li>
            <li
              onClick={() => handleItemClick("Completedwork")}
              className={
                active === "Completedwork"
                  ? "activetabs mr-4 nav-item"
                  : "mr-4 nav-item"
              }
            >
              <Link className="nav-link text-dark d-inline-flex align-items-center" to="/Completedworklist">
                {/* <PiNotepad style={{fontSize:'18px'}}/> */}
                Completed Work
              </Link>
            </li>

            <li>
              <Link onClick={backtoproject } to='/ContractorProjectList' >Back to project</Link>
            </li>
          </ul>
        </div>)}
      </div>
    </div>
  );
}
