import React from 'react';
import RadialBar from '../ApexCharts/RadialBar';
import './Vendorlist.css';


import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaStarAndCrescent } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from 'react'
import RequestModal from '../Modal/CreateRequest'
import StatusupdateModal from '../Modal/AssignProjectVendor'
import ViewTickets from '../Modal/ViewTickets'
import { baseurl } from '../BaseUrl/Baseurl'
import completed from '../../assets/images/completed-5.png'
import inprogress from '../../assets/images/buttons/inprogress.png'
import  received from '../../assets/images/buttons/completed.png'
import delayed from '../../assets/images/buttons/delayed.png'
// import  cancelled from '../../assets/images/buttons/cancelled.png'
import sandcorrect from '../../assets/images/buttons/sandcorrect.png'
import brickcorrect from '../../assets/images/buttons/brickcorrect.png'
import stonescorrect from '../../assets/images/buttons/stonescorrect.png'
import ironrodcorrect from '../../assets/images/buttons/ironrodcorrect.png'
import cementcorrect from '../../assets/images/buttons/cementcorrect.png'
import ordered from '../../assets/images/buttons/ordered.png'
import sandhead from '../../assets/images/resources/sandhead.png'
import brick from '../../assets/images/resources/brick.png'
import cement from '../../assets/images/resources/cement.png'
import ironrod from '../../assets/images/resources/ironrod.png'
import sand from '../../assets/images/resources/sand.png'
import stones from '../../assets/images/resources/stones.png'
import tiles from '../../assets/images/resources/tiles.png'
import LineChart from '../ApexCharts/LineChart'
import LineChart1 from '../ApexCharts/LineChart1'
import ButtonLink from '../ButtonLink/ButtonLink';
// import PDF from '../Doc/SarasuPrivacyPolicy.pdf'
import DocModel from '../Modal/DocumentView'
import EditVendor from './EditVendor';
import $ from 'jquery';
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import DocumentView from '../Modal/ContractOrVendorDoc'
const WeareHere = () => {
  const [structureName , setStructureName ] = useState('')
  const [structureId, setStructureId] = useState('')
  const [vendorId , setVendorId] = useState('')
  const [vendorStatus , setVendorStatus ] = useState(false)
  const [vendorStatus1 , setVendorStatus1 ] = useState(false)
  const [vendorwork, setVendorwork] = useState('')
  const [itemName, setItemName] = useState('')
  const [compDate, setCompDate] = useState('')
  const [itemOfWork, setItemOfWork] = useState('')
  const [fulldata, setFulldata] = useState([]);
  const [btnname, setBtnname] = useState('')
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [caxis, setCaxis] = useState([]);
  const [daxis, setDaxis] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [graphform, setGraphform] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
 
  const [modalText, setModalText] = useState('');
  const userData = useSelector((state) => state.userDetails)
  const [modalStatus, setModalStatus] = useState(false);
  const [list, setList] = useState([]);
  const [imagelist, setImagelist] = useState('');
  const [contractorid, setContractorid] = useState('');
  const [docImage, setDocImage] = useState('');
  const [docurldownload, setDocurldownload] = useState('');
  const [successstatus, setSuccessstatus] = useState(false);
    
  
  useEffect(() => {
    // //console.log("hai1")
    // ////alert(userData.clientId)
      axios({
        method: 'GET',

        url: baseurl+'/api/vendor/list/get/vendor_details?client_id='+userData.clientId+'&contractor_type=Vendor',

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
      })

        .then((response) => {
         
          //console.log(response.data)
          const RequestData = response.data
          // setRequestdata1(RequestData)
          //console.log("request",RequestData)
         const dt=RequestData
         setList(dt)
        setSuccessstatus(true)
        })
        .catch((error) => {
          
          //console.log(error)
          // setErrormsg(error.response.data.message)
        })

        let fmData5 = new FormData()

      fmData5.append('project_id', userData.projectId)

    //console.log("useeffect")
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    // document.addEventListener("click", handleClick);
    // return () => {
    //   document.removeEventListener("click", handleClick);
    // };

    // document.addEventListener("click", handleClick1);
    // return () => {
    //   document.removeEventListener("click", handleClick1);
    // };
	
////////////////////////// api for first graph ////////////////////////////////

    

  
    // axios({
    //   method: 'GET',

    //   url: 'http://localhost:8080/api/support/issue/ticket/list',

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   }
     
    // })
    //   .then((response) => {
    //     //console.log(response.data)
    //     //console.log("result coming")
    //     const TicketData = response.data
    //     setTicketlist(TicketData)
    //   })
    //   .catch((error) => {
    //     //console.log(error)
    //     // setErrormsg(error.response.data.message)
    //   })

  }, [])
 function  Editvendor1(vendorID)
 {

     setVendorId(vendorID)
      setVendorStatus(true)

 }

 function  getVendorId(vendorid)
 {

  setVendorId(vendorid)
      setVendorStatus1(true)

 }
 const RenewalUpdate = (docurl,docimage) => {

  setDocImage(docimage)
  setDocurldownload(docurl)
        setModalStatus(true)
  
};
const toggleRenewalStatus = () => {
  setModalStatus(false);
};
const Editdata = (id) => {

  //////alert("Before"+updatestatus)
  setContractorid(id)
  setUpdatestatus(true)

  //////alert("After"+updatestatus)
}
const toggleModelStatus1 = () => {

  setUpdatestatus(false)

  //////alert("status false is running")
};
  return (
    <> {vendorStatus && <ButtonLink btnPath="/EditVendor" vendorID={vendorId}/>}
   
   {vendorStatus1 && <ButtonLink btnPath="/ViewVendorProjects" vendorID={vendorId}/>}
    <div className="cont-margin1">
    <div class="cont-margin-latest pt-1 ">
    <div className='table1bdstyle'>
          <div class="row table1st">
          {/* <div class="pt-1 pb-1 col-xl-6 col-lg-6">
                <p><b>Overallvendorlist</b></p>
                </div>
                <div class="pt-1 pb-1 col-xl-6 col-lg-6">
                <Link to ='/ContractorCreation'>
                <button className='view4'>Create</button></Link>
           

                     


              </div> */}
                 <div class=" col-xl-4 pt-1 pb-1 ">
              <div className='padtext1'>  <h5 ><b>Vendors</b></h5></div>
              </div>
              <div class=" col-xl-5 pt-1 pb-1">
              
              </div>
              <div class=" col-xl-3 pt-1 pb-1 ">

            <Link to="/VendorCreation"> 
            <button 
                    type="button"
                    className="createpro-btn"
                  >
                   &nbsp;Create</button>
            </Link> 
              </div>
              <div class=" col-xl-1 pt-1 pb-1">
              
              </div>
              { list.length == 0 && successstatus &&
            // !checkduedate &&
           
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pt-3 pb-3">
                <center><b><h6>No Data Available</h6></b></center>
              
                </div>}
                { list.length !== 0 &&   

              <div className='tableContainer1' >
            <table
              class="table"
              readOnly="readOnly"
           

            >
              <tr class ='trst'>
                
                <th>Company Name</th>
                <th>On Boarding Date</th>
                <th>Resource Type</th>
                <th> Contact Person</th>
                <th>Contact Number</th>
                <th>Contact Mail Id</th>
                <th>Assign Project</th>
                <th>View Project</th>
                <th>Documents</th>
                <th>Edit</th>
                <th>Rating</th>

              </tr>
              {/* {vendorStatus && (
                <EditVendor
                  status={vendorStatus}
                  vendornames={vendorName}
                  vendorworks={vendorwork}
                />
              )} */}
               {modalStatus && (
                <DocumentView
                  status={modalStatus}
                  btnClick={toggleRenewalStatus}
                  imageData={docImage}
                  imageUrl={docurldownload}
            
                />
              )}
          {updatestatus &&
                <StatusupdateModal status={updatestatus} btnClick={toggleModelStatus1} contractorid={contractorid} />}     
              {list.length!=0 && list.map((dt,index) =>{
                const resource=dt.type_of_resource ||[]
              
                return(
                  <tr key ={index} className='tablefontsize'>
                 
                    <td className='fontcolor fontsize'>{dt.vendor_company_name!=undefined?dt.vendor_company_name:'NA'}<b></b></td>
                 
                    <td className='fontcolor1 fontsize1'>{dt.onboarding_date!=undefined?dt.onboarding_date:'NA'}</td>
                  
                    <td className='fontcolor fontsize'>{resource!=undefined && resource.length!=0 ?resource.join(', '):'NA'}</td>
                  
                    <td className='fontcolor1 fontsize1'>{dt.contact_person!=undefined?dt.contact_person:'NA'}</td>
                    {/* <td>{(dt.project_status === 'completed'? <img src = {completed} alt='completed' height={30} width={90} />:<img src = {inprogress} alt='completed' height={30} width={90} />)}</td> */}
                    <td className='fontcolor1 fontsize1'>{dt.phone_no!=undefined?dt.phone_no:'NA'}</td>
                    <td className='fontcolor1 fontsize1'>{dt.email!=undefined?dt.email:'NA'}</td>
                    <td><button
                      onClick={() => Editdata(dt.contractor_id)}
                      type="button"
                      className="modalbtn"
                    ><FaEdit /></button>

                    </td>
                    <td><center> <Link ><button
                      type="button"
                      className="viewnew"
                      onClick={() => getVendorId(dt.contractor_id)}
                    >
                      <b>View</b>
                    </button> </Link></center></td>
                    <td className='fontcolor1 fontsize1'>
                        <center>
                       { dt.contract_document_image!='NA'?
                          <button
                            type="button"
                             className="modalbutton"
                            onClick={() => RenewalUpdate(dt.contract_document_url,dt.contract_document_image)}
                            // disabled={ dt.contract_document_image!='NA'
                            //   ? false:true
                            
                            // }
                          >
                            
                            Document
                            
                          </button>:'NA'}
                        </center>
                      </td>
                      <td>
                        <center>
                          <button
                            type="button"
                            className="modalbtn"
                            onClick={() => Editvendor1(dt.contractor_id)}
                          >
                            <b>
                              <FaEdit />
                            </b>
                          </button>
                        </center>
                      </td>
                      <td>{dt.rating!=undefined?dt.rating:'NA'}</td>
                      
                  </tr>
                )
               })
              }
         
              
{/*               
              {viewticketstatus &&
                <ViewTickets status={viewticketstatus} btnClick={toggleViewTicketStatus} TicketNumber={ticketno}/>}
           {ticketlist.length != 0 && ticketlist.map((item, index) => {
                return (
                  <tr key={index}>

                    <td>{item.project_id}</td>
                    <td>{item.contact_person}</td>
                    <td>{item.ticket_number}</td>
                    <td>  <button
                      type="button"
                      className="project-btn1"
                      onClick={() => ViewticketstatusUpdate(item.ticket_number)}
                    >
                      <b>View Tickets</b>
                    </button></td>

                  </tr>
                )
              })} */}

            </table>
            </div>}
          </div>
          {/* <div class="modal fade pt-5" id="myModal">
            <div class="modal-dialog modal-md ">
              <button
                type="button"
                className="demobtn2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x"></i>
              </button>
              <div class="modal-content">
                <div class="modal-header">
                  <div className="header-text">
                    <b>{btnname}</b>
                  </div>
                </div>

              </div>
              <div className="modal-body">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-12 pt-3 pb-3 ">
                    <ul class="list-unstyled">
                      {btnname === 'Edit' && (
                        <>
                        
                          <h1>Hello</h1>
                        </>
                      )}


                    </ul>
                  </div>
                </div>
              
              </div>
            </div>
          </div> */}
        

          
        </div>
        </div>

        </div>

        </>
);
};

export default WeareHere;